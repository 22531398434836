import { Grid, Stack } from '@mui/material';
import { AddIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';

interface WalletsListHeaderProps {
    refresh: () => void; 
    addWallet: () => void;
}

export const WalletsListHeader: React.FC<WalletsListHeaderProps> = ({ refresh, addWallet }) => {
    const { isRootTenancy } = useTenant();
    const { hasPermission } = useUserPermissionData(); 
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Carteiras
                </Typography>
            </Grid>

            <Actions
                buttonsActionsList={[
                    {
                        enable: true,
                        label: "Atualizar",
                        action: refresh,
                        disabled: false,
                        icon: <UpdateIcon sx={iconSx} />
                    }, 
                    {
                        enable: hasPermission('BillingWallet', 'Create') && isRootTenancy,
                        label: "Adicionar carteira",
                        action: addWallet,
                        disabled: false,
                        icon: <AddIcon sx={iconSx} />
                    }, 
                ]}
                numberOfButtons={4}
            />
        </Stack>
    );
};