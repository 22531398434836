import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useIdentity } from './identityContext';
import {
    editOrCreateNaturalPerson,
    getBeneficiariesList,
    getNaturalPersonById,
    getNaturalPersonsList,
    postRegulateRegistrationNumber,
    postRequestNaturalPersonDatasetReserveToken,
    NaturalPersonFiltersProps,
    IGetTokensQuery,
    NaturalPersonReadModel,
    IReserveTokensRegistered,
    ReserveTokenCreateModel,
    postOpenBankAccountByPersonId,
} from 'services/accounts/naturalPerson';
import { ApiResponseError, GetListApiResponseSuccess, useApiRequest } from './apiRequestContext';
import { useTenant } from './tenantContext';
import { NaturalPersonSchema } from 'pages/NaturalPerson/NaturalPersonForm/NaturalPersonSchema';
import { useFilterValues } from './filterValuesContext';
import { useUserPermissionData } from './userContext';
import { isEmpty } from 'lodash';
import { getDatasetAvailableReserveToken, getDatasetOnlyTokensByPersonID } from 'services/datasets/datasets';
import { checkActionsPerms } from 'services/permissions';
import { handleErrorUseQuery } from 'helpers';
import { useState } from 'react';
import { SelectOption } from 'components/Forms/FormFields';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { RelatedBankAccountType } from './relatedBankAccountContext';

export function useNaturalPersonData(personId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('NaturalPerson', 'Read');

    const { data, error, status, refetch, isFetching, isLoading } = useQuery({
        enabled: !!token && personId !== 'nova' && !isEmpty(personId) && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-naturalPerson-data', personId],
        queryFn: async () => {
            startRequest();
            if (personId === 'nova') return;
            const resp = await getNaturalPersonById(personId, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data;
        },
    });

    return {
        naturalPersonData: data as NaturalPersonReadModel,
        naturalPersonError: error,
        naturalPersonStatus: status,
        refetch,
        isFetching,
        isLoading,
    };
}

export function useBankDetailsListBeneficiaries(personId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();
    const { selectedTenant } = useTenant();

    const { data, error, status, refetch } = useQuery({
        enabled: !!token && personId !== 'nova',
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['beneficiaries-list', personId, selectedTenant],
        queryFn: async () => {
            startRequest();
            const resp = await getBeneficiariesList(personId!, token!, {
                tenant: JSON.stringify(selectedTenant?.value),
            });
            const { data } = resp;
            endRequest(true);
            return data;
        },
    });

    return { data, status, error, refetch };
}

export function useNaturalPersonList(filters: NaturalPersonFiltersProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'NaturalPerson' : filterValues.recordType;
    const listDataFilters = recordType === 'NaturalPerson' && { ...filterValues.filters };
    const filtersComplete = { tenant: currentTenantId, ...filters, ...listDataFilters };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('NaturalPerson', 'Read');
    const [naturalPersonList, setNaturalPersonList] = useState<SelectOption[]>([]);

    const queryContext = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['natural-list', filtersComplete, filterValues],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getNaturalPersonsList(filtersComplete, token!);
                endRequest(true);

                const dataSucess = data as GetListApiResponseSuccess<NaturalPersonReadModel>;
                const operatorList = dataSucess.data.map((n) => {
                    return {
                        label: `${n?.name} ${
                            n.registrationNumber
                                ? `- (${formatDocumentNumber(n.registrationNumber)})`
                                : ''
                        }`,
                        value: n?.id,
                    };
                });

                setNaturalPersonList(operatorList);
                return dataSucess;
            } catch (err) {
                endRequest(true);
                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    const autocompleteNaturalPerson = {
        listOptions: naturalPersonList,
        loading: queryContext.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'NaturalPerson'),
    };

    return {
        ...queryContext,
        autocompleteNaturalPerson,
    };
}

export function useGetTokenRegisteredByPersonId(personId: string | undefined, queryKey?: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { data } = useUserPermissionData();
    const hasPermissionReadDatast = checkActionsPerms('ReadDataSet', data, 'NaturalPerson');

    const queryContext = useQuery({
        enabled: !!token && !!personId && hasPermissionReadDatast,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-dataset-tokens-by-person-id', personId, queryKey],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getDatasetOnlyTokensByPersonID(
                personId!,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as IReserveTokensRegistered[];
        },
    });

    return { ...queryContext };
}

export function useGetAvailableReserveTokenByPersonId(
    personId: string,
    isNaturalPerson?: boolean,
    queryKey?: string
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { data } = useUserPermissionData();
    const hasPermissionReadDatast = checkActionsPerms('ReadDataSet', data, 'NaturalPerson');

    const queryContext = useQuery({
        enabled: !!token && !!personId && hasPermissionReadDatast && !!isNaturalPerson,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-dataset-available-reserve-by-person-id', personId, queryKey],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getDatasetAvailableReserveToken(
                personId!,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            const query = data as IReserveTokensRegistered[];
            const queryMapped = query?.map(({ result, createdAt, createdBy, status }) => {
                return {
                    ...result,
                    createdAt,
                    createdBy,
                    status,
                    option: {
                        label: `${result?.WarrantyRegistrationOfficeDisplay} - ${result.Token.TokenTypeDisplay} - ${result.Token.TokenValue}`,
                        value: result.Token.TokenValue,
                    },
                } as IGetTokensQuery;
            });
            return queryMapped as IGetTokensQuery[];
        },
    });

    return { ...queryContext };
}

export function useNaturalPersonMutation(
    personId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void, 
    returnType: "Id" |"Full" = "Id"
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: NaturalPersonSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateNaturalPerson(
                values,
                personId,
                token,
                currentTenantId,
                returnType
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}

export function useDatasetReserveToken(
    personId?: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (payload: ReserveTokenCreateModel[]) => {
            startRequest();
            const { data, status, statusText } = await postRequestNaturalPersonDatasetReserveToken(
                personId!,
                payload,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}

export function useRegulateRegistratioNumberMutate(
    installmentId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async () => {
            startRequest();
            const { data } = await postRegulateRegistrationNumber(installmentId, token!);
            endRequest(true);
            endRequest(true);
            return data as unknown;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useOpenBankAccountByNaturalPersonMutate(
    onSuccess: (data: RelatedBankAccountType) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (personId: string) => {
            startRequest();
            const { data } = await postOpenBankAccountByPersonId(personId, token!);
            endRequest(true);
            endRequest(true);
            return data as RelatedBankAccountType;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        }
    });
}

export type NaturalPersonCreateResponse = NaturalPersonCreateSuccessResponse | ApiResponseError;

export type NaturalPersonCreateSuccessResponse = {
    type: 'success';
    id: string;
    enabled: boolean;
    userStatus: string;
};
