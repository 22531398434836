import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { PayrollAgreementsList } from './PayrollAgreementsList';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { PayrollAgreementsListHeader } from './PayrollAgreementsListHeader';
import { Drawer } from '@uy3/web-components';
import {
    defaultValuesAddNewGovernmentDepartment,
    validationSchemaAddNewGovernmentDepartment,
} from './AddNewPayrollAgreements/AddNewPayrollAgreementsSchema';
import { AddNewPayrollAgreementsForm } from './AddNewPayrollAgreements/AddNewPayrollAgreementsForm';
import { FieldValues } from 'react-hook-form';
import { PayrollAgreementReadModel } from 'services/creditNote';
import { removeDuplicateData } from 'helpers';
import { showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { useGovernmentDepartment } from 'contexts/governmentDepartmentContext';

export const PayrollAgreementsListContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [handleAction, setHandleAction] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onChangePage = (page: number) => setPage(page);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };
    const onClose = () => setHandleAction(undefined);

    const { watch, setValue } = useFormContext();
    const fieldName = 'governmentDepartments';
    const governmentDepartments = (watch(fieldName) as PayrollAgreementReadModel[]) ?? [];

    const { governmentDepartmentAutocomplete, data: payrollAgreementsData } =
        useGovernmentDepartment();

    const handleCreatePayrollAgreements = (values: FieldValues) => {
        const governmentDepartmentIds = values?.governmentDepartmentIds;
        const findPayroll = payrollAgreementsData?.data?.filter((x) =>
            governmentDepartmentIds.includes(x.id)
        );

        if (governmentDepartments.length > 0) {
            let currentList = governmentDepartments;
            findPayroll?.forEach((item) => currentList.push(item));
            const valuesNotDuplicateValue = removeDuplicateData(currentList, 'id');
            setValue(fieldName, valuesNotDuplicateValue);
            setValue(
                'governmentDepartmentIds',
                valuesNotDuplicateValue.map((x) => x.id)
            );
        } else {
            setValue(fieldName, findPayroll);
            setValue('governmentDepartmentIds', governmentDepartmentIds);
        }

        showSuccessToast("Órgão adicionado com sucesso", "", setToast);
        onClose();
    };

    const handlePayrollAgreements = (index: number) => {
        const listItemRemoved = governmentDepartments?.filter(
            (_, currentIndex) => index !== currentIndex
        );
        setValue(fieldName, listItemRemoved);
        showSuccessToast("Sucesso ao remover órgão", "", setToast);
    };

    return (
        <Stack spacing={2}>
            <Toast toast={toast} setToast={setToast} />
            <PayrollAgreementsListHeader handleAddNew={() => setHandleAction('create')} />

            <PayrollAgreementsList
                handleDeleteItem={handlePayrollAgreements}
                queryData={governmentDepartments}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
            />

            <Drawer
                open={handleAction === 'create'}
                anchor="right"
                title="Adicionar órgão"
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={validationSchemaAddNewGovernmentDepartment()}
                    defaultValues={defaultValuesAddNewGovernmentDepartment}
                    onSubmit={handleCreatePayrollAgreements}
                >
                    <AddNewPayrollAgreementsForm
                        onClose={onClose}
                        governmentDepartmentAutocomplete={governmentDepartmentAutocomplete}
                    />
                </FormProvider>
            </Drawer>
        </Stack>
    );
};
