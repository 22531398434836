import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { IConciliacaoLiquidacaoCaixa, IRemessaLiquidacaoForDate, IRemessaLiquidacaoForOp } from "./remessasVortx.type";
import { AxiosRequestConfig } from "axios";

let url = "/Reconciliation";
let urlVortx = "/Vortx";

const TIMEOUT_IN_MILLISECONDS = 60000; // 1 minuto

export const conciliacaoLiquidacaoCaixa = async (dataRequest: IConciliacaoLiquidacaoCaixa, token: string) => {
  const bodyFormData = new FormData();
  const payload = dataRequest.payload;

  for (let key in payload) {
    //@ts-ignore
    let currentData = payload[key];
    if (currentData !== null) {
      bodyFormData.append(key, currentData);
    }
  }

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    params: {
      persist: dataRequest.params.persist,
      queryCaixa: dataRequest.params.queryCaixa
    },
    timeout: TIMEOUT_IN_MILLISECONDS
  } as AxiosRequestConfig;

  return await creditAxiosApi.post(`${url}/Caixa`, bodyFormData, config);
};

export const remessaLiquidacaoForDate = async (params: IRemessaLiquidacaoForDate, token: string) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: params,
    timeout: TIMEOUT_IN_MILLISECONDS
  } as AxiosRequestConfig;

  return await creditAxiosApi.post(`${url}/LiquidationFile`, {}, config);
};

export const getRemessaLiquidacaoForOp = async (params: IRemessaLiquidacaoForOp, token: string) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: params,
    timeout: TIMEOUT_IN_MILLISECONDS
  } as AxiosRequestConfig;

  return await creditAxiosApi.get(`${url}/CalculateLiquidationSchedule`, config);
};

export const postRemessaLiquidacaoForOp = async (params: IRemessaLiquidacaoForOp, token: string) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: {
      incluirVencidos: params.incluirVencidos,
      liquidateByValue: params.liquidateByValue,
      opId: params.opId,
      date: params.date
    },
    timeout: TIMEOUT_IN_MILLISECONDS
  } as AxiosRequestConfig;

  return await creditAxiosApi.post(`${url}/LiquidateCreditNoteOp`, {}, config);
};

export async function sendRemessaAsync(filePath: string, token: string) {

  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      filePath
    }, 
    timeout: TIMEOUT_IN_MILLISECONDS
  } as AxiosRequestConfig;

  return await creditAxiosApi.post(`${urlVortx}/SendRemessa`, {}, config);
}