import { BankAccountOperationType } from "contexts/apiRequestContext";

export const typeAccountsLegalPerson = [
  { label: 'Conta Escrow', value: `EscrowAccount` },
  { label: 'Conta Pagamento', value: 'NaturalSimpleAccount' },
  { label: 'Conta Corrente', value: `LegalCheckingAccount` },
  { label: 'Conta Poupança', value: `LegalSavingsAccount` }
];

export const typeAccountsNaturalPerson = [
  { label: 'Conta Pagamento', value: 'NaturalSimpleAccount' },
  { label: 'Conta Corrente', value: `NaturalCheckingAccount` },
  { label: 'Conta Poupança', value: `NaturalSavingsAccount` },
  { label: 'Conta Escrow', value: `EscrowAccount` }
]

export type BankAccountBalanceType = {
  bankAccount: any;
  available: number;
  currentBalance: number;
};

export type FiltersTableTransfer = {
  size: number;
  page: number;
  f_operationType: BankAccountOperationType;
  f_transferDate?: string,
  f_amount?: number,
  f_beneficiaryName?: string,
  f_beneficiaryRegistrationNumber?: string,
  f_status?: string,
  isDeleted?: boolean
}

export type FiltersBeneficiaries = {
  size: number;
  page: number;
  f_name?: string;
  f_registrationNumber?: string,
  f_status?: string,
}

export type StatementQueryFiltersType = {
  finalDate?: string;
  initialDate?: string;
};

export type BankAccountStatementType = {
  issueDate: Date;
  previousBalance: {
    issueDate: Date;
    availableValue: number;
  };
  balances: TransactionsBundle[];
  tenant: {
    displayName: string;
    name: string;
  };
};


export interface PaymentBankSlip {
  barCode: string
  digitableLine: string
  discount: number
  rebateValue: number
  fee: number
  trafficTicket: number
  maximumValue: number
  minimumValue: number
  totalValue: number
  nominalValue: number
  dueDate: string
  paymentDeadline: string
  dateTimeBillingConsult: string
  beneficiary: Beneficiary
  payer: Payer
  guarantorDrawer: GuarantorDrawer
  issuingBank: IssuingBank
}

export interface Beneficiary {
  name: string
  document: string
}

export interface Payer {
  name: string
  document: string
}

export interface GuarantorDrawer {
  name: any
  document: any
}

export interface IssuingBank {
  code: string
  name: string
}

export type TransactionsBundle = {
  issueDate: Date;
  availableValue: number;
  transactions: TransactionType[];
}

export type TransactionType = {
  issueDate: Date;
  amount: number;
  description: string;
  movement: string;
  movementDisplay: 'DEBITO' | 'CREDITO';
  registrationNumber: string;
  information: string;
  reservedAccountingEntry: boolean;
  isfare: boolean;
};

export interface IMFAState {
  password: string;
  code: string;
}

export interface CreateBeneficiaryType extends IBankAccountBeneficiaryCreate {
  bank: string | number;
  code: string;
  name: string;
  account: string;
  sessionId: any;
  bankDisplay: string;
  agency: string;
  accountNumber: string
  bankIspb: string
  BankCOMPECode: string
  accountType: string
  accountDigit: string;
  registrationNumber: string;
  pixKeyTypeValue: string | number;
  pixKeyTypeValueDisplay: string;
  bankAccountBeneficiaryId?: string;
  operationTypeValue: string;
  keyPix: string;
  transferDate: Date;
  saveBeneficiary: boolean
  dataByKeyPixRequest: boolean
}

export interface IBankAccountBeneficiaryCreate {
  bankAccountBeneficiaryCreate: IPixKeyData
}

export type BankAccountBeneficiaryCreateType = {
  accountNumber: string;
  accountType: string;
  accountTypeDisplay: string;
  agency: string;
  bank: string;
  bankDisplay: string;
  name: string;
  operationTypeValue: string;
  registrationNumber: string;
  saveBeneficiary: boolean
}

export type CreateTransferType = {
  accountNumber: number
  accountType: string
  agency: number
  bankAccountBeneficiaryCreate: BankAccountBeneficiaryCreateType
  bank: string
  keyPix: string
  pixKeyTypeValue: string
  pixKeyTypeValueDisplay: string;
  bankAccountBeneficiaryId: string;
  amount: number;
  transferDate: Date;
  paymentPurpose: string;
  saveBeneficiary: boolean
  sessionId: string | null | undefined;
  code: string | null | undefined;
}

export interface BankAccountType {
  bank: number;
  bankDisplay: string;
  name: string;
  type: string;
  agency: number;
  agencyDigit: string;
  account: number;
  accountNumber: number
  accountDigit: string;
  id: string;
  jointAccount: boolean;
  operationTypeValue: string | number;
  accountTypeDisplay: string;
  registrationNumber: string;
  pixKeyTypeValue: string;
  pixKeyTypeValueDisplay: string;
  keyPix: string;
  tableData: {
    id: string;
  };
};

export interface IPixKeyData {
  registrationNumber: string
  name: string
  keyType: string
  keyTypeDisplay: string
  keyValue: string
  accountNumber: string
  accountAgency: string
  pixKeyTypeValue: string
  accountOpeningDate: string
  accountType?: string
  pixKeyTypeValueDisplay: string
  bank: string | number;
  account: string
  bankDisplay: string;
  agency: string;
  accountTypeDisplay: string
  personType: string
  personTypeDisplay: string
  bankName: string
  ispb: string
  endToEndId: string
}
