import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import { Button, Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { BankAccountFull, iconSx } from 'contexts/apiRequestContext';
import { Paper, styled } from '@mui/material';
import { formatAccountFormat, isValidString } from 'helpers/formats/StringFormats';
import { replacePhoneNumber } from 'helpers/formats/PhoneNumber';
import { genericFormatPixKey } from 'helpers/formats/BankAccount';
import { Card } from 'components/Card';
import { PaidOutlined } from '@mui/icons-material';
import { EditIcon, DeleteIcon } from '@uy3/web-components';

const CustomIconButton = styled(Button)`
    svg {
        * {
            stroke: currentColor;
        }
    }
`;

const CustomCardRoot = styled(Card.Root)`
    height: 100%;
    & > div {
        height: 100%;
        justify-content: space-between;
    }
`;

export type TransferenciaTabProps = {
    openDrawer: (indexNumber?: number | undefined) => void;
    onDeleteBankAccount: (index: number) => void;
    name: string;
    enableActions: boolean;
};

const typographySX = { fontSize: '19px', margin: '3px 0' };
export function BankDetailsList({
    openDrawer,
    name,
    onDeleteBankAccount,
    enableActions,
}: TransferenciaTabProps) {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];
    const theme = activeTheme();
    return (
        <>
            <Paper sx={{ p: 6, mb: 2, boxShadow: 'none', border: '1px solid #E0E3EA' }}>
                {queryData?.length === 0 ? (
                    <Stack display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            sx={typographySX}
                            fontWeight={600}
                            color="textSecondary"
                            align="center"
                        >
                            Nenhuma conta bancária cadastrada
                        </Typography>
                    </Stack>
                ) : (
                    <Grid container spacing={3} columns={{ xs: 1, sm: 3, md: 5 }}>
                        {queryData?.map((bankAccount: BankAccountFull, index: number) => {
                            if (bankAccount.pixKeyTypeValue === 'Phone' && !!bankAccount.keyPix) {
                                bankAccount.keyPix = replacePhoneNumber(bankAccount.keyPix);
                            }

                            const bankAccountFormated = () => {
                                const format = genericFormatPixKey(bankAccount);
                                const index = format.indexOf('-');
                                if (bankAccount?.pixKeyTypeValue === 'AgencyAndAccount') {
                                    return format;
                                }
                                return format.slice(index).replace('- ', '');
                            };

                            const isTransfer =
                                bankAccount?.operationTypeValue === '0' ||
                                bankAccount?.operationTypeValue === 'Transfer';
                            return (
                                <Grid item xs={1}>
                                    <CustomCardRoot>
                                        <Stack gap={'1.5rem'}>
                                            <Card.Content
                                                title="Tipo de operação"
                                                value={
                                                    <Stack direction="row" alignItems="center">
                                                        <PaidOutlined
                                                            sx={{
                                                                ...iconSx,
                                                                color: theme.palette.primary.main,
                                                            }}
                                                        />{' '}
                                                        {isTransfer ? 'Transferência' : 'Pix'}
                                                    </Stack>
                                                }
                                            />
                                            <Card.Content
                                                title={`Tipo de ${isTransfer ? 'Conta' : 'Chave'}`}
                                                value={
                                                    isTransfer
                                                        ? bankAccount?.typeDisplay
                                                        : formatAccountFormat(
                                                              bankAccount?.pixKeyTypeValue
                                                          )
                                                }
                                            />
                                            {(isTransfer ||
                                                bankAccount?.pixKeyTypeValue ===
                                                    'AgencyAndAccount') && (
                                                <>
                                                    <Card.Content
                                                        title={'Banco'}
                                                        value={bankAccount?.bankCodeDisplay}
                                                    />
                                                    <Card.Content
                                                        title={'Agência'}
                                                        value={bankAccount?.agency}
                                                    />
                                                    <Card.Content
                                                        title={'Conta'}
                                                        value={`${bankAccount?.account} - ${
                                                            isValidString(bankAccount?.accountDigit)
                                                                ? bankAccount.accountDigit
                                                                : 'N/D'
                                                        }`}
                                                    />
                                                </>
                                            )}

                                            {!isTransfer &&
                                                bankAccount?.pixKeyTypeValue !==
                                                    'AgencyAndAccount' && (
                                                    <>
                                                        <Card.Content
                                                            title={'Chave Pix'}
                                                            value={bankAccountFormated()}
                                                        />
                                                    </>
                                                )}
                                        </Stack>
                                        {enableActions && (
                                            <Stack gap={1}>
                                                <CustomIconButton
                                                    variant="outlined"
                                                    onClick={() => openDrawer(index)}
                                                    sx={{ gap: 1 }}
                                                >
                                                    <EditIcon />
                                                    Editar
                                                </CustomIconButton>
                                                <CustomIconButton
                                                    variant="outlined"
                                                    onClick={() => onDeleteBankAccount(index)}
                                                    sx={{ gap: 1 }}
                                                >
                                                    <DeleteIcon />
                                                    Excluir
                                                </CustomIconButton>
                                            </Stack>
                                        )}
                                    </CustomCardRoot>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Paper>
            {/* <Grid container justifyContent="right" spacing={3}>
                <Grid md={6} justifyContent="right" display="flex">
                    <Box sx={{ mt: 3 }}>
                        <Button
                            sx={{ alignSelf: 'flex-end' }}
                            variant="contained"
                            disabled={false}
                            onClick={() => openDrawer()}
                            size="medium"
                            startIcon={
                                <AddIcon
                                    htmlColor={theme.palette.primary.contrastText}
                                    sx={iconSx}
                                />
                            }
                            color="primary"
                        >
                            Adicionar conta
                        </Button>
                    </Box>
                </Grid>
            </Grid> */}
        </>
    );
}
