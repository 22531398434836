import { object, string } from "yup";

export const validationSchemaDownloadLongBatchForm = () => {
    return object().shape({
        email: string().required('E-mail: precisa ser preenchido').typeError('E-mail: precisa ser preenchido').email('E-mail inválido')
    })
};

export const defaultValueDownloadLongBatch = {
    email: null
}