import { FieldValues } from "react-hook-form";
import { number, object, string } from "yup";

export const simulateSchema = () => {
  return object().shape({
    amortization: object().shape({
      valueMarginReserve: number()
        .typeError('Valor da margem: precisa ser preenchido.')
        .required('Valor da margem: precisa ser preenchido.')
        .min(0.01, "Valor precisa ser maior que 0 (zero)"),
      startDate: string().typeError('Data de ínicio: precisa ser preenchido.').required('Data de ínicio: precisa ser preenchido.'),
      firstPaymentDate: string().typeError('Data do primeiro pagamento: precisa serpreenchido.').required('Data do primeiro pagamento: precisa serpreenchido.'),
    })
  });
};

export const simulateDefaultValues = (formValues: FieldValues) => {
  return {
    amortization: {
      valueMarginReserve: Number(formValues?.amortization?.valueMarginReserve??0),
      startDate: formValues?.amortization?.startDate ?? new Date().toISOString(),
      firstPaymentDate: formValues?.amortization?.firstPaymentDate ?? new Date().toISOString()
    }, 
    registrationNumber: formValues?.registrationNumber, 
    employeeCode: formValues?.employeeCode, 
  }
}