import { useQuery } from '@tanstack/react-query';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import {
    getGetPublicPayrollLoan,
    getPayrollAgreementList,
    IFiltersPayrollAgreements,
    IGetPublicPayrollLoanParams,
} from 'services/creditNote';
import { SelectOption } from 'components/Forms/FormFields';
import { useFilterValues } from './filterValuesContext';

export function useGetListPayrollAgreement() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [payrollAgreementList, setPayrollAgreementList] = useState<SelectOption[]>([]);
    const {
        filterValues: { filters },
        setFilterValues,
    } = useFilterValues();

    const filtersComplete = {
        searchString: filters?.searchString,
    } as IFiltersPayrollAgreements;

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-payroll-agreement-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const resp = await getPayrollAgreementList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const options = data.data.map((item) => ({
                label: `${item.name ?? 'Não informado'}`,
                value: item.id
            }));

            setPayrollAgreementList(options);

            return data;
        },
    });

    const payrollAgreementAutocompleteProps = {
        listOptions: payrollAgreementList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'PayrollAgreements'),
    };

    return {
        ...queryContext,
        data: queryContext?.data,
        payrollAgreementAutocompleteProps,
    };
}

export function useGetPublicPayrollLoan(id: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues, setFilterValues } = useFilterValues();
    const [payrollLoanList, setPayrollLoanList] = useState<{ label: string; value: string }[]>([]);

    const filtersComplete: IGetPublicPayrollLoanParams = {
        ...filterValues.filters,
    };

    const isNew = id === 'nova';
    const queryContext = useQuery({
        enabled: !!token && !isNew,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-public-payrollloan', !isNew],
        queryFn: async () => {
            startRequest();
            const resp = await getGetPublicPayrollLoan(id!, filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data;
            const options = dataSuccess.data.map((item) => ({
                label: `${item.creditNoteNo} - ${item.personDisplay} (${item.statusDisplay})`,
                value: item.id,
                optionExtraKeyValues: [{ key: 'Parceiro', value: item.tenantDisplay }],
            }));
            setPayrollLoanList(options);
            return dataSuccess;
        },
    });

    const payrollLoanAutoCompleteProps = {
        listOptions: payrollLoanList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'Fund'),
    };

    return {
        ...queryContext,
        payrollLoanAutoCompleteProps,
    };
}
