import BaasForm from './BaasForm'
import React from 'react'
import { PartnerFormSharedType } from '../../PartnerForm'

type BaasFormContainerType = {} & PartnerFormSharedType;

export const BaasFormContainer: React.FC<BaasFormContainerType> = (props) => {
    return (
        <BaasForm />
    )
}
