import { AddIcon, Button, DeleteIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { useEffect } from 'react';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { isZetraProduct } from 'services/zetra';
import { TextFormField } from 'components/Forms/FormFields';
import { lazy, object, string } from 'yup';
import { toIsoStringWithTimezone } from 'helpers';
import { IconButton, Stack, Typography } from '@mui/material';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';
import { ReserveToken } from 'components/ReserveToken/ReserveToken';
import { useFieldArray } from 'react-hook-form';
import { CommissionFormCostsAccordionMetadataKey } from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import {
    IWarrantyReadModel,
    mapDescriptionWarranty,
    mapTitleWarranty,
    WarrantyType,
} from 'services/creditNote';
import CardsSelect, {
    HandleChangeCardsSelectedProps,
    IOption,
} from 'components/CardSelect/CardSelect';
import { CreditProductReadModel } from 'services/creditProduct';

export function summaryWarranty(
    data: IWarrantyReadModel[] | undefined,
    product: CreditProductReadModel | undefined
): IOption[] {
    const options: IOption[] = [];

    data?.forEach((item, index) => {
        options.push({
            label: mapTitleWarranty((item?.warrantyType || '') as WarrantyType),
            description: mapDescriptionWarranty(item, isZetraProduct(product)),
            value: item.id,
            disabled: false,
        });
    });

    return options;
}

export const validationSchemaRevision = (isRequired: boolean) =>
    lazy((value) => {
        return object().shape({
            reason: string(),
        });
    });

const theme = activeTheme();

type TokenFormProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
};

const TokenForm = ({ fields, remove }: TokenFormProps) => (
    <>
        <Stack direction="column" spacing={2}>
            {fields.map((item, index) => (
                <Stack key={item.id} direction="row" alignItems="center">
                    <ReserveToken key={item.id} index={index} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

const DoneRevisionForm = ({ warranty }: { warranty: IWarrantyReadModel[] }) => {
    const { control, setValue, watch } = useFormContext();
    const { product } = useCreditNoteFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'details',
    });

    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);
    const watchedWarrantyIds: string[] = watch('warrantyIds') ?? [];

    const addNewComm = () => {
        append({
            expirationDate: toIsoStringWithTimezone(new Date(new Date().setHours(0, 0, 0, 0))),
        });
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length);
    };

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
    };

    const handleOnSelect = ({ value, checked }: HandleChangeCardsSelectedProps) => {
        if (checked) {
            setValue('warrantyIds', [...watchedWarrantyIds, value]);
        } else {
            setValue(
                'warrantyIds',
                watchedWarrantyIds.filter((item) => item !== value)
            );
        }
    };

    useEffect(() => {
        if (watch('details')?.length === 0) {
            addNewComm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const options = summaryWarranty(warranty, product);

    const enableSrcollItems: boolean = options?.length <= 5;

    return (
        <Stack gap={3}>
            <TextFormField name="reason" variant="outlined" fullWidth label="Motivo" />

            <Typography
                variant="xxxl"
                color="neutral.dark"
                lineHeight="34px"
                mb={1}
                fontWeight={700}
                fontSize={20}
            >
                Remover garantias
            </Typography>
            <Stack
                maxHeight={500}
                p={1}
                sx={{
                    display: 'block',
                    overflowY: enableSrcollItems ? 'auto' : 'scroll',
                    overflowX: 'hidden',
                    boxShadow: enableSrcollItems ? 'none' : '5px 5px 5px 5px #f2f2f2',
                    borderRadius: '10px',
                }}
            >
                <CardsSelect
                    onChange={handleOnSelect}
                    options={options}
                    value={watchedWarrantyIds}
                    isCheckbox
                />
            </Stack>

            <TokenForm fields={fields} remove={removeComm} />

            <Button
                sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 600,
                    fontSize: '15px',
                }}
                variant="text"
                onClick={addNewComm}
                startIcon={<AddIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
            >
                Adicionar
            </Button>
        </Stack>
    );
};

export default DoneRevisionForm;
