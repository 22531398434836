import { createContext } from 'react';
import { CreditNoteActionsProps } from './creditNoteActions.interface';

export const CreditNoteActionsContext = createContext<CreditNoteActionsProps>({
    assign: () => {},
    isNewCreditNote: true,
    assignmentPreview: {
        onClick: () => {},
        href: '',
        target: '',
    },
    draftPreview: {
        onClick: () => {},
        href: '',
        target: '',
    },
    submitApproval: () => {},
    deleteCheckList: () => {},
    handleSigature: () => {},
    approveLiquidation: () => {},
    approveLiquidationSchedule: () => {},
    rejectLiquidation: () => {},
    approveSignature: () => {},
    rejectSignatures: () => {},
    approvePaymentRevision: () => {},
    paymentRevisionLiquidationSchedule: () => {},
    sendSignatures: () => {},
    updateUploads: () => {},
    simulate: () => {},
    getSimulation: () => {},
    checkCredPayOperation: () => {},
    approveInstrument: () => {},
    rejectInstrument: () => {},
    approveCredit: () => {},
    rejectCredit: () => {},
    approveCompliance: () => {},
    rejectCompliance: () => {},
    cancel: () => {},
    deleteOp: () => {},
    manualUpdate: () => {},
    sendDocuments: () => {},
    clone: () => {},
    generatePaymentLink: () => {},
    transferReceiptPdf: () => {},
    status: '',
    uploads: [],
    unlockOperation: () => {},
    manualWarrantyMutate: () => {},
    approveMarginReserve: () => {},
    disapproveMarginReserve: () => {},
    reserveToken: () => {},
    showTokensRegistered: () => {},
    reprocessByStatus: () => {},
    fraudRecord: () => {},
    handleMarginQuery: () => {},
    installmentCreditNote: () => {},
    checkAverbation: () => {},
    onLiquidationScheduleClosure: () => {},
    onLiquidationScheduleClosureRevision: () => {},
    onReleaseToPayment: () => {},
    handleConsignment: () => {},
    handleChangeCalendar: () => {},
    showListWorkflows: () => {},
    showDoneRevision: function (): void {},
    approveSignatureByPartner: () => {},
    rejectSignatureByPartner: () => {}
});
