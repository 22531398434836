import { formatDocumentNumber, genericMessageRequired } from 'helpers';
import { string, object } from 'yup';

export const validationSchemaUpdateConsignment = object().shape({
    personId: string().nullable(),
    registrationNumber: string()
        .typeError('O CPF informado é inválido.')
        .optional()
        .cpfCnpjValidation('CPF inválido.'),
    employeeCode: string()
        .required(`Nº da Matrícula: ${genericMessageRequired}`)
        .typeError('Nº da Matrícula é inválido.'),
    employeePassword: string()
        .required(`Senha do servidor: ${genericMessageRequired}`)
        .typeError('Senha do servidor é inválida.'),
});

export const defaultValuesUpdateConsignment = (registrationNumber: string, employeeCode?: string) => {
    return {
        registrationNumber: formatDocumentNumber(registrationNumber),
        employeeCode: employeeCode ?? null,
    };
};
