import React, { useState } from 'react';
import { PartnerFormHeader } from './PartnerFormHeader';
import { Stack } from '@mui/material';
import { PartnerForm } from './PartnerForm';
import { FormProvider } from 'contexts/formContext';
import { partnerFormSchemaValidations, partnerFormDefaultValues } from './PartnerFormSchema';
import { FieldValues } from 'react-hook-form';
import { useLegalPersonListData } from 'contexts/legalPersonContext';
import { useNavigate, useParams } from 'react-router';
import { ApiResponseError, handleOnError, toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { useCreateTenantRequestMutation, useDeleteTenantRequestMutation, useGetByIdTenantRequest, useUpdateTenantRequestMutation } from 'contexts/tenantRequestContext';
import { useQueryClient } from '@tanstack/react-query';
import { TenantRequestCreateModel } from 'services/Partern/TenantRequest/Types/tenantRequestCreateModel';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { TenantRequestUpdateModel } from 'services/Partern/TenantRequest/Types/tenantRequestUpdateModel';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { Modal } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';

export enum PartnerFormEnum {
    BAAS = 0,
    CASS = 1,
    BILLING = 2,
}

export const PartnerFormContainer = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const queryClient = useQueryClient();
    const isNew = id === 'novo';
    const [action, setAction] = useState<string | undefined>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { legalPersonAutoCompleteProps } = useLegalPersonListData({ page: 0, size: 15 }, 'always');
    const [signalIndex, setSignalIndex] = useState<PartnerFormEnum>(PartnerFormEnum.BAAS);
    const enableOnSubmit = true;

    const { data: tenantRequestData, isLoading, refetch } = useGetByIdTenantRequest(id);

    const onSuccess = async (type: string) => {

        const message: { [type: string]: string } = {
            "delete": "Sucesso ao excluir o parceiro",
            "create": "Parceiro criado com sucesso",
            "update": "Parceiro atualizado com sucesso"
        }

        setToast({
            open: true,
            severity: "success",
            title: message[type]
        });

        if (['create', 'delete'].includes(type)) {
            await queryClient.refetchQueries(['get-tenant-request-all-items']);
            navigate('/parceiros/solicitacoes');
        }

        if (type === 'update') {
            refetch();
        }

    };

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);

    const { mutateAsync: createMutateAsync, isLoading: isLoadingCreate } = useCreateTenantRequestMutation(() => onSuccess(`create`), onError);
    const { mutateAsync: updateMutateAsync, isLoading: isLoadingUpdate } = useUpdateTenantRequestMutation(id!, () => onSuccess(`update`), onError);
    const { mutateAsync: deleteMutateAsync, isLoading: isLoadingDelete } = useDeleteTenantRequestMutation(() => onSuccess(`delete`), onError);

    const onSubmit = async (values: FieldValues) => {
        if (!isNew) {
            await updateMutateAsync(values as TenantRequestUpdateModel);
        } else {
            await createMutateAsync(values as TenantRequestCreateModel);
        }
    };

    const onClose = () => setAction(undefined);

    return (
        <Stack spacing={4} mt={-3}>
            <GenericFormSkeleton isLoading={isLoading && !isNew}>
                <React.Fragment>
                    <Toast toast={toast} setToast={setToast} />
                    <FormProvider
                        validationSchema={partnerFormSchemaValidations()}
                        defaultValues={partnerFormDefaultValues(tenantRequestData ?? null)}
                        onSubmit={onSubmit}
                    >
                        <React.Fragment>
                            <PartnerFormHeader enableOnSubmit={enableOnSubmit} isNew={isNew} handleDelete={() => setAction('delete')} refetch={refetch} />
                            <RefreshProgress refreshing={isLoadingCreate || isLoadingUpdate || isLoadingDelete}/>
                            <PartnerForm {...{ setSignalIndex, signalIndex, legalPersonAutocomplete: legalPersonAutoCompleteProps }} />
                        </React.Fragment>
                    </FormProvider>

                    <Modal
                        open={action === 'delete'}
                        align='left'
                        onClose={onClose}
                        title="Excluir"
                        description='Tem certeza que deseja excluir esse registro?'
                        children={(
                            <GenericActionHandler
                                handleSubmit={() => deleteMutateAsync(tenantRequestData?.id!)}
                                onClose={onClose}
                                isModal
                                titleCancel='Não, cancelar'
                                titleConfirm='Sim, excluir'
                            />
                        )}
                    />
                </React.Fragment>

            </GenericFormSkeleton>
        </Stack>
    );
};
