import { FormProvider } from 'contexts/formContext';
import { validationSchemaUpdateEmails, defaultValuesUpdateEmails } from './UpdateEmailsFormSchema';
import { UpdateEmailsForm } from './UpdateEmailsForm';
import React from 'react';
import { IIUpdateNotificationRecipient } from 'services/walletManagement/wallet/wallets.type';
import { isEmpty } from 'lodash';
import { emailsOfWalletToDisableAccordions } from './UpdateEmailsListForm';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { FieldValues } from 'react-hook-form';

type UpdateEmailsFormContainerProps = {
    recepientsList: string[]
    onClose: () => void;
    isLoading?: boolean
    updateEmailsWalletMutate: UseMutateAsyncFunction<unknown, unknown, IIUpdateNotificationRecipient, unknown>
}

export const UpdateEmailsFormContainer: React.FC<UpdateEmailsFormContainerProps> = ({ recepientsList, onClose, isLoading = false, updateEmailsWalletMutate }) => {
    const notificationRecepients = (recepientsList as string[]) ?? [];

    const onSubmit = async (values: FieldValues) => {
        const onlyEmailsInString = values?.notificationRecepients.map((item: { email: string }) => item.email);
        await updateEmailsWalletMutate({
            notificationRecepients: onlyEmailsInString
        });
    };

    const defaultValuesCurrentForm = () => {
        const notificationRecepientsMapped = notificationRecepients?.map((email) => {
            return { email }
        });

        if (!isEmpty(notificationRecepients)) {
            return {
                notificationRecepients: notificationRecepientsMapped?.sort((a, b) => {
                    const aInList = emailsOfWalletToDisableAccordions?.includes(a.email);
                    const bInList = emailsOfWalletToDisableAccordions?.includes(b.email);
                    if (aInList && !bInList) return -1;
                    if (!aInList && bInList) return 1;
                    return 0;
                })
            }
        }

        return defaultValuesUpdateEmails
    }

    return (
        <React.Fragment>
            <FormProvider {...{
                defaultValues: defaultValuesCurrentForm(),
                validationSchema: validationSchemaUpdateEmails(),
                onSubmit,
                children: <UpdateEmailsForm {...{
                    onClose,
                    isLoading
                }} />
            }} />
        </React.Fragment>
    )
}
