export const operationTypeList = [
    { label: 'Inclusão', value: 'Inclusion' },
    { label: 'Edição', value: 'Edition' },
    { label: 'Exclusão', value: 'Exclusion' },
];

export const evidenceType = [
    { label: 'Ocorrência de Fraude', value: 'Ocorrencia' },
    { label: 'Tentativa de Fraude', value: 'Tentativa' },
];

export const role = [
    { label: 'Reclamante', value: 'Reporter' },
    { label: 'Executor', value: 'Executor' },
    { label: 'Conta Destino', value: 'ToAccount' },
];

export const dataSource = [
    { label: 'Resolução 6', value: 'Resolucao6' },
    { label: 'Interoperabilidade', value: 'Interoperabilidade' },
    { label: 'Chargeback', value: 'Chargeback' },
];

export const fraudModalityList = [
    { label: 'Auto fraude', value: 'Self_Fraud' },
    { label: 'Conta de passagem/Conta Laranja', value: 'Mule_Account' },
    { label: "Roubo de identidade", value: "Identity_theft" }, 
    { label: "Identidade sintética", value: "Synthetic_Identity" }, 
    { label: "Invasão de conta (ATO)", value: "Account_Hacking" }, 
    { label: "Fraude amigável", value: "Friendly_Fraud" }, 
    { label: "Fraude do comprador", value: "Buyer_Fraud" }, 
    { label: "Fraude de vendedor", value: "Seller_Fraud" }, 
    { label: "SIM SWAP", value: "SIM_SWAP" }, 
    { label: "Dados fraudados do boleto", value: "Fraud_Adulterated_Bills" }, 
    { label: "Fraude de benefícios", value: "Benefits_Fraud" }, 
    { label: "Sequestro ou extorsão", value: "Kidnapping_Extortion" }, 
    { label: "Fraude sem identificação", value: "Inconclusive" }, 
    { label: "Classificação inadequada.", value: "ModalityNotIncludedRol" }
];

export const relatedActivityList = [
    { label: 'Abertura de conta de depósitos ou conta de pagamento', value: 'Opening_Deposit_Account_Payment_Account' },
    { label: 'Manutenção de conta de depósitos ou conta de pagamento', value: 'Maintenance_Deposit_Account_Payment_Account' },
    { label: 'Contratação de operação de crédito', value: 'Contracting_Credit_Operations' },
    { label: 'Transferências entre contas na própria instituição', value: 'Transfers_Between_Accounts_Institution_Itself' },
    { label: 'Transferência Eletrônica Disponível (TED)', value: 'Electronic_Transfer_Available_TED' },
    { label: 'Transações de pagamento com emprego de cheque', value: 'Payment_Transactions_Using_Checks' },
    { label: 'Transações de pagamento instantâneo (PIX)', value: 'Instant_Payment_Transactions_PIX' },
    { label: 'Transferências por meio de Documento de Crédito (DOC)', value: 'Transfers_Credit_Document_DOC' },
    { label: 'Boletos de pagamento', value: 'Payment_Slips' },
    { label: 'Saques de recursos em espécie', value: 'Withdrawals_Cash_Resources' },
];

export const channelOptionsList = [
    { label: 'Internet', value: 'Internet' },
    { label: 'Celular', value: 'Celular' },
    { label: 'Autoatendimento', value: 'Autoatendimento' },
    { label: 'Rede Parceiros', value: 'RedeParceiros' },
    { label: 'Mídias Sociais', value: 'MídiasSociais' },
    { label: 'Interbancário', value: 'Interbancário' },
    { label: 'Outros', value: 'Outros' },

];

export const taxPayerList = [
    { label: 'Pessoa Física', value: 'PF' },
    { label: 'Pessoa Jurídica', value: 'PJ' },
    { label: 'Não informado', value: undefined },
]


export const accountTypeList = [
    { label: 'Conta de Depósito à Vista', value: 'CV_Conta_Deposito_AVista' },
    { label: 'Conta de Depósito Poupança', value: 'CP_Conta_Deposito_Poupanca' },
    { label: 'Conta de Pagamento Pré-Paga', value: 'PP_Conta_Pagamento_Pre_Paga' },
];