import {
    RadioOptions,
} from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import React, { useState } from 'react';
import { SearchNaturalPerson } from './SearchNaturalPerson';
import { formatDocumentNumber } from 'helpers';

type SearchNaturalPersonContainerProps = {
    onClose: () => void;
    setDocumentNumber?: React.Dispatch<React.SetStateAction<string | null>>
};


export const SearchNaturalPersonContainer = ({ onClose, setDocumentNumber }: SearchNaturalPersonContainerProps) => {
    const { setValue } = useFormContext();
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { data: personList, isLoading } = usePersonsList(
        { page: 0, size: 10, searchString: search },
        'always'
    );
    //@ts-ignore
    const persons = (personList?.data ?? []) as PersonFull[];

    const options: RadioOptions[] = persons?.map((item) => {
        return {
            label: `${item.name} - ${formatDocumentNumber(item.registrationNumber)}`,
            value: item.id,
        };
    });

    const onSubmit = (personId: string) => {
        const person = persons.find((x: PersonFull) => x.id === personId);
        setValue('registrationNumber', person?.registrationNumber);
        setDocumentNumber?.(person?.registrationNumber!); 
        setValue('person', person);
        onClose();
    };

    return (
        <SearchNaturalPerson
            onClose={onClose}
            options={options}
            handleSave={onSubmit}
            isLoading={isLoading}
            search={search}
            setSearch={setSearch}
        />
    );
};
