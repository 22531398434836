import { SelectFormField } from 'components/Forms/FormFields';
import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type ReleaseToPaymentFormProps = {
    onClose: () => void;
};

const theme = activeTheme();
const ReleaseToPaymentForm = ({ onClose }: ReleaseToPaymentFormProps) => {
    const { submitting } = useApiRequest();
    return (
        <Stack gap={3} mt={2}>
            <SelectFormField
                name="action"
                label="Aprovar liberação"
                options={[
                    { label: 'Sim', value: "Release" },
                    { label: 'Não', value: "Cancel" },
                    { label: 'Solicitar nova assinatura', value: "Sign" },
                ]}
            />
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    disabled={submitting}
                >
                    Não, cancelar
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>
    );
};

export default ReleaseToPaymentForm;
