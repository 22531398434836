import React from 'react'
import { PartnerFormSharedType } from '../../PartnerForm'
import CaasForm from './CaasForm'

type CaasFormContainerType = {} & PartnerFormSharedType;

export const CaasFormContainer: React.FC<CaasFormContainerType> = (props) => {
    return (
        <React.Fragment>
            <CaasForm />
        </React.Fragment>
    )
}
