import React, { FunctionComponent } from 'react';
import {
    RadioGroup,
    Radio as MuiRadio,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
    SvgIconProps,
    Grid,
} from '@mui/material';
import { Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { ControlOptionRadio } from 'components/Forms/Radio/Radio';
import { TooltipComponent, TooltipComponentProps } from 'components/Tooltip/Tooltop';

export interface IOption {
    value: string | number;
    label: string;
    Icon?: FunctionComponent<SvgIconProps>;
    description?: string;
    disabled?: boolean;
    tooltipComponent?: TooltipComponentProps;
}

export type HandleChangeCardsSelectedProps = {
    value: string | string[] | number;
    displayName?: string;
    checked?: boolean;
};

interface CardsSelectProps {
    options: IOption[];
    value: string | string[];
    onChange: (props: HandleChangeCardsSelectedProps) => void;
    selectedList?: string[] | number[];
    isCheckbox?: boolean;
    noOptionSelect?: boolean;
    disableItem?: (item: IOption) => boolean;
}

const theme = activeTheme();

const CardsSelect: React.FC<CardsSelectProps> = ({
    options,
    value,
    onChange,
    selectedList,
    isCheckbox = false,
    disableItem = () => false,
    noOptionSelect
}) => {
    const handleChange = (event: any, checked: boolean, label: string) => {
        const value = event.target.value;
        onChange({ value, checked, displayName: label });
    };

    const isSelected = (optionValue: string | number): boolean => {
        return (
            selectedList?.some((item) => String(item) === String(optionValue)) ||
            value?.includes(String(optionValue))
        );
    };

    const getBorderStyle = (isActive: boolean): string =>
        `1.5px solid ${isActive ? theme.palette.primary.main : '#A0A0A0'}`;

    const renderLabel = (option: IOption) => {
        const { Icon, label, description, disabled, tooltipComponent } = option ?? {};
        return (
            <Stack direction="row" alignItems="center">
                {Icon && (
                    <Typography ml={3} variant="h5">
                        <Icon
                            htmlColor={
                                disabled || disableItem(option)
                                    ? '#9EA7AB'
                                    : theme.palette.common.black
                            }
                        />
                    </Typography>
                )}
                <Grid>
                    <Typography ml={Icon ? 1 : 0} variant="h5">
                        {label}
                    </Typography>
                    <Typography
                        ml={Icon ? 1 : 0}
                        display="block"
                        variant="subtitle1"
                        sx={{ width: tooltipComponent ? '95%' : 'auto' }}
                    >
                        {description || ''}
                    </Typography>
                </Grid>
            </Stack>
        );
    };

    const renderFormControlLabel = (option: IOption, isActive: boolean) => (
        <FormControlLabel
            key={option.value}
            sx={{
                border: getBorderStyle(isActive),
                borderRadius: 2,
                padding: '20px 10px',
                marginBottom: 3,
                position: 'relative',
                cursor: 'pointer',
                width: '100%',
                '.MuiFormControlLabel-label': {
                    fontSize: '1.5rem',
                    // ml: -2,
                },
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
            }}
            control={
                <Checkbox
                    size="medium"
                    checked={isSelected(option.value)}
                    sx={{
                        display: noOptionSelect ? 'none' : 'block',
                        '&.Mui-checked': { color: theme.palette.primary.main },
                        '& .MuiSvgIcon-root': { fontSize: '2.5rem' },
                    }}
                />
            }
            label={renderLabel(option)}
            disabled={option.disabled || disableItem(option)}
            value={option.value}
            onChange={(event, checked) => handleChange(event, checked, option.label)}
        />
    );

    const renderRadioGroup = (option: IOption, isActive: boolean) => (
        <RadioGroup key={option.value} value={value ?? null} sx={{ ml: 1, width: '100%' }}>
            <ControlOptionRadio
                disabled={option.disabled || disableItem(option)}
                sx={{
                    border: getBorderStyle(isActive),
                    borderRadius: 2,
                    padding: '20px 10px',
                    marginBottom: 3,
                    position: 'relative',
                    '.MuiFormControlLabel-label': {
                        fontSize: '1.5rem',
                        ml: noOptionSelect ? 0 : -2,
                    },
                }}
                control={
                    <MuiRadio
                        size="medium"
                        sx={{
                            display: noOptionSelect ? 'none' : 'block',
                            '&.Mui-checked': { color: theme.palette.primary.main },
                            position: 'relative',
                            right: 2,
                        }}
                    />

                }
                label={renderLabel(option)}
                value={option.value}
                onChange={(event, checked) => handleChange(event, checked, option.label)}
            />
        </RadioGroup>
    );

    return (
        <Stack>
            <FormGroup sx={{ ml: 1, width: '100%' }}>
                {options.map((option) => {
                    const isActive = isSelected(option.value);

                    const renderedOption = isCheckbox
                        ? renderFormControlLabel(option, isActive)
                        : renderRadioGroup(option, isActive);

                    return (
                        <React.Fragment key={option.value}>
                            {option?.tooltipComponent ? (
                                <TooltipComponent {...option.tooltipComponent}>
                                    <Grid>{renderedOption}</Grid>
                                </TooltipComponent>
                            ) : (
                                renderedOption
                            )}
                        </React.Fragment>
                    );
                })}
            </FormGroup>
        </Stack>
    );
};

export default CardsSelect;
