import { Skeleton, Stack } from '@mui/material';
import React from 'react'

export const SimualtionDetailsSkeleton = () => {
    return (
        <Stack spacing={3}>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='400px'
                height='25px'
            />
            <Stack sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                flexWrap: 'wrap',
                gap: 2
            }}>
                {[1, 2].map(item => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={100}
                            sx={{
                                margin: '20px 0',
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
            </Stack>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='400px'
                height='25px'
            />
             <Stack sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
                flexWrap: 'wrap',
                gap: 2
            }}>
                {[1, 2, 3, 4, 5, 6].map(item => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={100}
                            sx={{
                                margin: '20px 0',
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
            </Stack>
        </Stack>
    )
}
