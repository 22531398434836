import { Box, Grid, Stack } from '@mui/material';
import { SelectFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { ContainerFormFields } from 'pages/Partners/PartnersRequest/Generics/ContainerFormFields';
import MainTitle from 'pages/Partners/PartnersRequest/Generics/MainTitle';

const titlesFormList = [
  { title: "Registro", fields: ['registrationColtValue', 'registrationSpreadValue', 'registrationTotalValue', 'registrationSpreadDivisionValue'] },
  { title: "Liquidação", fields: ['liquidationColtValue', 'liquidationSpreadValue', 'liquidationTotalValueValue', 'liquidationSpreadDivisionValue'] },
  { title: "Baixa", fields: ['deletionColtValue', 'deletionSpreadValue', 'deletionTotalValueValue', 'deletionSpreadDivisionValue'] },
  { title: "Atendimento", fields: ['deductionColtValue', 'deductionSpreadValue', 'deductionTotalValueValue', 'deductionSpreadDivisionValue'] },
  { title: "Alteração do vencimento", fields: ['paymentDateChangeColtValue', 'paymentDateChangeSpreadValue', 'paymentDateChangeTotalValueValue', 'paymentDateChangeSpreadDivisionValue'] },
  { title: "Protesto", fields: ['protestColtValue', 'protestSpreadValue', 'protestTotalValueValue', 'protestSpreadDivisionValue'] },
  { title: "Sustar protesto", fields: ['withdrawProtestColtValue', 'withdrawProtestSpreadValue', 'withdrawProtestTotalValueValue', 'withdrawProtestSpreadDivisionValue'] },
  { title: "Baixa protesto", fields: ['protestRemovalColtValue', 'protestRemovalSpreadValue', 'protestRemovalTotalValueValue', 'protestRemovalSpreadDivisionValue'] },
  { title: "Liquidação cartório", fields: ['notarySettlementColtValue', 'notarySettlementSpreadValue', 'notarySettlementTotalValueValue', 'notarySettlementSpreadDivisionValue'] },
  { title: "Custo transferência registro", fields: ['registrationTransferFeeColtValue', 'registrationTransferFeeSpreadValue', 'registrationTransferFeeTotalValueValue', 'registrationTransferFeeSpreadDivisionValue'] },
  { title: "Custo transferência baixa", fields: ['lowTransferCostValue', 'lowTransferCostSpreadValue', 'lowTransferCostTotalValueValue', 'lowTransferCostSpreadDivisionValue'] },
  { title: "Decurso de prazo", fields: ['elapseTimeCostValue', 'elapseTimeSpreadValue', 'elapseTimeTotalValueValue', 'elapseTimeSpreadDivisionValue'] },
];

export default function BiliingForm() {
  return (
    <Stack spacing={2}>
      <MainTitle title="Defina o método de cobrança aplicado ao cliente pelo uso do módulo de gestão de cobranças" />

      <ContainerFormFields title="Forma de cobrança">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <SelectFormField
                label="Forma de cobrança"
                name="tenantRequestBilling.methodCollectionValue"
                fullWidth
                variant="outlined"
                required={false}
                showButtonClearValue
                options={[
                  { label: "Boleto", value: 0 },
                  { label: "TED", value: 1 }
                ]}
              />
            </Grid>

            <Grid item xs={2.5}>
              <SelectFormField
                label="Periodicidade da cobrança"
                name="tenantRequestBilling.billingFrequecyValue"
                fullWidth
                variant="outlined"
                required={false}
                showButtonClearValue
                options={[
                  { label: "Boleto", value: 0 }
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <MainTitle title="Defina as taxas da condição comercial da cobrança" />

      {titlesFormList.map(({ title, fields }, index) => {
        return (
          <ContainerFormFields title={title} key={index}>
            <Box>
              <Grid container spacing={2.5}>
                {fields.map((field, fieldIndex) => {
                  const labels = ['Custo UY3', 'Spread', 'Valor total', 'Divisão do spread']
                  return (
                    <Grid item xs={3} key={fieldIndex}>
                      <CurrencyFormField
                        label={labels[fieldIndex]}
                        name={`tenantRequestBilling.${field}`}
                        fullWidth
                        variant="outlined"
                        required={false}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </ContainerFormFields>
        );
      })}
    </Stack>
  );
}
