import { Stack } from '@mui/material';
import { Button, RightIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type FreeMarginProps = {
    title: string;
    description: string;
    enableMarginQuery: boolean;
    marginQueryHandle: () => void;
};

const theme = activeTheme();

export const FreeMargin = ({
    title,
    description,
    enableMarginQuery,
    marginQueryHandle,
}: FreeMarginProps) => {

    return (
        <Stack
            direction='row'
            alignItems='start'
            justifyContent='space-between'
            sx={{
                backgroundColor: '#BABFD029',
                color: theme.palette.common.black,
                padding: '16px',
                borderRadius: '8px',
            }}
        >
            <Stack spacing={2}>
                <Typography fontSize="17px" fontWeight="700">
                    {title}
                </Typography>
                <Typography fontSize="14px" pt={-2} fontWeight="400">
                    {description}
                </Typography>
            </Stack>
            {enableMarginQuery && (
                <Button
                    type="button"
                    variant="text"
                    startIcon={<RightIcon sx={iconSx} />}
                    sx={{ color: theme.palette.common.black }}
                    onClick={marginQueryHandle}
                >
                    Obter margem livre
                </Button>
            )}
        </Stack>
    );
};
