import { useState } from 'react';
import { BatchAssignmentList } from './BatchAssignment'
import { GridSortModel } from '@mui/x-data-grid';
import { useBatchAssignmentData } from 'contexts/batchAssignment/batchAssignmentContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { useFormContext } from 'contexts/formContext';
import { useFundsList } from 'contexts/fundContext';
import { FilterProvider } from 'contexts/filterContext';
import { batchAssignmentSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { BatchAssignmentFilter } from 'services/creditNote/BatchAssignment/BatchAssignment.types';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';

type BatchAssignmentContainerProps = {
  recordType: "creditNote" | "fund";
}

export const BatchAssignmentListContainer = ({ recordType }: BatchAssignmentContainerProps) => {
  const { watch } = useFormContext();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<GridSortModel>([]);
  const orderByField = orderBy?.map((item) => `${item?.field}_${item?.sort?.toUpperCase()}`) || [];

  const { fundAutoCompleteProps, data } = useFundsList({ page: 0, size: 10 }, 'always');
  const filterDefault = { page, size: rowsPerPage, orderBy: orderByField[0] }

  const params: { [type: string]: BatchAssignmentFilter } = {
    "fund": {
      ...filterDefault,
      fundId: watch("id")
    },
    "creditNote": {
      ...filterDefault,
      creditNoteNos: watch("creditNoteNo")
    }
  }

  const { batchAssignmentData, refetch, isFetching } = useBatchAssignmentData(params[recordType]);

  const onChangePage = (page: number) => setPage(page);

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  let fundAutocompleteData: any = {
    ...fundAutoCompleteProps,
    listOptions: data?.data?.map((i: any) => {
      return { label: i.name, value: i.id };
    }) || []
  };

  return (
    <>
      <GenericListHeader
        title="Cessões"
        titleButton="Atualizar"
        enableAction
        onClick={refetch}
      />

      <RefreshProgress refreshing={isFetching} />
      <FilterProvider
        availableFilters={{
          searchString: { label: 'Todos', type: 'text' },
          assignmentCalculationResult: { label: 'Ágio', type: 'text' },
          maxPresentValue: { label: 'Valor máximo do presente', type: 'text' },
          assignmentNumber: { label: 'Número da cessão', type: 'text' },
          creditNoteNos: { label: 'Número da operação', type: 'text' },
          minPresentValue: { label: 'Valor mínimo do ptesente', type: 'text' },
          maxAssigmentValue: { label: 'Valor máximo da cessão', type: 'text' },
          minAssigmentValue: { label: 'Valor mínimo da cessão', type: 'text' },
          minAssignmentCalculationValue: { label: 'Valor máximo do cálculo', type: 'text' },
          maxAssignmentCalculationValue: { label: 'Valor mínimo do cálculo', type: 'text' },
          presentValue: { label: 'Valor presente', type: 'text' },
          initialAssignmentDate: {
            label: 'Data da cessão',
            type: 'date',
            multiple: false,
            dateRangeName: 'finalAssignmentDate',
          },
          fundId: {
            label: 'Cessionário',
            type: 'autocomplete',
            multiple: false,
            options: fundAutocompleteData.listOptions,
          },
          status: {
            label: 'Status',
            type: 'select',
            multiple: true,
            options: batchAssignmentSelectFilterOptions,
          },
        }}
      >
        <BatchAssignmentList
          queryData={batchAssignmentData ?? []}
          page={page}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setPage={onChangePage}
          setRowsPerPage={onChangeRowsPerPage}
        />
      </FilterProvider>
    </>
  );
}
