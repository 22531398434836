import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DeleteIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { localPaginateDataTable } from 'helpers';
import React from 'react';
import { PayrollAgreementReadModel } from 'services/creditNote';

type PayrollAgreementsListProps = {
    queryData: PayrollAgreementReadModel[];
    handleDeleteItem: (id: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
};

export const PayrollAgreementsList: React.FC<PayrollAgreementsListProps> = ({
    queryData,
    handleDeleteItem,
    page,
    rowsPerPage,
    setPage, 
    setRowsPerPage
}) => {
    const columns: GridColDef[] = [
        {
            field: 'internalId',
            headerName: 'Código',
            hideSortIcons: true,
            minWidth: 120,
            flex: 0.5,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const rowIndex = queryData?.findIndex(
                    (row: PayrollAgreementReadModel) => cellValues?.row?.id === row?.id
                );
                return (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <IconButton name="btn-delete" onClick={() => handleDeleteItem(rowIndex)}>
                            <DeleteIcon sx={iconSx} />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];
    return (
        <DataTable
            columns={columns}
            rows={localPaginateDataTable(queryData, page, rowsPerPage)}
            page={page}
            rowCount={queryData?.length}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            enableJumpAction={false}
        />
    );
};
