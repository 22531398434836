import { Stack } from '@mui/material';
import { Button, CloseIcon, DoneIcon } from '@uy3/web-components';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type ActionButtonsProps = {
    onClose: () => void;
};

const theme = activeTheme();
const ActionButtons: React.FC<ActionButtonsProps> = ({ onClose }) => {
    const { submitting } = useApiRequest();

    return (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
            <Button
                startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                variant="outlined"
                size="medium"
                onClick={onClose}
            >
                Cancelar
            </Button>
            <Button
                name="btn-confirm-transfer"
                startIcon={<DoneIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                variant="contained"
                size="medium"
                type="submit"
                disabled={submitting}
            >
                Confirmar
            </Button>
        </Stack>
    );
};

export default ActionButtons;
