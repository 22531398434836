import { Button, CloseIcon, Drawer, SaveIcon } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React from 'react';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { isZetraProduct } from 'services/zetra';
import { lazy, object, string } from 'yup';
import { Stack, useMediaQuery } from '@mui/material';
import { activeTheme } from 'services/theme';
import DoneRevisionForm from './DoneRevisionForm';
import { useDoneRevisionWarranty } from '../creditContext';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { useParams } from 'react-router';

interface DoneRevisionContainerProps {
    open: boolean;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export const validationSchemaRevision = (isRequired: boolean) =>
    lazy((value) => {
        return object().shape({
            reason: string(),
        });
    });

const theme = activeTheme();

const DoneRevision: React.FC<DoneRevisionContainerProps> = ({ open, onCloseDrawer, setToast }) => {
    const { submitting, watch } = useFormContext();
    const { id } = useParams();

    const { product } = useCreditNoteFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isZetra = isZetraProduct(product);

    const onSucess = () => {
        onCloseDrawer();
        showSuccessToast('Revisão concluida com sucesso!', '', setToast);
    };

    const { mutate } = useDoneRevisionWarranty(id || '', onSucess, (err: ApiResponseError) =>
        handleOnError(err, setToast)
    );

    const onSubmit = (values: any) => {
        const doneRevisionBody = { ...values, warrantyOfficeTokens: values.details };
        mutate(doneRevisionBody);
    };

    const defaultValue = {
        reason: '',
    };

    return (
        <Drawer anchor="right" title="Concluir Revisão" open={open} onClose={onCloseDrawer}>
            <FormProvider
                validationSchema={validationSchemaRevision(!isZetra)}
                defaultValues={defaultValue}
                onSubmit={onSubmit}
            >
                <Stack gap={3}>
                    <DoneRevisionForm warranty={watch('warranty')} />
                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={3}
                    >
                        <Button
                            startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                            variant="outlined"
                            size="medium"
                            onClick={onCloseDrawer}
                            fullWidth={isMobile}
                        >
                            Fechar
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                            variant="contained"
                            size="medium"
                            disabled={submitting}
                            fullWidth={isMobile}
                        >
                            Concluir
                        </Button>
                    </Stack>
                </Stack>
            </FormProvider>
        </Drawer>
    );
};

export default DoneRevision;
