import { creditAxiosApi, createAxiosConfig } from 'services/axiosApi';
import {
    IFiltersPayrollAgreements,
    PayrollAgreementReadModel,
} from './types/PayrollAgreementReadModel';
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import {
    GetPublicPayrollLoanReadModel,
    IGetPublicPayrollLoanParams,
} from './types/GetPublicPayrollLoanReadModel';

export async function getPayrollAgreementList(params: IFiltersPayrollAgreements, token: string) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<GetListApiResponseSuccess<PayrollAgreementReadModel>>(
        `/Product/PayrollAgreements`,
        axiosConfig
    );
}

export async function getByPayrollAgreementById(PayrollAgreementId: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get<PayrollAgreementReadModel>(`/GovernmentDepartment/GetByInternalId/${PayrollAgreementId}`, axiosConfig);
}

export async function getGetPublicPayrollLoan(
    id: string,
    params: IGetPublicPayrollLoanParams,
    token: string
) {
    const axiosConfig = createAxiosConfig(token, params);

    const urlFull = `CreditNote/${id}/GetPublicPayrollLoan`;
    return await creditAxiosApi.get<GetListApiResponseSuccess<GetPublicPayrollLoanReadModel>>(
        urlFull,
        axiosConfig
    );
}
