import { object, string } from 'yup';

export function createWalletFormSchema() {
    return object().shape({
        bankAccountId: string().typeError('Conta bancária precisa ser preenchida').required('Conta bancária precisa ser preenchida'),
        billingProductCode: string().typeError('Produto de cobrança precisa ser preenchido').required('Produto de cobrança precisa ser preenchido'),
        billingCostsDefinitionCode: string().typeError('Parâmetros de cobrança precisa ser preenhcido').required('Parâmetros de cobrança precisa ser preenhcido'),
    });
};

export const defaultValuesCreateWalletForm = {
    bankAccountId: null,
    billingProductCode: null,
    billingCostsDefinitionCode: null
};