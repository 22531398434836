import { Stack } from '@mui/material';
import { Typography, UpdateIcon } from '@uy3/web-components';
import { Actions, ActionsType } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'

type ProductContentProps = {
    children: React.ReactNode;
    title: string;
    description?: string;
    refetch?: () => void;
    isFetching?: boolean;
    buttonsActionsList?: ActionsType[]
}

export const ProductContent = ({ children, title, description, refetch, isFetching, buttonsActionsList}: ProductContentProps) => {

    return (
        <Stack spacing={4}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Stack>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        {title}
                    </Typography>
                    {description && <Typography variant="sm" display="block">{description}</Typography>}
                </Stack>

                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refetch,
                            disabled: false,
                            icon: <UpdateIcon sx={iconSx} />
                        }, 
                        ...buttonsActionsList ?? [ ]
                    ]}
                />
            </Stack>

            {children}
        </Stack>
    )
}
