/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { accountTypeOptions } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import { formatDocument } from 'helpers/validations/formFieldValidator';
import { useEffect } from 'react';
import { getTransactionType } from 'services/LiquidationSchedule';
import { LiquidationScheduleReadModel } from 'services/LiquidationSchedule/types/LiquidationScheduleReadModel';

const transactionOptions = [
    { label: 'TED', value: 'transfer' },
    { label: 'Pix', value: 'pixKey' },
    { label: 'Pix Copia e Cola', value: 'pixQrCode' },
    { label: 'Boleto bancário', value: 'bankSlip' },
];

type LiquidationScheduleFormProps = {
    bankListAutocomplete: AutocompleteOptionList;
    liquidationScheduleEdit: LiquidationScheduleReadModel | undefined;
    hideAmountInCentsField?: boolean;
};

export default function LiquidationScheduleForm({
    bankListAutocomplete,
    liquidationScheduleEdit,
    hideAmountInCentsField = false
}: LiquidationScheduleFormProps) {
    const { watch, setValue } = useFormContext();
    const paymentType = liquidationScheduleEdit?.paymentType;

    const prefix = 'transfer.to';
    const transactionTypeWatch = watch('transactionType');
    const isTransfer = transactionTypeWatch === 'transfer';

    useEffect(() => {
        if (isTransfer) setValue('transfer.type', 'transfer');
    }, [isTransfer])

    useEffect(() => {
        const transactionType = getTransactionType(paymentType);
        if (transactionType) {
            setValue('transactionType', transactionType);
        }
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const formattedValue = formatDocument(inputValue);
        if (formattedValue.length > 18) return;
        setValue(`${prefix}.registrationNumber`, formattedValue);
    };

    const handleBankChange = (_: any, option: any) => {
        setValue(`${prefix}.bankCode`, option?.value);
        setValue(`${prefix}.bankCodeDisplay`, option?.label);
        setValue(`${prefix}.bankIspb`, option?.bankIspb);
    };

    return (
        <Stack>
            <Stack spacing={4}>
                <SelectFormField
                    name="transactionType"
                    label="Tipo de transação"
                    required
                    options={transactionOptions}
                />

                {transactionTypeWatch === 'pixQrCode' && (
                    <TextFormField
                        name="pixQrCode.qrCode"
                        label="QR Code"
                        required
                        variant="outlined"
                        fullWidth
                    />
                )}
                {transactionTypeWatch === 'pixKey' && (
                    <TextFormField
                        name="pixKey.key"
                        label="Chave Pix"
                        required
                        variant="outlined"
                        fullWidth
                    />
                )}
                {transactionTypeWatch === 'bankSlip' && (
                    <TextFormField
                        name="bankSlip.barcodeOrDigitableLine"
                        fullWidth
                        label="Código de Barras"
                        variant="outlined"
                        required
                        placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
                    />
                )}
                {isTransfer && (
                    <>
                        <AutocompleteField
                            name={`${prefix}.bankCode`}
                            label="Selecione uma instituição"
                            displayName="transfer.to.bankCodeDisplay"
                            required
                            onChange={handleBankChange}
                            {...bankListAutocomplete}
                        />

                        <TextFormField
                            name={`${prefix}.name`}
                            label="Titular"
                            required
                            variant="outlined"
                        />

                        <TextFormField
                            name={`${prefix}.registrationNumber`}
                            label="CPF/CNPJ"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />

                        <TextFormField
                            variant="outlined"
                            label="Agência (sem o dígito)"
                            placeholder='Ex: "1234"'
                            name={`${prefix}.agency`}
                            fullWidth
                            required
                        />

                        <TextFormField
                            name={`${prefix}.accountNumber`}
                            label="Conta"
                            variant="outlined"
                            fullWidth
                            required
                        />

                        <SelectFormField
                            name={`${prefix}.accountType`}
                            label="Tipo de Conta"
                            required
                            options={accountTypeOptions}
                        />
                    </>
                )}
            </Stack>
            {hideAmountInCentsField === false &&
                <Grid mt={4}>
                    <CurrencyFormField
                        name="amountInCents"
                        label="Qual o valor do pagamento?"
                        fullWidth
                        variant="outlined"
                    />
                </Grid>}
        </Stack>
    );
}
