import { boolean, object, string } from 'yup';


export function validationSchemaCalculateLiquidation() {
    return object().shape({
        date: string().typeError('Data de liquidação precisa ser preenchida').required('Data de liquidação: precisa ser preenchida'),
        opId: string().typeError('Operação precisa ser prenchido').required('Oeração: precisa ser prenchido'),
        incluirVencidos: boolean(),
        liquidateByValue: string().nullable()
    });
};

export const defaultValuesCalculateLiquidation = {
    date: null,
    opId: null,
    liquidateByValue: null,
    incluirVencidos: false
};