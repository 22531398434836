import { FormProvider } from 'contexts/formContext'
import { BillingProductForm } from './BillingProductForm'
import { Stack } from '@mui/material'
import { billingProductFormSchema, defaultValuesbillingProductForm } from './BillingProductFormSchema'
import { useCreateBillingProduct } from 'contexts/wallet/Product/BillingProductContext'
import { handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext'
import { BillingProductCreateModel } from 'services/walletManagement/product/models/billingProductCreateModel'
import { useFormFieldsError } from 'contexts/formFieldsErrors'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { Fragment, useState } from 'react'

type BillingProductFormContainerProps = {
    onClose: () => void;
    refetchList: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const BillingProductFormContainer = ({ onClose, refetchList, setToast }: BillingProductFormContainerProps) => {
    const [hasAddRecepients, setHasAddRecepients] = useState<boolean>(false);
    const { setFormFieldsErrors } = useFormFieldsError();

    const onSuccess = () => {
        showSuccessToast("Produto de cobrança criado com sucesso", "", setToast);
        onClose();
        refetchList();
        setFormFieldsErrors([])
    }

    const { mutateAsync, isLoading } = useCreateBillingProduct(
        onSuccess,
        (err) => {
            const { fieldErrors } = mapErrorResponse(err);
            setFormFieldsErrors(fieldErrors ?? [])
            handleOnError(err, setToast)
        }
    );

    const onSubmit = async (values: BillingProductCreateModel) => {
        await mutateAsync({
            bankAccountDebtCosts: values.bankAccountDebtCosts ?? null,
            billingTypeValue: values?.billingTypeValue,
            code: values?.code,
            daysOverdueLimit: values?.daysOverdueLimit,
            enableAutoSubmitToDebtCollection: values?.enableAutoSubmitToDebtCollection,
            interestCodeValue: values?.interestCodeValue,
            notificationRecepients: values?.notificationRecepients ?? []
        })
    };

    return (
        <Stack>
            <FormProvider
                defaultValues={defaultValuesbillingProductForm}
                validationSchema={billingProductFormSchema()}
                onSubmit={onSubmit}
            >
                <Fragment>
                    <BillingProductForm
                        {...{
                            onClose,
                            isLoading,
                            setHasAddRecepients, 
                            hasAddRecepients
                        }} />
                </Fragment>
            </FormProvider>
        </Stack>
    )
}
