import { Stack } from '@mui/material'
import { Button, CancelIcon, CompleteIcon, Drawer, EmailIcon, Typography } from '@uy3/web-components';
import { CheckboxFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { UpdateEmailsFormContainer } from 'components/Forms/UpdateEmailsForm/UpdateEmailsFormContainer';
import { ListNamesType, RenderAvatarsName } from 'components/RenderAvatarsName/RenderAvatarsName';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { isValidString } from 'helpers';
import { FieldValues } from 'react-hook-form';
import { activeTheme } from 'services/theme';
import { interestCodeOptionsList } from 'services/walletManagement/product/generics';

type BillingProductFormProps = {
    onClose: () => void;
    isLoading: boolean;
    hasAddRecepients: boolean
    setHasAddRecepients: (value: React.SetStateAction<boolean>) => void
};

const theme = activeTheme();

export const BillingProductForm = ({ onClose, isLoading, hasAddRecepients, setHasAddRecepients }: BillingProductFormProps) => {
    const { setValue, watch } = useFormContext();
    var notificationRecepients = watch('notificationRecepients') as string[];

    const AddRecepientsAsync = (values: FieldValues) => {
        if (values?.notificationRecepients?.length > 0) {
            setValue('notificationRecepients', values?.notificationRecepients);
        };
        setHasAddRecepients(false);
    };

    const list = notificationRecepients?.filter((x) => isValidString(x))?.map((item) => {
        return {
            id: Math.random().toString(),
            name: item
        }
    }) as ListNamesType[] ?? [];

    return (
        <Stack spacing={2}>
            <TextFormField
                label="Código"
                name='code'
                variant='outlined'
                required
            />
            <TextFormField
                label="Conta tarifa"
                name='bankAccountDebtCosts'
                variant='outlined'
            />
            <TextFormField
                label="Dias limite pagamento"
                name='daysOverdueLimit'
                variant='outlined'
            />
            <SelectFormField
                {...{
                    label: "Tipo de cobrança",
                    name: "billingTypeValue",
                    fullWidth: true,
                    required: true,
                    options: [
                        { label: "Mensal", value: "Monthly" },
                        { label: "Diária", value: "Daily" }
                    ]
                }}
            />
            <SelectFormField
                {...{
                    label: "Codigo Juros",
                    name: "interestCodeValue",
                    fullWidth: true,
                    options: interestCodeOptionsList
                }}
            />

            <CheckboxFormField name='enableAutoSubmitToDebtCollection' label='Habilitar envio automático cartório' />

            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                {list?.length > 0 ? <RenderAvatarsName list={list} title='Destinatários'/> : <Typography></Typography>}
                <Button variant='text' sx={{ color: 'black' }} onClick={() => setHasAddRecepients(true)} startIcon={<EmailIcon />}>
                    Adicionar destinatários
                </Button>
            </Stack>

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button variant='outlined' onClick={onClose} startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}>
                    Não, cancelar
                </Button>
                <Button type='submit' variant='contained' disabled={isLoading} startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}>
                    Continuar
                </Button>
            </Stack>


            <Drawer
                {...{
                    title: `Adicionar destinatários`,
                    open: !!hasAddRecepients,
                    anchor: 'right',
                    onClose: () => setHasAddRecepients(false),
                    toggleDrawer: true
                }}
            >
                <UpdateEmailsFormContainer {...{
                    recepientsList: watch('notificationRecepients') ?? [],
                    onClose: () => setHasAddRecepients(false),
                    updateEmailsWalletMutate: async (values) => AddRecepientsAsync(values)
                }}
                />
            </Drawer>
        </Stack>
    )
}
