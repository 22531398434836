import React from 'react'
import { AddPersonForm } from './AddPersonForm'
import { Stack } from '@mui/material'
import { FormProvider } from 'contexts/formContext'
import { FieldValues } from 'react-hook-form'
import { addPersonFormDefaultValues, addPersonFormSchema } from './AddPersonFormSchema'
import { useNaturalPersonMutation } from 'contexts/naturalPersonContext'
import { ApiResponseError, handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext'
import { NaturalPersonSchema } from 'pages/NaturalPerson/NaturalPersonForm/NaturalPersonSchema'
import { useFormFieldsError } from 'contexts/formFieldsErrors'

type AddPersonFormContainerProps = {
  onCloseAddPerson: () => void;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
  setFormValues: React.Dispatch<React.SetStateAction<FieldValues | null>>
  formValues: FieldValues
}

export const AddPersonFormContainer = ({ onCloseAddPerson, setToast, setFormValues, formValues }: AddPersonFormContainerProps) => {

  const { setFormFieldsErrors } = useFormFieldsError();

  const onSuccess = (response: any) => {
    showSuccessToast("Pessoa criada com sucesso", "", setToast);
    debugger
    setFormValues((prev) => ({...prev, person: response}))
    setFormFieldsErrors([]);
    onCloseAddPerson()
  }

  const onError = (error: ApiResponseError) => handleOnError(error as ApiResponseError, setToast, setFormFieldsErrors);

  const { mutate, isLoading } = useNaturalPersonMutation('nova', onSuccess, onError, "Full");

  const onSubmitPerson = async (values: NaturalPersonSchema) => {
    await mutate(values);
  }

  return (
    <Stack>
      <FormProvider
        defaultValues={addPersonFormDefaultValues(formValues?.registrationNumber)}
        validationSchema={addPersonFormSchema()}
        onSubmit={onSubmitPerson}
      >
        <AddPersonForm onClose={onCloseAddPerson} isLoading={isLoading} />
      </FormProvider>
    </Stack>
  )
}
