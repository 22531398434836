import { Drawer } from '@uy3/web-components';
import { lazy, Suspense, useState } from 'react';
import { SearchNaturalPersonContainer } from '../../Generics/SearchNaturalPerson/SearchNaturalPersonContainer';
import { useCheckPurchaseContracts, useZetraMarginQuery } from 'contexts/zetra';
import { FormProvider } from 'contexts/formContext';
import { isValidString, onlyNumbers } from 'helpers';
import UpdateConsignmentForPurchase from 'components/GenericForms/Consignment/update/UpdateConsignment';
import { PayrollCreditNoteSharedProps } from '../../../PayrollCreditNoteContainer';
import { personDebtPurchaseSchema, personDebtPurchaseDefaultValues } from './personDebtPurchaseSchema';
import { IResumo, IZetraMarginQueryReadModel, TCheckPurchaseContractsParams } from 'services/zetra';
import { FieldValues } from 'react-hook-form';
import { SimualtionDetailsSkeleton } from 'components/Skeleton/SimualtionDetailsSkeleton';
import { BaseContainerStepForm } from '../../Generics';
import { mapConsignmentWarranty } from 'services/creditNote';
import { useSiapeMarginQuery } from 'contexts/datasetContext';
import { ISiapeMarginQueryReadModel } from 'services/datasets';

const ClientDebtPurchase = lazy(() => import('./PersonDebtPurchase'));

type PersonDebtPurchaseContainerProps = {} & PayrollCreditNoteSharedProps;

export const PersonDebtPurchaseContainer = (props: PersonDebtPurchaseContainerProps) => {
  const { setToast, onNextStep, setFormValues, formValues } = props;

  const [action, setAction] = useState<string | undefined>(undefined);
  const [documentNumber, setDocumentNumber] = useState<string | null>(formValues?.registrationNumber ?? null);
  const registrationNumber = onlyNumbers(documentNumber ?? "");
  const [employeeCode, setEmployeeCode] = useState<string | undefined>(formValues?.employeeCode ?? undefined);

  const warrantyRegistrationOffice = formValues?.payrollAgreement?.warrantyRegistrationOffice;
  // const supportedOffices = ["zetra", "siape"];
  // const isSupoortedOffice = supportedOffices.includes(warrantyRegistrationOffice?.toLowerCase());

  const isZetra = String(warrantyRegistrationOffice ?? "").toLowerCase() === 'zetra';

  const { data: siapeMarginQuery } = useSiapeMarginQuery({
    registrationNumber: isZetra ? undefined : registrationNumber,
  });

  const { zetraMarginQueryData } = useZetraMarginQuery(
    { registrationNumber, employeeCode },
    isZetra,
    setToast
  );

  const zetraData = zetraMarginQueryData?.[0] as IZetraMarginQueryReadModel;
  const siapeData = siapeMarginQuery?.[0] as ISiapeMarginQueryReadModel;

  const isValidRegistrationNumber = isValidString(registrationNumber) && registrationNumber.length >= 11;

  const params = {
    registrationNumber: registrationNumber,
    employeeCode: employeeCode
  } as TCheckPurchaseContractsParams;

  const { data: purchaseContractsData, isLoading, refetch, error: errorPurchaseContractsData } = useCheckPurchaseContracts(params, isValidRegistrationNumber, warrantyRegistrationOffice);

  function getGovernmentDepartmentCodeByMarginQuery() {
    if (isZetra) {
      const orgaoCodigo = zetraData?.result?.servidores?.find(x => x.matricula === employeeCode)?.orgaoCodigo;
      return orgaoCodigo;
    } else {
      const codOrgao = siapeData?.result?.VinculoFuncionals?.find(x => x?.CdMatricula === employeeCode)?.CodOrgao;;
      return codOrgao;
    }
  };

  const onSubmit = (values: FieldValues) => {
    const additionalInformation = {
      warrantyOption: "Portability",
      payrollAgreementId: formValues?.payrollAgreement?.id,
      payrollAgreementName: formValues?.payrollAgreement?.name,
      employeeCode,
    }

    const contractsValues = (values?.contracts as IResumo[]) ?? [];
    const governmentDepartmentCode = getGovernmentDepartmentCodeByMarginQuery();

    const warrantyConsignment = contractsValues?.map((x: IResumo) => {
      const mapper = mapConsignmentWarranty({
        resumo: x,
        newValues: additionalInformation,
        registrationNumber,
        governmentDepartmentCode
      });
      return mapper;
    });

    if (contractsValues?.length === 0) {
      return setToast({
        open: true,
        severity: 'info',
        title: "Selecione ao menos 1 contrato para seguir"
      })
    }

    //@ts-ignore
    if (contractsValues?.length > 0 && !contractsValues?.every(x => isValidString(String(x?.saldoDevedor)) && x?.saldoDevedor > 0)) {
      return setToast({
        open: true,
        severity: 'info',
        title: "Importante",
        description: "É necessário informar o saldo devedor para os contratos selecionados."
      });
    }

    // does not allow values ​​higher than the original installment
    const valueIsInvalid = contractsValues.some(x => {
      const origin = purchaseContractsData?.resumos.find(resumo => resumo?.adeIdentificador === x.adeIdentificador);
      return x.valorParcela > (origin?.valorParcela ?? 0)
    });

    if (valueIsInvalid) {
      return setToast({
        open: true,
        severity: 'info',
        title: "Importante",
        description: "O valor da parcela alterado não pode ser maior que o valor original da parcela."
      });
    }
 
    const data = {
      person: values?.person ?? null,
      registrationNumber: values.registrationNumber,
      employeeCode: values?.employeeCode,
      contracts: values?.contracts,
      warrantyConsignment: warrantyConsignment,
      totalvalueOutstandingBalance: (values?.totalvalueOutstandingBalance ?? 0) * 100
    };

    setFormValues((prev) => ({ ...prev, ...data }));
    onNextStep();
  }

  const onClose = () => setAction(undefined);


  return (
    <Suspense fallback={<SimualtionDetailsSkeleton />}>
      <FormProvider {... {
        defaultValues: personDebtPurchaseDefaultValues(formValues),
        validationSchema: personDebtPurchaseSchema(),
        onSubmit,
        onChangeField: [
          {
            fieldName: 'registrationNumber',
            delegate(fieldValue, setValue, watch) {
              setDocumentNumber(fieldValue)
            },
          },
          {
            fieldName: 'employeeCode',
            delegate(fieldValue, setValue, watch) {
              setEmployeeCode(fieldValue)
            },
          },
        ]
      }}>
        <BaseContainerStepForm {...props}>
          <ClientDebtPurchase
            isValidRegistrationNumber={isValidRegistrationNumber}
            searchClient={() => setAction('searchClient')}
            contractsUpdate={() => setAction('queryContracts')}
            currentValues={formValues}
            isLoading={(isLoading && isValidRegistrationNumber)}
            purchaseContractsData={purchaseContractsData}
            errorPurchaseContractsData={errorPurchaseContractsData}
          />

          <UpdateConsignmentForPurchase
            {...{
              openDrawer: action === 'queryContracts',
              onCloseDrawer: onClose,
              registrationNumber,
              employeeCode,
              setToast,
              refetch,
              resource: warrantyRegistrationOffice,
              title: 'Consultar consignações para compra',
              description: 'Consulte as consignações disponíveis para serem envolvidas em processos de compra.'
            }}
          />

          <Drawer
            open={action === 'searchClient'}
            onClose={onClose}
            anchor="right"
            title="Buscar pessoa"
          >
            <SearchNaturalPersonContainer onClose={onClose} setDocumentNumber={setDocumentNumber} />
          </Drawer>
        </BaseContainerStepForm>
      </FormProvider>
    </Suspense>
  );
};