import { createAxiosConfig, creditAxiosApi } from "services/axiosApi/axiosApi";
import { TenantRequestCreateModel } from "./Types/tenantRequestCreateModel";
import { TenantRequestUpdateModel } from "./Types/tenantRequestUpdateModel";

var url = "TenantRequest";

export async function createTenantRequest(body: TenantRequestCreateModel, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(`${url}`, body, axiosConfig);
}

export async function updateTenantRequest(id: string, body: TenantRequestUpdateModel, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.put(`${url}/${id}`, body, axiosConfig);
}

export async function deleteTenantRequest(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.delete(`${url}/${id}`,axiosConfig);
}

export async function getByIdTenantRequest(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get(`${url}/${id}`,axiosConfig);
}

export async function getAllTenantRequest(token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get(`${url}`,axiosConfig);
}