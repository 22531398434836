import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import {
    validationSchemaCalculateLiquidation,
    defaultValuesCalculateLiquidation,
} from './CalculateLiquidationSchema';
import { Button, Stack } from '@mui/material';
import { CalculationLiquidationHeader } from './CalculationLiquidationHeader';
import { GenerateRemessaLiquidacaoOpForm } from '../GenerateFileLiquidation/Forms/RemessaLiquidacaoForOpForm/RemessaLiquidacaoForOpForm';
import { useIdentity } from 'contexts/identityContext';
import {
    ApiResponseError,
    ToastType,
    handleOnError,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { IRemessaLiquidacaoForOp, IResponseForOp } from 'services/remessasVortx/remessasVortx.type';
import {
    getRemessaLiquidacaoForOp,
    postRemessaLiquidacaoForOp,
} from 'services/remessasVortx/remessasVortx';
import { FieldValues } from 'react-hook-form';
import Toast from 'components/Toast/Toast';
import { ResponseForOp } from './ResponseForOp';
import { RefreshProgress } from 'components/RefreshProgress';
import { activeTheme } from 'services/theme';
import { CompleteIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';

export const CalculateLiquidationContainer = () => {
    const { token } = useIdentity();
    const { endRequest, startRequest, submitting: isLoading } = useApiRequest();
    const [queryResponse, setQueryResponse] = useState<IResponseForOp | null>(null);

    const [toast, setToast] = useState<ToastType>(toastState);

    const getLiquidationValues = async (values: IRemessaLiquidacaoForOp) => {
        startRequest();
        try {
            var { data } = await getRemessaLiquidacaoForOp(values, token!);
            const responseDate = data as IResponseForOp;
            setQueryResponse(responseDate);
            showSuccessToast('Sucesso ao calcular a liquidação', '', setToast);
            endRequest(true);
        } catch (error) {
            endRequest(false);
            handleOnError(error as ApiResponseError, setToast);
        }
    };

    const handleSubmitForm = async (values: FieldValues) => {
        await getLiquidationValues({
            date: values?.date,
            liquidateByValue: values?.liquidateByValue,
            opId: values?.opId,
            incluirVencidos: values?.incluirVencidos
        });
    };

    const liquidateOp = async (values: FieldValues) => {
        startRequest();
        try {
            var { data } = await postRemessaLiquidacaoForOp(
                values as IRemessaLiquidacaoForOp,
                token!
            );
            const responseDate = data as IResponseForOp;
            setQueryResponse(responseDate);
            showSuccessToast('Sucesso ao calcular a liquidação', '', setToast);
            endRequest(true);
        } catch (error) {
            endRequest(false);
            handleOnError(error as ApiResponseError, setToast);
        }
    };
    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />

            <RefreshProgress refreshing={isLoading} />

            <FormProvider
                defaultValues={defaultValuesCalculateLiquidation}
                validationSchema={validationSchemaCalculateLiquidation()}
                onSubmit={handleSubmitForm}
            >
                <CalculateOpTableResult
                    isLoading={isLoading}
                    queryResponse={queryResponse}
                    liquidateOp={liquidateOp}
                ></CalculateOpTableResult>
            </FormProvider>
        </Stack>
    );
};

type CalculateOpTableResultProps = {
    isLoading: boolean;
    queryResponse: IResponseForOp | null;
    liquidateOp: (values: FieldValues) => Promise<void>;
};

const CalculateOpTableResult: React.FC<CalculateOpTableResultProps> = ({
    isLoading,
    queryResponse,
    liquidateOp,
}) => {
    const theme = activeTheme();
    const { getValues } = useFormContext();
    return (
        <>
            <CalculationLiquidationHeader isLoading={isLoading} />
            <GenerateRemessaLiquidacaoOpForm showCheckbox={queryResponse != null} />
            {queryResponse !== null && (
                <>
                    <Stack spacing={2} marginTop={4}>
                        <ResponseForOp response={queryResponse} />
                    </Stack>
                    <Stack direction="column-reverse" alignItems="end">
                        <Button
                            variant="text"
                            size="large"
                            type="submit"
                            disabled={isLoading}
                            onClick={() => {
                                liquidateOp(getValues());
                            }}
                            sx={{ color: theme.palette.common.black }}
                            startIcon={
                                <CompleteIcon
                                    sx={iconSx}
                                    htmlColor={isLoading ? 'grey' : theme.palette.common.black}
                                />
                            }
                        >
                            Baixar parcelas
                        </Button>
                    </Stack>
                </>
            )}
        </>
    );
};
