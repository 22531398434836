import { PersonFull } from 'contexts/personContext';
import { isEmpty } from 'lodash';
import { formatDocumentNumber, maskCurrencyInCents, onlyNumbers } from 'helpers';
import { RelatedPersonFull } from 'services/creditProduct';
import { BankAccountCreateOrUpdateModel, EPixKeyType } from 'utils';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { IProductMarginQuery, ISiapeMarginQueryReadModel } from 'services/datasets';
import { WarrantyRegistrationOffice } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';

type RedirectByLineType = PersonFull & RelatedPersonFull;

type DescriptionFreeMarginType = {
  title: string;
  description: string;
  totalValueMarginQuery?: number;
  isSuccessAndNotErrorMessage?: boolean
}

export const genericMessageRequired = 'precisa ser preenchido.';

export const handleRedirectByLine = (row: RedirectByLineType) => {
  const discriminator = row?.personDiscriminator ?? row?.discriminator;
  const isNaturalPerson = discriminator === 'NaturalPerson';
  const pathLink = isNaturalPerson
    ? `/pessoas-fisicas/todas/${row?.personId}`
    : `/pessoas-juridicas/todas/${row?.personId}`;
  return pathLink;
};

export const isSupportedFileType = (fileType: string): boolean => {
  const supportedFileTypes = [
    'Draft',
    'RegistrationForm',
    'Assignment',
    'Addition',
    'FiduciaryAssignment',
    'Procuration',
    'TermOfOwnership',
    'Collateral',
    'PLDFT',
    'Receipt',
    'AssignmetReport',
  ];
  return supportedFileTypes.includes(fileType);
};

export const isActionAllowed = (
  readOnly: boolean,
  hasPermissionCustom: boolean,
  rootTenancy: boolean
): boolean => !readOnly && hasPermissionCustom && rootTenancy;

type HasPermissionFunc = (permission: PermissionType, type: string) => boolean;
type PermissionType =
  | 'CreditNote'
  | 'CreditProduct'
  | 'BankAccountProduct'
  | 'Fund'
  | 'NaturalPerson'
  | 'LegalPerson'
  | 'BatchAssignment'
  | 'BankAccount'
  | 'BankAccountRequest';

// Mapeamento de nomes para tipos de permissão
const permissionMap: { [key: string]: PermissionType } = {
  CreditNote: 'CreditNote',
  CreditProduct: 'CreditProduct',
  BankAccountProduct: 'BankAccountProduct',
  Fund: 'Fund',
  NaturalPerson: 'NaturalPerson',
  LegalPerson: 'LegalPerson',
  BatchAssignment: 'BatchAssignment',
  BankAccount: 'BankAccount',
  BankAccountRequest: 'BankAccountRequest',
};

// Função genérica para verificar a permissão customizada
export const hasCustomPermission = (resource: PermissionType, typePermission: string, hasPermission: HasPermissionFunc): boolean => {
  const permissionType: PermissionType = permissionMap[resource]
  return hasPermission(permissionType, typePermission);
};

export const runSkeleton = (isLoading: boolean, filters: Record<string, any> | undefined): boolean => {
  return isLoading && Object.values(filters?.filters ?? {}).length === 0;
};

export function localPaginateDataTable<T>(array: T[], pageNumber: number, pageSize: number): T[] {
  const startIndex = pageNumber * pageSize;
  return array.slice(startIndex, startIndex + pageSize);
}

export const handlePersonLink = (personId: string, discriminator: string): string => {
  if (discriminator === 'LegalPerson') {
    return `/pessoas-juridicas/todas/${personId}`;
  } else return `/pessoas-fisicas/todas/${personId}`;
};

export function everyItemsIsPendingApproval(list: []) {
  if (list?.length === 0) return false;
  return list?.every((x: any) => {
    return String(x?.status) === 'Pending' || Number(x?.status) === 2;
  });
}

/**
 * Verifica se uma chave Pix já está registrada na lista de contas bancárias.
 *
 * @param {string | undefined | null} pixKey - A chave Pix a ser validada.
 * @param {BankAccountCreateOrUpdateModel[]} bankAccounts - Lista de contas bancárias.
 * @returns {boolean} Retorna `true` se a chave Pix já estiver registrada, `false` caso contrário.
 */
export function isPixKeyDuplicate(
  currentPixKey: string | undefined | null,
  bankAccounts: BankAccountCreateOrUpdateModel[] = []
): boolean {
  if (!currentPixKey) return false;

  return bankAccounts.some((bankAccount) => {
    let keyPix = null;
    let sanitizedCurrentPixKey = currentPixKey;

    switch (bankAccount.pixKeyTypeValue) {
      case EPixKeyType.NaturalRegistrationNumber:
        keyPix = onlyNumbers(bankAccount.keyPix!);
        sanitizedCurrentPixKey = onlyNumbers(currentPixKey);
        break;
      case EPixKeyType.LegalRegistrationNumber:
        keyPix = onlyNumbers(bankAccount.keyPix!);
        sanitizedCurrentPixKey = onlyNumbers(currentPixKey);
        break;
      case EPixKeyType.Phone:
        keyPix = onlyNumbers(bankAccount.keyPix!);
        sanitizedCurrentPixKey = onlyNumbers(currentPixKey);
        break;
      default:
        keyPix = bankAccount.keyPix;
        break;
    }

    return arePixKeysEqual(keyPix, sanitizedCurrentPixKey);
  });
}

function arePixKeysEqual(sanitizedBankPixKey: string | null | undefined, sanitizedCurrentPixKey: string): boolean {
  return sanitizedBankPixKey === sanitizedCurrentPixKey;
}

/**
 * Valida o formulário de conta bancária. Verifica se a conta bancária já existe na lista de contas bancárias.
 *
 * @param {BankAccountCreateOrUpdateModel} formData - Dados do formulário.
 * @param {BankAccountCreateOrUpdateModel[]} existingAccounts - Lista de contas já existentes.
 * @returns {boolean} Retorna `true` se houver duplicação, ou `false` caso contrário.
 */
export function isBankAccountDuplicate(
  formData: BankAccountCreateOrUpdateModel,
  existingAccounts: BankAccountCreateOrUpdateModel[]
): boolean {
  const isDuplicate = existingAccounts.some((account) => {
    if (isIncompleteAccount(account)) return false;

    return (
      String(account.operationTypeValue) === String(formData.operationTypeValue) &&
      String(account.pixKeyTypeValue) === String(formData.pixKeyTypeValue) &&
      Number(account.bankCode) === Number(formData.bankCode) &&
      Number(account.agency) === Number(formData.agency) &&
      Number(account.account) === Number(formData.account) &&
      Number(account.accountDigit) === Number(formData.accountDigit)
    );
  });

  return isDuplicate;
}

function isIncompleteAccount(account: BankAccountCreateOrUpdateModel): boolean {
  return (
    isEmpty(account.bankCode) ||
    isEmpty(account.agency) ||
    isEmpty(account.account) ||
    isEmpty(account.accountDigit)
  );
}

/**
 * Aplica filtro para os cenários de get do produto na nova simulação.
 */
export const applyFilteredQueryProduct = (payrollAgreementCode: string, isCompraDeDivida: boolean ) => {
  return {
    page: 0,
    size: 20,
    categoryName: !isCompraDeDivida ? 'CONSIGNADO PÚBLICO' : undefined,
    payrollAgreementId: payrollAgreementCode,
    isConsiderPostingDeadline: true
  }
}

type MapDescriptionFreeMarginType = {
  zetraMarginQueryData: IZetraMarginQueryReadModel | null,
  siapeMarginQuery: ISiapeMarginQueryReadModel | null,
  warrantyRegistrationOffice: WarrantyRegistrationOffice,
  hasPermission: boolean,
  registrationNumber: string
}

export const mapDescriptionFreeMargin = ({
  hasPermission,
  registrationNumber,
  siapeMarginQuery,
  warrantyRegistrationOffice,
  zetraMarginQueryData
}: MapDescriptionFreeMarginType): DescriptionFreeMarginType => {
  const defaultTitle = "Margem livre";

  if (!hasPermission) {
    return {
      title: defaultTitle,
      description: "Operador sem permissão, solicite ao seu gerente comercial a consulta de margem", 
      totalValueMarginQuery: 0
    };
  }

  const supportedOffices = ["zetra", "siape"];
  if (!supportedOffices.includes(warrantyRegistrationOffice?.toLowerCase())) {
    return {
      title: defaultTitle,
      description: "Convênio não suporta margem online",
      totalValueMarginQuery: 0
    };
  }

  // Processamento Siape
  if (siapeMarginQuery) {
    const siapeResult = siapeMarginQuery.result;
    const isEmptyList = siapeResult?.VinculoFuncionals?.length === 0;

    if (isEmptyList && siapeResult?.DsRetCode) {
      return {
        title: defaultTitle,
        description: siapeResult?.DsRetCode,
        totalValueMarginQuery: 0
      };
    }

    if (siapeResult?.DsRetCode === null || siapeResult?.DsRetCode === undefined) {
      return {
        title: defaultTitle,
        description: registrationNumber,
        isSuccessAndNotErrorMessage: true,
        totalValueMarginQuery: 0
      }
    }

    const totalValue = siapeResult?.VinculoFuncionals
      .flatMap(vinculo => vinculo.Produtos)
      .reduce((acc, product: IProductMarginQuery) => acc + (product?.VlMargemDisp ?? 0), 0);

    return {
      title: "Saldo disponível",
      description: maskCurrencyInCents(totalValue),
      totalValueMarginQuery: totalValue * 100
    };
  }

  // Processamento Zetra
  if (zetraMarginQueryData?.result) {
    const zetraResult = zetraMarginQueryData.result;
    const isEmptyList = (zetraResult?.servidores ?? [])?.length === 0;

    if (isEmptyList && zetraResult.mensagem) {
      return {
        title: defaultTitle,
        description: zetraResult.mensagem,
        totalValueMarginQuery: 0
      };
    }

    if (zetraResult?.mensagem === null || zetraResult?.mensagem === undefined) {
      return {
        title: defaultTitle,
        description: registrationNumber,
        isSuccessAndNotErrorMessage: true,
        totalValueMarginQuery: 0
      }
    }

    const totalValue = zetraResult?.servidores
      .flatMap(servidor => servidor?.margens)
      .map(x => x.valorDisponivel)
      .reduce((acc, margem) => acc + margem, 0);

    return {
      title: "Saldo disponível",
      description: maskCurrencyInCents(totalValue),
      totalValueMarginQuery: totalValue * 100
    };
  }

  return {
    title: defaultTitle,
    description: formatDocumentNumber(registrationNumber) ?? "",
    totalValueMarginQuery: 0
  };
};