import { FormProvider } from 'contexts/formContext'
import { useState } from 'react'
import { generateAssignmentDefaultValues, generateAssignmentFormSchema } from './GenerateAssignmentFormSchema'
import GenerateAssignmentForm from './GenerateAssignmentForm'
import { GenerateAssignmentType } from 'services/creditNote/types/creditNoteReadModel'

type GenerateAssignmentFormContainerProps = {
  onClose: () => void;
  onGenerateAssignment: (payload: GenerateAssignmentType) => void;
}

const GenerateAssignmentFormContainer = ({ onClose, onGenerateAssignment }: GenerateAssignmentFormContainerProps) => {
  const [agioDesagio, setAgioDesagio] = useState<boolean>(false);

  const onSubmit = (values: GenerateAssignmentType) => {
    let payload = {
      assignmentCalculation: agioDesagio ? values?.assignmentCalculation : null,
      sendEmail: values.sendEmail,
      ignoreOverDuePayment: values?.ignoreOverDuePayment
    } as GenerateAssignmentType;
    onGenerateAssignment(payload);
  }

  return (
    <FormProvider
      validationSchema={generateAssignmentFormSchema(agioDesagio)}
      defaultValues={generateAssignmentDefaultValues}
      onSubmit={onSubmit}
    >
      <GenerateAssignmentForm
        agioDesagio={agioDesagio}
        setAgioDesagio={setAgioDesagio}
        onClose={onClose}
      />
    </FormProvider>
  )
}

export default GenerateAssignmentFormContainer
