import { Stack } from '@mui/material';
import { PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { BankSlipDetails, BeneficiaryPayerDetails } from 'components/GenericForms/BankSlip';
import { Typography } from '@uy3/web-components';

type PaySlipDetailsProps = {
    bankSlipData: PaymentBankSlip;
};

const PaySlipDetails = ({ bankSlipData }: PaySlipDetailsProps) => {
    return (
        <Stack spacing={3} mt={4}>
            <Typography variant="md" fontWeight="700" mb={2}>
                Dados do beneficiário e pagador original
            </Typography>

            <BeneficiaryPayerDetails
                title="Beneficiário"
                document={bankSlipData?.beneficiary?.document}
                name={bankSlipData?.beneficiary?.name}
            />

            <BeneficiaryPayerDetails
                title="Pagador"
                document={bankSlipData?.payer?.document}
                name={bankSlipData?.payer?.name}
            />

            <Typography variant="md" fontWeight="700">
                Detalhes do boleto
            </Typography>

            <BankSlipDetails bankSlipData={bankSlipData} showPaymentValueField={false} />
        </Stack>
    );
};

export default PaySlipDetails;
