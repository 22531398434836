import { useMutation, useQuery } from "@tanstack/react-query";
import { useIdentity } from "./identityContext";
import { ApiResponseError, useApiRequest } from "./apiRequestContext";
import { handleErrorUseQuery } from "helpers";
import { createTenantRequest, deleteTenantRequest, getAllTenantRequest, getByIdTenantRequest, updateTenantRequest } from "services/Partern/TenantRequest/tenantRequest";
import { TenantRequestCreateModel } from "services/Partern/TenantRequest/Types/tenantRequestCreateModel";
import { TenantRequestUpdateModel } from "services/Partern/TenantRequest/Types/tenantRequestUpdateModel";
import { TenantRequestReadModel } from "services/Partern/TenantRequest/Types/tenantRequestReadModel";
import { isAxiosError } from "axios";

export function useGetAllTenantRequest() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-tenant-request-all-items'],
        queryFn: async (): Promise<TenantRequestReadModel[]> => {
            startRequest();
            const { data, status, statusText } = await getAllTenantRequest(token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as TenantRequestReadModel[]
        },
    });
}

export function useGetByIdTenantRequest(id?: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token && id !== 'novo' && !!id,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-tenant-request-by-id', id],
        queryFn: async (): Promise<TenantRequestReadModel> => {
            startRequest();
            const { data, status, statusText } = await getByIdTenantRequest(id!, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as TenantRequestReadModel
        },
    });
}

export function useCreateTenantRequestMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (body: TenantRequestCreateModel) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await createTenantRequest(body, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useUpdateTenantRequestMutation(
    id: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (body: TenantRequestUpdateModel) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await updateTenantRequest(id, body, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useDeleteTenantRequestMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (id: string) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await deleteTenantRequest(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}
