import { Stack, SvgIconProps } from "@mui/material";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { OptionCard, TitleCard } from "./OptionCards.style";
import { activeTheme } from "services/theme";
import { Typography } from "@uy3/web-components";
import { isValidString } from "helpers";

type OptionType = {
  label: string;
  Icon: FunctionComponent<SvgIconProps>;
  onClick: () => void;
  title?: string;
  description?: string;
  disable?: boolean
  additionalInformation?: ReactNode
};

type OptionCardsType = {
  options: OptionType[];
};

const palette = activeTheme().palette;

export const OptionCards: React.FC<OptionCardsType> = ({ options }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const itemSelected = hoveredIndex !== null ? options[hoveredIndex] : null;
  const title = itemSelected?.title ?? '';
  const description = itemSelected?.description ?? '';
  const hasTitleAndDescription = isValidString(title) && isValidString(description);

  return (

    <Stack>
      <Stack direction="row" alignItems="center" gap={5} flexWrap='wrap'>
        {options.map((item, index) => {
          const isHovered = hoveredIndex === index;
          const isIconCustomSx = ['fgts'].includes(item?.label.toLowerCase());
          return (
            <OptionCard
              disable={item.disable ?? false}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={item.onClick}
            >
              <item.Icon
                sx={isIconCustomSx ? { width: 50, height: 50, mt: -1.7 } : OptionsCardStyle.icon}
                htmlColor={isHovered && !item.disable ? palette.primary.main : palette.grey[800]}
              />
              <TitleCard className="title">{item.label}</TitleCard>
            </OptionCard>
          );
        })}
      </Stack>
      {hasTitleAndDescription &&
        <Stack display='block' borderRadius='8px' border={`1px solid ${palette.grey[600]}`} padding='48px 24px' marginRight={20} mt={2}>
          <Typography fontSize='25px' fontWeight={600}>
            {title}
          </Typography>

          <Typography fontSize='16px' fontWeight={400} pt={2}>
            {description}
          </Typography>

          {itemSelected?.additionalInformation ?? <></>}
        </Stack>}
    </Stack>

  );
};

const OptionsCardStyle = {
  icon: {
    height: "24px",
    width: "24px",
  },
};
