import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const FgtsIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="9" viewBox="0 0 40 9" fill="none">
        <path d="M19.5605 0V0.855705C19.5605 1.29905 19.1556 1.65844 18.6561 1.65844H12.0879V6.63375H17.6923V4.97523H14.8603C14.3608 4.97523 13.956 4.61585 13.956 4.1725V3.31687H18.6561C19.1556 3.31687 19.5605 3.67626 19.5605 4.11961V7.48937C19.5605 7.93272 19.1556 8.2921 18.6561 8.2921H11.124C10.6246 8.2921 10.2198 7.93272 10.2198 7.48937V0.802732C10.2198 0.359387 10.6246 0 11.124 0H19.5605Z" fill={htmlColor} />
        <path d="M29.7802 0V0.855705C29.7802 1.29905 29.3753 1.65844 28.8759 1.65844H26.0439V7.48937C26.0439 7.93272 25.6391 8.2921 25.1397 8.2921H24.1758V1.65844H21.3438C20.8444 1.65844 20.4395 1.29905 20.4395 0.855705V0H29.7802Z" fill={htmlColor} />
        <path d="M40 4.11961V7.45549L39.0576 8.2921H30.6593V7.4364C30.6593 6.99305 31.0641 6.63367 31.5635 6.63367H38.1318V4.97523H31.5635C31.0641 4.97523 30.6593 4.61584 30.6593 4.1725V0.802732C30.6593 0.359387 31.0641 0 31.5635 0H40V0.855705C40 1.29905 39.5951 1.65844 39.0957 1.65844H32.5274V3.31687H39.0957C39.5951 3.31687 40 3.67626 40 4.11961Z" fill={htmlColor} />
        <path d="M1.86807 1.78739H8.4712C8.9514 1.78739 9.34063 1.44185 9.34063 1.01556V0.128951H0V8.42105H0.998645C1.47884 8.42105 1.86807 8.07544 1.86807 7.64923V4.97779H5.37772C6.02422 4.97779 6.5484 4.6184 6.5484 4.17506V3.31935H1.86807V1.78739Z" fill={htmlColor} />
      </svg>
    </SvgIcon>
  );
};

FgtsIcon.defaultProps = {
  viewBox: '0 0 40 9',
  width: '40',
  height: '9',
  fill: 'none',
};
