import { Box, Grid, Stack } from "@mui/material";
import CurrencyFormField from "components/Forms/FormFields/CurrencyFormField/CurrencyFormField";
import { ContainerFormFields } from "pages/Partners/PartnersRequest/Generics/ContainerFormFields";
import MainTitle from "pages/Partners/PartnersRequest/Generics/MainTitle";

export default function CaasForm() {
  return (
    <Stack spacing={2}>
      <MainTitle title="Defina os custos de formalização do contrato CAAS" />

      <ContainerFormFields title="Formalização">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Nova assinatura"
                name="tenantRequestCaas.newSignatureFormalizationValue"
                fullWidth
                variant="outlined"
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Recorrência"
                name="tenantRequestCaas.recurrenceFormalizationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

          </Grid>
        </Box>
      </ContainerFormFields>

      <MainTitle title="Custos de ativação" />

      <ContainerFormFields title="Plataforma, API e BI">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Setup"
                name="tenantRequestCaas.setupActivationCostValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="White label"
                name="tenantRequestCaas.whiteLabelActivationCostValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Mensalidade"
                name="tenantRequestCaas.monthlyActivationCostValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Power BI"
                name="tenantRequestCaas.powerBiActivationCostValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

          </Grid>
        </Box>
      </ContainerFormFields>

      <MainTitle title="Chatbot" />

      <ContainerFormFields title="Autocontratação">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Setup"
                name="tenantRequestCaas.chatBotSetupValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Custo por conttato"
                name="tenantRequestCaas.chatBotCostContractValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Mensalidade"
                name="tenantRequestCaas.chatBotMonthlyValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

          </Grid>
        </Box>
      </ContainerFormFields>

      <MainTitle title="Defina as taxas de receita de emissão" />

      <ContainerFormFields title="Receita de emissão e bancarização">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            {[
              { label: "FEE estruturada UY3", name: "issuanceRevenueFEEStructuredValue" },
              { label: "Divisão do excedente UY3", name: "issuanceRevenueFEEStructuredSurplusDivisionValue" },
              { label: "Franquia mínima mensal", name: "issuanceRevenueFEEStructuredMinimunMonthlyFeeValue" },
              { label: "Custo min por operação", name: "issuanceRevenueFEEStructuredMinimumOperationValue" },

              { label: "FEE nota comercial UY3", name: "saleInvoiceFEEStructuredValue" },
              { label: "Divisão do excedente UY3", name: "saleInvoiceFEEStructuredSurplusDivisionValue" },
              { label: "Franquia mínima mensal", name: "saleInvoiceFEEStructuredMinimunMonthlyFeeValue" },
              { label: "Custo min por operação", name: "saleInvoiceFEEStructuredMinimumOperationValue" },

              { label: "FEE massificada UY3", name: "massifieldFEEStructuredValue" },
              { label: "Divisão do excedente UY3", name: "massifieldFEEStructuredSurplusDivisionValue" },
              { label: "Franquia mínima mensal", name: "massifieldFEEStructuredMinimunMonthlyFeeValue" },
              { label: "Custo min por operação", name: "massifieldFEEStructuredMinimumOperationValue" },
            ].map(({ label, name }, index) => (
              <Grid item xs={2.5} key={index}>
                <CurrencyFormField
                  label={label}
                  name={name}
                  variant="outlined"
                  fullWidth
                  required={false}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

      </ContainerFormFields>

      <MainTitle title="Defina as demais tarifas" />

      <ContainerFormFields title="Tarifas CEF">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Consulta"
                name="tenantRequestCaas.rateCEFInquiryValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Averbação"
                name="tenantRequestCaas.rateCEFEndorsementValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Amortização"
                name="tenantRequestCaas.rateCEFAmortizationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Liquidação"
                name="tenantRequestCaas.rateCEFLiquidationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Cancelamento"
                name="tenantRequestCaas.rateCEFCancellationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <ContainerFormFields title="Tarifas ZETRA">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Consulta"
                name="tenantRequestCaas.zetraInquiryValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Liquidação"
                name="tenantRequestCaas.zetraLiquidationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <ContainerFormFields title="Tarifas gerais">
        <Box>
          <Grid container columns={10} spacing={2.5}>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Gravame do veículo"
                name="tenantRequestCaas.vehicleLienValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Escrituração"
                name="tenantRequestCaas.bookeepingValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
  
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Conciliação de cessão"
                name="tenantRequestCaas.assignmentReconciliationValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Outras tarifas"
                name="tenantRequestCaas.otherRatesValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>

          </Grid>
        </Box>
      </ContainerFormFields>


    </Stack>
  )
}
