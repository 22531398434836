import { Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, SaveIcon, Typography } from '@uy3/web-components';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { RefreshProgress } from 'components/RefreshProgress';
import { ApiResponseError, iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { asText } from 'services/permissions';
import { activeTheme } from 'services/theme';
import {
    BankAccountActions,
    BankAccountRequestActions,
    BatchAssignmentActions,
    BillingAccountActions,
    CreditNoteActions,
    CreditProductActions,
    NaturalAndLegalPersonActions,
    PermissionsActions,
    SignatureWorkFlowActions,
    UserActions,
} from './Actions';
import { ReactNode } from 'react';
import { SelectFormFieldWithTooltip } from './HelpersTooltip/SelectWithTooltip';
import { HelpPermission } from 'components/HelpPermission/HelpPermission';
import { useTenant } from 'contexts/tenantContext';

type PermissionFormProps = {
    onClose: () => void;
    submitError: ApiResponseError | undefined;
    submitting: boolean;
};

const restrictedOptionsActions = [
    { label: 'Todos', value: 'All' },
    { label: 'Nenhum', value: 'None' },
];

const fullOptionsActions = [
    ...restrictedOptionsActions,
    { label: 'Só os meus', value: 'OnlyMine' },
    { label: 'Meus grupos', value: 'MyGroups' },
];

const isRestricted = (resource: string) => {
    if (resource === 'User' || resource === 'UserGroup' || resource === 'BankAccount') return true;
    return false;
};

const isReadOnly = (resource: string) => {
    if (resource === 'Person' || resource === 'BankAccount' || resource === 'PendingApprovals')
        return true;
    return false;
};

const theme = activeTheme();
export const PermissionForm = ({ onClose, submitError, submitting }: PermissionFormProps) => {
    const { isRootTenancy } = useTenant();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { watch, getValues } = useFormContext();
    const resource = watch('resource');

    const hasTenancyAndResourceSecific = (resource: string): boolean => {
        if (
            (resource === 'BankAccountProduct' ||
                resource === 'CreditProduct' ||
                resource === 'Fund') &&
            !isRootTenancy
        )
            return true;
        return false;
    };

    const permissionOptions: SelectOption[] = [
        { label: 'Contas Digitais', value: 'BankAccount' },
        { label: 'Cessionários', value: 'Fund' },
        { label: 'Grupos de Usuários', value: 'UserGroup' },
        { label: 'Operações de Crédito', value: 'CreditNote' },
        { label: 'Pessoas Jurídicas', value: 'LegalPerson' },
        { label: 'Pessoas Físicas', value: 'NaturalPerson' },
        { label: 'Permissões', value: 'Permission' },
        { label: 'Pessoas', value: 'Person' },
        { label: 'Produtos de Crédito', value: 'CreditProduct' },
        { label: 'Produtos de Conta', value: 'BankAccountProduct' },
        { label: 'Produtos de Cobrança', value: 'BillingProduct' },
        { label: 'Solicitações de Abertura de Conta', value: 'BankAccountRequest' },
        { label: 'Usuários', value: 'User' },
        { label: 'Cessão em Lote', value: 'BatchAssignment' },
        { label: 'Central de Aprovação', value: 'PendingApprovals' },
        { label: 'Processo de Assinatura', value: 'SignatureWorkFlow' },
        { label: 'Gestão de Carteiras', value: 'BillingAccount' },
        { label: 'Convênios', value: 'BillingWallet' },
        { label: 'Parceiros', value: 'TenantRequest' },
    ];

    const restrictedOrFullOptions = isRestricted(resource)
        ? restrictedOptionsActions
        : fullOptionsActions;

    const renderComponentActionsByResource: { [resource: string]: ReactNode } = {
        BankAccount: <BankAccountActions />,
        BatchAssignment: <BatchAssignmentActions />,
        CreditNote: <CreditNoteActions />,
        BankAccountRequest: <BankAccountRequestActions />,
        BillingAccount: <BillingAccountActions />,
        SignatureWorkFlow: <SignatureWorkFlowActions />,
        Permission: <PermissionsActions />,
        NaturalPerson: <NaturalAndLegalPersonActions recordType="pessoas físicas" />,
        LegalPerson: <NaturalAndLegalPersonActions recordType="pessoas jurídicas" />,
        User: <UserActions />,
        CreditProduct: <CreditProductActions />
    };

    const currentFormLabel = permissionOptions
        .find((item) => item.value === resource)
        ?.label?.toLocaleLowerCase();

    const formatMessageDefault = (levelDisplay: string) => {
        return (
            <HelpPermission
                {...{
                    currentFormLabel: currentFormLabel!,
                    levelDisplay,
                    isRestricted: isRestricted(resource),
                }}
            />
        );
    };

    return (
        <Stack spacing={2}>
            <SelectFormField name="resource" label="Recurso" options={permissionOptions} />

            {resource && (
                <SelectFormFieldWithTooltip
                    selectFormField={{
                        label: 'Visualizar',
                        name: 'readPermission',
                        options: restrictedOrFullOptions,
                    }}
                    helpMessage={formatMessageDefault('leitura')}
                />
            )}

            {!isReadOnly(resource) && !hasTenancyAndResourceSecific(resource) ? (
                <>
                    <SelectFormFieldWithTooltip
                        selectFormField={{
                            label: 'Criar',
                            name: 'createPermission',
                            options: restrictedOrFullOptions,
                        }}
                        helpMessage={formatMessageDefault('adição')}
                    />
                    <SelectFormFieldWithTooltip
                        selectFormField={{
                            label: 'Atualizar',
                            name: 'updatePermission',
                            options: restrictedOrFullOptions,
                        }}
                        helpMessage={formatMessageDefault('atualização')}
                    />
                    <SelectFormFieldWithTooltip
                        selectFormField={{
                            label: 'Excluir',
                            name: 'deletePermission',
                            options: restrictedOrFullOptions,
                        }}
                        helpMessage={formatMessageDefault('exclusão')}
                    />
                </>
            ) : (
                <></>
            )}

            {resource === 'BankAccount' && (
                <SelectFormFieldWithTooltip
                    selectFormField={{
                        label: 'Atualizar',
                        name: 'updatePermission',
                        options: restrictedOrFullOptions,
                    }}
                    helpMessage={formatMessageDefault('atualização')}
                />
            )}

            {renderComponentActionsByResource[resource!]}

            <Typography variant="h6">{asText(getValues())}</Typography>
            <RefreshProgress refreshing={submitting} />
            <ApiErrorAlert error={submitError} />
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={
                        <SaveIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />
                    }
                    variant="contained"
                    disabled={submitting}
                    size="medium"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
