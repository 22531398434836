import { boolean, object, string } from "yup";

export const generateAssignmentFormSchema = (agioDesagio: boolean) => {
  return object().shape(agioDesagio ?
    {
      assignmentCalculation: object().shape({
        amount: string().typeError("Custo de cessão precisa ser preenchido").required("Custo de cessão: precisa ser preenchido"),
        type: string().typeError("Tipo de taxa ser preenchido").required("Tipo de taxa: precisa ser preenchido"),
        mode: string().typeError("Modelo de cálculo ser preenchido").required("Modelo de cálculo: precisa ser preenchido")
      }), 
      sendEmail: boolean().nullable(),
      ignoreOverDuePayment: boolean().nullable(),
    } : {
      assignmentCalculation: object().shape({
        amount: string().nullable(),
        type: string().nullable(),
        mode: string().nullable()
      }), 
      sendEmail: boolean().nullable(),
      ignoreOverDuePayment: boolean().nullable(),
    }
  );
};

export const generateAssignmentDefaultValues = {
  assignmentCalculation: {
    amount: null,
    type: null,
    mode: null
  }, 
  ignoreOverDuePayment: true,
  sendEmail: false
}