import { createAxiosConfig, creditAxiosApi } from "services/axiosApi";
import { GovernmentDepartmentsReadModel, IFiltersGovernmentDepartment } from "./types";
import { GetListApiResponseSuccess } from "contexts/apiRequestContext";

var url = '/GovernmentDepartment';

export const getGovernmentDepartment = async (filters: IFiltersGovernmentDepartment, token?: string) => {
    const axiosConfig = createAxiosConfig(token!, filters);
    return await creditAxiosApi.get<GetListApiResponseSuccess<GovernmentDepartmentsReadModel>>(url, axiosConfig);
};
