import { Box, Grid, Stack } from "@mui/material";
import { Typography } from "@uy3/web-components";
import { DatePickerFormField, SelectFormField } from "components/Forms/FormFields";
import CurrencyFormField from "components/Forms/FormFields/CurrencyFormField/CurrencyFormField";
import { ContainerFormFields } from "pages/Partners/PartnersRequest/Generics/ContainerFormFields";

export const clientBillingOptions = [
  { label: "Débito", value: 0 },
  { label: "Crédito", value: 1 },
]

export default function BaasForm() {
  return (
    <Stack spacing={2}>
      <Typography fontSize='23px' fontWeight='800'>
        Custos de ativação do BAAS
      </Typography>

      <ContainerFormFields title="Plataforma e API">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <DatePickerFormField
                label="Data da cobrança"
                name="tenantRequestBaas.billingDate"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Setup"
                name="tenantRequestBaas.setupValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Mensalidade"
                name="tenantRequestBaas.monthlyValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <Typography fontSize='23px' fontWeight='800'>
        Defina as taxas da condição comercial do BAAS
      </Typography>

      <ContainerFormFields title="PIX">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Informe o valor da taxa"
                name="tenantRequestBaas.feePixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <SelectFormField
                label="Como o cliente será cobrado?"
                name="tenantRequestBaas.clientBillingPix"
                variant="outlined"
                fullWidth
                showButtonClearValue
                required={false}
                options={clientBillingOptions}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Divisão de spread"
                name="tenantRequestBaas.spreadDivisionPixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Cash in"
                name="tenantRequestBaas.cashInPixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Cash out"
                name="tenantRequestBaas.cashOutPixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Spread Cash in"
                name="tenantRequestBaas.spreadCashInPixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Spread Cash out"
                name="tenantRequestBaas.spreadCashOutPixValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <ContainerFormFields title="TED">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Informe o valor da taxa"
                name="tenantRequestBaas.feeTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <SelectFormField
                label="Como o cliente será cobrado?"
                name="tenantRequestBaas.clientBillingTed"
                variant="outlined"
                fullWidth
                showButtonClearValue
                required={false}
                options={clientBillingOptions}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Divisão de spread"
                name="tenantRequestBaas.spreadDivisionTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Cash in"
                name="tenantRequestBaas.cashInTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Cash out"
                name="tenantRequestBaas.cashOutTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Spread Cash in"
                name="tenantRequestBaas.spreadCashInTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Spread Cash out"
                name="tenantRequestBaas.spreadCashOutTedValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <ContainerFormFields title="Conta pagamento">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Informe o valor da taxa"
                name="tenantRequestBaas.feePaymentAccountValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <SelectFormField
                label="Como o cliente será cobrado?"
                name="tenantRequestBaas.clientBillingPaymentAcountValue"
                variant="outlined"
                fullWidth
                showButtonClearValue
                required={false}
                options={clientBillingOptions}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Divisão de spread"
                name="tenantRequestBaas.spreadDivisionPaymentAcountValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>

      <ContainerFormFields title="Conta escrow">
        <Box>
          <Grid container columns={10} spacing={2.5}>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Informe o valor da taxa"
                name="tenantRequestBaas.feeEscrowValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
            <Grid item xs={2.5}>
              <SelectFormField
                label="Como o cliente será cobrado?"
                name="tenantRequestBaas.clientBillingEscrowValue"
                variant="outlined"
                fullWidth
                showButtonClearValue
                required={false}
                options={clientBillingOptions}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CurrencyFormField
                label="Divisão de spread"
                name="tenantRequestBaas.spreadDivisionEscrowValue"
                variant="outlined"
                fullWidth
                required={false}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerFormFields>
    </Stack>
  )
}
