/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { Button, CompleteIcon, LeftIcon, Typography, UpdateIcon } from '@uy3/web-components';
import CardsSelect, {
    HandleChangeCardsSelectedProps,
    IOption,
} from 'components/CardSelect/CardSelect';
import { useFormContext } from 'contexts/formContext';
import { PublicPayrollForm } from 'pages/CreditNote/WarrantyTab';
import { activeTheme } from 'services/theme';
import { summaryConsignment } from '../Summary';
import { CheckPurchaseContractsReadModel } from 'services/zetra';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { TextFormField } from 'components/Forms/FormFields';
import { CreditProductReadModel } from 'services/creditProduct';
import { useCallback, useEffect } from 'react';
import { activeConfig } from 'services/config';
import { IWarrantyReadModel } from 'services/creditNote';

type SelectConsignmentProps = {
    setOpenSelectConsignment: Function;
    setUpdateConsignment: Function;
    checkPurchaseContracts: CheckPurchaseContractsReadModel | undefined;
    productData: CreditProductReadModel | undefined;
    warrantyList: IWarrantyReadModel[];
};

const config = activeConfig();
const color = activeTheme().palette;
export default function SelectConsignmentForm({
    setOpenSelectConsignment,
    setUpdateConsignment,
    checkPurchaseContracts,
    productData,
    warrantyList,
}: SelectConsignmentProps) {
    const { submitting, setValue, watch } = useFormContext();
    const watchedConsignment: string[] = watch('selectConsignment') ?? [];
    const watchedWarranty = warrantyList;

    const selectableQuantityContracts = config.QUANTITY_CONSIGNMENT_SELECT;

    const disabledSelectContracts = useCallback(
        (item: IOption) => {
            if (watchedConsignment.includes(String(item.value))) {
                return false;
            }
            if (watchedConsignment.length >= selectableQuantityContracts) {
                return true;
            }
            return false;
        },
        [watchedConsignment, selectableQuantityContracts]
    );

    const handleOnSelect = ({ value, checked }: HandleChangeCardsSelectedProps) => {
        if (checked) {
            setValue('selectConsignment', [...watchedConsignment, value]);
        } else {
            setValue(
                'selectConsignment',
                watchedConsignment.filter((item) => item !== value)
            );
        }
    };

    const options = summaryConsignment(checkPurchaseContracts);
    const listOptionsFromAutocomplete = productData?.governmentDepartments?.map((item) => {
        return {
            label: `${item.name} (${item.internalId})`,
            value: item.id,
            paymentTransferDay: item.paymentTransferDay,
            warrantyRegistrationCutoffDay: item.warrantyRegistrationCutoffDay,
            agreementCode: item.internalId
        };
    });

    const enableSrcollItems: boolean = options?.length <= 5;

    useEffect(() => {
        if (
            watchedWarranty.length > 0 &&
            watchedWarranty.find((item) => item.warrantyOption === 'Portability')
        ) {
            setValue(
                'selectConsignment',
                watchedWarranty
                    .filter((item) => item.warrantyOption === 'Portability')
                    .map((item) => item?.consignmentValue)
            );
        }
    }, [watchedWarranty]);

    return (
        <Stack spacing={2} mt={-1}>
            <Typography variant="md" fontWeight="bold">
                Informações do consignado
            </Typography>
            <PublicPayrollForm isReadOnlyMode />

            <AutocompleteField
                label="Selecione o órgão de averbação"
                name="id"
                displayName="name"
                required
                loading={false}
                onChange={(_, option: any) => {
                    setValue('name', option?.label);
                    setValue('id', option?.value);
                    setValue('agreementCode', option?.agreementCode);
                    setValue('paymentTransferDay', option?.paymentTransferDay);
                    setValue(
                        'warrantyRegistrationCutoffDay',
                        option?.warrantyRegistrationCutoffDay
                    );
                }}
                listOptions={listOptionsFromAutocomplete ?? []}
            />
            <TextFormField
                label="Matrícula"
                name="employeeCode"
                variant="outlined"
                fullWidth
                required
            />

            <TextFormField
                label="Dia do repasse"
                name="paymentTransferDay"
                variant="outlined"
                fullWidth
                required
            />

            <Typography variant="md" fontWeight="bold">
                Lista de contratos disponíveis
            </Typography>

            <Stack
                maxHeight={500}
                p={1}
                sx={{
                    display: 'block',
                    overflowY: enableSrcollItems ? 'auto' : 'scroll',
                    overflowX: 'hidden',
                    boxShadow: enableSrcollItems ? 'none' : '5px 5px 5px 5px #f2f2f2',
                    borderRadius: '10px',
                }}
            >
                <CardsSelect
                    onChange={handleOnSelect}
                    options={options}
                    value={watchedConsignment}
                    isCheckbox
                    disableItem={disabledSelectContracts}
                />
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={-1}
                pb={1}
            >
                <Typography variant="md" fontWeight="bold">
                    Contratos selecionados
                </Typography>
                <Typography variant="md" fontWeight="bold" fontSize="20px">
                    {`${(watch('selectConsignment') ?? [])?.length}/${(options ?? [])?.length}`}
                </Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<LeftIcon htmlColor={color.primary.main} />}
                    onClick={() => setOpenSelectConsignment(false)}
                >
                    Voltar
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<UpdateIcon htmlColor={color.primary.main} />}
                    onClick={() => setUpdateConsignment(true)}
                >
                    Atualizar contratos
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
}
