import { Grid, Stack } from '@mui/material'
import { BankSlipIcon, BriefcaseIcon, FgtsIcon, LiquidateIcon, StudentIcon, TransferIcon, Typography } from '@uy3/web-components'
import GetLabelForField from 'components/GetLabelForField'
import { OptionCards } from 'components/OptionCards/OptionCards'
import { pathNewSimulationConsignedConfig } from 'constants/labelConfig'
import { useNavigate } from 'react-router'

export const NewSimulation = () => {
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Grid>
                <Typography
                    variant='h1'
                    fontSize='25px'
                    fontWeight='800'
                >
                    Selecione o simulador
                </Typography>
                <Typography
                    fontSize='16px'
                    fontWeight='500'
                >
                    Cada simulador auxilia no cálculo correto da operação, incluindo consulta de margens, indicação de campos obrigatórios, listagem de produtos etc.
                </Typography>

            </Grid>
            <OptionCards
                options={[
                    {
                        Icon: LiquidateIcon,
                        label: GetLabelForField("optionsNewSimulation.consignadoPublico.Label", "Consignado público"),
                        onClick: () => navigate(pathNewSimulationConsignedConfig),
                        title: GetLabelForField("optionsNewSimulation.consignadoPublico.Title", ""),
                        description: GetLabelForField("optionsNewSimulation.consignadoPublico.Description", ""),
                    },
                    {
                        Icon: LiquidateIcon,
                        label: GetLabelForField("optionsNewSimulation.creditoPessoal.Label", "Crédito pessoal"),
                        onClick: () => navigate('/ccb/nova-simulacao/credito-pessoal'),
                        title: GetLabelForField("optionsNewSimulation.creditoPessoal.Title", ""),
                        description: GetLabelForField("optionsNewSimulation.creditoPessoal.Description", ""),
                    },
                    {
                        Icon: FgtsIcon,
                        label: GetLabelForField("optionsNewSimulation.fgts.Label", "FGTS"),
                        onClick: () => navigate('/ccb/nova-simulacao/credito-pessoal?simulationMode=fgts'),
                        title: GetLabelForField("optionsNewSimulation.fgts.Title", ""),
                        description: GetLabelForField("optionsNewSimulation.fgts.Description", ""),
                    },
                    {
                        Icon: LiquidateIcon,
                        label: "Consignado privado",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: BankSlipIcon,
                        label: "CCB",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: BankSlipIcon,
                        label: "CDC",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: BriefcaseIcon,
                        label: "Processo trabalhista",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: LiquidateIcon,
                        label: "Veículo",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: TransferIcon,
                        label: "Nota comercial",
                        onClick: () => { },
                        disable: true
                    },
                    {
                        Icon: StudentIcon,
                        label: "Financiamento estudantil",
                        onClick: () => { },
                        disable: true
                    },
                ]}
            />
        </Stack>
    )
}
