/* eslint-disable no-useless-escape */
import { string, object } from 'yup';

export function partenersHeaderSchema() {
    return object().shape({
        search: string().typeError('Procurar inválido.')
            .required('Procurar: Precisa ser preenchido.'),
        lastStep: string().typeError('Último andamento inválido.')
            .required('Último andamento: Precisa ser preenchido.'),
        status: string().typeError('Status do parceiro inválido.')
            .required('Status do parceiro: Precisa ser preenchido.'),
    });
}

export const partenersHeaderDefaultValues = () => {
    return {}
};