import { FormProvider } from 'contexts/formContext';
import React, { lazy, Suspense } from 'react'
import { FieldValues } from 'react-hook-form';
import { paymentAccountDefaultValues, paymentAccountSchema } from './PaymentAccountSchema';

const PaymentAccount = lazy(() => import('./PaymentAccount'));

type PaymentAccountContainerProps = {
    onClose: () => void;
    setFormValues: (value: React.SetStateAction<FieldValues | null>) => void
}

export default function PaymentAccountContainer({ onClose, setFormValues }: PaymentAccountContainerProps) {
    
    const onSubmit = (values: FieldValues) => {
        setFormValues((prev) => {
            var contracts = (prev?.contracts) as any[];
            contracts.push(values); 
            return {...prev, contracts};
        })
        onClose();
    }

    return (
        <Suspense fallback={<></>}>
            <FormProvider {...{
                defaultValues: paymentAccountDefaultValues(),
                validationSchema: paymentAccountSchema(),
                onSubmit
            }}>
                <PaymentAccount {...{
                    onClose
                }}/>
            </FormProvider>
        </Suspense>
    )
}
