import { Stack } from '@mui/material';
import { Typography, UserAddIcon } from '@uy3/web-components';
import { ShowInformationTypography } from '../ShowInformationTypography';
import { AlertWithAction } from 'components/AlertWithAction/AlertWithAction';
import { FieldValues } from 'react-hook-form';

type PersonInformationsProps = { formValues: FieldValues; addNewPerson: (action: string) => void };

export const PersonInformations = ({ formValues, addNewPerson }: PersonInformationsProps) => (
    <Stack spacing={3} border="1px solid #666" padding="24px" borderRadius="8px">
        <Typography fontSize="18px" fontWeight="700">
            Dados da pessoa
        </Typography>
        <ShowInformationTypography title="CPF" value={formValues?.registrationNumber} />
        <AlertWithAction
            message="Pessoa não cadastrada. Antes de prosseguir, é necessário adicioná-lo"
            severityType="Warning"
            action={{
                label: 'Adicionar nova pessoa',
                Icon: UserAddIcon,
                onClick: () => addNewPerson('addPerson'),
            }}
        />
    </Stack>
);
