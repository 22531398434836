import { Stack } from '@mui/material'
import { GenericFormSkeletonSuspense } from 'components/Skeleton/GenericFormSkeleton'
import { lazy, Suspense, useState } from 'react'
import { PartnersRequestHeader } from './PartnersRequestHeader'
import { FormProvider } from 'contexts/formContext'
import { partenersHeaderSchema, partenersHeaderDefaultValues } from './PartenersHeaderSchema'
import { useGetAllTenantRequest } from 'contexts/tenantRequestContext'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton'
import { RefreshProgress } from 'components/RefreshProgress'

const PartnersRequestList = lazy(() => import('./PartnersRequestList'))

export const PartnersRequestContainer = () => {
  const defaultRowsPerPage = parseInt(localStorage.getItem('rowsPerPage')!) || 5;
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const { data, isLoading, refetch, isFetching } = useGetAllTenantRequest();

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => setPage(page);

  return (
    <Suspense fallback={<GenericFormSkeletonSuspense />}>
      <Stack spacing={4} mr={2} mb={7}>
        <FormProvider
          defaultValues={partenersHeaderDefaultValues()}
          validationSchema={partenersHeaderSchema()}
          onSubmit={() => { }}
        >
          <PartnersRequestHeader refetch={refetch}/>
        </FormProvider>
        {!!isFetching && <RefreshProgress refreshing={isFetching} />}
        <ListSkeleton isLoading={isLoading}>
          <PartnersRequestList {...{
            page,
            queryData: data ?? [],
            rowsPerPage,
            setRowsPerPage: onChangeRowsPerPage,
            setPage: onChangePage,
          }} />
        </ListSkeleton>
      </Stack>
    </Suspense>
  )
}