import { IconButton } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid/';
import { ProductFiltersContainer } from 'components/DataTableFilters/ProductFilters/ProductFiltersContainer';
import { iconSx } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';

interface CreditProductListType {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setOpenPopup: (rowData: any) => void;
    hasPermission: (resource: string | string[], type: any) => boolean
}

const CreditProductList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setOpenPopup,
    hasPermission
}: CreditProductListType) => {
    const { isRootTenancy } = useTenant(); 

    if (queryData?.type === 'error') return <Error error={queryData} />;

    const colunmsProductList = [
        isRootTenancy
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                minWidth: 120,
                flex: 2,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <div
                            style={{
                                textAlign: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            {cellValues.value}
                        </div>
                    );
                },
            }
            : undefined,
        {
            field: 'name',
            headerName: 'Produto',
            hideSortIcons: true,
            minWidth: 450,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'amortizationTypeDisplay',
            headerName: 'Sistema de amortização',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'termStatusDisplay',
            headerName: 'Status de vigência',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            flex: 0, 
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Link to={`/cadastro/produtos-credito/${cellValues.id}`}>
                            <IconButton name="btn-edit">
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {hasPermission('CreditProduct', 'Delete') &&
                            <IconButton onClick={() => setOpenPopup(cellValues)} name="btn-delete">
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </>
                );
            },
        },
    ].filter(Boolean);

    const columns = colunmsProductList as GridColDef[];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <ProductFiltersContainer typeFilter="creditProduct" />,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default CreditProductList;
