import { addDays } from 'date-fns';
import { genericMessageRequired, toIsoStringWithTimezone } from 'helpers';
import { publicPayrollSchema } from 'pages/CreditNote/WarrantyTab';
import { array } from 'yup';
import { string, object, InferType } from 'yup';

export const validationSchemaSelectConsignment = object().shape({
    selectConsignment: array()
        .of(string())
        .required('Selecione ao menos um contrato.')
        .typeError('Selecione ao menos um contrato.'),
    ...publicPayrollSchema.fields,
    id: string()
        .typeError('Selecione um código do órgão.')
        .required('Selecione um código do órgão.'),
    paymentTransferDay: string()
        .typeError('Dia do repasse é inválido.')
        .required('Dia do repasse: ' + genericMessageRequired),
    employeeCode: string()
        .typeError('Matrícula é inválido.')
        .required('Matrícula: ' + genericMessageRequired),
});

export type TSelectConsignmentProps = InferType<typeof validationSchemaSelectConsignment>;

export const defaultValuesSelectConsignment = {
    selectConsignment: null,
    previousAdeNumber: 0,
    warrantyOption: 'Portability',
    warrantyDeadline: toIsoStringWithTimezone(addDays(new Date(), 40)),
    paymentTransferDay: null,
    id: null,
    employeeCode: null
};
