import { Stack } from "@mui/material";
import { Typography } from "@uy3/web-components";

type ShowInformationTypographyProps = {
    title: string; 
    value: string
}

export const ShowInformationTypography = ({ title, value }:ShowInformationTypographyProps) => {
    return (
        <Stack>
            <Typography fontSize="11px" fontWeight="400">
                {title}{' '}
            </Typography>
            <Typography fontSize="14px" fontWeight="800">
                {value}
            </Typography>
        </Stack>
    );
};
