import { createAxiosConfig, creditAxiosApi } from 'services/axiosApi';
import {
    CancelConsignmentCreateModel,
    CheckConsignmentsReadModel,
    ReserveMarginCreateModel,
    TCancelConsignmentParams,
    TCheckConsignmentsParams,
    TUpdateZetraProps,
    TZetraMarginQueryParams,
    IZetraMarginQueryReadModel,
    ZetraMarginQueryUpdateModel,
    ConsignmentForPurchaseCreateModel,
    TConsignmentForPurchaseParams,
    TCheckPurchaseContractsParams,
    TCheckConsignmentsResult,
} from './types';

const url = '/Zetra';
export async function postConsignmentForPurchaseAsync(
    params: TConsignmentForPurchaseParams,
    resource: 'Siape' | 'Zetra',
    token: string
) {
    const endpoint = `${resource}/PurchaseMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.post<ConsignmentForPurchaseCreateModel>(endpoint, {}, axiosConfig);
}

export async function getCheckPurchaseContractsAsync(
    params: TCheckPurchaseContractsParams,
    resource: 'Siape' | 'Zetra',
    token: string
) {
    const endpoint = `${resource}/PurchaseMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<TCheckConsignmentsResult[]>(endpoint, axiosConfig);
}

export async function postUpdateZetraMarginQueryAsync(prop: TUpdateZetraProps, token: string) {
    const { personId, registrationNumber, creditProductId, tokens = [], employeeCode } = prop ?? {};
    const params = { registrationNumber, personId, creditProductId, employeeCode};
    const endpoint = `${url}/FreeMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);

    return await creditAxiosApi.post<ZetraMarginQueryUpdateModel>(endpoint, tokens, axiosConfig);
}

export async function getZetraMarginQueryAsync(params: TZetraMarginQueryParams, token: string) {
    const endpoint = `${url}/FreeMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<IZetraMarginQueryReadModel[]>(endpoint, axiosConfig);
}

export async function getCheckConsignmentsAsync(params: TCheckConsignmentsParams, token: string) {
    const endpoint = `${url}/CheckConsignments`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<CheckConsignmentsReadModel>(endpoint, axiosConfig);
}

export async function postReserveMarginAsync(creditNoteOpId: string, token: string) {
    const endpoint = `/CreditNote/${creditNoteOpId}}/Zetra/Admin/ReserveMargin`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post<ReserveMarginCreateModel>(endpoint, {}, axiosConfig);
}

export async function postCancelConsignmentAsync(params: TCancelConsignmentParams, token: string) {
    const endpoint = `/CreditNote/${params.CreditNoteOpId}/Zetra/Admin/Cancel`;
    const axiosConfig = createAxiosConfig(token,
        {
            adeNumber: params?.adeNumber
        });
    return await creditAxiosApi.post<CancelConsignmentCreateModel>(endpoint, {}, axiosConfig);
}
