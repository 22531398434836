import { billingAxiosApi } from "services/axiosApi";
import { ProductsCostsDefinitionFilters, BillingProductRaadModel, CostsBillingParametersRadModel } from "./models/billingProductReadModel";
import { GetListApiResponseSuccess } from "contexts/apiRequestContext";
import { BillingProductCreateModel } from "./models/billingProductCreateModel";
import { CostsBillingParametersCreateModel } from "./models/CostsBillingParametersCreateModel";

const url = '/Product'; 

export async function getBillingProducts(token: string, filters?: ProductsCostsDefinitionFilters) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await billingAxiosApi.get<GetListApiResponseSuccess<BillingProductRaadModel>>(`${url}/Billing`, config);
}

export async function getProductsCostsDefinition(token: string, filters: ProductsCostsDefinitionFilters) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await billingAxiosApi.get<GetListApiResponseSuccess<CostsBillingParametersRadModel>>(`${url}/CostsDefinition`, config);
}

export async function createBillingProduct(payload: BillingProductCreateModel, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post<BillingProductRaadModel>(`${url}/Billing`, payload, config);
}

export async function createProductCostsDefinition(payload: CostsBillingParametersCreateModel, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post<CostsBillingParametersCreateModel>(`${url}/CostsDefinition`, payload, config);
}