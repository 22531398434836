import { Stack } from '@mui/material';
import { IBankAccountReadModel } from 'utils';
import { ShowInformationTypography } from '../../Generics';
import { genericFormatPixKey, mapperOperationTypeValue } from 'helpers';

type ShowBankAccountProps = { bankAccount: IBankAccountReadModel };

export const ShowBankAccount = ({ bankAccount }: ShowBankAccountProps) => {
    const isAgencyAndAccount = bankAccount?.pixKeyTypeValue === 'AgencyAndAccount';

    if (bankAccount.operationTypeValue.toLowerCase() === 'pix' && !isAgencyAndAccount) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <ShowInformationTypography
                    title="Tipo de Operação"
                    value={bankAccount?.operationTypeValue === 'Transfer' ? 'Transferência' : 'Pix'}
                />
                <ShowInformationTypography
                    title="Tipo de chave"
                    value={mapperOperationTypeValue(bankAccount?.pixKeyTypeValue)}
                />
                <ShowInformationTypography title="Chave Pix" value={genericFormatPixKey(bankAccount) ?? ''} />
            </Stack>
        );
    }

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ShowInformationTypography
                title="Tipo de Operação"
                value={bankAccount?.operationTypeValue === 'Transfer' ? 'Transferência' : 'Pix'}
            />
            {isAgencyAndAccount && (
                <ShowInformationTypography
                    title="Tipo de chave"
                    value={mapperOperationTypeValue(bankAccount?.pixKeyTypeValue)}
                />
            )}

            <ShowInformationTypography title="Banco" value={bankAccount?.bankCodeDisplay ?? ''} />
            <ShowInformationTypography
                title="Tipo de conta"
                value={bankAccount?.typeDisplay ?? ''}
            />
            <ShowInformationTypography title="Agência" value={bankAccount?.agency ?? ''} />
            <ShowInformationTypography title="Conta" value={bankAccount?.account ?? ''} />
        </Stack>
    );
};
