import { Stack } from '@mui/material';
import { AddIcon, EditIcon, Typography } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { StepNewSimulationEnum } from './PayrollCreditNoteContainer';
import GetLabelForField from 'components/GetLabelForField';
import { labelConsignadoPublicoConfig } from 'constants/labelConfig';

type PayrollCreditNoteHeaderProps = {
    step: StepNewSimulationEnum;
    isCompraDeDivida: boolean;
    handleEditProposal?: () => void;
    handleAddAccountOfPayment?: () => void;
};

export const PayrollCreditNoteHeader = ({
    step,
    handleEditProposal,
    isCompraDeDivida,
    handleAddAccountOfPayment
}: PayrollCreditNoteHeaderProps) => {
    const isLastStep: boolean = step === StepNewSimulationEnum.Summary;
    const customTitle = GetLabelForField("optionsNewSimulation.compraDeDivida.Label", "Compra de dívida");

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
                <Typography fontSize="24px" fontWeight={800}>
                    {isCompraDeDivida
                        ? `Nova simulação de ${customTitle.toLowerCase()}`
                        : `Nova simulação  ${labelConsignadoPublicoConfig.toLowerCase()} `}
                </Typography>
                {!isLastStep  && <Typography fontSize="14px" fontWeight={500}>
                    {isCompraDeDivida
                        ? 'Preencha o simulador para que possamos oferecer a melhor oportunidade para você.'
                        : 'Confira as informações antes de criar uma nova simulação de crédito'}
                </Typography>}
            </Stack>
            <Actions
                numberOfButtons={3}
                buttonsActionsList={[
                    {
                        enable: step === StepNewSimulationEnum.Details && !isCompraDeDivida,
                        label: 'Editar proposta',
                        action: handleEditProposal,
                        icon: <EditIcon sx={iconSx} />,
                    },
                    {
                        enable: isLastStep && isCompraDeDivida,
                        label: 'Inserir conta para pagamento',
                        action: handleAddAccountOfPayment,
                        icon: <AddIcon sx={iconSx} />,
                    }                    
                ]}
            />
        </Stack>
    );
};
