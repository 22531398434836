import { Tabs, Tab, Box, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { activeTheme } from "services/theme";
import { Typography } from "@uy3/web-components";

const theme = activeTheme();

type StepType = { label: string, children: React.ReactNode, isComplated: boolean }

type ProgressTabProps = {
    steps: StepType[];
    signalIndex: number;
    setSignalIndex: (step: number) => void;
}

export default function ProgressTab({ steps, signalIndex, setSignalIndex }: ProgressTabProps) {

    return (
        <Box sx={{ width: "100%", border: "1px solid #E0E0E0", borderRadius: 1 }}>
            <Tabs
                value={signalIndex}
                onChange={(_, newValue: number) => setSignalIndex(newValue)}
                variant="fullWidth"
                TabIndicatorProps={{ style: { display: "none" } }}
                sx={{
                    "& .MuiTabs-flexContainer": { borderBottom: "1px solid #E0E0E0" },
                }}
            >
                {steps.map((step, index) => {
                    const someIndex = signalIndex === index;
                    return (
                        <Tab
                            key={index}
                            label={
                                <Stack alignItems="flex-start" gap={0.5}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {step.isComplated ? (
                                            <>
                                                <Typography
                                                    color={theme.palette.success.main}
                                                    sx={{ fontWeight: 500, fontSize: 15, textTransform: "none" }}
                                                >
                                                    Completo
                                                </Typography>
                                                <CheckCircleIcon sx={{ fontSize: 14, color: theme.palette.success.main }} />
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    fontSize={15}
                                                    sx={{ fontWeight: !step.isComplated ? 600 : 400, textTransform: "none" }}
                                                >
                                                    Em aberto
                                                </Typography>
                                                {!step.isComplated && someIndex && (
                                                    <Box sx={{ width: 8, height: 8, bgcolor: "black", borderRadius: "50%" }} />
                                                )}
                                            </>
                                        )}
                                    </Stack>
                                    <Typography fontSize={12} sx={{ fontWeight: 500, textTransform: "none" }}>
                                        {index + 1}. {step.label}
                                    </Typography>
                                </Stack>
                            }
                            sx={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                textAlign: "left",
                                borderTop: someIndex ? `4px solid ${theme.palette.primary.main}` : "none",
                                color: "inherit",
                                "&.Mui-selected": { color: "inherit" },
                                "&:focus": { outline: "none" },
                                textTransform: "none"
                            }}
                        />
                    )
                })}
            </Tabs>

            <Box sx={{ mt: 1, p: 2 }}>
                {steps[signalIndex]?.children ?? <></>}
            </Box>
        </Box>
    );
}
