import { ITokensInformation } from 'services/accounts/naturalPerson';

export class DoneWarrantyRevisionCreateModel {
    warrantyIds?: string[];
    reason?: string;
    warrantyOfficeTokens?: ITokensInformation[];

    constructor(init?: Partial<DoneWarrantyRevisionCreateModel>) {
        Object.assign(this, init);
    }
}
