import React, { ReactNode } from 'react'
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer'
import { useNavigate } from 'react-router'
import { Stack } from '@mui/material'
import { BookCheckIcon, Button, CancelIcon, CompleteIcon, LeftIcon, RightIcon } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import { activeTheme } from 'services/theme'
import { pathNewSimulationConsignedConfig } from 'constants/labelConfig'

type BaseContainerStepFormProps = {
    children: ReactNode; 
    disabled?: boolean
} & PayrollCreditNoteSharedProps

const theme = activeTheme();

export const BaseContainerStepForm = ({children, onBackStep, step, disabled = false}: BaseContainerStepFormProps) => {
    const navigate = useNavigate();
  return (
    <div>
        {children}
        {ManagementButtonsByStep(step, navigate, onBackStep, disabled)}
    </div>
  )
}

function ManagementButtonsByStep(
    step: number,
    navigate: (path: string) => void,
    onBackStep: () => void, 
    disabled: boolean
) {
    return (
        <Stack direction="row" justifyContent="flex-end" mt={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
                {step === 1 && (
                    <Button
                        onClick={() => navigate(pathNewSimulationConsignedConfig)}
                        variant="outlined"
                        startIcon={
                            <CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />
                        }
                    >
                        Cancelar
                    </Button>
                )}

                {step !== 1 && (
                    <Button
                        variant="outlined"
                        onClick={onBackStep}
                        startIcon={<LeftIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    >
                        {step === 5 ? 'Voltar para simulação': 'Voltar'}
                    </Button>
                )}
                {step === 4 && <Button
                    variant="contained"
                    disabled={disabled}
                    type="submit"
                    startIcon={<BookCheckIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                >
                    Aprovar proposta
                </Button>}

                {step === 5 && <Button
                    variant="contained"
                    disabled={disabled}
                    type="submit"
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                >
                    Enviar pra análise
                </Button>}

                {step !== 4 && step !== 5 && <Button
                    variant="contained"
                    disabled={disabled}
                    type="submit"
                    startIcon={<RightIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                >
                    Continuar
                </Button>}
            </Stack>
        </Stack>
    );
}
