import { onlyNumbers } from 'helpers/validations/formFieldValidator';
import { ERelatedBankAccountType } from 'utils/enums';
import { array, mixed, number, object, string, InferType } from 'yup';

const genericMessage = 'Precisa ser preenchido.';
const pixQrCodeSchema = object({
    qrCode: string().required(`O QR code: ${genericMessage}`).typeError('O QR code é inválido.'),
});

const bankSlipSchema = object({
    barcodeOrDigitableLine: string()
        .typeError('Valor precisa ser um número.')
        .required(`A linha digitável ou código de barras: ${genericMessage}`)
        .matches(/^\d+$/, 'Deve conter apenas números.')
        .test(
            'length',
            'O código de barras ou linha digitável inválida.',
            (value: string | undefined) => {
                if (!value) return false;

                let barCodeOrLineDigitable = onlyNumbers(value);
                return (
                    barCodeOrLineDigitable?.length === 44 || barCodeOrLineDigitable?.length === 47
                );
            }
        ),
});

const pixKeySchema = object({
    key: string().required(`A chave pix: ${genericMessage}`).typeError('A chave pix é inválida.'),
});

const transferToSchema = object({
    name: string().required(`O nome: ${genericMessage}`).typeError('O nome é inválido.'),
    registrationNumber: string()
        .typeError('CPF/CNPJ é inválido.')
        .required(`CPF/CNPJ: ${genericMessage}`)
        .cpfCnpjValidation('CPF/CNPJ: inválido.'),
    bankCode: string()
        .typeError('Selecione o código bancário.')
        .required('Código bancário: Precisa ser preenchido.'),
    bankIspb: number().nullable(),
    agency: string()
        .required(`A agência: ${genericMessage}`)
        .max(4, 'A agência deve ter no máximo 4 caracteres.')
        .typeError('A agência é inválida.'),
    accountNumber: string()
        .required(`O número da conta: ${genericMessage}`)
        .typeError('O número da conta é inválido.')
        .min(1, 'Número da conta deve ter pelo menos 1 caractere.'),
    accountType: mixed<ERelatedBankAccountType>()
        .oneOf(Object.values(ERelatedBankAccountType), 'Tipo de conta inválido.')
        .required(`O tipo de conta: ${genericMessage}`)
        .typeError('Tipo de conta inválido.'),
});

const transferSchema = object({
    to: transferToSchema.required('Os detalhes da transferência são obrigatórios.'),
    type: string()
        .required(`O tipo de operação: ${genericMessage}`)
        .typeError('O tipo de operação é inválido.'),
});

export const LiquidationScheduleBaseSchema = object().shape({
    amountInCents: number()
        .required('Campo é obrigatório.')
        .typeError('O valor precisa ser um número.')
        .positive('O valor deve ser positivo.'),
    pixQrCode: mixed().when('transactionType', {
        is: 'pixQrCode',
        then: pixQrCodeSchema,
        otherwise: mixed().nullable(),
    }),
    bankSlip: mixed().when('transactionType', {
        is: 'bankSlip',
        then: bankSlipSchema,
        otherwise: mixed().nullable(),
    }),
    pixKey: mixed().when('transactionType', {
        is: 'pixKey',
        then: pixKeySchema,
        otherwise: mixed().nullable(),
    }),
    transfer: mixed().when('transactionType', {
        is: 'transfer',
        then: transferSchema,
        otherwise: mixed().nullable(),
    }),
});

export const liquidationItemSchema = object().shape({
    transactionType: string()
        .oneOf(['pixQrCode', 'bankSlip', 'pixKey', 'transfer'])
        .required('Selecione o tipo de transação.')
        .typeError('Tipo de transação inválido.'),
    ...LiquidationScheduleBaseSchema.fields,
});

export const liquidationToBankAccountContractSchema = () => {
    var fields = { ...LiquidationScheduleBaseSchema.fields };
    //@ts-ignore
    delete fields.amountInCents;
    return object().shape({
        transactionType: string()
            .oneOf(['pixQrCode', 'bankSlip', 'pixKey', 'transfer'])
            .required('Selecione o tipo de transação.')
            .typeError('Tipo de transação inválido.'),
        ...fields

    });
}


export type TLiquidationScheduleType = InferType<typeof liquidationItemSchema>;
export const liquidationScheduleSchema = array().of(LiquidationScheduleBaseSchema).nullable();

export const defaultValuesLiquidationSchema = {
    amountInCents: 0,
    pixQrCode: null,
    bankSlip: null,
    pixKey: null,
    transfer: null,
};
