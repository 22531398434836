import { Stack } from "@mui/material";
import { Typography } from "@uy3/web-components";
import { BankAccounts } from "../Summary/BankAccount/BankAccounts";
import { FieldValues } from "react-hook-form";

type PaymentAccountProps = { formValues: FieldValues, handleEditOrCreateBankAccountContract: (resource: "create" | "edit", index: number) => void }

export const PaymentAccount = ({ formValues, handleEditOrCreateBankAccountContract }: PaymentAccountProps) => (
    <Stack spacing={3}>
        <Typography fontSize="22px" fontWeight="600">Contas para pagamento</Typography>
        <BankAccounts contracts={formValues?.contracts ?? []} handleEditOrCreateBankAccountContract={handleEditOrCreateBankAccountContract} />
    </Stack>
);
