import { FieldValues } from "react-hook-form";
import { object, string } from "yup";

export const personDebtPurchaseSchema = () => {
    return object().shape({
        registrationNumber: string().required('CPF prcisa ser preenchido').typeError('CPF prcisa ser preenchido').cpfCnpjValidation('CPF inválido'),
        employeeCode: string().notRequired().nullable()
    });
};

export const personDebtPurchaseDefaultValues = (currentVelues: FieldValues) => {
    return {
        registrationNumber: currentVelues?.registrationNumber ?? null, 
        employeeCode: currentVelues?.employeeCode ?? null, 
        contracts: currentVelues?.contracts ?? []
    }
}