import { Box, Grid, Stack } from '@mui/material';
import { Modal, Timeline, Typography } from '@uy3/web-components';
import { TabBar } from 'components/TabBar/TabBar';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React from 'react';
import { useParams } from 'react-router';
import ButtonOfActionsBatch from './ButtonOfActionsBatch';
import { TimelineType, UploadGet } from 'contexts/apiRequestContext';
import { UseMutateFunction } from '@tanstack/react-query';
import { sendFileHemeraDefaultValues, sendFileHemeraSchema } from './Modals/SendFileHemera/SendFileHemeraSchema';
import { FieldValues } from 'react-hook-form';
import SendFileHemeraForm from './Modals/SendFileHemera/SendFileHemeraForm';
import { FundReadModel } from 'services/fund/types/fundReadModel';

type BatchAssignmentFormProps = {
    refetch: () => void;
    setStepAction: (action: string) => void;
    onSubmitSaveDocuments: UseMutateFunction<any, unknown, UploadGet[], unknown>;
    timeline: TimelineType[];
    statusDisplay: string | undefined;
    drawerActionSteps: string | undefined
    onClose: () => void;
    mutateHemeraDeposit: UseMutateFunction<any, unknown, void, unknown>
    mutateGenerateFile: UseMutateFunction<any, unknown, void, unknown>
    mutateSendFile: UseMutateFunction<any, unknown, string[], unknown>
    onDigiMaisGenerateCsvFile: () => void;
    fund: FundReadModel | undefined
};

export const BatchAssignmentForm: React.FC<BatchAssignmentFormProps> = ({
    refetch,
    setStepAction,
    timeline,
    statusDisplay,
    onSubmitSaveDocuments,
    drawerActionSteps,
    onClose,
    mutateHemeraDeposit,
    mutateSendFile,
    mutateGenerateFile,
    onDigiMaisGenerateCsvFile,  
    fund
}) => {
    const { id } = useParams();
    const { watch, getValues } = useFormContext();

    const tabs = [
        {
            label: 'Informações',
            href: id,
        },
        {
            label: 'Documentos',
            href: 'documentos',
        },
        {
            label: 'Operações',
            href: 'operacoes',
        },
        {
            label: 'Parcelas',
            href: 'parcelas',
        },
        {
            label: 'Histórico',
            href: 'historico',
        },
    ];

    const steps: { [status: string]: string } = {
        'Em processamento': 'Processamento',
        'Coleta de assinaturas': 'Assinatura',
        'Concluída': 'Encerrado'
    };

    const handleSendFileHemera = async (values: FieldValues) => await mutateSendFile(values?.list);

    const fundIdDisplay = watch('fundIdDisplay') ?? 'N/D';
    const assignmentNumber = watch('assignmentNumber') ?? 'N/D';
    const uploads = watch('uploads') ?? [];

    const enableSendFileHemera = uploads?.filter((item: any) => item?.fileType === 'AssignmetReport')?.length > 0

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Grid container direction="column">
                    <Typography
                        variant="h4"
                        color="neutral.medium"
                        sx={{
                            fontWeight: '700',
                            fontSize: '32px',
                            lineHeight: '38px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {assignmentNumber}ª Cessão - {fundIdDisplay}
                    </Typography>
                    <Typography color="neutral.medium" variant="xs">
                        {`Status: ${statusDisplay ?? "N/D"}`}
                    </Typography>
                </Grid>

                <ButtonOfActionsBatch
                    onDepositHemera={mutateHemeraDeposit}
                    onGenerateFileHemera={mutateGenerateFile}
                    refetch={refetch}
                    setStepAction={setStepAction}
                    onSubmitSaveDocuments={onSubmitSaveDocuments}
                    enableSendFileHemera={enableSendFileHemera}
                    fund={fund}
                    onDigiMaisGenerateCsvFile={onDigiMaisGenerateCsvFile}
                />
            </Stack>
            {timeline.length > 0 && <Grid mt={4} ml={-2}>
                <Timeline
                    status={steps[statusDisplay!] ?? statusDisplay!}
                    statusDisplay={statusDisplay}
                    timeline={timeline}
                    timelineType='batchAssignment'
                />
            </Grid>}

            <Modal
                open={drawerActionSteps === 'sendHemera'}
                title="Enviar arquivo hemera"
                description="Tem certeza que deseja enviar arquivo hemera?"
                onClose={onClose}
                size='large'
                children={
                    <FormProvider
                        defaultValues={sendFileHemeraDefaultValues}
                        validationSchema={sendFileHemeraSchema()}
                        onSubmit={handleSendFileHemera}
                    >
                        <SendFileHemeraForm formValues={getValues()} onClose={onClose} />
                    </FormProvider>
                }
            />
            <Box p={0} mt={4} mr={3}>
                <TabBar tabs={tabs} />
            </Box>
        </>
    );
};
