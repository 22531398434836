import { number, object, string } from "yup";

const requiredMessage: string = "Precisa ser preenchido";

export function costsBillingParametersFormSchema() {
    return object().shape({
      code: string()
        .required(`Código: ${requiredMessage}`)
        .typeError(`Código: ${requiredMessage}`)
        .matches(/^[A-Z0-9]*$/, "O código deve conter apenas letras maiúsculas e números."),
      register: number()
        .required(`Custo registro: ${requiredMessage}`)
        .typeError(`Custo registro: ${requiredMessage}`)
        .min(0, "Custo registro não pode ser negativo."),
      settlement: number()
        .required(`Custo liquidação: ${requiredMessage}`)
        .typeError(`Custo liquidação: ${requiredMessage}`)
        .min(0, "Custo liquidação não pode ser negativo."),
      debtCollectionSettlement: number()
        .required(`Custo liquidação cartório: ${requiredMessage}`)
        .typeError(`Custo liquidação cartório: ${requiredMessage}`)
        .min(0, "Custo liquidação cartório não pode ser negativo."),
      cancellation: number()
        .required(`Custo baixa: ${requiredMessage}`)
        .typeError(`Custo baixa: ${requiredMessage}`)
        .min(0, "Custo baixa não pode ser negativo."),
      debtCollection: number()
        .required(`Custo protesto: ${requiredMessage}`)
        .typeError(`Custo protesto: ${requiredMessage}`)
        .min(0, "Custo protesto não pode ser negativo."),
      debtCollectionSuccess: number()
        .required(`Custo baixa protesto: ${requiredMessage}`)
        .typeError(`Custo baixa protesto: ${requiredMessage}`)
        .min(0, "Custo baixa protesto não pode ser negativo."),
      debtCollectionCancellation: number()
        .required(`Custo sustar protesto: ${requiredMessage}`)
        .typeError(`Custo sustar protesto: ${requiredMessage}`)
        .min(0, "Custo sustar protesto não pode ser negativo."),
      discount: number()
        .required(`Desconto: ${requiredMessage}`)
        .typeError(`Desconto: ${requiredMessage}`)
        .min(0, "Desconto não pode ser negativo."),
      changeDueDate: number()
        .required(`Custo alteração vencimento: ${requiredMessage}`)
        .typeError(`Custo alteração vencimento: ${requiredMessage}`)
        .min(0, "Custo alteração vencimento não pode ser negativo."),
      transferRegister: number()
        .required(`Custo transferência registro: ${requiredMessage}`)
        .typeError(`Custo transferência registro: ${requiredMessage}`)
        .min(0, "Custo transferência registro não pode ser negativo."),
      transferCancellation: number()
        .required(`Custo transferência baixa: ${requiredMessage}`)
        .typeError(`Custo transferência baixa: ${requiredMessage}`)
        .min(0, "Custo transferência baixa não pode ser negativo."),
      overDueCancellation: number()
        .required(`Custo baixa decurso prazo: ${requiredMessage}`)
        .typeError(`Custo baixa decurso prazo: ${requiredMessage}`)
        .min(0, "Custo baixar decurso prazo não pode ser negativo."),
    });
  }

  export const defaultValuescostsBillingParametersForm = {
    code: null,
    register: null,
    settlement: null,
    debtCollectionSettlement: null,
    cancellation: null,
    debtCollection: null,
    debtCollectionSuccess: null,
    debtCollectionCancellation: null,
    discount: null,
    changeDueDate: null,
    transferRegister: null,
    transferCancellation: null,
    overDueCancellation: null
};