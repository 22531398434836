import { Grid, Stack } from '@mui/material';
import { Button, CompleteIcon, DocExcelIcon, DocPdfIcon } from '@uy3/web-components';
import { useBankAccountCnab240Mutation, useBankAccountStatementMutation, useBankAccountStatementSpreadsheetMutation } from 'contexts/bankAccount/bankAccountContext';
import { useIdentity } from 'contexts/identityContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';
import { DateFilterType } from './AccountStatementTabContainer';
import { ApiResponseError, handleOnError, ToastType } from 'contexts/apiRequestContext';
import { isValidString } from 'helpers';

interface ButtonExportProps {
    isEmptyData: boolean;
    isFetching: boolean;
    dateFilter: DateFilterType;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const theme = activeTheme();
const ButtonExportAccountStatement: React.FC<ButtonExportProps> = ({ dateFilter, isEmptyData, isFetching, setToast }) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();

    let filterStatement = {
        bankAccountId,
        initialDate: dateFilter.initialDate,
        finalDate: dateFilter.finalDate,
        token,
    };

    const onSuccess = (type: string) => {
        const optionsMessage: { [type: string]: string } = {
            "excel": "Extrato da conta exportado com sucesso em formato Excel.",
            "pdf": "Extrato da conta exportado com sucesso em formato PDF.",
            "cnab240": "Arquivo CNAB240 exportado com sucesso.",
        };
        
        const message = optionsMessage[type]; 

        setToast({
            open: isValidString(type),
            severity: 'success',
            title: message
        })
    }

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);

    const { 
        mutateStatementExportPdf, 
        isLoading: isLoadingExportPDf 
    } = useBankAccountStatementMutation(filterStatement, () => onSuccess('pdf'), onError);
    const { 
        mutateStatementSpreadsheet, 
        isLoading: isLoadingSpreadsheet 
    } = useBankAccountStatementSpreadsheetMutation(filterStatement, () => onSuccess('excel'), onError);
    const { 
        mutateCnab240, 
        isLoading: lodingCnab240 
    } = useBankAccountCnab240Mutation(filterStatement, () => onSuccess('cnab240'), onError);

    const postStatementExportPdf = async () => mutateStatementExportPdf();
    const postStatementSpreadsheet = async () => mutateStatementSpreadsheet();
    const postCnab240 = async () => mutateCnab240();

    const isLoadingActions = (
        isLoadingExportPDf || 
        isLoadingSpreadsheet || 
        lodingCnab240 || 
        isEmptyData || 
        isFetching
    );

    return (
        <Stack direction="row" justifyContent="flex-end" spacing={3} m={3}>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={isLoadingExportPDf}
                    onClick={postStatementExportPdf}
                    startIcon={<DocPdfIcon htmlColor={theme.palette.common.white} />}
                >
                    Exportar PDF
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={isLoadingActions}
                    onClick={postStatementSpreadsheet}
                    startIcon={<DocExcelIcon htmlColor={theme.palette.common.white} />}
                    color="primary"
                >
                    Exportar Excel
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={isLoadingActions}
                    startIcon={<CompleteIcon htmlColor={theme.palette.primary.contrastText} />}
                    onClick={postCnab240}
                >
                    Exportar CNAB240
                </Button>
            </Grid>
        </Stack>
    );
};

export default ButtonExportAccountStatement;
