import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import moment from "moment";
import { boolean, number, object, string } from "yup";

const genericMessage = 'Precisa ser preenchido.';

export const validationSchemaFGTSManagement = () => {
  return object().shape({
    assignmentDate: string().typeError('Data da cessão inválida.').required(`Data da cessão: ${genericMessage}`),
    fundId: string().typeError('Selecione um cessionário.').required(`Cessionário: ${genericMessage}`),
    minRate: number()
      .typeError(`Taxa mínima inválida.`)
      .required(`Taxa mínima: ${genericMessage}`)
      .moreThan(0, 'Taxa precisa ser maior que 0.')
      .max(0.10, 'Valor precisa ser menor ou igual a 10%')
      .transform(handleNumericNaN),
    assignmentPremiumValue: number()
      .typeError('Ágio inválido.')
      .required(`Ágio (R$): ${genericMessage}`)
      .transform(handleNumericNaN),
    assignmentPremiumPercent: number()
      .typeError('Ágio inválido.')
      .required(`Ágio (%): ${genericMessage}`)
      .max(0.15, 'Valor precisa ser menor ou igual a 15%')
      .transform(handleNumericNaN),
    reassign: boolean().nullable(),
    minAcceptanceDate: string().nullable(), 
    maxAcceptanceDate: string().typeError("Data máxima inválida.").required("Data máxima: Precisa ser preenchida")
  });
};

export const defaultValuesFGTSManagement = {
  id: null,
  assignmentDate: null,
  reassign: false,
  minRate: 0,
  assignmentPremiumValue: 0,
  assignmentPremiumPercent: 0,
  maxAcceptanceDate: moment().subtract(1, 'days').format(),
  minAcceptanceDate: moment().subtract(31, 'days').format()
};