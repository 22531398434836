/* eslint-disable react-hooks/exhaustive-deps */
import { DataTable, EditIcon, DeleteIcon, Typography } from '@uy3/web-components';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
    GridCallbackDetails,
    GridColDef,
    GridRowId,
    GridSelectionModel,
    GridSortModel
} from '@mui/x-data-grid';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import RiskScoreContainer from 'components/RiskScore/RiskScore/RiskScoreContainer';
import { useAppConfig } from 'contexts/appConfig';
import { CreditNoteFilterContainer } from 'components/DataTableFilters/CreditNoteFilter/CreditNoteFilterContainer';
import { Error } from 'components/Errors/Error';
import DatasetsStatusColumnContainer from 'components/RiskScore/DatasetsStatusColumn/DastasetsStatusColumnContainer';
import { useEffect, useState } from 'react';
import ButtonRemoveSelectedRow from 'components/DataTableFilters/MethodsForFilters/ButtonRemoveSelectedRow';
import { IconButton } from '@mui/material';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import moment from 'moment';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import { useQueryClient } from '@tanstack/react-query';
import { iconSx } from 'contexts/apiRequestContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { useTenant } from 'contexts/tenantContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type CreditListProps = {
    queryData: any;
    rowsSelected: string[] | GridRowId[] | undefined;
    setRowsSelected: (rows: any) => void;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setOpenPopup?: (rowData: any) => void;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    setOrderBy: React.Dispatch<React.SetStateAction<GridSortModel>>;
};

const defaultFieldsNamesColumn = [
    'tenantDisplay',
    'productIdDisplay', 
    'creditNoteNo',
    'personDisplay',
    'amortization.principalAmountInCents',
    'statusDisplay',
    'liquidationClosureStatusDisplay',
    'amortization.apr',
    'amortization.startDate',
    'statusMotorAnalysis',
    'statusMotorCredit',
    'riskScore',
    'personRegistrationNumber'
];

const CreditNoteList = ({
    queryData,
    rowsSelected,
    setRowsSelected,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setOpenPopup,
    handleSelectionModelChange,
    setOrderBy
}: CreditListProps) => {
    const { selectedColumns, setColumns, setDefaultValues, defaultValues } = useSelectColumns();
    const { appConfig } = useAppConfig();
    const [selectionModel, setSelectionModel] = useState([]);
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const queryClient = useQueryClient();
    const [storedQueryData, setStoredQueryData] = useState<any[]>([]);
    const { isParentTenant, isRootTenancy } = useTenant();

    useEffect(() => {
        if (queryData?.data) {
            setStoredQueryData(prev => {
                // Verifica se há novos dados que não estão presentes em storedQueryData
                const newData = queryData.data.filter((item: CreditNoteReadModel) => !prev.find(prevItem => prevItem.id === item.id));
                return [...prev, ...newData];
            });
        }
    }, [queryData?.data]);


    const hasPermissionCreditNoteToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, 'CreditNote')
    const hasPermissionPersonToReadDataset = () => {
        if (
            checkActionsPerms('ReadDataSet', permissionsData, 'NaturalPerson') ||
            checkActionsPerms('ReadDataSet', permissionsData, 'LegalPerson')
        ) return true;
        return false;
    };

    const sumTotalOperation = () => {
        const dataRowSelected = storedQueryData?.filter((operation: any) =>
            rowsSelected?.includes(operation.id)
        );
        const getOnlyValuesSelected = dataRowSelected?.map(
            (item: any) => item?.amortization?.principalAmountInCents
        )!;
        if (getOnlyValuesSelected?.length > 0) {
            return (
                getOnlyValuesSelected?.reduce((current: number, acc: number) => acc + current) ?? ''
            );
        }
    };

    useEffect(() => {
        setColumns(columns);
        if (defaultFieldsNamesColumn.length) {
            setDefaultValues(defaultFieldsNamesColumn);
        };
    }, []);

    useEffect(() => {
        setRowsSelected(selectionModel);
    }, [selectionModel]);

    if (queryData?.type === 'error') return <Error error={queryData} />;

    const useComplianceAnalysis = appConfig.USE_COMPLIANCE_ANALYSIS_DATASET;
    const useCreditAnalysis = appConfig.USE_CREDIT_ANALYSIS_DATASET;
    const hiddenAmortizationApr = appConfig.HIDDEN_AMORTIZATION_APR === 1;

    const handleLinkClick = async (id: string) => {
        await queryClient.invalidateQueries(['credit-data', id]);
        await queryClient.refetchQueries(['credit-data', id]);
    };

    const columnsCreditList = [
        isParentTenant
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                width: 141,
                align: 'left',
                editable: false,
                renderCell: (cellValues: any) => cellValues.value,
            }
            : undefined,
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            minWidth: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => `#${cellValues.value}`,
        },
        {
            field: 'productIdDisplay',
            headerName: 'Produto',
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => `${cellValues.value}`,
        },
        {
            field: 'liquidationModeDisplay',
            headerName: 'Modo de liquidação',
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            align: 'left',
        },
        {
            field: 'productCategoryDisplay',
            headerName: 'Categoria do produto',
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            align: 'left',
        },
        {
            field: 'personDisplay',
            headerName: 'Tomador',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'left',
        },
        {
            field: 'personRegistrationNumber',
            headerName: 'CPF/CNPJ',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                formatDocumentNumber(cellValues?.row?.personRegistrationNumber),
        },
        // OBS: Aguardando liquidationTypeDisplay
        // {
        //     field: 'liquidationType',
        //     headerName: 'Tipo de pagamento',
        //     hideSortIcons: true,
        //     flex: 2,
        //     editable: false,
        //     align: 'left',
        // },
        {
            field: 'amortization.principalAmountInCents',
            headerName: 'Valor principal',
            hideSortIcons: true,
            minWidth: 130,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                formatCurrencyInCents(cellValues?.row?.amortization?.principalAmountInCents),
        },
        {
            field: 'amortization.liquidValue',
            headerName: 'Valor líquido',
            hideSortIcons: true,
            width: 130,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues?.row?.amortization?.liquidValue),
        },
        {
            field: 'amortization.startDate',
            headerName: 'Data de início',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                moment(cellValues.row?.amortization?.startDate).format('DD/MM/YY')
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 190,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => {
                // Using "pago" temporarily
                const statusDisplay = cellValues.row?.statusDisplay;
                return statusDisplay === 'Liquidação' ? "Pago" : statusDisplay;
            }
        },
        {
            field: 'liquidationClosureStatusDisplay',
            headerName: 'Status do roteiro de liquidação',
            hideSortIcons: true,
            minWidth: 190,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => {
                return cellValues.row?.liquidationClosureStatusDisplay;
            }
        },
        hiddenAmortizationApr ? undefined : {
            field: 'amortization.apr',
            headerName: 'Taxa',
            hideSortIcons: true,
            width: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatPercentage(cellValues?.row?.amortization?.apr),
        },
        {
            field: 'amortization.termInMonths',
            headerName: 'Prazo',
            hideSortIcons: true,
            width: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => Math.max(cellValues?.row?.amortization?.termInMonths, cellValues?.row?.amortization?.numberOfPayments)
        },
        {
            field: 'createdBy.userIdDisplay',
            headerName: 'Criado por',
            hideSortIcons: true,
            editable: false,
            width: 125,
            align: 'left',
            renderCell: (cellValues: any) => cellValues?.row?.createdBy?.userIdDisplay,
        },
        {
            field: 'ownerUser.userIdDisplay',
            headerName: 'Operador',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues?.row?.ownerUser?.userIdDisplay,
        },
        {
            field: 'ownerGroup.groupDisplay',
            headerName: 'Grupo',
            width: 125,
            hideSortIcons: true,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues?.row?.ownerGroup?.groupDisplay,
        },
        {
            field: 'createdAt',
            headerName: 'Data de criação',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => moment(cellValues.value).format('DD/MM/YY HH:mm')
        },
        {
            field: 'updatedAt',
            headerName: 'Data de modificação',
            hideSortIcons: true,
            width: 160,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => moment(cellValues.value).format('DD/MM/YY HH:mm')
        },
        {
            field: 'acceptanceDate',
            headerName: 'Data de pagamento',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                cellValues.value === null ? 'N/D' : moment(cellValues.value).format('DD/MM/YY')
        },
        useComplianceAnalysis && hasPermissionCreditNoteToReadDataset
            ? {
                field: 'statusMotorAnalysis',
                headerName: 'Compliance',
                hideSortIcons: true,
                minWidth: 113,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <DatasetsStatusColumnContainer
                            recordType={'CreditNote'}
                            id={cellValues?.id}
                            datasetName="complianceanalysis"
                        />
                    );
                },
            }
            : undefined,
        useCreditAnalysis && hasPermissionCreditNoteToReadDataset
            ? {
                field: 'statusMotorCredit',
                headerName: 'Análise de crédito',
                hideSortIcons: true,
                minWidth: 110,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <DatasetsStatusColumnContainer
                            recordType={'CreditNote'}
                            id={cellValues?.id}
                            datasetName="creditanalysis"
                        />
                    );
                },
            }
            : undefined,
        isRootTenancy && hasPermissionPersonToReadDataset()
            ? {
                field: 'riskScore',
                headerName: 'Score de risco',
                hideSortIcons: true,
                minWidth: 120,
                editable: false,
                renderCell: (cellValues: any) => {
                    var isLegalperson = cellValues.row?.personDiscriminator === 'LegalPerson';
                    return (
                        <RiskScoreContainer
                            discriminator={cellValues.row?.personDiscriminator}
                            id={isLegalperson ? undefined : cellValues.row?.personId}
                        />
                    );
                },
            }
            : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 80,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            renderCell: (cellValues: any) => {
                const id = cellValues.id;
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={id} onClick={(e) => handleLinkClick(id)}>
                            <IconButton name='btn-edit'>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {hasPermission('CreditNote', 'Delete') &&
                            <IconButton name='btn-delete' onClick={() => setOpenPopup && setOpenPopup(cellValues)}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsCreditList as GridColDef[];
    const columnsActions = columns?.filter(item => item?.field === 'actions');

    var filteredColumns = columns?.filter(c => selectedColumns?.length === 0 ?
        defaultValues?.includes(c?.field) :
        selectedColumns?.includes(c?.field));

    const creditNoteClumns = [...filteredColumns, ...columnsActions];

    const handleClearSelection = () => {
        setSelectionModel([]);
        setRowsSelected([]);
    };

    return (
        <Stack position='relative'>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: (
                        <Grid mb={-0.5}>
                            <CreditNoteFilterContainer />
                        </Grid>
                    ),
                }}
                getRowId={(row) => row.id}
                columns={creditNoteClumns}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                paginationMode="server"
                checkboxSelection
                keepNonExistentRowsSelected
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={handleSelectionModelChange}
                onSortModelChange={(model) => setOrderBy(model)}
            />
            {rowsSelected!?.length > 0 && queryData !== undefined && (
                <ButtonRemoveSelectedRow
                    handleClearRow={handleClearSelection}
                    rowsSelected={rowsSelected}
                />
            )}
            {sumTotalOperation() > 0 && selectedColumns?.includes('amortization.principalAmountInCents') && (
                <Typography
                    variant="sm"
                    color="#6F6F6F"
                    style={{
                        position: 'absolute',
                        bottom: 14,
                        left: rowsSelected!?.length >= 10 ? 230 : 220
                    }}
                >
                    VOP: <strong>{formatCurrencyInCents(sumTotalOperation())}</strong>
                </Typography>
            )}
        </Stack>
    );
};

export default CreditNoteList;
