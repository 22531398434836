import { IconButton, Stack } from '@mui/material';
import {
    DataTable,
    SearchIcon,
    BankSlipIcon,
    ApproveIcon,
    RejectIcon,
    CancelIcon,
    CopyIcon,
} from '@uy3/web-components';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { onCopyText } from 'helpers/methods/OnCopyText';
import { ToastType, iconSx, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { formatBarcode } from 'helpers/formats/BarCodeFormat';
import { sumRowsSelectedDataTable } from 'helpers';
import { TableWithTotalValue } from 'components/TableWithTotalValue/TableWithTotalValue';

type PaymentTabProps = {
    page: number;
    queryData: any;
    rowsPerPage: number;
    setPage: (newPage: number) => void;
    setRowsPerPage: (page: number) => void;
    setTransferInfo: (cellValues: any) => void;
    onApprove: (paymentId: string) => void;
    onCancel: (paymentId: string) => void;
    onReject: (paymentId: string) => void;
    handlePaymentReceipt: (values: any) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    rowsSelected: GridRowId[];
    handleSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
};

export const PaymentTab = ({
    page,
    queryData,
    onCancel,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setTransferInfo,
    onApprove,
    onReject,
    handlePaymentReceipt,
    setToast,
    handleSelectionModelChange,
    rowsSelected
}: PaymentTabProps) => {
    const hasRowsSelected = rowsSelected?.length > 0;
    const totalValueAmount = sumRowsSelectedDataTable(queryData?.data, rowsSelected as string[], 'amount');

    const columnsPaymentList: GridColDef[] = [
        {
            field: 'date',
            headerName: 'Data do pagamento',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => {
                const formattedDate = new Date(value).toLocaleDateString('pt-BR');
                return value && formattedDate;
            },
        },
        {
            field: 'amount',
            headerName: 'Valor do pagamento',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {formatCurrencyInCents(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'barCode',
            headerName: 'Código de barras',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {formatBarcode(cellValues?.value)}
                    </div>
                );
            },
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                const statusDisplay = cellValues.row?.statusDisplay;

                const handleCopyBarCode = (barCode: string) => {
                    onCopyText({
                        showErrorToast: () =>
                            showErrorToast(
                                'Ops, houve um erro',
                                'Não foi possível copiar o Código de Barras',
                                setToast
                            ),
                        showSuccessToast: () =>
                            showSuccessToast(
                                'Sucesso ao copiar o Código de Barras',
                                'Código de Barras foi copiado para a sua área de transferência',
                                setToast
                            ),
                        value: barCode,
                    });
                };

                const barCode = cellValues.row?.barCode;
                const hasItemsSelected = rowsSelected?.length > 0;
                return (
                    <Stack direction='row' alignItems='center'>
                        <IconButton
                            title="Detalhes"
                            onClick={() => setTransferInfo(cellValues?.row)}
                        >
                            <SearchIcon sx={iconSx} />
                        </IconButton>

                        {barCode && (
                            <IconButton
                                title="Copiar código de barras"
                                onClick={() => handleCopyBarCode(barCode)}
                            >
                                <CopyIcon sx={iconSx} />
                            </IconButton>
                        )}

                        {statusDisplay === 'Aguardando aprovação' && (
                            <RowActions
                                listButtons={[
                                    {
                                        action: () => onApprove(cellValues?.row?.id),
                                        disabled: hasItemsSelected,
                                        icon: <ApproveIcon />,
                                        label: 'Aprovar',
                                    },
                                    {
                                        action: () => onReject(cellValues?.row?.id),
                                        disabled: hasItemsSelected,
                                        icon: <RejectIcon />,
                                        label: 'Reprovar',
                                    },
                                    {
                                        action: () => onCancel(cellValues?.row?.id),
                                        disabled: false,
                                        icon: <CancelIcon />,
                                        label: 'Cancelar',
                                    },
                                ]}
                            />
                        )}

                        {cellValues.row.statusDisplay === 'Efetivada' && (
                            <IconButton
                                title="Comprovante"
                                onClick={() => handlePaymentReceipt(cellValues)}
                            >
                                <BankSlipIcon sx={iconSx} />
                            </IconButton>
                        )}
                    </Stack>
                );
            },
        },
    ];

    return (
        <TableWithTotalValue {...{
            enableTotalValue: hasRowsSelected,
            qtdRowsSelected: rowsSelected?.length ?? 0,
            totalValue: totalValueAmount
        }}>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <BankAccountFiltersContainer typeFilter="payment" />,
                }}
                columns={columnsPaymentList}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                checkboxSelection={true}
                keepNonExistentRowsSelected
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={handleSelectionModelChange}
            />
        </TableWithTotalValue>

    );
};
