import { Grid, Stack } from '@mui/material'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import React from 'react'

type InformationBatchAssignmentProps = {
    fundAutocomplete: AutocompleteOptionList;
}

const InformationBatchAssignment: React.FC<InformationBatchAssignmentProps> = ({ fundAutocomplete }) => {
    const { watch } = useFormContext();
    return (
        <>
            <Grid container spacing={2} columns={10}>
                <Grid item xs={10}>
                    <AutocompleteField
                        name="fundId"
                        label="Cessionário"
                        displayName="fundIdDisplay"
                        link={`/cadastro/fundos/${watch('fundId')}`}
                        showEndIcon={watch('fundId')}
                        endIconType="link"
                        {...fundAutocomplete!}
                    />
                </Grid>
                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Valor inicial"
                        fullWidth
                        name="initialValue"
                        variant="outlined"
                        required
                    />
                </Grid>
                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Valor presente"
                        fullWidth
                        name="presentValue"
                        variant="outlined"
                        required
                    />
                </Grid>
                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Valor da cessão"
                        fullWidth
                        name="assignmentPrice"
                        variant="outlined"
                        required
                    />
                </Grid>

                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Valor creditado"
                        fullWidth
                        name="liquidationValue"
                        variant="outlined"
                        required
                    />
                </Grid>
                
                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Ágio"
                        fullWidth
                        name="assignmentCalculationResult"
                        variant="outlined"
                        required
                    />
                </Grid>
                <Grid item xs={5}>
                    <CurrencyFormField
                        label="Juros apropriados"
                        fullWidth
                        name="accruedInterest"
                        variant="outlined"
                        required
                    />
                </Grid>
                <Grid item xs={5}>
                    <DatePickerFormField
                        label='Data da Cessão'
                        name='assignmentDate'
                        fullWidth
                        shrink
                    />
                </Grid>
                <Grid item xs={5}>
                    <DatePickerFormField
                        label='Data do crédito'
                        name='creditDate'
                        fullWidth
                        shrink
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Stack spacing={2} mt={2}>
                </Stack>
                <TextFormField
                    fullWidth
                    label="Observações"
                    name="observations"
                    rows={4}
                    type="text"
                    margin="dense"
                    multiline
                    variant="outlined"
                />
            </Grid>
        </>
    )
}

export default InformationBatchAssignment
