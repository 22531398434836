import { Stack } from '@mui/material'
import { Button, Typography, UpdateIcon } from '@uy3/web-components';
import { ApiResponseError, iconSx } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import React from 'react'
import { activeTheme } from 'services/theme';

type ErrorBoxProps = {
    resource: string;
    status: 'loading' | 'success' | 'error';
    children: React.ReactNode;
    error: ApiResponseError;
    refreshByResource?: () => void;
    height?: number;
    title?: string;
}

const theme = activeTheme();
export const ErrorBox: React.FC<ErrorBoxProps> = (props) => {
    const {
        error,
        resource,
        title = "Serviço temporariamente indisponível",
        children,
        status,
        height = 476, 
        refreshByResource
    } = props;
    const textAlign = height > 400 ? 'center' : 'left'
    const { errorMessage } = mapErrorResponse(error);

    if (status === 'success' || status === 'loading') {
        return <>{children}</>
    }

    return (
        <Stack
            sx={{
                border: '1px solid #BABFD0',
                width: '100%',
                borderRadius: '8px',
                height: `${height}px`,
                padding: '12vh 24px',
                gap: '24px',
                textAlign: textAlign
            }}>
            <Typography fontSize='27px' fontWeight={700}>{title}</Typography>

            <Typography fontSize='22px' textAlign='left' margin='auto' fontWeight={400}>
                O servico <span style={{ color: theme.palette.primary.main }}>{resource ?? ""}</span> encontra-se temporariamente indisponível no momento. <br />
                Por favor, tente novamente mais tarde.  <br />
                Mensagem: {errorMessage}
            </Typography>

            <Stack direction='row' justifyContent={'center'}>

                <Button
                    startIcon={<UpdateIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    size='large'
                    variant='outlined'
                    sx={{ width: '300px' }}
                    onClick={refreshByResource}
                >
                    Recarregar informações
                </Button>
            </Stack>
        </Stack >
    )
}
