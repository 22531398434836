import { Fragment, useState } from 'react'
import { BillingProduct } from './BillingProduct'
import { useGetBillingProduct } from 'contexts/wallet/Product/BillingProductContext';
import { Drawer } from '@uy3/web-components';
import { BillingProductFormContainer } from './Drawers/BillingProductForm/BillingProductFormContainer';
import { toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';

export const BillingProductContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [page, setPage] = useState(0);
    const { data: queryData, isLoading, refetch, isFetching } = useGetBillingProduct({
        page,
        size: rowsPerPage
    });

    const onClose = () => setAction(undefined);

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onChangePage = (newPage: number) => setPage(newPage);

    return (
        <Fragment>
            <Toast toast={toast} setToast={setToast} />
            <BillingProduct {...{
                page,
                queryData,
                rowsPerPage,
                setPage: onChangePage,
                setRowsPerPage: onChangeRowsPerPage,
                isLoading,
                refetch,
                isFetching,
                addBillingProduct() {
                    setAction('addBillingProduct');
                },
            }} />

            <Drawer {...{
                anchor: 'right',
                open: action === 'addBillingProduct',
                title: "Adicionar produto de cobrança",
                onClose,
                children: (
                    <BillingProductFormContainer onClose={onClose} refetchList={refetch} setToast={setToast}/>
                )
            }} />
        </Fragment>
    )
}
