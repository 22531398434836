import { useState } from 'react';
import { useParams } from 'react-router';
import AccountStatementTabList from './AccountStatementTabList';
import { RefreshProgress } from 'components/RefreshProgress';
import moment from 'moment';
import {
    useBankAccountOperatorList,
    useBankAccountStatement,
    usePixRefoundMutation,
} from 'contexts/bankAccount/bankAccountContext';
import { FilterProvider } from 'contexts/filterContext';
import {
    BalancesType,
    PixRefundSuccess,
    StatementQueryDataType,
} from 'services/accounts/bankAccount';
import Toast from 'components/Toast/Toast';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import ButtonExportAccountStatement from './ButtonExport';
import AccountStatementHeader from './AccountStatementHeader';
import { Popup } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesPixRefund, validationSchemaPixRefund } from '../../Popup';
import PixRefundForm from '../../Popup/PixRefund/PixRefund';
import { FieldValues } from 'react-hook-form';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useIdentity } from 'contexts/identityContext';

export type DateFilterType = {
    initialDate: string;
    finalDate: string;
};

export function AccountStatementTabContainer() {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { submitting } = useApiRequest();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [periodSelected, setPeriodSelected] = useState<string>('útimos 7 dias');
    const [dateFilter, setDateFilter] = useState<DateFilterType>({
        initialDate: moment().subtract(7, 'days').format(),
        finalDate: moment().format(),
    });
    const [rowData, setRowData] = useState<StatementQueryDataType>();
    const { userSubId } = useIdentity();
    
    let bankAccountIdParam: string | undefined = undefined;
    if (userSubId !== undefined) {
        bankAccountIdParam = bankAccountId;
    }

    const { operatorListData } = useBankAccountOperatorList(bankAccountIdParam, { f_userUserId: userSubId });

    let tableData: StatementQueryDataType[] = [];
    const {
        data: dataStatement,
        refetch,
        isFetching,
    } = useBankAccountStatement(bankAccountId!, dateFilter);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    if (dataStatement) {
        tableData = (dataStatement?.balances ?? [])
            ?.filter((row: BalancesType) => row?.transactions?.length > 0)
            ?.reduce((transactionList: StatementQueryDataType[], currentBalance: BalancesType) => {
                return [
                    ...transactionList,
                    {
                        id: 'Balance' + transactionList.length,
                        type: 'Balance',
                        availableValue: currentBalance.availableValue,
                        date: currentBalance.issueDate,
                    },
                    ...currentBalance.transactions.map((transaction: StatementQueryDataType) => {
                        return {
                            ...transaction,
                            id: transaction?.id ?? Math.random().toString().replace('0.', ''),
                            type: 'Transaction',
                        };
                    }),
                ];
            }, [])
            .filter((result: StatementQueryDataType) => result.type !== 'Balance');
    }

    const onClosePopup = () => setRowData(undefined);
    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast('Ops, ocorreu um erro', errorMessage, setToast);
    };

    const handleOnSuccess = (_: PixRefundSuccess) => {
        showSuccessToast('Sucesso!', 'Operação realizada com sucesso', setToast);
        onClosePopup();
        refetch();
    };

    const { pixRefoundMutation } = usePixRefoundMutation(bankAccountId!, handleOnSuccess, onError);
    const handleOnPixRefound = async (values: FieldValues) =>  await pixRefoundMutation(values);

    const isEmptyData = tableData?.length === 0;
    const operatorLevel = ["MasterApprover"];
    const operatorHasPerm = operatorListData?.data?.some(op => op.userId === userSubId && operatorLevel.includes(op.level)) ?? false;

    return (
        <>
            <Popup
                open={rowData !== undefined}
                title="Estornar Pix"
                text="Tem certeza que deseja estornar o Pix?"
                onClose={onClosePopup}
                children={
                    <FormProvider
                        onSubmit={handleOnPixRefound}
                        validationSchema={validationSchemaPixRefund()}
                        defaultValues={defaultValuesPixRefund(rowData)}
                    >
                        <PixRefundForm onClosePopup={onClosePopup} />
                    </FormProvider>
                }
            />

            <Toast toast={toast} setToast={setToast} />
            <AccountStatementHeader
                isFetching={isFetching || submitting}
                refetch={refetch}
                periodSelected={periodSelected}
            />

            <RefreshProgress refreshing={submitting} />

            <FilterProvider
                availableFilters={{
                    initialDate: {
                        label: 'Data de início',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDate',
                    },
                }}
            >
                <AccountStatementTabList
                    {...{
                        queryData: tableData,
                        setDateFilter,
                        setPeriodSelected,
                        setPage: onChangePage,
                        setRowsPerPage: onChangeRowsPerPage,
                        rowsPerPage,
                        page,
                        setToast,
                        setRowData,
                        operatorHasPerm
                    }}
                />
            </FilterProvider>
            <ButtonExportAccountStatement
                dateFilter={dateFilter}
                isEmptyData={isEmptyData}
                isFetching={isFetching}
                setToast={setToast}
            />
        </>
    );
}
