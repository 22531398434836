import { ApiResponseError, showErrorToast, showWarningToast, ToastType } from "contexts/apiRequestContext";
import { HelperErrorType } from "contexts/formFieldsErrors";
import { mapErrorResponse } from "contexts/responseErrorContext";

export function handleOnErrorAmortizationSimulation(
    response: ApiResponseError, 
    setFormFieldsErrors: (value: HelperErrorType[]) => void, 
    setToast: React.Dispatch<React.SetStateAction<ToastType>>, 
    setError?: React.Dispatch<React.SetStateAction<ApiResponseError | undefined>>
) {
    const { fieldErrors, warningToastError, errorMessage } = mapErrorResponse(response);
    setError?.(response);
    setFormFieldsErrors(fieldErrors ?? []);

    if (warningToastError && warningToastError?.length > 0) {
        const title = 'Atenção!';
        showWarningToast(title, warningToastError, setToast);
    } else {
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    }
}

