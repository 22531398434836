import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Card = styled(Stack)<{ enableMediaStyles: boolean }>(
  ({ theme, enableMediaStyles }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
    border: '0.5px solid #BABFD0',
    background: theme.palette.common.white,
    containerType: 'inline-size',
    '&.isCard': {
      padding: '15px',
      boxShadow: 'none',
    },
    '& .infoText': {
      width: '100%',
    },
    '& .title': {
      fontSize: '16px',
      fontWeight: 700,
    },
    '& .value': {
      fontSize: '24px',
      fontWeight: 700,
    },
    '& .icon': {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ...(enableMediaStyles && {
      '@media (min-width: 442px)': {
        '& .value-content': {
          justifyContent: 'center',
          width: '100%',
          alignContent: 'center',
          textAlign: 'center',
          marginTop: '12px'
        },
      },
    }),
  })
);

Card.defaultProps = {
  alignItems: 'center',
}