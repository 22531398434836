import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type CostsBillingParametersFormProps = {
    onClose: () => void;
    isLoading: boolean;
};

const theme = activeTheme();

export const CostsBillingParametersForm = ({
    isLoading,
    onClose,
}: CostsBillingParametersFormProps) => {
    return (
        <Stack spacing={2}>
            <TextFormField label="Código" name="code" variant="outlined" required />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo de registro', name: 'register' },
                    { label: 'Custo de baixa', name: 'cancellation' },
                ]}
                />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo de abatimento', name: 'discount' },
                    { label: 'Custo alteração vencimento', name: 'changeDueDate' },
                ]}
                />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo de liquidação', name: 'settlement' },
                    { label: 'Custo protesto', name: 'debtCollection' },
                ]}
                />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo sustar protesto', name: 'debtCollectionCancellation' },
                    { label: 'Custo liquidação cartório', name: 'debtCollectionSettlement' },
                ]}
                />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo baixa protesto', name: 'debtCollectionSuccess' },
                    { label: 'Custo transferência registro', name: 'transferRegister' },
                ]}
            />
            <CurrencyCostsFormFieldsGroup
                fields={[
                    { label: 'Custo transferência baixa', name: 'transferCancellation' },
                    { label: 'Custo baixar decurso prazo', name: 'overDueCancellation' },
                ]}
            />
            <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    name="btn-close-mfa"
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm-mfa"
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    sx={{ justifyContent: 'center' }}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};

type CurrencyCostsFormFieldsGroupProps = {
    fields: {
        label: string;
        name: string;
    }[];
};
const CurrencyCostsFormFieldsGroup = ({ fields }: CurrencyCostsFormFieldsGroupProps) => {
    return (
        <Stack spacing={2} direction="row">
            {fields.map((field) => (
                <Grid container key={field.name}>
                    <CurrencyFormField
                        name={field.name}
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Grid>
            ))}
        </Stack>
    );
};
