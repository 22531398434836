import { FormProvider } from 'contexts/formContext';
import {
    defaultValueDownloadLongBatch,
    validationSchemaDownloadLongBatchForm,
} from './DownloadLongBatchSchema';
import { DownloadLongBatchBillingType } from 'services/walletManagement/billing/billing.types';
import { FieldValues } from 'react-hook-form';
import { useDownloadLongBatchBillingMutation } from 'contexts/wallet/Billing/billingContext';
import { ApiResponseError } from 'contexts/apiRequestContext';
import { DownloadLongBatch } from './DownloadLongBatch';

type DownloadLongBatchContainerProps = {
    onClose: () => void;
    rowsSelected: string[];
    handleSuccess: (action: string) => void;
    onError: (error: ApiResponseError) => void;
};

export const DownloadLongBatchContainer = ({
    onClose,
    rowsSelected,
    handleSuccess,
    onError,
}: DownloadLongBatchContainerProps) => {
    const { mutateAsync: downloadLongBatchBankSlip, isLoading: isLoadingDownloadLongBatch } =
        useDownloadLongBatchBillingMutation(() => handleSuccess('downloadLongBatch'), onError);
    const onDownloadLongBatchBankSlip = async (values: FieldValues) => {
        const { email } = values;
        const payload: DownloadLongBatchBillingType = {
            email,
            billingIds: rowsSelected as string[],
        };

        await downloadLongBatchBankSlip(payload);
        onClose();
    };

    return (
        <FormProvider
            validationSchema={validationSchemaDownloadLongBatchForm()}
            defaultValues={defaultValueDownloadLongBatch}
            onSubmit={onDownloadLongBatchBankSlip}
        >
            <DownloadLongBatch
                isLoadingDownloadLongBatch={isLoadingDownloadLongBatch}
                onClose={onClose}
            />
        </FormProvider>
    );
};
