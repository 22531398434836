import { activeConfig } from 'services/config';
import { string, object, number, boolean, date } from 'yup';
import { DefaultSchemaAmmSAC, ValidationSchemaAmmSac } from '../schema/SAC';
import { DefaultSchemaAmmCleanPrice, ValidationSchemaAmmCleanPrice } from '../schema/CleanPrice';
import {
    DefaultSchemaAmmPriceCoefficient,
    ValidationSchemaAmmPriceCoefficient,
} from '../schema/PriceCoefficient';
import {
    DefaultSchemaAmmReceivablesPrice,
    ValidationSchemaAmmReceivablesPrice,
} from '../schema/ReceivablesPrice';
import { DefaultSchemaAmmStudentLoan, ValidationSchemaAmmStudentLoan } from '../schema/StudentLoan';
import {
    DefaultSchemaAmmComercialPapper,
    ValidationSchemaAmmCommercialPapper,
} from '../schema/CommercialPapper';
import { DefaultSchemaAmmPrice } from '../schema/Price';
import { DefaultSchemaAmmFGTS } from '../schema/FGTS';
import { ValidationSchemaBasicAmortization } from '../schema/BasicAmortization';
import { handleNumericNaN, setDefaultValues, getNumberWithPrecision } from 'helpers';
import { isEmpty } from 'lodash';
import { DefaultSchemaDiscount } from '../schema/Discount';
import { CreditProductReadModel } from 'services/creditProduct';
import { liquidationScheduleSchema } from '../schema';

const appConfig = activeConfig();
const genericRequired = 'Precisa ser preenchido.';

const beneficiaryIdNotEqualPersonId = string().when('liquidationMode', {
    is: (value: string | number) => value === 'Simple',
    then: string()
        .typeError('Selecione um beneficiário')
        .required('Selecione um beneficiário')
        .test(
            'beneficiaryIdNotEqualPersonId',
            'Beneficiário e Tomador não podem ser iguais.',
            function (value) {
                const personId = this.parent.personId;
                return value !== personId;
            }
        ),
    otherwise: string().nullable().optional(),
});

export const validationSchemaCreditNoteForm = (product?: CreditProductReadModel) => {
    var minimum = getNumberWithPrecision(product?.minimumInterestRate ?? 0);
    var maximum = getNumberWithPrecision(product?.maximumInterestRate ?? 100);

    var useCDC = product?.isConsumerCreditNote;
    var isFGTS = product?.amortizationType === 'FGTS';
    var isInstrumentTypeCommercialPapper =
        product?.instrumentType?.toLowerCase() === 'commercialpapper';

    const ammSacOrPriceSchema = new ValidationSchemaAmmSac();
    const ammCleanPriceSchema = new ValidationSchemaAmmCleanPrice();
    const ammPriceCoefficientSchema = new ValidationSchemaAmmPriceCoefficient();
    const ammReceivablesPrice = new ValidationSchemaAmmReceivablesPrice();
    const ammStudentLoan = new ValidationSchemaAmmStudentLoan();
    const ammComercialPapper = new ValidationSchemaAmmCommercialPapper();
    const basicAmm = new ValidationSchemaBasicAmortization();

    const creditNoteSchemaBase = {
        transitoryBankAccountId: string().nullable().optional(),
        transitoryBankAccountOpenAccount: boolean().nullable(),
        liquidationMode: string().nullable().optional(),
        liquidationSchedule: liquidationScheduleSchema,
        beneficiaryId: string().nullable().optional(),
        fundId: appConfig.USE_FUND === 1 ? string().nullable() : string().nullable(),
        productId: string().typeError('Selecione um produto').required('Selecione um produto'),
        personId: string()
            .typeError('Tomador inválido')
            .required('Tomador: Precisa ser preenchido.'),
        liquidationType: string().when('liquidationMode', {
            is: (value: string | number) => value === 'Simple',
            then: string()
                .typeError('Selecione um tipo de liquidação')
                .required('Selecione um tipo de liquidação'),
            otherwise: string().nullable(),
        }),
        bankAccountId: string().when(['liquidationMode', 'liquidationType'], {
            is: (liquidationMode: string | number, liquidationType: string) =>
                liquidationMode === 'Simple' && liquidationType === 'EletronicTransfer',
            then: string()
                .typeError('Conta inválida')
                .required('Valor obrigatório para liquidações via TED.'),
            otherwise: string().nullable(),
        }),
        invoiceBarCode: string().when('liquidationType', {
            is: 'Invoice',
            then: string()
                .typeError('Valor precisa ser um número.')
                .required('Valor obrigatório para liquidações via boleto.')
                .test('length', 'O código de barras ou linha digitável inválida.', (value: any) => {
                    let onlyNumbers = /\D/g;
                    let barCodeOrLineDigitable = value?.replace(onlyNumbers, '');
                    return (
                        barCodeOrLineDigitable?.length === 44 ||
                        barCodeOrLineDigitable?.length === 47
                    );
                }),
            otherwise: string().nullable(),
        }),
        quantity: string().when('field', {
            is: () => isInstrumentTypeCommercialPapper === true,
            then: string()
                .test(
                    'conditionalRequired',
                    'Quantidade: precisa ser preenchido',
                    function (value) {
                        const unitPrice = this.parent.unitPrice;
                        return !unitPrice || unitPrice.length === 0 ? !!value : true;
                    }
                )
                .test('isInteger', 'Número precisa ser inteiro', (valueField: any) => {
                    let value;
                    if (
                        valueField !== undefined &&
                        valueField !== null &&
                        !isNaN(valueField) &&
                        !isEmpty(valueField) &&
                        valueField?.length !== 0
                    ) {
                        value = Number(valueField);
                    } else {
                        return true;
                    }
                    return Number.isInteger(value);
                })
                .transform(handleNumericNaN),
            otherwise: string().notRequired().transform(handleNumericNaN),
        }),
        unitPrice: string().when('field', {
            is: () => isInstrumentTypeCommercialPapper === true,
            then: string()
                .nullable()
                .test(
                    'conditionalRequired',
                    'Preço unitário: precisa ser preenchido',
                    function (value) {
                        const quantity = this.parent.quantity;
                        return !quantity || quantity.length === 0 ? !!value : true;
                    }
                )
                .transform(handleNumericNaN),
            otherwise: string().notRequired().transform(handleNumericNaN),
        }),
        issueNumber: number().when('field', {
            is: () => isInstrumentTypeCommercialPapper === true,
            then: number()
                .typeError('Número de série: precisa ser preenchido')
                .required('Número de série: precisa ser preenchido'),
            otherwise: number().notRequired().nullable(),
        }),
        emissionNumber: number().when('field', {
            is: () => isInstrumentTypeCommercialPapper === true,
            then: number()
                .typeError('Número da emissão: precisa ser preenchido')
                .required('Número de emissão: precisa ser preenchido'),
            otherwise: number().notRequired().nullable(),
        }),
        isRegistered: boolean(),
        bulkAssignment: boolean(),
        observations: string().nullable(),
        dueDateOnBusinessDays: boolean().default(false).nullable()
    };

    if (useCDC) {
        creditNoteSchemaBase.beneficiaryId = beneficiaryIdNotEqualPersonId;
    }

    const amortizationTypeObject: { [key: string]: any } = {
        //CÉDULA DE CRÉDITO COMERCIAL SAC B3
        sac: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammSacOrPriceSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        //CÉDULA DE CRÉDITO COMERCIAL PRICE B3
        price: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammSacOrPriceSchema,
                includePaymentFixedCosts: boolean(),
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        //CÉDULA DE CRÉDITO COMERCIAL
        cleanprice: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammCleanPriceSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        // PRICE COEFFICIENT
        pricecoefficient: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammPriceCoefficientSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        //CÉDULA PRICE COM GARANTIA FIDUCIÁRIA
        receivablesprice: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammReceivablesPrice,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        //FINANCIAMENTO ESTUDANTIL
        studentloan: object().shape({
            ...creditNoteSchemaBase,
            changeBankAccountId: string().typeError('Conta inválida').nullable(),
            amortization: object().shape({
                ...ammStudentLoan,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
            invoiceNumber:
                useCDC && isFGTS
                    ? number()
                          .typeError('Nº da NF/Contrato inválido.')
                          .required('Nº da NF/Contrato: Precisa ser preenchido.')
                    : string().nullable(),
            invoiceValue:
                useCDC && isFGTS
                    ? number()
                          .typeError('Valor da NF-e: é obrigatório.')
                          .required('Valor da NF-e: Precisa ser preenchido.')
                          .transform(handleNumericNaN)
                    : string().nullable(),
        }),

        //FGTS
        fgts: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...basicAmm,
                termInMonths: number()
                    .typeError('Valor precisa ser um número.')
                    .required(`Parcelas: ${genericRequired}`)
                    .min(1, 'Informe a quantidade de parcelas')
                    .max(12, 'Quantidade máxima de parcelas excedida (12 parcelas)'),
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required('Taxa de juros: Precisa ser preenchido.')
                    .transform(handleNumericNaN),
            }),
            changeBankAccountId: string().typeError('Conta inválida').nullable(),
            invoiceNumber:
                useCDC && isFGTS
                    ? number()
                          .typeError('Nº da NF/Contrato inválido.')
                          .required('Nº da NF/Contrato: Precisa ser preenchido.')
                    : string().nullable(),
            invoiceValue:
                useCDC && isFGTS
                    ? number()
                          .typeError('Valor da NF-e: é obrigatório.')
                          .required('Valor da NF-e: Precisa ser preenchido.')
                          .transform(handleNumericNaN)
                    : string().nullable(),
        }),

        //NOTA COMERCIAL
        commercialpapper: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...ammComercialPapper,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),

        discount: object().shape({
            amortization: object().shape({
                startDate: date()
                    .typeError('Data inválida')
                    .required('Data de início é obrigatória.'),
            }),
        }),

        //DEFAULT
        default: object().shape({
            ...creditNoteSchemaBase,
            amortization: object().shape({
                ...basicAmm,
            }),
        }),
    };

    const productType = (product?.amortizationType || 'default').toLowerCase();

    return amortizationTypeObject[productType];
};

export const creditNoteDefault = {
    liquidationMode: 'Simple',
    liquidationSchedule: null,
    fundId: null,
    emissionDate: null,
    acceptanceDate: new Date().toDateString(),
    liquidationType: 'EletronicTransfer',
    sameBeneficiary: true,
    isRegistered: false,
    bulkAssignment: false,
    personId: null,
    observations: null,
    collateral: null,
    legalPerson: false,
    assignmentCalculation: null,
    bankAccountId: null,
    transitoryBankAccountId: null,
    transitoryBankAccountOpenAccount: false, 
    dueDateOnBusinessDays: false
};

export const defaultValuesCreditNoteForm = (product?: CreditProductReadModel) => {
    const ammSac = new DefaultSchemaAmmSAC();
    const ammPrice = new DefaultSchemaAmmPrice();
    const ammCleanPrice = new DefaultSchemaAmmCleanPrice();
    const ammPriceCoefficient = new DefaultSchemaAmmPriceCoefficient();
    const ammReceivablesPrice = new DefaultSchemaAmmReceivablesPrice();
    const ammStudentLoan = new DefaultSchemaAmmStudentLoan();
    const ammDiscount = new DefaultSchemaDiscount();
    const ammFGTS = new DefaultSchemaAmmFGTS();
    const ammComercialPapper = new DefaultSchemaAmmComercialPapper();


    const amortizationTypeObject: { [key: string]: any } = {
        sac: {
            ...creditNoteDefault,
            legalPerson: false,
            amortization: { ...ammSac },
        },
        price: {
            ...creditNoteDefault,
            legalPerson: false,
            amortization: { ...ammPrice },
            invoiceValue: 0,
        },
        cleanprice: {
            legalPerson: false,
            amortization: { ...ammCleanPrice },
        },
        pricecoefficient: {
            legalPerson: false,
            amortization: { ...ammPriceCoefficient },
        },
        receivablesprice: {
            ...creditNoteDefault,
            amortization: { ...ammReceivablesPrice },
        },
        studentloan: {
            ...creditNoteDefault,
            amortization: { ...ammStudentLoan },
            invoiceNumber: null,
            invoiceValue: 0,
            changeBankAccountId: '',
        },

        fgts: {
            ...creditNoteDefault,
            amortization: { ...ammFGTS },
        },

        commercialpapper: {
            ...creditNoteDefault,
            amortization: {
                ...ammComercialPapper,
                indexer: 'CDI',
            },
        },
        discount: {
            ...creditNoteDefault,
            amortization: {
                ...ammDiscount,
            },
        },
        default: {
            ...creditNoteDefault,
            amortization: {
                ...ammCleanPrice,
            },
        },
    };

    const productType = (product?.amortizationType || 'default').toLowerCase();

    return setDefaultValues(amortizationTypeObject[productType]);
};
