import { FieldValues } from "react-hook-form";
import { number, object } from "yup";

export const editProposalSchema = (currentVelues: FieldValues) => {
    return object().shape({
        installmentValue: number()
        .typeError('Valor parcela: precisa ser preenchido.')
        .required('Valor parcela: precisa ser preenchido.')
        .min(0.01, "Valor da parcela: precisa ser maior que 0"),
        numberOfPayments: number()
            .typeError('Quantidade de parcela: precisa ser preenchido.')
            .required('Quantidade de parcela: precisa ser preenchido.'),
    });
};

export const editProposalDefaultValues = (currentVelues: FieldValues) => {
    return {
        installmentValue: currentVelues?.requestedAmount ?? 0,
        numberOfPayments: currentVelues?.numberOfPayments ?? 1
    }
}