import { object, string } from 'yup';

export function validationSchemaGenerateFileLiquidationSchema() {
    return object().shape({
        dateMin: string()
            .typeError('Data de liquidação inicial precisa ser preenchida')
            .required('Data de liquidação inicial: precisa ser preenchida'),
        dateMax: string()
            .typeError('Data de liquidação final precisa ser preenchida')
            .required('Data de liquidação final : precisa ser preenchida'),
        fundId: string().nullable(),
    });
}

export const defaultValuesGenerateFileLiquidationSchema = {
    dateMin: null,
    dateMax: null,
    fundId: null,
    amortizationTypes: ['fgts'],
};
