import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DeleteIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { FieldValues } from 'react-hook-form';
import { RelatedCreditorBankAccount } from 'services/accounts/bankAccount';

type CreditorsListProps = {
    queryData: FieldValues[] | RelatedCreditorBankAccount[];
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    page: number;
    setActions: React.Dispatch<React.SetStateAction<{
        step: string;
        rowData?: FieldValues;
    } | undefined>>
    isLydians?: boolean
}

export const CreditorsList: React.FC<CreditorsListProps> = ({
    queryData,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setActions,
    isLydians
}) => {

    const columns: GridColDef[] = [
        {
            field: isLydians ? 'personName': 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false

        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            align: 'center',
            headerAlign: 'center',
            minWidth: 80,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        <IconButton
                            onClick={() => setActions({
                                rowData: cellValues.row,
                                step: 'delete'
                            })}
                            name="btn-delete"
                        >
                            <DeleteIcon sx={iconSx} />
                        </IconButton>

                    </Stack>
                );
            },
        }
    ]

    return (
        <React.Fragment>
            <DataTable
                NoRowsOverlayNew="Nenhum credor encontrado"
                NoResultsOverlayNew="Nenhum credor encontrado"
                getRowId={(row) => Math.random().toString()}
                columns={columns}
                rows={queryData ?? []}
                page={page}
                rowCount={5}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </React.Fragment>
    )
}
