import { useQuery } from '@tanstack/react-query';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { SelectOption } from 'components/Forms/FormFields';
import { useFilterValues } from './filterValuesContext';
import { getGovernmentDepartment } from 'services/creditNote/governmentDepartment/governmentDepartment';
import { IFiltersGovernmentDepartment } from 'services/creditNote/governmentDepartment/types';
import { useTenant } from './tenantContext';

export function useGovernmentDepartment() {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant(); 
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [payrollAgreementList, setPayrollAgreementList] = useState<SelectOption[]>([]);
    const {
        filterValues: { filters },
        setFilterValues,
    } = useFilterValues();

    const filtersComplete = {
        searchString: filters?.searchString,
        tenant: currentTenantId
    } as IFiltersGovernmentDepartment;

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-government-departement-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const resp = await getGovernmentDepartment(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const options = data.data.map((item) => ({
                label: `${item.name ?? 'Não informado'}`,
                value: item.id,
            }));

            setPayrollAgreementList(options);

            return data;
        },
    });

    const governmentDepartmentAutocomplete = {
        listOptions: payrollAgreementList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'PayrollAgreements'),
    };

    return {
        ...queryContext,
        data: queryContext?.data,
        governmentDepartmentAutocomplete,
    };
}