export const interestCodeOptionsList = [
    { label: "Valor dias corridos", value: "ValorDiasCorridos" },
    { label: "Percentual dia dias corridos", value: "PercentualDiaDiasCorridos" },
    { label: "Percentual mês dias corridos", value: "PercentualMesDiasCorridos" },
    { label: "Percentual ano dias corridos", value: "PercentualAnoDiasCorridos" },
    { label: "Isento", value: "Isento" },
    { label: "Valor dias úteis", value: "ValorDiasUteis" },
    { label: "Percentual dia dias úteis", value: "PercentualDiaDiasUteis" },
    { label: "Percentual mês dias úteis", value: "PercentualMesDiasUteis" },
    { label: "Percentual ano dias úteis", value: "PercentualAnoDiasUteis" },
  ];