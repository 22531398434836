import { Grid, Stack } from '@mui/material';
import { Button, CardData, CloseIcon, CompleteIcon, Typography } from '@uy3/web-components';
import { CheckboxFormField } from 'components/Forms/FormFields';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types'

interface IRegisterForm {
    data: IInstallmentFull;
    onClose: () => void
    isLoading: boolean;
};

const theme = activeTheme();

export const RegisterForm: React.FC<IRegisterForm> = ({ data, onClose, isLoading}) => {
    
    return (
        <Stack spacing={2} mt={3}>
            <Typography variant='h6' fontWeight='bold'>Detalhes</Typography>
            <CardData {...{
                listItem: [
                    { id: '1', title: "Código do convênio", value: data?.walletCode },
                    { id: '2', title: "Status", value: data?.statusDisplay },
                    { id: '3', title: "Devedor", value: data?.payer?.name },
                ]
            }} />
            <Grid xs={2}>
                <CheckboxFormField
                    name='enableRegisterOverDue'
                    label='Registrar cobranças vencidas'
                />
            </Grid>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" mt={-2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    size="medium"
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, fechar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type='submit'
                    size="medium"
                    disabled={isLoading}
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    sx={{ mt: 3 }}
                >
                    Sim, registrar
                </Button>
            </Stack>
        </Stack>
    )
}
