import React from 'react'
import { PartnerFormSharedType } from '../../PartnerForm'
import BiliingForm from './BiliingForm'

type BiliingFormContainerType = {} & PartnerFormSharedType;

export const BiliingFormContainer: React.FC<BiliingFormContainerType> = (props) => {
    return (
        <BiliingForm />
    )
}
