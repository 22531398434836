import { Stack, styled } from "@mui/material";
import { activeTheme } from "services/theme";

const theme = activeTheme();
const palette = theme.palette; 
type OptionCustomStyle = {
    disable: boolean
}

export const OptionCard = styled(Stack)<OptionCustomStyle>(({ disable }) => ({
    background: disable ? palette.grey[100] : palette.common.white,
    border: `2px solid ${disable ? palette.grey[500] : palette.grey['700']}`,
    borderRadius: '8px',
    cursor: disable ? 'not-allowed' : 'pointer',
    width: '144px',
    height: '144px',
    padding: '12px 16px',
    position: 'relative',
    transition: 'border 0.3s ease-in-out',

    ...(disable
        ? {
              opacity: 0.6,
              "& .title": {
                  color: palette.grey[600],
              },
          }
        : {
              ":hover": {
                  border: `2px solid ${palette.primary.main}`,
                  "& .iconCard": {
                      htmlColor: palette.primary.main,
                      transform: "scale(1.1)",
                      transition: "transform 0.2s ease-in-out",
                  },
                  "& .title": {
                      color: palette.primary.main,
                  },
              },
          }),
}));

export const TitleCard = styled(Stack)(() => ({
    fontSize: '16px', 
    fontWeight: 700,
    position: 'absolute', 
    bottom: 10,

}));