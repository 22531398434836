import { Stack } from '@mui/material';
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { activeTheme } from 'services/theme';

type UpdateConsignmentProps = {
    onCloseDrawer: () => void;
    isDisableAction: boolean;
};
const palette = activeTheme().palette;

export default function UpdateConsignmentForm({ onCloseDrawer, isDisableAction }: UpdateConsignmentProps) {

    return (
        <Stack spacing={3}>
            <TextFormField
                variant="outlined"
                name="registrationNumber"
                label="CPF"
                required
                disabled
            />

            <TextFormField
                variant="outlined"
                name="employeeCode"
                label="Nº da Matrícula"
                required
            />

            <TextFormField
                variant="outlined"
                name="employeePassword"
                label="Senha do servidor"
                required
            />

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon htmlColor={palette.primary.main} />}
                    onClick={onCloseDrawer}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    disabled={isDisableAction}
                    startIcon={<CompleteIcon htmlColor={palette.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
}
