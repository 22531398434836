import { Stack, styled, SvgIconProps } from '@mui/material';
import { AlertIcon, Button, CheckRectangleIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React, { FunctionComponent } from 'react';
import { activeTheme } from 'services/theme';

type SeverityType = 'Success' | 'Warning';

type AlertWithActionProps = {
    severityType: SeverityType;
    message: string;
    action: {
        label: string;
        Icon?: FunctionComponent<SvgIconProps>;
        onClick?: () => void;
    };
};

const theme = activeTheme();

export const AlertWithAction: React.FC<AlertWithActionProps> = ({
    severityType,
    message,
    action,
}) => {
    return (
        <AlertStack severityType={severityType}>
            <Stack direction='row' alignItems='center' spacing={2}>
                {severityType === 'Success' ? <CheckRectangleIcon sx={iconSx}/> : <AlertIcon sx={iconSx}/>}
                <Typography fontSize="17px" fontWeight="600">
                    {message}
                </Typography>
            </Stack>
            <Button
                type="button"
                onClick={() => action.onClick?.()}
                variant="text"
                sx={{ color: theme.palette.common.black }}
                startIcon={action.Icon && <action.Icon sx={iconSx} />}
            >
                {action?.label}
            </Button>
        </AlertStack>
    );
};

const AlertStack = styled(Stack)<{ severityType: SeverityType }>(({ severityType }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 20px',
    borderRadius: '8px',
    background: severityType === 'Success' ? '#7BC67E' : '#FFDFAE'
}));
