import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
type DownloadLongBatchProps = {
    onClose: () => void;
    isLoadingDownloadLongBatch: boolean;
};

export const DownloadLongBatch = ({
    onClose,
    isLoadingDownloadLongBatch,
}: DownloadLongBatchProps) => {
    return (
        <Stack spacing={4}>
            <TextFormField name="email" label="E-mail" variant="outlined" fullWidth required />
            <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    disabled={isLoadingDownloadLongBatch}
                    size="medium"
                >
                    Enviar
                </Button>
            </Stack>
        </Stack>
    );
};
