import { FilterProvider } from 'contexts/filterContext';
import { BatchAssignmentList } from '../BatchAssignmentList';
import {
    GridCallbackDetails,
    GridRowId,
    GridSelectionModel,
    GridSortModel,
} from '@mui/x-data-grid';
import { GetViewApiResponseSuccess } from 'contexts/apiRequestContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

interface BatchListWithFilterProps {
    batchAssignmentData: GetViewApiResponseSuccess<CreditNoteReadModel[]> | [];
    fundAutocompleteData: any;
    batchAssignmentSelectFilterOptions: any;
    rowsPerPage: number;
    page: number;
    setOrderBy: React.Dispatch<React.SetStateAction<GridSortModel>>;
    selectedStatusOfTable: string[] | GridRowId[] | undefined;
    setSelectedStatusOfTable: React.Dispatch<React.SetStateAction<string[]>>;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    onChangeRowsPerPage: (page: number) => void;
    onChangePage: (page: number) => void;
}

const BatchListWithFilters: React.FC<BatchListWithFilterProps> = ({
    batchAssignmentData,
    fundAutocompleteData,
    page,
    handleSelectionModelChange,
    setOrderBy,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    selectedStatusOfTable,
    setSelectedStatusOfTable,
    batchAssignmentSelectFilterOptions,
}) => {
    return (
        <FilterProvider
            availableFilters={{
                searchString: { label: 'Todos', type: 'text' },
                assignmentCalculationResult: { label: 'Ágio', type: 'text' },
                maxPresentValue: { label: 'Valor máximo do presente', type: 'text' },
                assignmentNumber: { label: 'Número da cessão', type: 'text' },
                creditNoteNos: { label: 'Número da operação', type: 'text' },
                minPresentValue: { label: 'Valor mínimo do ptesente', type: 'text' },
                maxAssigmentValue: { label: 'Valor máximo da cessão', type: 'text' },
                minAssigmentValue: { label: 'Valor mínimo da cessão', type: 'text' },
                minAssignmentCalculationValue: {
                    label: 'Valor máximo do cálculo',
                    type: 'text',
                },
                maxAssignmentCalculationValue: {
                    label: 'Valor mínimo do cálculo',
                    type: 'text',
                },
                presentValue: { label: 'Valor presente', type: 'text' },
                initialAssignmentDate: {
                    label: 'Data da cessão',
                    type: 'date',
                    multiple: false,
                    dateRangeName: 'finalAssignmentDate',
                },
                fundId: {
                    label: 'Cessionário',
                    type: 'autocomplete',
                    multiple: false,
                    options: fundAutocompleteData.listOptions,
                },
                status: {
                    label: 'Status',
                    type: 'select',
                    multiple: true,
                    options: batchAssignmentSelectFilterOptions,
                },
            }}
        >
            <BatchAssignmentList
                queryData={batchAssignmentData ?? []}
                page={page}
                handleSelectionModelChange={handleSelectionModelChange}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsSelected={setSelectedStatusOfTable}
                rowsSelected={selectedStatusOfTable}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
            />
        </FilterProvider>
    );
};

export default BatchListWithFilters;
