import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiResponseError, GetListApiResponseSuccess, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { handleErrorUseQuery } from "helpers";
import { createBillingProduct, createProductCostsDefinition, getBillingProducts, getProductsCostsDefinition } from "services/walletManagement/product/product"
import { BillingProductCreateModel } from "services/walletManagement/product/models/billingProductCreateModel";
import { ProductsCostsDefinitionFilters, BillingProductRaadModel, CostsBillingParametersRadModel } from "services/walletManagement/product/models/billingProductReadModel";
import { CostsBillingParametersCreateModel } from "services/walletManagement/product/models/CostsBillingParametersCreateModel";
import { SelectOption } from "components/Forms/FormFields";
import { useFilterValues } from "contexts/filterValuesContext";
import { useTenant } from "contexts/tenantContext";
import { useState } from "react";


export function useGetBillingProduct(params: ProductsCostsDefinitionFilters = { page: 0, size: 5 }) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const [billingProduct, setBillingProduct] = useState<SelectOption[]>([]);
    params = { ...params, tenant: currentTenantId }
    var query = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-products-billing', params],
        queryFn: async (): Promise<GetListApiResponseSuccess<BillingProductRaadModel>> => {
            const { data } = await getBillingProducts(token!, params);
            const dataFromAutocomplete = data?.data?.map((item) => {
                return {
                    label: `Código: ${item.code} `,
                    value: item?.code,
                    optionExtraKeyValues: [
                        {
                            key: 'Tipo de cobrança',
                            value: item.billingTypeValueDisplay,
                        },
                        {
                            key: 'Criado em',
                            value: new Date(item?.createdAt).toLocaleDateString('pt-br'),
                        }
                    ],
                }
            });
            setBillingProduct(dataFromAutocomplete);
            return data;
        },
    });

    const billingProductAutocomplete = {
        listOptions: billingProduct,
        loading: query.isLoading || query.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ ...filterValues.filters, searchString }, 'BillingProduct'),
    };

    return { ...query, billingProductAutocomplete }
}

export function useGetProductsCostsDefinition(filters: ProductsCostsDefinitionFilters = {
    page: 0,
    size: 10
}) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const [productsCostsDefinition, setProductsCostsDefinition] = useState<SelectOption[]>([]);
    filters = {...filters, tenant: currentTenantId}
    var query = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-products-costs', filters],
        queryFn: async (): Promise<GetListApiResponseSuccess<CostsBillingParametersRadModel>> => {
            const { data } = await getProductsCostsDefinition(token!, filters);
            const dataFromAutocomplete = data?.data?.map((item) => {
                return {
                    label: `Código: ${item.code} `,
                    value: item?.code
                }
            });
            setProductsCostsDefinition(dataFromAutocomplete);
            return data;
        },
    });

    const productsCostsDefinitionAutocomplete = {
        listOptions: productsCostsDefinition,
        loading: query.isLoading || query.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ ...filterValues.filters, searchString }, 'BillingProduct'),
    };

    return {
        ...query,
        productsCostsDefinitionAutocomplete
    }
}

export function useCreateBillingProduct(
    onSuccess: (data: BillingProductRaadModel) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: BillingProductCreateModel) => {
            startRequest();
            const { data } = await createBillingProduct(payload, token!);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useCreateProductsCostsDefinition(
    onSuccess: (data: CostsBillingParametersCreateModel) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: CostsBillingParametersCreateModel) => {
            startRequest();
            const { data } = await createProductCostsDefinition(payload, token!);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}
