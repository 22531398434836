import {
    GroupedButtons,
    TransactionalLimitsIcon,
    Typography,
    Offline,
    Alert,
    UpdateIcon,
    Button,
} from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import MyLimitsList from './MyLimitsList/MyLimitsList';
import React, { useState } from 'react';
import DrawerFormContainer from './DrawerForm/DrawerFormContainer';
import { useParams } from 'react-router';
import { useBankAccountBalance, useBankAccountLimitsList, useBankAccountOperatorList } from 'contexts/bankAccount/bankAccountContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { ApiResponseError, iconSx } from 'contexts/apiRequestContext';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { activeTheme } from 'services/theme';
import { RefreshProgress } from 'components/RefreshProgress';
import { BankAccountLimitSkeleton } from 'components/Skeleton/BankAccountLimitSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';

const theme = activeTheme();

export function MyLimitsContainer() {
    const { userSubId } = useIdentity();
    const [recordType, setRecordType] = useState(0);
    const { bankAccountId } = useParams();
    const [openDrawer, setOpenDrawer] = useState(false);
    const { isRootTenancy } = useTenant();
    const { data: permissionsData } = useUserPermissionData();
    const hasViewAllBankAccountsPerm = checkActionsPerms("ViewAllBankAccounts", permissionsData, 'BankAccount');
    const { data: BalanceData } = useBankAccountBalance(bankAccountId!);

    let bankAccountIdParam: string | undefined = undefined;
    // A inclusão desta lógica visa a execução da requisição de operadores de conta bancária somente quando "userSubId" estiver definido.
    // Evitando requisições desnecessárias.
    if (userSubId !== undefined) {
        bankAccountIdParam = bankAccountId;
    }

    // O contexto é habilitado somente quando token e bankAccountIdParam é definido. (enabled: !!token && !!bankAccountId)
    const { operatorListData } = useBankAccountOperatorList(bankAccountIdParam, { f_userUserId: userSubId });

    const { limitsListData, limitsListError, limitsListStatus, limitsListRefetch, isFetching, isLoading } =
        useBankAccountLimitsList(bankAccountId!);

    if (limitsListStatus === 'error') {
        const { errorMessage } = mapErrorResponse(limitsListError as ApiResponseError);
        return <Offline errorMessage={errorMessage} highlightedText="Limites transacionais" />;
    }

    const operatorLevel = ["MasterApprover", "JointApprover", "Requester"];
    const operatorHasPerm = !!operatorListData?.data?.length && operatorListData?.data?.some((operator) => {
        return operatorLevel.includes(operator.level);
    });

    const showBtnRequestLimit = ((!!isRootTenancy && hasViewAllBankAccountsPerm) || operatorHasPerm) && !limitsListData?.existPendingLimitsApproval;
    const accountType = BalanceData?.bankAccount?.typeDisplay;

    return (
        <ErrorBoundary fallback='limites'>
            <BankAccountLimitSkeleton isLoading={isLoading}>
                <React.Fragment>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Stack direction="column" spacing={1} mb={4}>
                            <Typography variant="xxl" fontWeight={800} color="neutral.medium">
                                Limite transacional
                            </Typography>
                            <Typography variant="xs" fontWeight={400} color="neutral.medium">
                                Horário comercial: 06 às 20h / Fora do horário: 20h às 06h.
                            </Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                            <Button
                                variant='text'
                                onClick={() => limitsListRefetch()}
                                startIcon={<UpdateIcon sx={iconSx} />}
                                sx={{ fontSize: 18, color: theme.palette.common.black }}
                            >
                                Atualizar
                            </Button>
                            {showBtnRequestLimit && <Button
                                variant='text'
                                startIcon={<TransactionalLimitsIcon sx={iconSx} />}
                                sx={{ fontSize: 18, color: theme.palette.common.black }}
                                onClick={() => setOpenDrawer(true)}
                            >
                                Solicitar aumento de limite
                            </Button>}
                        </Stack>
                    </Stack>

                    <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                        <GroupedButtons
                            groupedButtons={['Mesma titularidade', 'Pessoa física', 'Pessoa Jurídica']}
                            onClick={(value) => {
                                setRecordType(value);
                            }}
                            size="large"
                            width={148}
                        />
                    </Stack>
                    {limitsListData?.existPendingLimitsApproval &&
                        <Stack m='25px 22px 3px 0'>
                            <Alert
                                severity='info'
                                text='Informamos que, para solicitar um novo aumento de limite, é necessário que a solicitação anterior seja previamente aprovada ou reprovada.'
                            />
                        </Stack>
                    }
                    <Grid mb={2}>
                        <RefreshProgress refreshing={isFetching} />
                    </Grid>
                    
                    <MyLimitsList
                        recordType={recordType}
                        isLoading={false}
                        queryData={limitsListData ?? []}
                        accountType={accountType}
                    />

                    <DrawerFormContainer
                        openDrawer={openDrawer}
                        limitsListData={limitsListData}
                        limitsListRefetch={limitsListRefetch}
                        setOpenDrawer={setOpenDrawer}
                        accountType={accountType}
                    />
                </React.Fragment>
            </BankAccountLimitSkeleton>
        </ErrorBoundary>
    );
}
