import { Fragment, lazy, Suspense } from 'react';
import { useGetListPayrollAgreement } from 'contexts/PayrollAgreementContext';
import { ToastType } from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { payrollAgreementDefaultValues, payrollAgreementSchema } from './PayrollAgreementSchema';
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer';
import { FieldValues } from 'react-hook-form';
import { PayrollAgreementReadModel } from 'services/creditNote';
import { BaseContainerStepForm } from '../Generics';

const PayrollAgreement = lazy(() => import('./PayrollAgreement'));

type PayrollAgreementContainerProps = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
} & PayrollCreditNoteSharedProps;

export const PayrollAgreementContainer = (props: PayrollAgreementContainerProps) => {
    const { onNextStep, setFormValues, formValues} = props;
    const { payrollAgreementAutocompleteProps, data: payrollAgreementData } = useGetListPayrollAgreement();

    const onSubmit = (values: FieldValues) => {
        const agreementData = {
            payrollAgreementCode: values.payrollAgreementCode,
            payrollAgreement: values.payrollAgreement,
        };
        setFormValues((prev) => ({ ...prev, ...agreementData }));
        onNextStep();
    }

    return (
        <Suspense fallback={<Fragment />}>
            <FormProvider {...{
                defaultValues: payrollAgreementDefaultValues(formValues?.payrollAgreement as PayrollAgreementReadModel),
                validationSchema: payrollAgreementSchema(),
                onSubmit
            }}>
                <BaseContainerStepForm {...props}>
                    <PayrollAgreement
                        payrollAgreementData={payrollAgreementData?.data ?? []}
                        payrollAutocomplete={payrollAgreementAutocompleteProps}
                    />
                </BaseContainerStepForm>
            </FormProvider>
        </Suspense>
    )
}
