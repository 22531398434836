import { EwarrantyOption } from 'services/creditNote/enum';
import { WarrantyType } from './generics';
import { currentDateWithSetHours, toIsoStringWithTimezone } from 'helpers';

export class PublicPayrollCreateModel {
    warrantyType: WarrantyType = 'PublicPayroll';
    employeeCode?: string; // Código da Matrícula
    agreementCode?: string; // Código do Convênio
    employeerInstCode?: string; // Órgão + Matrícula do Instituidor
    payrollAgreementId?: string;
    governmentDepartmentCode?: string; // Código do órgão (obrigatório na SIAPE e ZETRA)
    payrollAgreementName?: string; // Nome do convênio
    warrantyCode?: string; // Código de averbação
    paymentTransferDay!: number; // Dia do repasse 
    paymentAmountInCents!: number; // Valor da Margem
    employmentStatusCode?: string; // Código da situação funcional do vínculo
    employmentStatusDescription?: string; // Descrição da situação funcional do vínculo
    previousAdeNumber: string | null = null; // Número do ADE anterior
    warrantyDeadline?: string = toIsoStringWithTimezone(new Date(currentDateWithSetHours)); // Data limite de averbação
    warrantyOption: EwarrantyOption = EwarrantyOption.FreeMargin; // Opções da garantia
    simulationRegistrationNumber?: string;
    termInMonths?: number;
    governmentDepartmentWarrantyStatusDisplay?: string;

    constructor(obj?: Partial<PublicPayrollCreateModel>) {
        Object.assign(this, obj);
    }
}

export function warrantyDescription(warrantyOption: string): string {
    if (warrantyOption === 'FreeMargin') {
        return 'Margem Livre';
    }
    if (warrantyOption === 'Portability') {
        return 'Portabilidade';
    }

    return 'Nenhum';
}
