import { Typography } from '@mui/material'
import React from 'react'

type MainTitleProps = {
    title: string
}

export default function MainTitle({ title }: MainTitleProps) {
    return (
        <Typography
            variant="h5"
            color="neutral.dark"
            fontStyle="normal"
            fontWeight="700"
            fontSize="23px"
            lineHeight="38.4px"
        >
            {title}
        </Typography>
    )
}
