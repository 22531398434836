import { Stack } from '@mui/material'
import { Typography } from '@uy3/web-components';
import { CurrencyValueType, formatCurrencyInCents } from 'helpers';
import React from 'react'

type TableWithTotalValueProps = {
    children: React.ReactNode;
    enableTotalValue: boolean;
    totalValue: CurrencyValueType;
    qtdRowsSelected: number
}

export const TableWithTotalValue: React.FC<TableWithTotalValueProps> = ({
    children,
    enableTotalValue,
    totalValue,
    qtdRowsSelected
}) => {
    return (
        <Stack position='relative'>
            {children}

            {enableTotalValue && (
                <Typography
                    variant="sm"
                    color="#6F6F6F"
                    style={{
                        position: 'absolute',
                        bottom: 14,
                        left: qtdRowsSelected >= 10 ? 230 : 220
                    }}
                >
                    Valor total: <strong>{formatCurrencyInCents(totalValue)}</strong>
                </Typography>
            )}
        </Stack>
    )
}