import { Stack } from '@mui/material';
import { useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import { ISiapeMarginQueryReadModel, ResultMarginQuery } from 'services/datasets';
import {
    ApiResponseError,
    handleOnError,
    showInfoToast,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { useSiapeMarginMutation, useSiapeMarginQuery } from 'contexts/datasetContext';
import { Alert } from '@uy3/web-components';
import { useUpdateZetraMarginQuery, useZetraMarginQuery } from 'contexts/zetra';
import { UpdateZetra } from '../Drawer';
import { ListAvailableMargins } from './ListAvailableMargins';

type MarginQueryProps = {
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    isZetra: boolean;
    registrationNumber: string;
    employeeCode: string
};

export const MarginQueryToNewSimulation: React.FC<MarginQueryProps> = ({
    onClose,
    setToast,
    isZetra,
    employeeCode,
    registrationNumber
}) => {
    const [isUpdateZetra, setIsUpdateZetra] = useState(false);
    const { getValues } = useFormContext();

    const personId = getValues('personId');

    const { data: siapeMarginQuery, refetch } = useSiapeMarginQuery({ registrationNumber: isZetra ? undefined : registrationNumber });

    const { zetraMarginQueryData, zetraMarginQueryRefecth } = useZetraMarginQuery(
        { registrationNumber, employeeCode },
        isZetra,
        setToast
    );

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);
    const successMessage = 'Consulta de margem realizada com sucesso!';
    const onSuccessSiape = (props: ISiapeMarginQueryReadModel[]) => {
        refetch();
        const response = props[0] as ISiapeMarginQueryReadModel;
        if (resultIncludeWarnningMessage(response?.result!)) return;
        showSuccessToast(successMessage, undefined, setToast);
    };

    const resultIncludeWarnningMessage = (result: ResultMarginQuery): boolean => {
        if (result.Nome === null || !result.DsRetCode.includes('realizado com sucesso')) {
            showInfoToast(
                result.DsRetCode,
                `Código de retorno: ${result.CdRetCode} - data consulta: ${result.DtOperacao}`,
                setToast
            );
            return true;
        }
        return false;
    };

    const { mutateAsync: siapeMarginMutation, isLoading: isLoadingUpdateSiape } = useSiapeMarginMutation(
        onSuccessSiape,
        onError
    );

    const handleSubmit = () => {
        isZetra ? zetraMarginQueryRefecth() : refetch();
        onClose();
    };

    const marginQueryData = isZetra ? zetraMarginQueryData?.[0] : siapeMarginQuery?.[0];

    const openDrawerZetra = () => setIsUpdateZetra((prev) => !prev);

    const onSuccessZetra = () => {
        if (!personId) openDrawerZetra();
        zetraMarginQueryRefecth();
        showSuccessToast(
            'Margem atualizada com sucesso!',
            'Ótimo! Agora você pode selecionar umas das opções de margem disponíveis.',
            setToast
        );
    };

    const { mutateUpdateZetra, isLoading: isLoadingUpdateZetra } = useUpdateZetraMarginQuery(onSuccessZetra, onError);

    return (
        <Stack spacing={2}>
            {!marginQueryData && (
                <Alert
                    text="Não há dados de margem disponíveis para esse CPF. Clique no botão 'Obter margem' para fazer a primeira consulta"
                    severity="info"
                />
            )}
            <UpdateZetra
                {...{
                    isUpdateZetra,
                    onCloseDrawer: openDrawerZetra,
                    mutateUpdateZetra,
                    registrationNumber,
                    setToast,
                    creditProductId: undefined,
                    employeeCode
                }}
            />
            <ListAvailableMargins
                setToast={setToast}
                marginQueryData={marginQueryData}
                onCloseAll={onClose}
                onConfirm={handleSubmit}
                handleMarginQuery={async () => {
                    if (isZetra && !personId) {
                        openDrawerZetra();
                    } else if (isZetra && personId) {
                        await mutateUpdateZetra({
                            personId
                        });
                    } else if (!isZetra) {
                        await siapeMarginMutation({ registrationNumber });
                    }
                }}
                isZetra={isZetra}
                registrationNumber={registrationNumber}
                isLoadingUpdateMargin={isLoadingUpdateSiape || isLoadingUpdateZetra}
            />
        </Stack>
    );
};
