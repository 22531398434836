/* eslint-disable no-mixed-operators */
import { useFormContext } from 'contexts/formContext';
import { Button, Typography, Popup, Alert, Timeline } from '@uy3/web-components';
import moment from 'moment';
import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from 'react-router';
import { useIdentity } from 'contexts/identityContext';
import * as creditNoteAPI from 'services/creditNote';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import CreditNoteButtons from './CreditNoteButtons';
import { TimelineType } from 'contexts/apiRequestContext';
import { ComplianceChecklistMessage } from 'components/ComplianceChecklistMessage/ComplianceChecklistMessage';
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext';
import { useCreditData } from 'contexts/creditNote/creditContext';
interface IHeaderAndButtonProps {
    newOp: boolean;
    statusDisplay?: string;
    timeline: TimelineType[];
}

const CreditNoteFormHeader: React.FC<IHeaderAndButtonProps> = ({
    newOp,
    timeline,
    statusDisplay
}) => {
    const { submitting, watch, isDirty } = useFormContext();
    const { data: complianceChecklistData } = useComplianceChecklistVerifyPersonId(watch('personId')!);
    const showComplianceChecklist = !newOp && !!complianceChecklistData && complianceChecklistData?.length > 0;
    const { id } = useParams();
    const { token } = useIdentity();
    const navigate = useNavigate();
    const [error, setError] = useState({} as any);
    const [openPopup, setOpenPopup] = useState(false);
    const { creditData } = useCreditData(id!);
    const onClose = () => setOpenPopup(false);
    const createdBy = watch('createdBy.userIdDisplay');
    const createdAt = `Criado em ${moment(watch('createdAt')).format('DD/MM/YYYY')} ${createdBy ? ` por ${createdBy}` : ''}`;

    const steps: { [status: string]: string } = {
        "Coleta de Assinaturas": "Assinatura",
        "Validação de Assinaturas": "Assinatura",
        "Revisão de Pagamento": "Revisão",
        "Reserva de Margem": "Aprovação",
    };

    // Using "pago" temporarily
    const statusTimelineDisplay = statusDisplay === 'Liquidação' ? "Pago" : statusDisplay;

    return (
        <>
            <ApiErrorAlert error={error && error?.response?.data} />

            {openPopup && (
                <Popup
                    title={`Excluir operação #${watch('creditNoteNo')}`}
                    text="Deseja realmente excluir esta operação?"
                    open={true}
                    onClose={onClose}
                    children={
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            mt={2}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onClose}
                                size="medium"
                                sx={{ mr: 2 }}
                                disabled={submitting}
                            >
                                Não, cancelar
                            </Button>
                            <Button
                                disabled={submitting}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    return creditNoteAPI
                                        .deleteCreditNoteById(id!, token!)
                                        .then(() => {
                                            onClose();
                                            navigate('/ccb/operacoes');
                                        })
                                        .catch((Err) => {
                                            setError(Err);
                                        });
                                }}
                                size="medium"
                            >
                                Sim, confirmar
                            </Button>
                        </Grid>
                    }
                />
            )}

            <Grid container spacing={2} rowSpacing={0.7} mr={2}>
                <Grid xs={4}>
                    <Typography
                        variant="h4"
                        color="neutral.medium"
                        sx={{
                            fontWeight: '700',
                            fontSize: '32px',
                            lineHeight: '38px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {newOp ? 'Nova operação' : `Operação #${watch('creditNoteNo') ?? ""}`}
                    </Typography>
                </Grid>

                <Grid
                    xs={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <CreditNoteButtons />
                </Grid>

                {!newOp && (
                    <Grid xs={6} mt={-2} lineHeight='2rem'>
                        <Typography color="neutral.medium" variant="xs">
                            {!newOp ? `Status: ${creditData['statusDisplay'] ?? watch('statusDisplay')}` : ''}
                        </Typography>
                        <br />
                        <Typography color="neutral.medium" variant="xs">
                            {createdAt}
                        </Typography>
                    </Grid>
                )}

                {isDirty && (
                    <Grid xs={12}>
                        <Alert severity="warning" text="Existem informações não salvas" />
                    </Grid>
                )}
            </Grid>

            {!newOp &&
                <Grid mt={4} ml={-2} mr={3}>
                    <Timeline
                        status={steps[statusDisplay!] ?? statusDisplay!}
                        statusDisplay={statusTimelineDisplay!}
                        timeline={timeline}
                        timelineType='creditNote'
                    />
                </Grid>}

            {showComplianceChecklist &&
                <Grid spacing={2} mt={2} mr={3}>
                    <ComplianceChecklistMessage
                        complianceChecklistData={complianceChecklistData}
                        name={watch('personDisplay')}
                    />
                </Grid>
            }

        </>
    );
};

export default CreditNoteFormHeader;
