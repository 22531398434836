import { InputAdornment, Stack } from '@mui/material';
import { Button, CancelIcon, RadioOptions, SaveIcon, SearchIcon, TextField, Typography } from '@uy3/web-components';
import { Radio } from 'components/Forms/Radio/Radio';
import { RefreshProgress } from 'components/RefreshProgress';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import React from 'react'
import { activeTheme } from 'services/theme';

type SearchNaturalPersonProps = {
    onClose: () => void;
    options: RadioOptions[]
    handleSave: (registrationNumber: string) => void;
    isLoading: boolean
    setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
    search: string | undefined
}

const theme = activeTheme();

export const SearchNaturalPerson = ({ onClose, handleSave, options, setSearch, isLoading, search }: SearchNaturalPersonProps) => {
    const { setValue, watch } = useFormContext();

    return (
        <Stack spacing={2}>

            <Typography fontSize='18px' fontWeight={500}>
                Pessoas
            </Typography>

            <TextField
                label='Procurar'
                variant='outlined'
                fullWidth
                value={search}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon
                                sx={{ width: 25, height: 25 }}
                                htmlColor="#666666"
                            />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearch(e.target.value)}
            />

            {!isLoading ?
                <Stack width='100%'>
                    <Radio
                        onChange={(event) => {
                            const value = event?.target?.value;
                            setValue('personId', value);
                        }}
                        modernStyle={true}
                        options={options}
                        value={watch('personId')}
                    />
                </Stack> : <RefreshProgress refreshing={isLoading} />}

            {options?.length === 0 && !isLoading&& (
                <Typography fontSize='18px'>
                    Nenhuma pessoa encontrado
                </Typography>
            )}

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button variant='outlined' onClick={onClose} startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}>
                    Não, cancelar
                </Button>
                <Button type='submit' variant='contained' onClick={() => handleSave(watch('personId'))} startIcon={<SaveIcon sx={iconSx} htmlColor={theme.palette.common.white} />}>
                    Salvar
                </Button>
            </Stack>
        </Stack>
    )
}
