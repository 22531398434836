import { Grid, Stack } from '@mui/material';
import ProgressTab from 'components/ProgressTab/ProgressTab';
import { BaasFormContainer, BiliingFormContainer, CaasFormContainer } from './Forms';
import { PartnerFormEnum } from './PartnerFormContainer';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useFormContext } from 'contexts/formContext';

export type PartnerFormSharedType = {
    handleBackStep: () => void;
    handleNextStep: () => void;
    step: PartnerFormEnum;
};

type PartnerFormProps = {
    signalIndex: PartnerFormEnum;
    setSignalIndex: React.Dispatch<React.SetStateAction<PartnerFormEnum>>;
    legalPersonAutocomplete: AutocompleteOptionList;
}

export const PartnerForm = ({ setSignalIndex, signalIndex, legalPersonAutocomplete }: PartnerFormProps) => {
    const { watch } = useFormContext();

    const handleBackStep = () => setSignalIndex((prev) => prev - 1);
    const handleNextStep = () => setSignalIndex((prev) => prev + 1);

    const formTypesShared: PartnerFormSharedType = {
        handleBackStep,
        handleNextStep,
        step: signalIndex
    };

    function funValidationSome(c: any) {
        return c !== null && c!== undefined && c!== 0 && c !== new Date().toISOString();
    }

    const isCompletedTenantRequestBaas: boolean = Object.values(watch('tenantRequestBaas'))?.some(funValidationSome)
    const isCompletedTenantRequestCaas: boolean = Object.values(watch('tenantRequestCaas'))?.some(funValidationSome)
    const isCompletedTenantRequestBilling: boolean = Object.values(watch('tenantRequestBilling'))?.some(funValidationSome)

    return (
        <Stack spacing={4} mt={2} mb={8} mr={2}>
            <Grid width={500}>
                <AutocompleteField
                    label="Busque pelo nome do novo parceiro"
                    name="legalPersonId"
                    displayName='legalPersonIdDisplay'
                    {...legalPersonAutocomplete}
                />
            </Grid>

            <ProgressTab
                signalIndex={signalIndex}
                setSignalIndex={setSignalIndex}
                steps={[
                    { label: 'Bass', children: <BaasFormContainer {...formTypesShared} />, isComplated: isCompletedTenantRequestBaas},
                    { label: 'Caas', children: <CaasFormContainer {...formTypesShared} />, isComplated: isCompletedTenantRequestCaas},
                    { label: 'Billing', children: <BiliingFormContainer {...formTypesShared} />, isComplated: isCompletedTenantRequestBilling},
                ]}
            />
            
        </Stack>
    );
};
