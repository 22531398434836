import { useState } from 'react';
import { Stack } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';

import { Button, CardData, CloseIcon, Drawer } from '@uy3/web-components';

import { IWarrantyReadModel, mapCardDataFromWarranty } from 'services/creditNote';
import { activeTheme } from 'services/theme';
import { isZetraProduct } from 'services/zetra';

import { useFormContext } from 'contexts/formContext';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct';

import { WarrantyListTab } from './WarrantyTab';
import WarrantyFormContainer from './WarrantyForm/WarrantFormContainer';
import DoneRevision from '../../../contexts/creditNote/DoneRevision/DoneRevisionForm';

const theme = activeTheme();
export function WarrantyTabContainer() {
    const { setValue, watch } = useFormContext();
    const { product } = useCreditNoteFormContext();

    const [selectedWarranty, setSelectedWarranty] = useState<number | undefined>();
    const [doneRevisionDrawer, setDoneRevisionDrawer] = useState<number | undefined>();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState<GridRowModel<IWarrantyReadModel> | undefined>();

    const warrantyData = watch('warranty') ?? [];

    const deleteAsset = function (index: number) {
        let rowData = [...warrantyData];
        rowData?.splice(index, 1);
        setValue('warranty', rowData);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const handleOnEdit = (rowIndex: number) => setSelectedWarranty(rowIndex);
    const onCloseDrawer = () => {
        setSelectedWarranty(undefined);
        setSelectedRow(undefined);
    };

    const rowDataFn = (rowData: GridRowModel<IWarrantyReadModel>) => setSelectedRow(rowData);

    const listItem = mapCardDataFromWarranty(selectedRow, isZetraProduct(product));

    return (
        <>
            <GenericListHeader
                enableAction
                onClick={() => setSelectedWarranty(warrantyData?.length)}
                title="Garantias"
                titleButton="Adicionar garantia"
            />

            <WarrantyListTab
                name="warranty"
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                deleteAsset={deleteAsset}
                handleOnEdit={handleOnEdit}
                onDetails={rowDataFn}
            />

            <WarrantyFormContainer
                {...{
                    selectedWarranty,
                    onCloseDrawer,
                }}
            />

            <Drawer
                title="Detalhes"
                anchor="right"
                open={selectedRow !== undefined}
                onClose={onCloseDrawer}
            >
                <CardData listItem={listItem} />

                <Stack spacing={2} justifyContent="right" direction="row">
                    <Button
                        variant="outlined"
                        onClick={onCloseDrawer}
                        startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    >
                        Fechar
                    </Button>
                </Stack>
            </Drawer>
        </>
    );
}
