import { PayrollAgreementReadModel } from "services/creditNote";
import { object, string } from "yup";

export const payrollAgreementSchema = () => {
    return object().shape({
        payrollAgreementCode: string().required('Convênio precisa ser preenchido').typeError('Convênio precisa ser preenchido'),
    });
};

export const payrollAgreementDefaultValues = (data?: PayrollAgreementReadModel | null) => {
    return {
        payrollAgreementCode: data?.id ?? null, 
        payrollAgreementCodeDisplay: data?.name ?? null
    }
}