import { FormProvider } from 'contexts/formContext';
import React, { lazy, Suspense } from 'react'
import { editProposalDefaultValues, editProposalSchema } from './EditProposalSchema';
import { FieldValues } from 'react-hook-form';
import { isCodeError } from '../../Steps/Simulate/SimulateContainer';
import { ApiResponseError, showSuccessToast } from 'contexts/apiRequestContext';
import { } from 'services/creditProduct';
import { handleOnErrorAmortizationSimulation } from 'helpers/Exceptions/Exceptions';
import { useAmortizationSimulationMutate } from 'contexts/creditNote/creditContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer';

const EditProposal = lazy(() => import("./EditProposal"));

type EditProposalContainerProps = {
    onClose: () => void;
} & PayrollCreditNoteSharedProps

export const EditProposalContainer = ({ onClose, formValues, setToast, setFormValues }: EditProposalContainerProps) => {
    const { setFormFieldsErrors } = useFormFieldsError();
    const simulationData = formValues?.simulationSelectedToEdit;
    const modelSimulation = formValues?.modelSimulation;

    const onSuccess = (response: any) => {
        const allItemsHasError = (response as any[])?.every(x => isCodeError(x));
        if (allItemsHasError) {
            return response?.forEach((data: ApiResponseError) => onErrorSimulation(data));
        }

        const responseFiltered = response?.filter((res: any, index: number) => !isCodeError(res));
        setFormValues((prev) => ({
            ...prev,
            simulationResponse: responseFiltered
        }));

        setFormFieldsErrors([]);
        showSuccessToast('Sucesso ao realizar a simulação', '', setToast);
        onClose();
    };

    const onErrorSimulation = (error: ApiResponseError) => handleOnErrorAmortizationSimulation(error, setFormFieldsErrors, setToast);
    const { mutateAsync } = useAmortizationSimulationMutate(onSuccess, onErrorSimulation);

    const onSubmit = (values: FieldValues) => {
        //@ts-ignore
        const models: QueryAmortizationCreateModel[] = modelSimulation.map((simulation, index) => {
            if (simulationData?.productId === simulation?.productId) {
                return {
                    ...simulation,
                    amortization: {
                        ...simulation?.amortization,
                        requestedAmount: values?.installmentValue,
                        numberOfPayments: values?.numberOfPayments
                    }
                };
            }
            return simulation;
        });

        setFormValues((prev) => ({
            ...prev,
            modelSimulation: models
        }));

        mutateAsync(models);
    };

    return (
        <Suspense fallback={<></>}>
            <FormProvider {...{
                defaultValues: editProposalDefaultValues(simulationData),
                validationSchema: editProposalSchema(simulationData),
                onSubmit
            }}>
                <EditProposal onClose={onClose} simulationData={simulationData} />
            </FormProvider>
        </Suspense>
    )
}
