import { genericMessageRequired } from 'helpers';
import { date } from 'yup';
import { string } from 'yup';
import { object } from 'yup';

export const publicPayrollSchema = object().shape({
    warrantyOption: string()
        .required('Selecione a opção de garantia.')
        .oneOf(['None', 'FreeMargin', 'Portability'], 'Selecione a opção de garantia')
        .typeError('Opção de garantia é inválida.'),
    warrantyDeadline: date().nullable(),
    previousAdeNumber: string().when('warrantyOption', {
        is: (value: string) => value === 'Portability',
        then: string()
            .typeError('Número do ADE anterior é inválido.')
            .required('Número do ADE anterior: ' + genericMessageRequired),
        otherwise: string().nullable(),
    }),
});
