import { Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { SelectOption } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type AddNewPayrollAgreementsFormProps = {
    onClose: () => void
    governmentDepartmentAutocomplete: AutocompleteOptionList;
}

export const AddNewPayrollAgreementsForm = ({ onClose, governmentDepartmentAutocomplete }: AddNewPayrollAgreementsFormProps) => {
    const [payrollAgreementList, setPayrollAgreementList] = useState<SelectOption[]>([]);
    const { setValue } = useFormContext(); 
    const filedName: string = 'governmentDepartmentIds'; 

    const handlerSignerChange = (_: any, option: any) => {
        if (!isEmpty(option)) {
            setPayrollAgreementList(option);
            let arraysOfIds = option?.map((option: SelectOption) => option?.value) as string[];
            setValue(filedName, arraysOfIds);         
        } else {
            setPayrollAgreementList([]);
            setValue(filedName, []);
        }
    };

    return (
        <Stack spacing={2}>
            <AutocompleteField
                name={filedName}
                multiple
                label="Orgãos"
                value={payrollAgreementList}
                onChange={handlerSignerChange}
                {...governmentDepartmentAutocomplete}
            />

            <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    type="submit"
                >
                    Sim, confirmar
                </Button>
            </Stack>

        </Stack>
    )
}
