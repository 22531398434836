import { WalletForm } from './WalletForm';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { useState } from 'react';
import AssignDrawer from 'components/Assign/AssignDrawer';
import React from 'react';
import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { RefreshProgress } from 'components/RefreshProgress';
import {
    useExportWalletPositionAsyncMutation,
    useUpdateWalltByIdMutation,
    useUpdateBankslipCostsMudataion,
    useWalletById,
    useUpdateNotificationRecipientMutation,
    useManualDebtFromWalletMutation,
} from 'contexts/wallet/walletContext/walletContext';
import { Drawer, Modal } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import { UpdateBankSlipCostForm } from './Drawers/UpdateBankSlipCostForm/UpdateBankSlipCostForm';
import { defaultValuesWalletForm, validationWalletFormSchema } from './WalletFormSchema';
import { defaultValuesUpdateWalletForm, validationUpdateWalletFormSchema } from './Drawers/UpdateWalletForm/UpdateWalletFormSchema';
import { UpdateWalletForm } from './Drawers/UpdateWalletForm/UpdateWalletForm';
import { UpdateEmailsFormContainer } from '../../../../components/Forms/UpdateEmailsForm/UpdateEmailsFormContainer';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';

export const WalletFormContainer = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const onClose = () => setAction(undefined);

    const onSuccess = (_: any, type: string) => {
        const optionsMessage: { [type: string]: string } = {
            "exportWalletPosition": "Sucesso ao exportar relatorio de posição carteira",
            "updateBankSlipeCosts": "Custos da carteira atualizado com sucesso",
            "updateWallet": "Carteira atualizada com sucesso",
            "updateEmails": `E-mails da carteira ${walletData?.walletCode} atualizados com sucesso.`,
            "manualDebtFromWallet": `Sucesso ao executar processo de cobrança das tarifas pendentes para a carteira (#${walletData?.walletCode})`
        }

        const title = optionsMessage[type];
        const description = undefined;
        showSuccessToast(title, description, setToast);
        if (['updateWallet'].includes(type)) refetch();
        else refreshFull();
        onClose();
    };

    const { handleErrorException } = useApiRequest();

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)
    const { data: walletData, isLoading, refetch } = useWalletById(id!);
    const {
        mutateAsync: exportWalletPositionAsync,
        isLoading: isLoadingExportWallet
    } = useExportWalletPositionAsyncMutation(walletData?.walletCode!, (data) => onSuccess(data, "exportWalletPosition"), onError);
    const {
        mutateAsync: updateBankSlipCostAsync,
        isLoading: isLoadingUpdateBankslip
    } = useUpdateBankslipCostsMudataion(id!, (data) => onSuccess(data, "updateBankSlipeCosts"), onError);
    const {
        mutateAsync: updateWalletAsync,
        isLoading: isLoadingUpdateWallet
    } = useUpdateWalltByIdMutation(id!, (data) => onSuccess(data, "updateWallet"), onError);
    const {
        mutateAsync: updateEmailsWalletMutate,
        isLoading: isLoadingUpdateEmails
    } = useUpdateNotificationRecipientMutation(id!, (data) => onSuccess(data, "updateEmails"), onError);
    const {
        mutateAsync: manualDebtFromWalletMutate,
        isLoading: isLoadingManualDebtFromWallet
    } = useManualDebtFromWalletMutation((data) => onSuccess(data, "manualDebtFromWallet"), onError);

    const refreshFull = async () => {
        await queryClient.refetchQueries(['get-wallet-by-id']);
        await queryClient.removeQueries(['get-wallet-by-id', id]);
    };

    const defaultValuesCustom = {
        ...walletData?.bankSlipCosts
    }

    const defaultValuesUpdateWallet = {
        billingTypeValue: walletData?.billingTypeValue,
        enableAutoSubmitToDebtCollection: walletData?.enableAutoSubmitToDebtCollection,
    }

    return (
        <GenericFormSkeleton {...{ isLoading }}>
            <React.Fragment>
                <RefreshProgress refreshing={isLoadingExportWallet || isLoadingUpdateBankslip} />
                <Toast toast={toast} setToast={setToast} />

                <WalletForm
                    refetch={refreshFull}
                    walletData={walletData!}
                    handleAction={setAction}
                    handleExportWalletPosition={exportWalletPositionAsync}
                />

                <AssignDrawer
                    recordId={id!}
                    recordType="Wallet"
                    openDrawer={action === 'assign'}
                    onClose={onClose}
                    navigate="/gestao-carteira/carteiras"
                />

                <Drawer
                    {...{
                        title: 'Atualizar custos da carteira',
                        open: action === 'updateBankSlipCost',
                        anchor: 'right',
                        onClose,
                    }}
                >
                    <FormProvider
                        defaultValues={defaultValuesCustom ?? defaultValuesWalletForm}
                        validationSchema={validationWalletFormSchema()}
                        onSubmit={(values) => updateBankSlipCostAsync(values)}
                    >
                        <UpdateBankSlipCostForm
                            {...{
                                onClose,
                                isLoading: isLoadingUpdateBankslip,
                            }}
                        />
                    </FormProvider>
                </Drawer>
                <Drawer
                    {...{
                        title: `Atualizar carteira ${walletData?.walletCode}`,
                        open: action === 'updateWalletCode',
                        anchor: 'right',
                        onClose
                    }}
                >
                    <FormProvider
                        defaultValues={defaultValuesUpdateWallet ?? defaultValuesUpdateWalletForm}
                        validationSchema={validationUpdateWalletFormSchema()}
                        onSubmit={(values) => updateWalletAsync(values)}
                    >
                        <UpdateWalletForm {...{
                            isLoading: isLoadingUpdateWallet,
                            onClose
                        }} />
                    </FormProvider>
                </Drawer>

                <Drawer
                    {...{
                        title: `Atualizar e-mails`,
                        open: action === 'updateEmails',
                        anchor: 'right',
                        onClose
                    }}
                >
                    <UpdateEmailsFormContainer {...{
                        recepientsList: (walletData?.notificationRecepients ?? []) as string[], 
                        onClose, 
                        isLoading: isLoadingUpdateEmails, 
                        updateEmailsWalletMutate: updateEmailsWalletMutate
                    }} />
                </Drawer>

                <Modal
                    {...{
                        title: `Cobrar tarifa pendente`,
                        description: "Têm certeza que deseja cobrar as tarifas pendentes desta certeira?",
                        open: action === 'manualDebtFromWallet',
                        onClose,

                    }}
                >
                    <GenericActionHandler
                        isModal
                        handleSubmit={() => manualDebtFromWalletMutate(id!)}
                        onClose={onClose}
                        isLoading={isLoadingManualDebtFromWallet}
                    />
                </Modal>
            </React.Fragment>
        </GenericFormSkeleton>
    );
};
