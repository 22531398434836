import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";
import { activeTheme } from "services/theme";

const theme = activeTheme();

export const CustomAccordion = styled(Accordion)(({ expanded }) => ({
    border: expanded ? `1.5px solid ${theme.palette.primary.main}` : '1px solid #5c5c5c',
    boxShadow: 'none',
    borderRadius: '8px',
}));

export const CustomAccordionSummary = styled(AccordionSummary)({
    display: 'flex',
    position: 'relative',
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        flexDirection: 'row', 
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
});

export const CustomAccordionDetails = styled(AccordionDetails)({
    borderTop: '1px solid #ddd',
    padding: '20px',
});
