import { Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { BankSlipIcon, Button, DataTable, Typography } from '@uy3/web-components';
import { StatementFiltersContainer } from 'components/DataTableFilters/StatementFilters/StatementFiltersContainer';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { DateFilterType } from './AccountStatementTabContainer';
import { StatementQueryDataType } from 'services/accounts/bankAccount';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { ToastType } from 'contexts/apiRequestContext';
import { hasCustomPermission } from 'helpers/methods/GenericMethods';
import { useUserPermissionData } from 'contexts/userContext';
import { useAppConfig } from 'contexts/appConfig';
import { isValidString } from 'helpers';

type AccountStatementTabListProps = {
    queryData: StatementQueryDataType[];
    setPeriodSelected: React.Dispatch<React.SetStateAction<string>>;
    setDateFilter: React.Dispatch<React.SetStateAction<DateFilterType>>;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (page: number) => void;
    page: number;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    setRowData: (rowData: any) => void;
    operatorHasPerm: boolean;
};

const AccountStatementTabList = ({
    queryData,
    setDateFilter,
    setPeriodSelected,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    page,
    setToast,
    setRowData,
    operatorHasPerm
}: AccountStatementTabListProps) => {
    const { featureFlagLydiansContaPag } = useAppConfig();
    const { hasPermission } = useUserPermissionData();
    const hasBankAccountPerm = hasCustomPermission('BankAccount', 'Update', hasPermission);

    const checkInformationBedoreShowing = (value: string): string => {
        if (isValidString(value)) return value; 
        return "N/D"
    }

    var columnsAccountStatementTabList: GridColDef[] = [];
    const isLydians = featureFlagLydiansContaPag() === 'Lydians';
    //TODO: FeatureFlag by Lydians
    if (isLydians){
        columnsAccountStatementTabList = [
            {
                field: 'issueDate',
                headerName: 'Data da ocorrência',
                hideSortIcons: true,
                minWidth: 180,
                renderCell: ({ value }) => {
                    const formattedDate = new Date(value).toLocaleString('pt-BR');
                    return value && formattedDate;
                },
            },
            {
                field: 'description',
                headerName: 'Descrição',
                hideSortIcons: true,
                flex: 1,
                renderCell: (cellValues) => {
                    return checkInformationBedoreShowing(cellValues.row.description); 
                },
            },
            {
                field: 'information',
                headerName: 'Complemento',
                hideSortIcons: true,
                flex: 2,
                renderCell: (cellValues) => {
                    return checkInformationBedoreShowing(cellValues.row.information); 
                },
            },
            {
                field: 'counterpart',
                headerName: 'Contraparte',
                hideSortIcons: true,
                flex: 2,
                renderCell: (cellValues) => {
                    return checkInformationBedoreShowing(cellValues.row.counterpart); 
                },
            },
            {
                field: 'amount',
                headerName: 'Valor',
                hideSortIcons: true,
                flex: 1,
                renderCell: (cellValues) => {
                    return (
                        cellValues.row.movement === 'DEBIT' ? (
                            <Typography
                                variant="h6"
                                fontWeight="400"
                                fontFamily='"Lato", sans-serif'
                                color="#F50057"
                            >
                                - {formatCurrencyInCents(cellValues.row.amount)}
                            </Typography>
                        ) : (
                            <Typography
                                variant="h6"
                                fontWeight="400"
                                fontFamily='"Lato", sans-serif'
                                color="#00CD2D"
                            >
                                + {formatCurrencyInCents(cellValues.row.amount)}
                            </Typography>
                        )
                    );
                },
            },
            {
                field: 'balances',
                headerName: 'Ações',
                hideSortIcons: true,
                minWidth: 100,
                editable: false,
                renderCell: (cellValues) => {
                    const isPixReceived =
                        cellValues.row.movement === 'CREDITO' &&
                        cellValues.row.description === 'Pix Recebido';
    
                    const showBtnRefund = operatorHasPerm && isPixReceived && hasBankAccountPerm;
                    return (
                        <>
                            {showBtnRefund && (
                                <Stack
                                    component={Grid}
                                    direction={'row'}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => setRowData(cellValues.row)}
                                        startIcon={
                                            <BankSlipIcon sx={{ height: 20, width: 20, ml: 1 }} />
                                        }
                                    />
                                </Stack>
                            )}
                        </>
                    );
                },
            },
        ];
    }
    else{
        columnsAccountStatementTabList = [
            {
                field: 'issueDate',
                headerName: 'Data da ocorrência',
                hideSortIcons: true,
                flex: 2,
                renderCell: ({ value }) => {
                    const formattedDate = new Date(value).toLocaleDateString('pt-BR');
                    return value && formattedDate;
                },
            },
            {
                field: 'description',
                headerName: 'Lançamento',
                hideSortIcons: true,
                flex: 2,
                renderCell: (cellValues) =>
                    cellValues.row.information
                        ? cellValues.row.description + ' para ' + cellValues.row.information
                        : cellValues.row.description,
            },
            {
                field: 'registrationNumber',
                headerName: 'Descrição',
                hideSortIcons: true,
                flex: 2,
                renderCell: (cellValues) => formatDocumentNumber(cellValues.value),
            },
            {
                field: 'amount',
                headerName: 'Valor',
                hideSortIcons: true,
                flex: 2,
                renderCell: (cellValues) => {
                    return (
                        <>
                            {cellValues.row.movementDisplay === 'DEBITO' ? (
                                <Typography
                                    variant="h6"
                                    fontWeight="400"
                                    fontFamily='"Lato", sans-serif'
                                    color="#F50057"
                                >
                                    - {formatCurrencyInCents(cellValues.row.amount)}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="h6"
                                    fontWeight="400"
                                    fontFamily='"Lato", sans-serif'
                                    color="#00CD2D"
                                >
                                    + {formatCurrencyInCents(cellValues.row.amount)}
                                </Typography>
                            )}
                        </>
                    );
                },
            },
            {
                field: 'balances',
                headerName: 'Ações',
                hideSortIcons: true,
                minWidth: 150,
                editable: false,
                renderCell: (cellValues) => {
                    const isPixReceived =
                        cellValues.row.movement === 'CREDITO' &&
                        cellValues.row.description === 'Pix Recebido';
    
                    const showBtnRefund = operatorHasPerm && isPixReceived && hasBankAccountPerm;
                    return (
                        <>
                            {showBtnRefund && (
                                <Stack
                                    component={Grid}
                                    direction={'row'}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => setRowData(cellValues.row)}
                                        startIcon={
                                            <BankSlipIcon sx={{ height: 20, width: 20, ml: 1 }} />
                                        }
                                    />
                                </Stack>
                            )}
                        </>
                    );
                },
            },
        ];
    }
    

    return (
        <>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: (
                        <Stack
                            component={Grid}
                            justifyContent="right"
                            alignItems="center"
                            direction="row"
                            mb={2}
                        >
                            <StatementFiltersContainer
                                setPeriodSelected={setPeriodSelected}
                                setDateFilter={setDateFilter}
                                setToast={setToast}
                            />
                        </Stack>
                    ),
                }}
                columns={columnsAccountStatementTabList}
                rows={queryData}
                page={page}
                paginationMode={'client'}
                rowCount={queryData?.length}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                enableJumpAction={false}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};

export default AccountStatementTabList;
