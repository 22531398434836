import React from 'react'
import { CreateWalletForm } from './CreateWalletForm'
import { useCreateWalletMutation } from 'contexts/wallet/walletContext/walletContext';
import { handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { createWalletFormSchema, defaultValuesCreateWalletForm } from './CreateWalletFormSchema';
import { useBankAccountListData } from 'contexts/bankAccount/bankAccountContext';
import { useGetBillingProduct, useGetProductsCostsDefinition } from 'contexts/wallet/Product/BillingProductContext';

type CreateWalletFormContainerProps = {
    onClose: () => void;
    refreshList: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const CreateWalletFormContainer = ({ onClose, refreshList, setToast }: CreateWalletFormContainerProps) => {
    const { bankAccountAutocomplete } = useBankAccountListData({ page: 0, size: 5 });
    const { billingProductAutocomplete } = useGetBillingProduct({ page: 0, size: 100 });
    const { productsCostsDefinitionAutocomplete } = useGetProductsCostsDefinition({ page: 0, size: 100 });
    
    const onSuccess = () => {
        onClose();
        refreshList();
        showSuccessToast("Carteira criada com sucesso", "", setToast)
    }; 

    const { mutateAsync } = useCreateWalletMutation(onSuccess, (err) => handleOnError(err, setToast));

    return (
        <FormProvider
            defaultValues={defaultValuesCreateWalletForm}
            validationSchema={createWalletFormSchema()}
            onSubmit={(values) => {
                mutateAsync({
                    bankAccountId: values?.bankAccountId,
                    billingCostsDefinitionCode: values?.billingCostsDefinitionCode,
                    billingProductCode: values?.billingProductCode
                })
            }}
        >
            <CreateWalletForm 
                onClose={onClose} 
                bankAccountAutocomplete={bankAccountAutocomplete}
                billingProductCodeAutocomplete={billingProductAutocomplete}    
                billingCostsDefinitionCodeAutocomplete={productsCostsDefinitionAutocomplete}
            />
        </FormProvider>
    )
}
