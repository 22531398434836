import Timeline from "@mui/lab/Timeline";
import { Stack } from "@mui/material";
import { activeTheme } from "services/theme";

type PayrollCreditNoteTimelineProps = {
    totalSteps: number;
    currentStep: number;
};

const theme = activeTheme();

export default function PayrollCreditNoteTimeline({ totalSteps, currentStep }: PayrollCreditNoteTimelineProps) {
    return (
        <Timeline
            sx={{
                display: "flex",
                flexDirection: "row",
                padding: 0,
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
        >
            {[...Array(totalSteps)].map((_, index) => {
                const stepNumber = index + 1;
                const isPast = stepNumber < currentStep;
                const isCurrent = stepNumber === currentStep;
                const primaryColor = theme.palette.primary.dark; 
                const colorStep = isPast ? "green" : isCurrent ? primaryColor : "grey";

                return (
                    <Stack
                        key={stepNumber}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexGrow: stepNumber < totalSteps ? 1 : 0 }}
                    >
                        <Stack
                            sx={{
                                color: isPast ? "green" : isCurrent ? primaryColor : "grey",
                                border: "2px solid",
                                borderColor: colorStep, 
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                width: 40,
                                height: 40,
                                fontWeight: "bold",
                                fontSize: "14px",
                                transition: "all 0.5s ease",
                            }}
                        >
                            {stepNumber}
                        </Stack>

                        {stepNumber < totalSteps && (
                            <Stack
                                sx={{
                                    height: "3px",
                                    backgroundColor: colorStep, 
                                    flexGrow: 1,
                                    marginLeft: "30px",
                                    marginRight: "30px",
                                    transition: "background-color 0.5s ease"
                                }}
                            />
                        )}
                    </Stack>
                );
            })}
        </Timeline>
    );
}
