import { Box } from '@mui/material';
import { TextField, TextFieldProps, Typography } from '@uy3/web-components';
import { FunctionComponent, useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { Controller } from 'react-hook-form';
import { validateConditionalRequiredBySchema, getLabelField, isFieldDisable, isFieldRequired } from 'helpers';

const theme = activeTheme();

export interface TextFormFieldProps extends TextFieldProps {
    name: string;
}

export const namesWithDuplicates = ["phone", "code"];

export const TextFormField: FunctionComponent<TextFormFieldProps> = ({
    name,
    label,
    required,
    ...props
}) => {
    var { validationErrors, readOnly, control, getValues } = useFormContext();
    const { appConfig } = useAppConfig();
    const { formFieldsErrors } = useFormFieldsError();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        const hasDuplicateName = namesWithDuplicates.filter(name => fieldName);
        if (hasDuplicateName.length > 0) {
            return fieldName === key;
        }
        return fieldName.includes(key)
    });

    var error = validationErrors && resolve(name, validationErrors)?.message;
    const isConditionalRequired = validateConditionalRequiredBySchema({
        type: validationErrors?.[name]?.type as string
    });

    const isRequired = isConditionalRequired ? isConditionalRequired :
        (!!required || isFieldRequired(name));

    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);
    const labelFieldConfig = getLabelField(name, formFieldsConfig);

    const labelField = labelFieldConfig ?? label;
    const labelWithRequired = isRequired ? `${labelField} *` : labelField;

    const currentValue = getValues(name);
    const startAdornment = props?.InputProps?.startAdornment;
    const endAdornment = props?.InputProps?.endAdornment;
    const hasInputProps = Boolean(startAdornment || endAdornment);
    const isValidValue = currentValue !== null && currentValue !== '' && currentValue !== undefined;
    const shouldShrinkLabel = isValidValue || (hasInputProps && isValidValue) || Boolean(startAdornment);

    const [shrink, setShrink] = useState(shouldShrinkLabel);

    const handleOnFocus = () => setShrink(true);
    const unShrinkLabel = () => setShrink(false);

    return (
        <Box width="100%">
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        {...props}
                        id={name}
                        error={!!error || hasResponseError}
                        label={labelWithRequired}
                        fullWidth
                        disabled={isFieldDisabledConfig || readOnly || props.disabled}
                        variant="outlined"
                        onFocus={handleOnFocus}
                        onBlur={unShrinkLabel}
                        InputLabelProps={{ shrink: props?.InputLabelProps?.shrink || shrink || shouldShrinkLabel }}
                    />
                )}
            />
            {!!error && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{responseError?.helpMessage}</>
                </Typography>
            )}
        </Box>
    );
};
