/* eslint-disable no-unsafe-optional-chaining */
import type { FunctionComponent } from 'react';
import type { CardValueProps } from './CardValue.interface';

import { Box, Stack, Typography } from '@mui/material';
import { Card } from './CardValue.styled';
import { CardIcon } from '../../atoms/CardIcon';

export const CardValue: FunctionComponent<CardValueProps> = ({
  title,
  description,
  icon,
  value,
  color,
  showValue,
  isCurrency,
  enableMediaStyles = false
}) => {
  const cents = value?.slice(-2);
  const totValue = value ? value?.length : 0;
  const newValue = value?.slice?.(0, totValue - 2);
  return (
    <Card enableMediaStyles={enableMediaStyles}>
      <Stack direction='row' alignItems='center'>
        <Stack className="icon" margin="auto" direction="row" justifyContent="center">
          <CardIcon icon={icon} size="medium" color={color} />
        </Stack>
        <Stack direction="column" justifyContent="center" className="infoText" ml={2}>
          <Typography
            className="title"
            component="h5"
            variant="md"
            color="neutral.dark"
            mb={0.5}
            lineHeight="19px"
          >
            {title}
          </Typography>
          <Typography variant="xs" color="neutral.medium" component="p" lineHeight="14px">
            {description}
          </Typography>
        </Stack>
      </Stack>

      <Stack className='value-content'>
        {isCurrency ? (
          <>
            {value && (
              <Typography
                variant="xxxl"
                color="primary"
                lineHeight="38px"
                fontWeight={700}
                className="value"
              >
                {showValue ? (
                  '----------------'
                ) : (
                  <>
                    {newValue}
                    <Typography variant="sm" lineHeight="28px" fontWeight={400} className="value">
                      {cents}
                    </Typography>
                  </>
                )}
              </Typography>
            )}
          </>
        ) : (
          <Typography
            variant="xxxl"
            color="primary"
            lineHeight="28px"
            fontWeight={700}
            className="value"
          >
            {value}
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

CardValue.defaultProps = {
  isCard: true,
};
