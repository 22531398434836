import moment from 'moment';
import { string, object } from 'yup';

export function batchAssignmentFiltersSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        status: string().when("optionFilter", {
            is: "status",
            then: string().typeError('Status precisa ser preenchido.').required('Status: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        assignmentCalculationResult: string().when("optionFilter", {
            is: "assignmentCalculationResult",
            then: string().required('Ágio precisa ser preenchida.').typeError('Ágio: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        assignmentPrice: string().when("optionFilter", {
            is: "assignmentPrice",
            then: string().required('Valor da cessão ser preenchida.').typeError('Valor da cessão: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        maxPresentValue: string().when("optionFilter", {
            is: "maxPresentValue",
            then: string().typeError('Valor do presente ser preenchido.').required('Valor do presente: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minPresentValue: string().when("optionFilter", {
            is: "minPresentValue",
            then: string().typeError('Valor do presente ser preenchido.').required('Valor do presente: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxAssigmentValue: string().when("optionFilter", {
            is: "maxAssigmentValue",
            then: string().typeError('Valor máximo da cessão ser preenchido.').required('Valor máximo da cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minAssigmentValue: string().when("optionFilter", {
            is: "minAssigmentValue",
            then: string().typeError('Valor mínimo da cessão ser preenchido.').required('Valor mínimo da cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxAssignmentCalculationValue: string().when("optionFilter", {
            is: "maxAssignmentCalculationValue",
            then: string().typeError('Valor máximo de cálculo ser preenchido.').required('Valor máximo de cálculo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minAssignmentCalculationValue: string().when("optionFilter", {
            is: "minAssignmentCalculationValue",
            then: string().typeError('Valor mínimo de cálculo ser preenchido.').required('Valor mínimo de cálculo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialAssignmentDate: string().when("optionFilter", {
            is: "initialAssignmentDate",
            then: string().typeError('Data da cessão ser preenchido.').required('Data da cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalAssignmentDate: string().when("optionFilter", {
            is: "finalAssignmentDate",
            then: string().typeError('Data final precisa ser preenchido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        fundId: string().when("optionFilter", {
            is: "fundId",
            then: string().typeError('Cessionário precisa ser preenchido.').required('Cessionário: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }), 
        creditNoteNos: string().when("optionFilter", {
            is: "creditNoteNos",
            then: string().typeError('Número da operação precisa ser preenchido.').required('Número da operação: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }), 
        assignmentNumber: string().when("optionFilter", {
            is: "assignmentNumber",
            then: string().typeError('Número da cessão precisa ser preenchido.').required('Número da cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesBatchAssignmentFiltersSchema = {
    optionFilter: 'status',
    status: null,
    assignmentCalculationResult: null,
    assignmentPrice: null,
    maxPresentValue: null,
    minPresentValue: null,
    maxAssigmentValue: null,
    minAssigmentValue: null,
    maxAssignmentCalculationValue: null,
    creditNoteNos: null,
    assignmentNumber: null,
    minAssignmentCalculationValue: null,
    fundId: null,
    initialAssignmentDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalAssignmentDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
};