/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer';
import { PayrollCreditNoteHeader } from '../../PayrollCreditNoteHeader';
import { FormProvider } from 'contexts/formContext';
import { summaryDefaultValues, summarySchema } from './SummarySchema';
import { BaseContainerStepForm } from '../Generics';
import { Drawer } from '@uy3/web-components';
import { AddPersonFormContainer } from '../../Drawers';
import { BankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetails';
import { bankDetailsListValidationSchema, defaultValuesBankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetailsSchema';
import { useNaturalPersonData, useNaturalPersonList, useNaturalPersonMutation } from 'contexts/naturalPersonContext';
import { ApiResponseError, handleOnError, showSuccessToast } from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { handleOnErrorAmortizationSimulation } from 'helpers/Exceptions/Exceptions';
import { useCreditNoteBatchMutation } from 'contexts/creditNote/creditContext';
import { CreditNoteReadModel } from 'services/creditNote';
import PaymentAccountContainer from '../../Drawers/PaymentAccount/PaymentAccountContainer';
import { useNavigate } from 'react-router';
import { defaultValuesLiquidationSchema } from 'pages/CreditNote/schema';
import LiquidationScheduleForm from 'pages/CreditNote/CreditNoteForm/Tabs/BankAccount/LiquidationSchedule/Form/LiquidationScheduleForm';
import PaySlipDetails from 'pages/CreditNote/CreditNoteForm/Tabs/BankAccount/LiquidationSchedule/Form/BankSlipDetails';
import ActionButtons from 'pages/CreditNote/CreditNoteForm/Tabs/BankAccount/LiquidationSchedule/ActionButtons';
import { useBankList } from 'contexts/bankList';
import { liquidationToBankAccountContractSchema } from 'pages/CreditNote/schema/LiquidationSchedules';
import { CreditProductReadModel, getCreditProductById } from 'services/creditProduct';
import { useIdentity } from 'contexts/identityContext';
import { onlyNumbers } from 'helpers';

const Summary = lazy(() => import('./Summary'));

type ProductConainerProps = {} & PayrollCreditNoteSharedProps;

export const SummaryContainer = (props: ProductConainerProps) => {
    const navigate = useNavigate();
    const { token } = useIdentity();
    const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
    const { isCompraDeDivida, step, setToast, setFormValues, formValues } = props;
    const [action, setAction] = useState<string | undefined>(undefined);
    const { setFormFieldsErrors } = useFormFieldsError();

    const [selectedContractIndex, setSelectedContractIndex] = useState<number | undefined>(undefined);
    const creditNotes = (formValues?.creditNotes ?? []) as CreditNoteReadModel[];
    const contracts = formValues?.contracts ?? [];
    const registrationNumber = onlyNumbers(formValues?.registrationNumber ?? "");

    const { data: naturalPersonList, refetch } = useNaturalPersonList({ page: 0, size: 5, registrationNumber, ...formValues?.person });
    const { naturalPersonData, refetch: refetchPerson } = useNaturalPersonData(naturalPersonList?.data[0]?.id as string) // natural person object full;
    const { bankListAutocomplete } = useBankList({ compe: false });

    useEffect(() => {
        if (naturalPersonData !== null && naturalPersonData !== undefined) {
            setFormValues((prev) => ({ ...prev, person: naturalPersonData }));
        } else {
            setFormValues((prev) => ({ ...prev, person: null }));
        }
    }, [naturalPersonData])

    const hasNaturalPerson: boolean = naturalPersonData !== null && naturalPersonData !== undefined;

    const onSuccess = async (response: CreditNoteReadModel[]) => {
        setFormValues((prev) => ({ ...prev, creditNotes: response }));
        const creditnotesNos = response.map(x => x.creditNoteNo).join(' e ');
        showSuccessToast(
            "Operações foram criadas com sucesso",
            `Agora, você pode conferir as operações de número(s): ${creditnotesNos}.`,
            setToast
        );
        navigate('/ccb/operacoes')
    };

    const onErrorSimulation = (error: ApiResponseError) => handleOnErrorAmortizationSimulation(error, setFormFieldsErrors, setToast);
    const { mutateAsync: creditNoteOpMutateAsync, isLoading: isLoadingCreditNote } = useCreditNoteBatchMutation(onSuccess, onErrorSimulation);

    const onSubmit = async (values: FieldValues) => {

        const allContractsHasBankAccount: boolean = contracts?.every((x: any) => {
            return x?.bankAccount !== null && x?.bankAccount !== undefined;
        });

        if (isCompraDeDivida && !allContractsHasBankAccount) {
            return setToast({
                open: true,
                severity: 'info',
                title: 'Importante',
                description: 'Nem todas as contas de pagamento parecem conter dados bancários. Favor ajustar conforme necessário.'
            });
        };

        const creditNoteMapped = creditNotes.map(creditNote => {

            const isLiquidationScheduleMode = creditNote?.liquidationMode === 'LiquidationSchedule';

            const informationBase = {
                ...creditNote,
                personId: naturalPersonData?.id,
                changeBankAccountId: naturalPersonData?.bankAccounts[0]?.id,
                status: 'ComplianceApproval'
            };


            if (isCompraDeDivida) {
                const liquidationSchedule = contracts?.map((x: any) => {
                    var base = {
                        ...x?.bankAccount,
                        amountInCents: x?.valorParcela * 100
                    };

                    if (base?.transactionType === 'transfer') {
                        return {
                            ...base,
                            pixKey: null,
                            bankSlip: null,
                            pixQrCode: null
                        }
                    } else {
                        return {
                            ...base,
                            transfer: null,
                        }
                    }
                });
                debugger
                return {
                    ...informationBase,
                    liquidationSchedule: isLiquidationScheduleMode ? liquidationSchedule : null
                }
            }

            return informationBase;
        });

        // product configuration is invalid why is not field
        setIsLoadingProduct(true);
        const creditProducts = await Promise.all(
            creditNoteMapped?.map(async x => {
                const { data } = await getCreditProductById(x.productId, token!);
                setIsLoadingProduct(false);
                return data as CreditProductReadModel;
            }) ?? []

        );

        const isAmortizationPeriodsInvalid: boolean = creditProducts.some(x => {
            const maximum = x?.maximumAmortizationPeriods;
            const minimum = x?.minimumAmortizationPeriods;
            return (maximum === null) && (minimum === null)
        });

        if (isAmortizationPeriodsInvalid) {
            return setToast({
                open: true,
                severity: 'info',
                title: "Configuração inválida",
                description: "Um ou mais produtos não possuem períodos de amortização configurados corretamente. Verifique os valores mínimo e máximo."
            });
        }

        // @ts-ignore
        creditNoteOpMutateAsync(creditNoteMapped);
    }

    const { mutate: createNaturalPersonMutate } = useNaturalPersonMutation(
        naturalPersonData?.id ?? 'nova',
        () => {
            if (!!naturalPersonData?.id) {
                refetchPerson();
            } else {
                refetch();
            }
            onClose();
        },
        (error) => handleOnError(error as ApiResponseError, setToast)
    );

    const onClose = () => setAction(undefined);

    const bankAccount = naturalPersonData?.bankAccounts[0];

    const defaultValuesEditOrCreatePerson = () => {
        if (action === 'editBankAccountPerson')
            return mapperEntityBankAccount(bankAccount);
        return defaultValuesBankDetails;
    }

    const handleBankAccountPerson = (values: FieldValues) => {
        //@ts-ignore
        createNaturalPersonMutate({ ...naturalPersonData, bankAccounts: [values] });
    }

    const handleBankAccountContract = (values: FieldValues) => {

        if (contracts[selectedContractIndex!]) {
            contracts[selectedContractIndex!].bankAccount = values;
        };
        setFormValues((prev) => ({ ...prev, contracts: contracts }));
        onClose();
    }

    const getDefaultValuesCustomLiquidationSchedule = () => {
        if (selectedContractIndex === undefined) return defaultValuesLiquidationSchema;

        const selectedContract = contracts[selectedContractIndex];
        if (!selectedContract?.bankAccount) return defaultValuesLiquidationSchema;

        return {
            ...selectedContract.bankAccount,
            amountInCents: selectedContract.valorParcela * 100
        };
    };

    return (
        <Suspense fallback={<Fragment />}>
            <FormProvider
                defaultValues={summaryDefaultValues()}
                validationSchema={summarySchema()}
                onSubmit={onSubmit}
            >
                <BaseContainerStepForm {...{
                    ...props,
                    disabled: !hasNaturalPerson || naturalPersonData?.bankAccounts?.length === 0 || isLoadingCreditNote || isLoadingProduct
                }}>
                    <PayrollCreditNoteHeader
                        isCompraDeDivida={isCompraDeDivida}
                        step={step}
                        handleAddAccountOfPayment={() => setAction('addAccountOfPayment')}
                    />

                    <Summary {...{
                        ...props,
                        handleAction: (action, index) => {
                            setAction(action);
                            setSelectedContractIndex(index);
                        },
                        naturalPersonData
                    }} />
                </BaseContainerStepForm>
            </FormProvider>

            <Drawer
                anchor='right'
                open={action === 'addPerson'}
                title='Adicionar pessoa'
                onClose={onClose}
                children={<AddPersonFormContainer
                    onCloseAddPerson={onClose}
                    setToast={setToast}
                    setFormValues={setFormValues}
                    formValues={formValues}
                />}
            />

            <Drawer
                anchor='right'
                open={action === 'addAccountOfPayment'}
                title='Adicionar conta para pagamento'
                onClose={onClose}
                children={<PaymentAccountContainer onClose={onClose} setFormValues={setFormValues} />}
            />

            {/* Form to add or edit person bank acount  */}
            <Drawer
                title={action === 'editBankAccountPerson' ? 'Editar dados bancários' : "Adicionar dados bancários"}
                anchor='right'
                open={['createBankAccountPerson', 'editBankAccountPerson'].includes(action!)}
                onClose={onClose}
                toggleDrawer
                children={
                    <FormProvider
                        defaultValues={defaultValuesEditOrCreatePerson()}
                        validationSchema={bankDetailsListValidationSchema()}
                        onSubmit={handleBankAccountPerson}
                    >
                        <BankDetails
                            isLegalPerson={false}
                            onClose={() => setAction(undefined)}
                        />
                    </FormProvider>
                }
            />

            {/* Form to add or edit selected contracts bank account */}
            <Drawer
                title={action === 'editBankAccountContract' ? 'Editar dados bancários' : "Adicionar dados bancários"}
                anchor='right'
                open={['createBankAccountContract', 'editBankAccountContract'].includes(action!)}
                onClose={onClose}
                toggleDrawer
                children={
                    <FormProvider
                        validationSchema={liquidationToBankAccountContractSchema()}
                        defaultValues={getDefaultValuesCustomLiquidationSchedule()}
                        onSubmit={handleBankAccountContract}
                    >
                        <>
                            <LiquidationScheduleForm
                                {...{
                                    bankListAutocomplete,
                                    liquidationScheduleEdit: undefined,
                                    hideAmountInCentsField: true
                                }}
                            />
                            {bankSlipData && <PaySlipDetails bankSlipData={bankSlipData} />}
                            <ActionButtons onClose={onClose} />
                        </>
                    </FormProvider>
                }
            />


        </Suspense>
    )
}

function mapperEntityBankAccount(ba: any) {
    return {
        agency: ba?.agency ?? null,
        accountNumber: ba?.accountNumber ?? null,
        accountType: ba?.account ?? ba?.accountType ?? null,
        accountTypeDisplay: ba?.accountTypeDisplay ?? null,
        type: ba?.type ?? null,
        account: ba?.account ?? null,
        typeDisplay: ba?.typeDisplay ?? null,
        accountDigit: ba?.accountDigit ?? null,
        bankCodeDisplay: ba?.bankCodeDisplay ?? null,
        bankCode: ba?.bankCode ?? null,
        operationTypeValue: ba?.operationTypeValue ?? null,
        pixKeyTypeValue: ba?.pixKeyTypeValue ?? null,
        keyPix: ba?.keyPix ?? null
    }
}

const bankSlipData = undefined;