import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import React from 'react'

type ContainerFormFieldsProps = {
    children: React.ReactNode;
    title: string
}

export const ContainerFormFields = ({ children, title }:ContainerFormFieldsProps) => {
  return (
    <Stack border='0.5px solid #BABFD0' borderRadius='8px' padding='20px' spacing={2}>
        <Typography fontSize='18px' color='#575757' fontWeight='600'>{title}</Typography>
        {children}
    </Stack>
  )
}
