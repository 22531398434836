import { Grid, InputAdornment, Stack } from '@mui/material';
import { AddIcon, SearchIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { iconSx } from 'contexts/apiRequestContext';
import { useNavigate } from 'react-router';

type PartnersRequestHeaderProps = {
    refetch: () => void;
}

export const PartnersRequestHeader = ({ refetch }: PartnersRequestHeaderProps) => {
    const nav = useNavigate();
    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='start' marginBottom={2}>
                <Grid>
                    <Typography fontSize='24px' fontWeight='700'>Solicitações de novos parceiros</Typography>
                    <Typography fontSize='16px'>Adicione novos parceiros, acompanhe os prazos, visualize pendências e monitore o tempo em cada etapa do processo</Typography>
                </Grid>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refetch,
                            icon: <UpdateIcon sx={iconSx} />,
                        },
                        {
                            enable: true,
                            label: "Adicionar novo parceiro",
                            action: () => nav('/parceiros/solicitacoes/novo'),
                            icon: <AddIcon sx={iconSx} />,
                        },
                    ]}
                />
            </Stack>
            <Stack
                sx={{
                    border: '0.5px solid #BABFD0',
                    borderRadius: '12px',
                    padding: '16px'
                }}
                spacing={2}
            >
                <Typography fontSize='17px' fontWeight='700'>
                    Equipe comercial
                </Typography>

                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <TextFormField
                            name='search'
                            variant='outlined'
                            placeholder='Procurar'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end" sx={{ marginLeft: -0.4 }}>
                                        <SearchIcon
                                            sx={{ width: 20, height: 20 }}
                                            htmlColor="#666666"
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <RenderAvatarsName
                            title='Responsáveis'
                            list={[
                                { id: '1', name: 'João', tenant: '1', onClick: () => console.log('João') },
                                { id: '2', name: 'Maria', tenant: '2', onClick: () => console.log('Maria') },
                                { id: '3', name: 'José', tenant: '3', onClick: () => console.log('José') },
                                { id: '4', name: 'Joana', tenant: '4', onClick: () => console.log('Joana') },
                                { id: '5', name: 'Pedro', tenant: '5', onClick: () => console.log('Pedro') }
                            ]}
                            renderType='AvatarAction'
                            maxAvatars={5}
                        /> */}
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <Grid>
                            <SelectFormField
                                label='Último andamento'
                                name='lastStep'
                                variant='outlined'
                                fullWidth
                                sx={{ minWidth: '200px' }}
                                options={[]}
                            />
                        </Grid>
                        <Grid>
                            <SelectFormField
                                label='Status do parceiro'
                                name='status'
                                variant='outlined'
                                sx={{ minWidth: '200px' }}
                                fullWidth
                                options={[]}
                            />
                        </Grid>
                    </Stack>
                </Stack>

            </Stack>
        </Stack>

    )
}
