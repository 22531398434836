import { GetListApiResponseSuccess, iconSx } from 'contexts/apiRequestContext';
import React, { Fragment } from 'react'
import { BillingProductRaadModel } from 'services/walletManagement/product/models/billingProductReadModel';
import { ProductContent } from '../ProductContent';
import { GridColDef } from '@mui/x-data-grid';
import { AddIcon, DataTable, Typography } from '@uy3/web-components';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { RefreshProgress } from 'components/RefreshProgress';
import { ListNamesType, RenderAvatarsName } from 'components/RenderAvatarsName/RenderAvatarsName';
import { isValidString } from 'helpers';

type BillingProductProps = {
    addBillingProduct: () => void;
    queryData?: GetListApiResponseSuccess<BillingProductRaadModel>
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    rowsPerPage: number;
    page: number;
    refetch: () => void;
    isLoading: boolean;
    isFetching: boolean;
}

export const BillingProduct = ({ addBillingProduct, queryData, isLoading, page, refetch, rowsPerPage, setPage, setRowsPerPage, isFetching }: BillingProductProps) => {
    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: 'Código',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'bankAccountDebtCosts',
            headerName: 'Conta tarifa',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'enableAutoSubmitToDebtCollection',
            headerName: 'Habilitar envio automatico cartorio',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => !!value ? "Sim" : "Não"
        },
        {
            field: 'daysOverdueLimit',
            headerName: 'Dias limite pagamento',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'billingTypeValueDisplay',
            headerName: 'Tipo cobrança',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'interestCodeValueDisplay',
            headerName: 'Codigo Juros',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'notificationRecepients',
            headerName: 'Destinatários',
            hideSortIcons: true,
            align: 'left',
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                const list = cellValues.row?.notificationRecepients?.filter((x: any) => isValidString(x))?.map((item: any) => {
                    return {
                        id: Math.random().toString(),
                        name: item
                    }
                }) as ListNamesType[] ?? [];

                return (
                    <>
                        {list?.length > 0 ? <RenderAvatarsName list={list} title='Destinatários'/> : <Typography sx={{ ml: 2 }}>-</Typography>}
                    </>)
            }
        },
    ];

    return (
        <ProductContent
            title='Produtos de cobrança'
            description='Confira na listagem abaixo os produtos de cobrança cadastrados.'
            isFetching={isFetching}
            refetch={refetch}
            buttonsActionsList={[
                {
                    enable: true,
                    label: "Adicionar produto de cobrança",
                    action: addBillingProduct,
                    disabled: false,
                    icon: <AddIcon sx={iconSx} />
                }
            ]}
        >
            <Fragment>
                <RefreshProgress refreshing={isFetching} />
                <ListSkeleton isLoading={isLoading}>
                    <DataTable
                        columns={columns}
                        rows={(queryData?.data ?? [])}
                        page={queryData ? queryData.page ?? 0 : page}
                        rowCount={queryData?.totalItems ?? 0}
                        pageCount={queryData?.totalPages ?? 0}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </ListSkeleton>
            </Fragment>
        </ProductContent>
    )
}
