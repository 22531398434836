import { boolean, object, string } from 'yup';

export function billingProductFormSchema() {
    return object().shape({
        code: string().required('Código: precisa ser preenchido').typeError('Código: precisa ser preenchido'),
        bankAccountDebtCosts: string().notRequired().nullable(),
        daysOverdueLimit: string().notRequired().nullable(),
        billingTypeValue: string().required('Tipo cobrança: precisa ser preenchido').typeError('Tipo cobrança: precisa ser preenchido'),
        interestCodeValue: string().notRequired().nullable(),
        enableAutoSubmitToDebtCollection: boolean().nullable().default(false),
    });
};

export const defaultValuesbillingProductForm = {
    code: null,
    bankAccountDebtCosts: null,
    daysOverdueLimit: null,
    billingTypeValue: null,
    interestCodeValue: null,
    enableAutoSubmitToDebtCollection: false
};