import { addDays } from 'date-fns';
import { toIsoStringWithTimezone } from 'helpers';
import { publicPayrollSchema } from 'pages/CreditNote/WarrantyTab';
import { array } from 'yup';
import { string, object } from 'yup';

export const validationSchemaSelectWarrantyForm = () => {
    return object().shape({
        marginSiape: array().of(string()).nullable(),
        marginZetra: array().of(string()).nullable(),
        ...publicPayrollSchema.fields,
    });
};

export const defaultvaluesSelectWarrantyForm = {
    marginZetra: null,
    marginSiape: null,
    previousAdeNumber: null,
    warrantyOption: 'FreeMargin',
    warrantyDeadline: toIsoStringWithTimezone(addDays(new Date(), 40)),
};
