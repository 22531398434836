import { validateRequiredFields } from "helpers";
import { date, object, string } from "yup";

export const addPersonFormSchema = () => {
  return object().shape({
    name: validateRequiredFields(string().typeError(`Nome: precisa ser preenchido`), 'name', `Nome`),
    registrationNumber: validateRequiredFields(
      string().typeError('CPF: precisa ser preenchido').cpfCnpjValidation('CPF inválido.'),
      'registrationNumber',
      `CPF`
    ),
    phone: validateRequiredFields(
      string().typeError(`Telefone: precissa ser preenchido.`).phoneNumberValidation('Número de telefone deve conter 11 Caracteres'),
      'phone',
      `Telefone`
    ),
    email: validateRequiredFields(
      string().email('Email inválido.').typeError(`Email: precisa ser preenchido.`),
      'email',
      `Email`
    ),
    nationality: validateRequiredFields(
      string().typeError('Nacionalidade: precisa ser preenchido'),
      'nationality',
      `Nacionalidade`
    ),
    birthDate: validateRequiredFields(
      date().typeError('Data de nascimento: precisa ser preenchido').max(new Date(), 'A data de nascimento não pode ser maior que a data atual.'),
      'birthDate',
      `Data de nascimento`
    ),
    civilStatus: validateRequiredFields(
      string().typeError('Estado civil inválido.'),
      'civilStatus',
      `Estado civil`
    ),
    gender: validateRequiredFields(
      string().typeError(`Gênero inválido.`),
      'gender',
      `Gênero`
    ),
    mothersName: validateRequiredFields(
      string().typeError('Nome da mãe inválido.'),
      'mothersName',
      `Nome da mãe`
    ),
    fathersName: validateRequiredFields(
      string().typeError('Nome do pai inválido.'),
      'fathersName',
      `Nome do Pai`
    ),
  });
};

export const addPersonFormDefaultValues = (registrationNumber: string) => {
  return {
    name: null,
    registrationNumber: registrationNumber ?? null,
    nationality: null,
    birthDate: null,
    civilStatus: null,
    gender: null,
    mothersName: null,
    fathersName: null,

  }
}