import { Grid, IconButton, Stack } from "@mui/material";
import { Button, RightIcon, SearchIcon, Typography, UserAddIcon } from "@uy3/web-components";
import { ShowInformationTypography } from "../ShowInformationTypography";
import { formatDocumentNumber } from "helpers";
import { NaturalPersonReadModel } from "services/accounts/naturalPerson";
import { iconSx } from "contexts/apiRequestContext";
import { ShowBankAccount } from "../../Summary/BankAccount/ShowBankAccount";
import { AlertWithAction } from "components/AlertWithAction/AlertWithAction";
import { activeTheme } from "services/theme";
import { Link } from "react-router-dom";

const theme = activeTheme();

export const OwnerInformations = ({ naturalPersonData, handleAction }: { naturalPersonData: NaturalPersonReadModel, handleAction: (action: string) => void }) => {
    const bankAccount = naturalPersonData?.bankAccounts[0];
    return (
        <Stack spacing={3} border="1px solid #666" padding="24px" borderRadius="8px">
            <Typography fontSize="18px" fontWeight="700">Dados do titular</Typography>
            <Stack direction='row' alignItems='end' spacing={1}>
                <Grid>
                    <ShowInformationTypography title="Nome do titular" value={naturalPersonData?.name} />
                </Grid>
                <Link to={`/pessoas-fisicas/todas/${naturalPersonData?.id}`} target="_blank">
                    <IconButton>
                        <SearchIcon sx={iconSx} />
                    </IconButton>
                </Link>
            </Stack>
            <ShowInformationTypography title="CPF" value={formatDocumentNumber(naturalPersonData?.registrationNumber) ?? ""} />
            {bankAccount ? (
                <Stack>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography fontSize="18px" fontWeight="700">Dados bancários</Typography>
                        <Button
                            type="button"
                            variant="text"
                            onClick={() => handleAction('editBankAccountPerson')}
                            startIcon={<RightIcon sx={iconSx} />}
                            sx={{ color: theme.palette.common.black }}
                        >
                            Editar dados bancários
                        </Button>
                    </Stack>
                    <ShowBankAccount bankAccount={bankAccount} />
                </Stack>
            ) : (
                <AlertWithAction
                    message="Pessoa não possui conta bancária. Adicione uma conta para seguir."
                    severityType="Warning"
                    action={{ label: 'Adicionar conta bancária', Icon: UserAddIcon, onClick: () => handleAction('createBankAccountPerson') }}
                />
            )}
        </Stack>
    )
};