import { FormProvider } from 'contexts/formContext';
import React, { lazy, Suspense } from 'react'
import { CreditNoteOpCreateModel } from 'services/creditNote';
import { SimualtionDetailsSkeleton } from 'components/Skeleton/SimualtionDetailsSkeleton';
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer';
import { FieldValues } from 'react-hook-form';
import { simulationDetailsDefaultValues, simulationDetailsSchema } from './SimulationDetailsSchema';
import { BaseContainerStepForm } from '../Generics';
import { creditNoteDefault } from 'pages/CreditNote/CreditNoteForm/CreditNoteSchema';

const NewSimulationDetails = lazy(() => import('./NewSimulationDetails'));
const SimulationDebtPurchaseDetails = lazy(() => import('./SimulationDebtPurchaseDetails'));

type SimulationDetailsContainerProps = {} & PayrollCreditNoteSharedProps;
const CAREGORY_NAME_FILTER = "CONSIGNADO PÚBLICO";

const SimulationDetailsContainer = (props: SimulationDetailsContainerProps) => {
  const { isCompraDeDivida, setFormValues, onNextStep, setToast, formValues, products } = props;

  const onSubmit = (values: FieldValues) => {
    var creditNotes: CreditNoteOpCreateModel[] = [];
    const product = values?.product ?? null;
    const productPersonal = values?.productPersonal ?? null;
    const productConsined = values?.productConsined ?? null;

    if ((!isCompraDeDivida && product === null) || (isCompraDeDivida && (productPersonal === null || productConsined === null))) {
      return setToast({
        open: true,
        severity: 'info',
        title: 'Selecione ao menos uma proposta para seguir.'
      })
    }

    setFormValues((prev) => {
      var updatedCreditNotes = [...(prev?.creditNotes || [])];
      updatedCreditNotes = [];

      const addUniqueProduct = (productId: string, product: any, liquidationMode?: string) => {
        if (!updatedCreditNotes.some((item) => item.productId === productId)) {
          updatedCreditNotes.push({ productId, product, liquidationMode });
        }
      };

      if (values?.product && !isCompraDeDivida) {
        addUniqueProduct(values.productId, values.product);
      } else {
        if (values?.productPersonal) addUniqueProduct(values.productPersonalId, values.productPersonal, "Simple");
        if (values?.productConsined) addUniqueProduct(values.productConsinedId, values.productConsined, "LiquidationSchedule");
      }

      creditNotes = updatedCreditNotes;

      return {
        ...prev,
        creditNotes: updatedCreditNotes
      };
    });

    //@ts-ignore
    const mapperCreditNote: CreditNoteOpCreateModel[] = creditNotes.map((creditNote, index) => {
      const simulationResponse = formValues?.simulationResponse?.find((x: any) => x.productId === creditNote.productId);
      const getModelSimulation = (formValues?.modelSimulation as any[]).find(x => x.productId === creditNote.productId);
      const product = getModelSimulation?.product;
      const amortization = getModelSimulation?.amortization;

      return {
        ...creditNoteDefault,
        productId: creditNote?.productId,
        product,
        amortization: {
          ...amortization, 
          ...simulationResponse
        },
        paymentScheduleItems: simulationResponse?.paymentScheduleItems ?? [], 
        warranty: formValues?.warranty,
        liquidationMode: creditNote?.liquidationMode
      }
    });
    
    // add mapped data to creditnotes
    setFormValues((prev) => ({...prev, creditNotes: mapperCreditNote}))
    onNextStep();
  }

  const productFilteredByCreditPersonal = products.filter((product) => product.categoryName.toUpperCase() !== CAREGORY_NAME_FILTER);
  const productFilteredByPublicPayroll = products.filter((product) => product.categoryName.toUpperCase() === CAREGORY_NAME_FILTER);

  const disableActionNext = isCompraDeDivida ? (
    productFilteredByCreditPersonal?.length === 0 ||
    productFilteredByPublicPayroll?.length === 0) :
    products.length === 0;

  return (
    <Suspense fallback={<SimualtionDetailsSkeleton />}>
      <FormProvider {...{
        defaultValues: simulationDetailsDefaultValues(),
        validationSchema: simulationDetailsSchema(),
        onSubmit
      }}>
        <BaseContainerStepForm {...{ ...props, disabled: disableActionNext }}>
          {isCompraDeDivida ? (
            <SimulationDebtPurchaseDetails
              isLoading={false}
              productFilteredByCreditPersonal={productFilteredByCreditPersonal}
              productFilteredByPublicPayroll={productFilteredByPublicPayroll}
              formValues={formValues}
            />
          ) : (
            <NewSimulationDetails
              products={products ?? []}
              responseSimulation={formValues?.simulationResponse}
              isLoading={false}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          )}

        </BaseContainerStepForm>
      </FormProvider>

    </Suspense>
  )
}

export default SimulationDetailsContainer;