import { AvatarGroup, Grid, Stack } from '@mui/material';
import { AvatarProps, Avatar as AvatarUI, Typography } from '@uy3/web-components';
import { StyledTooltip } from 'components/Custom/CustomTooltip/Tooltip';
import { ListNamesType } from 'components/RenderAvatarsName/RenderAvatarsName';
import { iconSx } from 'contexts/apiRequestContext';
import { useState } from 'react';
import { activeTheme } from 'services/theme';

interface AvatarListProps extends AvatarProps {
    optionsList: ListNamesType[]
    title?: string;
    maxAvatars?: number;
    renderType: 'AvatarGroup' | 'AvatarAction';
}

const theme = activeTheme();
const AvatarComponent: React.FC<AvatarListProps> = ({
    optionsList: list,
    title = 'Credores',
    maxAvatars = 3,
    renderType
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const optionsList = list ?? [];

    function extractFirstTwoLetters(listOfNames: string[]): string[] {
        return listOfNames?.map((name) => name?.toUpperCase().slice(0, 2));
    }

    const listNames = optionsList.map((item: ListNamesType) => item.name);
    const abbreviatedNames = extractFirstTwoLetters(listNames);


    const avatarSx = {
        ...iconSx,
        p: 0.7,
        color: theme.palette.common.black,
        bgcolor: '#BABFD0',
    };

    if (renderType === 'AvatarAction') {
        return (
            <Stack direction="row" alignItems="center" justifyContent="start">
                {abbreviatedNames.map((item, index) => {
                    const isActive = selectedIndex === index;
                    return (
                        <AvatarUI
                            key={index}
                            sx={{
                                ...avatarSx,
                                cursor: 'pointer',
                                margin: '0 5px',
                                padding: 1.6,
                                border: isActive ? `1px solid ${theme.palette.primary.main}` : 'none',
                                ':hover': {
                                    border: '1px solid black'
                                },
                            }}
                            onClick={() => {
                                setSelectedIndex(index);
                                optionsList[index]?.onClick?.();
                            }}
                        >
                            {item}
                        </AvatarUI>
                    )
                })}
            </Stack>
        );
    }

    return (
        <StyledTooltip
            title={<TooltipContent listNames={listNames} title={title} />}
            placement="bottom"
        >
            <AvatarGroup
                max={maxAvatars}
                componentsProps={{
                    additionalAvatar: { sx: avatarSx },
                }}
            >
                {abbreviatedNames.map((item, index) => (
                    <AvatarUI key={index} sx={avatarSx}>
                        {item}
                    </AvatarUI>
                ))}
            </AvatarGroup>
        </StyledTooltip>
    );
};

export default AvatarComponent;

interface TooltipContentProps {
    listNames: string[];
    title: string;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ listNames, title }) => {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
            <Grid item>
                <Typography variant="xs" textAlign="center">
                    {title}
                </Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'start' }}>
                {listNames.map((creditor, index) => (
                    <Typography key={index}>{creditor}</Typography>
                ))}
            </Grid>
        </Grid>
    );
};
