/* eslint-disable react-hooks/exhaustive-deps */
import { ApproveIcon, DataTable, RejectIcon, SearchIcon } from '@uy3/web-components';
import React, { useEffect } from 'react';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { Stack } from '@mui/material';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { ApprovalsFull } from 'services/approvals/approvals';
import { RowActions, RowDataAction } from 'components/RowActions/RowActions';
import { BankAccountFull } from 'contexts/apiRequestContext';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { formatBankAccountForString } from 'helpers/formats/BankAccount';
import HighlightBox from 'components/HighlightBox';
import { activeTheme } from 'services/theme';
import { TableWithTotalValue } from 'components/TableWithTotalValue/TableWithTotalValue';

type BankAccuntListProps = {
    page: number;
    filterSelected: string | Date | string[];
    setPage: (newPage: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setRowAction: React.Dispatch<RowDataAction<ApprovalsFull>>;
    onSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    rowsSelected: GridRowId[];
    queryData: any;
};

const defaultColumnsByResource: { [type: string]: string[] } = {
    BankAccountTransfer: [
        'BankAccountTransfer.tenantDisplay',
        'BankAccountTransfer.operationTypeValueDisplay',
        'BankAccountTransfer.nameOfBeneficiaryDisplay',
        'BankAccountTransfer.registrationNumberOwner',
        'BankAccountTransfer.registrationNumber',
        'BankAccountTransfer.ownerAccountTypeDisplay',
        'BankAccountTransfer.ownerName',
        'BankAccountTransfer.date',
        'BankAccountTransfer.accountNumber',
        'BankAccountTransfer.amount',
    ],
    BankAccountUser: [
        'BankAccountUser.tenantDisplay',
        'BankAccountUser.registrationNumberOwner',
        'BankAccountUser.ownerName',
        'BankAccountUser.levelDisplay',
        'BankAccountUser.accountNumber',
        'BankAccountUser.ownerAccountTypeDisplay',
        'BankAccountUser.date',
        'BankAccountUser.userIdDisplay',
    ],
    BankAccountBeneficiary: [
        'BankAccountBeneficiary.tenantDisplay',
        'BankAccountBeneficiary.nameOfBeneficiaryDisplay',
        'BankAccountBeneficiary.registrationNumberOwner',
        'BankAccountBeneficiary.accountNumber',
        'BankAccountBeneficiary.registrationNumber',
        'BankAccountBeneficiary.bankAccountData',
        'BankAccountBeneficiary.ownerAccountTypeDisplay',
        'BankAccountBeneficiary.ownerName',
    ],
    BankAccountPayment: [
        'BankAccountPayment.ownerName',
        'BankAccountPayment.tenantDisplay',
        'BankAccountPayment.accountNumber',
        'BankAccountPayment.date',
        'BankAccountPayment.ownerAccountTypeDisplay',
        'BankAccountPayment.registrationNumberOwner',
        'BankAccountPayment.amount',
    ],
    BankAccountTransactionLimits: [
        'BankAccountTransactionLimits.tenantDisplay',
        'BankAccountTransactionLimits.accountNumber',
        'BankAccountTransactionLimits.tenantDisplay',
        'BankAccountTransactionLimits.registrationNumberOwner',
        'BankAccountTransactionLimits.ownerAccountTypeDisplay',
        'BankAccountTransactionLimits.ownerName',
        'BankAccountTransactionLimits.date',
        'BankAccountTransactionLimits.requestedBy',
    ],
};

const theme = activeTheme();
const palette = theme.palette;
export const BankAccuntList: React.FC<BankAccuntListProps> = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowAction,
    setRowsPerPage,
    filterSelected,
    onSelectionModelChange,
    rowsSelected,
}) => {
    const enableTotalValueByFilter = ['BankAccountTransfer', 'BankAccountPayment'].includes(filterSelected as string);
    const isBankAccountTransactionLimits = filterSelected === 'BankAccountTransactionLimits';
    const { selectedColumns, setColumns, setDefaultValues, defaultValues } = useSelectColumns();

    const fieldHeaderToList: { [type: string]: { field: string; columns: string[] } } = {
        BankAccountTransfer: {
            field: 'BankAccountTransfer',
            columns: [
                'BankAccountTransfer.tenantDisplay',
                'BankAccountTransfer.operationTypeValueDisplay',
                'BankAccountTransfer.nameOfBeneficiaryDisplay',
                'BankAccountTransfer.registrationNumberOwner',
                'BankAccountTransfer.registrationNumber',
                'BankAccountTransfer.ownerAccountTypeDisplay',
                'BankAccountTransfer.ownerName',
                'BankAccountTransfer.date',
                'BankAccountTransfer.accountNumber',
                'BankAccountTransfer.amount',
                'actions',
            ],
        },
        BankAccountUser: {
            field: 'BankAccountUser',
            columns: [
                'BankAccountUser.tenantDisplay',
                'BankAccountUser.registrationNumberOwner',
                'BankAccountUser.ownerName',
                'BankAccountUser.levelDisplay',
                'BankAccountUser.accountNumber',
                'BankAccountUser.ownerAccountTypeDisplay',
                'BankAccountUser.userIdDisplay',
                'BankAccountUser.date',
                'actions',
            ],
        },
        BankAccountBeneficiary: {
            field: 'BankAccountBeneficiary',
            columns: [
                'BankAccountBeneficiary.tenantDisplay',
                'BankAccountBeneficiary.nameOfBeneficiaryDisplay',
                'BankAccountBeneficiary.registrationNumberOwner',
                'BankAccountBeneficiary.accountNumber',
                'BankAccountBeneficiary.bankAccountData',
                'BankAccountBeneficiary.registrationNumber',
                'BankAccountBeneficiary.ownerAccountTypeDisplay',
                'BankAccountBeneficiary.ownerName',
                'actions',
            ],
        },
        BankAccountPayment: {
            field: 'BankAccountPayment',
            columns: [
                'BankAccountPayment.ownerName',
                'BankAccountPayment.tenantDisplay',
                'BankAccountPayment.date',
                'BankAccountPayment.accountNumber',
                'BankAccountPayment.ownerAccountTypeDisplay',
                'BankAccountPayment.registrationNumberOwner',
                'BankAccountPayment.amount',
                'actions',
            ],
        },
        BankAccountTransactionLimits: {
            field: 'BankAccountTransactionLimits',
            columns: [
                'BankAccountTransactionLimits.tenantDisplay',
                'BankAccountTransactionLimits.accountNumber',
                'BankAccountTransactionLimits.agency',
                'BankAccountTransactionLimits.registrationNumberOwner',
                'BankAccountTransactionLimits.account',
                'BankAccountTransactionLimits.ownerAccountTypeDisplay',
                'BankAccountTransactionLimits.ownerName',
                'BankAccountTransactionLimits.date',
                'BankAccountTransactionLimits.requestedBy',
                'actions',
            ],
        },
    };

    const filterSelectedToString = filterSelected as string;

    const defaultFieldsNamesColumn = defaultColumnsByResource[filterSelectedToString];

    const fields = fieldHeaderToList[filterSelectedToString];

    const addedFirstToSmallLetter = (str: string) => str[0].toLowerCase() + str.substring(1);

    useEffect(() => {
        setColumns(columnsByResourceFiltered);
        if (defaultFieldsNamesColumn.length) {
            setDefaultValues(defaultFieldsNamesColumn);
        }
    }, [filterSelected]);

    const getNameByFilterSelected = (currentFilter: string) => {
        const options: { [type: string]: string } = {
            BankAccountTransfer: 'Beneficiário',
            BankAccountBeneficiary: 'Favorecido',
            BankAccountTransactionLimits: 'Não informado',
        };

        return `${options[currentFilter]}`;
    };

    const sumTotalOperation = () => {
        const list = (queryData?.data ?? []);

        if (!enableTotalValueByFilter || rowsSelected?.length === 0) return 0; 
        
        const dataRowSelected = list?.filter((operation: any) => {
            return rowsSelected?.includes(operation?.id);
        }
        );

        var getOnlyValuesSelected = []; 

        if (filterSelected === 'BankAccountTransfer') {
            getOnlyValuesSelected = dataRowSelected?.map((x:any)=>x["bankAccountTransfer"]?.amount) ?? [];
        } else if (filterSelected === 'BankAccountPayment') {
            getOnlyValuesSelected = dataRowSelected?.map((x:any)=>x["bankAccountPayment"]?.amount) ?? [];
        }

        if (getOnlyValuesSelected?.length > 0) {
            return (
                getOnlyValuesSelected?.reduce((current: number, acc: number) => acc + current) ?? ''
            );
        }
    };

    const paymentOrTransfer =
        filterSelected === 'BankAccountTransfer' ? 'Transferência' : 'Pagamento';

    const columns: GridColDef[] = [
        {
            field: `${[fields.field]}.tenantDisplay`,
            headerName: isBankAccountTransactionLimits ? 'Correspondente' : 'Correspondente (Solicitante)',
            flex: 0.7,
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                const data = row?.[value];
                return isBankAccountTransactionLimits ? data?.tenantDisplay : data?.requester?.tenantDisplay;
            },
        },
        {
            field: `${[fields.field]}.ownerName`,
            headerName: 'Nome (Titular)',
            minWidth: 200,
            flex: 1,
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const type = addedFirstToSmallLetter(fields.field);
                const value =
                isBankAccountTransactionLimits
                        ? row?.[type]?.bankAccount?.person?.name
                        : row?.bankAccount?.person?.name;
                return value ?? 'N/D';
            },
        },
        {
            field: `${[fields.field]}.agency`,
            headerName: 'Agência',
            minWidth: 80,
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return row?.[value]?.bankAccount?.agency;
            },
        },
        {
            field: `${[fields.field]}.account`,
            headerName: 'Conta',
            minWidth: 200,
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return row?.[value]?.bankAccount?.account;
            },
        },
        {
            field: `${[fields.field]}.registrationNumberOwner`,
            headerName: 'CPF/CNPJ (Titular)',
            minWidth: 150,
            flex: 1,
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                const registrationNumber =
                    isBankAccountTransactionLimits
                        ? row?.[value]?.bankAccount?.person?.registrationNumber
                        : row?.bankAccount?.person?.registrationNumber;
                return formatDocumentNumber(registrationNumber);
            },
        },
        {
            field: `${[fields.field]}.ownerAccountTypeDisplay`,
            headerName: 'Tipo de conta (Titular)',
            flex: 1, 
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const type = addedFirstToSmallLetter(fields.field);
                const value =
                    isBankAccountTransactionLimits
                        ? row?.[type]?.bankAccount?.typeDisplay
                        : row?.bankAccount?.typeDisplay;
                return value ?? 'N/D';
            },
        },
        {
            field: `${[fields.field]}.accountNumber`,
            headerName: 'Número da conta (Titular)',
            hideSortIcons: true,
            minWidth: 130,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                const account = row['bankAccount'] as BankAccountFull;
                if (filterSelectedToString === 'BankAccountTransactionLimits') {
                    return row?.bankAccountTransactionLimits?.bankAccount?.account ?? 'N/D';
                }
                return account?.account ?? 'N/D';
            },
        },
        {
            field: `${[fields.field]}.userIdDisplay`,
            headerName: 'Nome (Operador)',
            minWidth: 200,
            flex: 1,
            align: 'left',
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return row?.[value]?.user?.userIdDisplay;
            },
        },
        {
            field: `${[fields.field]}.levelDisplay`,
            headerName: 'Nível (Operador)',
            minWidth: 200,
            flex: 1,
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                const levelDisplay = row?.[value]?.levelDisplay;

                const backgroundColor: { [key: string]: string } = {
                    'Aprovador em Conjunto': palette.warning.light,
                    'Aprovador Master': palette.error.main,
                };

                const background = backgroundColor[levelDisplay];

                return (
                    <HighlightBox
                        background={background}
                        textDisplay={levelDisplay}
                        width={'170px'}
                    />
                );
            },
        },
        {
            field: `${[fields.field]}.operationTypeValueDisplay`,
            headerName: 'Tipo de solicitação',
            flex: 1,
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return row?.[value]?.bankAccountBeneficiary?.operationTypeValueDisplay;
            },
        },
        {
            field: `${[fields.field]}.amount`,
            headerName: `Valor (${paymentOrTransfer})`,
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return formatCurrencyInCents(row?.[value]?.amount);
            },
        },
        {
            field: `${[fields.field]}.nameOfBeneficiaryDisplay`,
            headerName: `Nome (${getNameByFilterSelected(String(filterSelected))})`,
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                const nameDisplay =
                    filterSelected === 'BankAccountBeneficiary'
                        ? row?.[value]?.name
                        : row?.[value]?.bankAccountBeneficiary?.name;
                return nameDisplay ?? 'N/D';
            },
        },
        {
            field: `${[fields.field]}.registrationNumber`,
            headerName: `CPF/CNPJ (${getNameByFilterSelected(String(filterSelected))})`,
            align: 'left',
            flex: 1, 
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const type = addedFirstToSmallLetter(fields.field);
                const registrationNumber = row[type]?.registrationNumber;
                const documentNumber = row[type]?.bankAccountBeneficiary?.registrationNumber;
                const value =
                    filterSelected === 'BankAccountBeneficiary'
                        ? registrationNumber
                        : documentNumber;
                return formatDocumentNumber(value) ?? 'N/D';
            },
        },
        {
            field: `${[fields.field]}.bankAccountData`,
            headerName: `Dados da conta (${getNameByFilterSelected(String(filterSelected))})`, 
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ row }) => {
                const value = addedFirstToSmallLetter(fields.field);
                return formatBankAccountForString(row[value]);
            },
        },
        {
            field: `${[fields.field]}.requestedBy`,
            headerName: `Solicitado por`,
            flex: 1,
            editable: false,
            hideSortIcons: true,
            renderCell: ({ row }) => {
                const type = addedFirstToSmallLetter(fields.field);
                return row[type]?.createdBy?.userIdDisplay ?? 'N/D';
            },
        },
        {
            field: `${fields.field}.createdAt`,
            headerName: 'Data da solicitação',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                let value = row?.createdAt;
                return new Date(value).toLocaleDateString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
            },
        },
        {
            field: `${fields.field}.date`,
            headerName: 'Data da ocorrência',
            hideSortIcons: true,
            flex: 1,
            minWidth: 160,
            align: 'left',
            editable: false,
            renderCell: ({ row }) => {
                const options: { [type: string]: string } = {
                    BankAccountPayment: row?.bankAccountPayment?.date,
                    BankAccountTransfer: row?.bankAccountTransfer?.transferDate,
                    BankAccountTransactionLimits: row?.bankAccountTransactionLimits?.updatedAt,
                    BankAccountUser: row?.bankAccountUser?.updatedAt,
                };
                return new Date(options[String(filterSelected)]).toLocaleDateString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 80,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Stack mr={1}>
                        <RowActions
                            listButtons={[
                                {
                                    action: () =>
                                        setRowAction({
                                            action: 'details',
                                            rowData: row,
                                        }),
                                    disabled: false,
                                    icon: <SearchIcon />,
                                    label: 'Detalhes',
                                },
                                {
                                    action: () =>
                                        setRowAction({
                                            action:
                                                filterSelectedToString ===
                                                'BankAccountTransactionLimits'
                                                    ? 'formApprovalLimits'
                                                    : 'approve',
                                            rowData: row,
                                        }),
                                    disabled: rowsSelected.length > 0,
                                    icon: <ApproveIcon />,
                                    label: 'Aprovar',
                                },
                                {
                                    action: () =>
                                        setRowAction({
                                            action: 'reject',
                                            rowData: row,
                                        }),
                                    disabled: rowsSelected.length > 0,
                                    icon: <RejectIcon />,
                                    label: 'Reprovar',
                                },
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];

    const columnsFields = fieldHeaderToList[fields.field].columns;
    const columnsByResourceFiltered = columns.filter((info) =>
        columnsFields.includes(info.field)
    ) as GridColDef[];

    const columnsActions = columnsByResourceFiltered?.filter((x) => x.field === 'actions');

    const filteredColumns = columnsByResourceFiltered?.filter((column) =>
        selectedColumns?.length === 0
            ? defaultValues?.includes(column?.field)
            : selectedColumns?.includes(column?.field)
    );

    const columnsCompleteToList = [...filteredColumns, ...columnsActions];

    return (
        <TableWithTotalValue {...{
            enableTotalValue: sumTotalOperation() > 0 && enableTotalValueByFilter,
            qtdRowsSelected: rowsSelected?.length ?? 0,
            totalValue: sumTotalOperation()
        }}>
            <DataTable
                columns={columnsCompleteToList}
                filterComponent={{
                    componentFilter: (
                        <BankAccountFiltersContainer
                            typeFilter="pendingApprovals"
                            enableSelectFilter
                            recordType={filterSelected as string}
                        />
                    ),
                    showFilters: true,
                }}
                page={queryData ? queryData.page ?? 0 : page}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                keepNonExistentRowsSelected
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={onSelectionModelChange}
                checkboxSelection
            />
        </TableWithTotalValue>
    );
};
