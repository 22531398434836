import { Drawer } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import { lazy, Suspense, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { PayrollCreditNoteSharedProps } from '../../../PayrollCreditNoteContainer';
import { personNewSimulaionDefaultValues, personNewSimulaionSchema } from './personNewSimulaionSchema';
import { SimualtionDetailsSkeleton } from 'components/Skeleton/SimualtionDetailsSkeleton';
import { BaseContainerStepForm, SearchNaturalPersonContainer } from '../../Generics';

const PersonNewSimulaion = lazy(() => import('./PersonNewSimulaion'));

type PersonNewSimulaionContainerProps = {} & PayrollCreditNoteSharedProps

export const PersonNewSimulaionContainer = (props: PersonNewSimulaionContainerProps) => {
  const { formValues, setFormValues, onNextStep } = props;
  const [useOpenClient, setOpenClient] = useState<boolean>(false);

  const onSubmit = (values: FieldValues) => {
    const data = {
      person: values?.person ?? null,
      registrationNumber: values.registrationNumber,
      employeeCode: values.employeeCode
    };

    setFormValues((prev) => ({ ...prev, ...data }));
    onNextStep();
  }

  const onClose = () => setOpenClient(false);

  return (
    <Suspense fallback={<SimualtionDetailsSkeleton />}>
      <FormProvider {...{
        defaultValues: personNewSimulaionDefaultValues(formValues),
        validationSchema: personNewSimulaionSchema(),
        onSubmit
      }}>
        <BaseContainerStepForm {...props}>
          <PersonNewSimulaion
            formValues={formValues}
            searchClient={() => setOpenClient(true)}
          />

          <Drawer
            open={useOpenClient}
            onClose={onClose}
            anchor="right"
            title="Buscar pessoa"
          >
            <SearchNaturalPersonContainer onClose={onClose} />
          </Drawer>
        </BaseContainerStepForm>
      </FormProvider>
    </Suspense>
  );
};