import React from 'react';
import { TabBar } from 'components/TabBar/TabBar';

export const BillingProductsViewContainer: React.FC = () => {
  const tabs = [
    { label: `Produtos cobrança`, href: 'produtos-cobranca' },
    { label: `Parâmetros/custos cobrança`, href: 'parametros-custos' }
  ];
  return <TabBar tabs={tabs} />
};
