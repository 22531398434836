/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Alert, Button } from '@uy3/web-components';
import { CheckboxFormField } from 'components/Forms/FormFields';
import { ToastType, handleOnError, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { assignmentPreviewCreditNoteById, draftPreviewCreditNoteById } from 'services/creditNote';
import {
    validationSchemaGeneratePreviewForm,
    defaultValuesGeneratePreviewForm,
} from './GeneratePreviewSchema';
import { FieldValues } from 'react-hook-form';
import { useCreditData } from 'contexts/creditNote/creditContext';
import { useParams } from 'react-router';
import { useFundData } from 'contexts/fundContext';
import { useCreditProductData } from 'contexts/creditProductContext';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';

type GeneratePreviewCreditNoteProps = {
    setPopupProps: (props: any) => void;
    isAssignmentPreview?: boolean;
};

export const GeneratePreviewCreditNote = ({
    setPopupProps,
    isAssignmentPreview
}: GeneratePreviewCreditNoteProps) => {
    const { startRequest, endRequest } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { token } = useIdentity();
    const { id } = useParams();
    const { refetch } = useCreditData(id!);
    const { watch } = useFormContext();
    const fundId = watch('fundId') ?? 'novo';
    const productId = watch('productId') ?? 'novo';
    const { fundData } = useFundData(fundId!);
    const { creditProductData } = useCreditProductData(productId!);
    const templateDoc = fundData?.templateDoc;

    const handleFinally = () => {
        endRequest(true);
        setPopupProps(undefined);
    };

    const handleAssignmentPreview = (values: FieldValues) => {
        const { useWatermark, createPdf } = values;

        startRequest();
        assignmentPreviewCreditNoteById(id!, token!, useWatermark, createPdf)
            .then(() => {
                refetch();
                const title = 'Contrato gerado com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
                handleFinally();
            })
            .catch((error) => handleOnError(error, setToast))
    };

    const handleDraftPreview = (values: FieldValues) => {
        const { useWatermark, createPdf } = values;
        startRequest();
        draftPreviewCreditNoteById(id!, token!, useWatermark, createPdf)
            .then(() => {
                refetch();
                const title = 'Contrato gerado com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
                handleFinally();
            })
            .catch((error) => handleOnError(error, setToast))
    };

    const schema = validationSchemaGeneratePreviewForm();
    const defaultValues = defaultValuesGeneratePreviewForm;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={isAssignmentPreview ? handleAssignmentPreview : handleDraftPreview}
            >
                <>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <CheckboxFormField
                                color="primary"
                                label="Usar marca d'água"
                                name="useWatermark"
                                disabled={isAssignmentPreview ?
                                    templateDoc === null : creditProductData?.templateDoc === null}
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxFormField
                                color="primary"
                                label="Gerar PDF"
                                name="createPdf"
                                disabled={isAssignmentPreview ?
                                    templateDoc === null : creditProductData?.templateDoc === null}
                            />
                        </Grid>
                    </Grid>
                    {templateDoc === null && isAssignmentPreview && (
                        <Grid item sx={{ mt: 3 }}>
                            <Alert
                                severity="warning"
                                text='Ops! Cessionário sem contrato de cessão. Para resolver isso, por favor, vá até o menu "Cessionários" e adicione um arquivo de cessão individual.'
                            />
                        </Grid>
                    )}
                    {creditProductData?.templateDoc === null && !isAssignmentPreview && (
                        <Grid item sx={{ mt: 3 }}>
                            <Alert
                                severity="warning"
                                text='Ops! Produto sem contrato definido. Para resolver isso, por favor, vá até o menu "Produto" e adicione um arquivo de modelo de contrato.'
                            />
                        </Grid>
                    )}
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Enviar
                        </Button>
                    </Grid>
                </>
            </FormProvider>
        </>
    );
};
