import { Stack } from '@mui/material';
import { ShowInformationTypography } from '../../Generics';
import { TLiquidationScheduleType } from 'pages/CreditNote/schema';

type ShowBankAccountDebtPurchaseProps = {
    bankAccount: TLiquidationScheduleType
};

export const ShowBankAccountDebtPurchase = ({ bankAccount }: ShowBankAccountDebtPurchaseProps) => {
    const transferToData = bankAccount?.transfer?.to

    if (bankAccount.pixKey !== null) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <ShowInformationTypography title="Tipo de Operação" value='Pix' />
                <ShowInformationTypography title="Chave" value={bankAccount?.pixKey?.key}/>
            </Stack>
        );
    }

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ShowInformationTypography title="Tipo de Operação" value={'Transferência'} />
            <ShowInformationTypography title="Banco" value={transferToData?.bankCodeDisplay ?? ''} />
            <ShowInformationTypography title="Titular" value={`${transferToData?.name} (${transferToData?.registrationNumber})`} />
            <ShowInformationTypography title="Tipo de conta" value={transferToData?.accountTypeDisplay ?? ''}/>
            <ShowInformationTypography title="Agência" value={transferToData?.agency ?? ''} />
            <ShowInformationTypography title="Conta" value={transferToData?.accountNumber ?? ''} />
        </Stack>
    );
};
