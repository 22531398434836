import { Stack } from '@mui/material';
import { handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useCreateProductsCostsDefinition } from 'contexts/wallet/Product/BillingProductContext';
import React, { Fragment } from 'react';
import {
    costsBillingParametersFormSchema,
    defaultValuescostsBillingParametersForm,
} from './CostsBillingParametersFormSchema';
import { CostsBillingParametersForm } from './CostsBillingParametersForm';
import { CostsBillingParametersCreateModel } from 'services/walletManagement/product/models/CostsBillingParametersCreateModel';

type CostsBillingParametersFormContainerProps = {
    onClose: () => void;
    refetchList: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const CostsBillingParametersFormContainer = ({
    onClose,
    refetchList,
    setToast,
}: CostsBillingParametersFormContainerProps) => {
    const { setFormFieldsErrors } = useFormFieldsError();
    const onSuccess = () => {
        showSuccessToast('Produto de cobrança criado com sucesso', '', setToast);
        onClose();
        refetchList();
        setFormFieldsErrors([]);
    };

    const { mutateAsync, isLoading } = useCreateProductsCostsDefinition(onSuccess, (err) => {
        const { fieldErrors } = mapErrorResponse(err);
        setFormFieldsErrors(fieldErrors ?? []);
        handleOnError(err, setToast);
    });

    const onSubmit = async (values: CostsBillingParametersCreateModel) => {
        await mutateAsync({
            ...values,
        });
    };

    return (
        <Stack>
            <FormProvider
                defaultValues={defaultValuescostsBillingParametersForm}
                validationSchema={costsBillingParametersFormSchema()}
                onSubmit={onSubmit}
            >
                <Fragment>
                    <CostsBillingParametersForm
                        {...{
                            onClose,
                            isLoading,
                        }}
                    />
                </Fragment>
            </FormProvider>
        </Stack>
    );
};
