import { Grid, Stack } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import Toast from 'components/Toast/Toast';
import React, { ReactNode, useState } from 'react';
import { Drawer } from '@uy3/web-components';
import { toastState, ToastType } from 'contexts/apiRequestContext';
import { PayrollCreditNoteHeader } from './PayrollCreditNoteHeader';
import SimulationDetailsContainer from './Steps/SimulationDetails/SimulationDetailsContainer';
import { PayrollAgreementContainer, PersonDebtPurchaseContainer, PersonNewSimulaionContainer, SimulateContainer, SummaryContainer } from './Steps';
import { EditProposalContainer } from './Drawers';
import PayrollCreditNoteTimeline from 'components/Timeline/PayrollCreditNoteTimeline/PayrollCreditNoteTimeline';
import { CreditProductReadModel } from 'services/creditProduct';

type PayrollCreditNoteContainerType = {
    resource: 'CompraDeDivida' | 'NovaSimulacao';
};

export type PayrollCreditNoteSharedProps = {
    onNextStep: () => void;
    onBackStep: () => void;
    setFormValues: React.Dispatch<React.SetStateAction<FieldValues | null>>
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    isCompraDeDivida: boolean;
    formValues: FieldValues;
    step: StepNewSimulationEnum;
    products: CreditProductReadModel[]
};

export enum StepNewSimulationEnum {
    PayrollAgreeement = 1,
    Person = 2,
    Simulate = 3,
    Details = 4,
    Summary = 5
}

export const PayrollCreditNoteContainer: React.FC<PayrollCreditNoteContainerType> = ({
    resource,
}) => {
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const [step, setStep] = useState<StepNewSimulationEnum>(StepNewSimulationEnum.PayrollAgreeement);

    const onNextStep = () => setStep(step + 1);
    const onBackStep = () => setStep(step - 1);
    const onCloseAction = () => setAction(undefined);

    const isCompraDeDivida = resource === 'CompraDeDivida';

    const defaultProps = {
        onBackStep,
        onNextStep,
        setFormValues,
        setToast,
        isCompraDeDivida,
        formValues,
        step, 
        products: (formValues?.products ?? [])

    } as PayrollCreditNoteSharedProps;

    const renderFormByStep = (): { [step: number]: ReactNode } => {
        const renderPersonStep = isCompraDeDivida ? <PersonDebtPurchaseContainer {...defaultProps} /> : <PersonNewSimulaionContainer {...defaultProps} />;
        return {
            [StepNewSimulationEnum.PayrollAgreeement]: <PayrollAgreementContainer {...defaultProps} />,
            [StepNewSimulationEnum.Person]: renderPersonStep,
            [StepNewSimulationEnum.Simulate]: <SimulateContainer {...defaultProps} />,
            [StepNewSimulationEnum.Details]: <SimulationDetailsContainer {...defaultProps} />,
            [StepNewSimulationEnum.Summary]: <SummaryContainer {...defaultProps} />,
        }
    };

    console.log('formvalues: ', {
        ...formValues
    })

    return (
        <Stack spacing={3} mb={20}>
            <Toast toast={toast} setToast={setToast} />
            {step !== StepNewSimulationEnum.Summary && <PayrollCreditNoteHeader
                isCompraDeDivida={isCompraDeDivida}
                step={step}
                handleEditProposal={() => setAction("editProposal")}
            />}

            <Stack spacing={3} paddingRight={3}>
                {step !== StepNewSimulationEnum.Summary &&
                    <Grid mb={20}>
                        <PayrollCreditNoteTimeline currentStep={step} totalSteps={4} />
                    </Grid>}
                {renderFormByStep()[step]}
            </Stack>

            <Drawer {...{
                title: "Editar proposta",
                description: 'Antes de personalizar a proposta, defina os novos parâmetros.',
                anchor: 'right',
                open: action === 'editProposal',
                onClose: onCloseAction,
                children: <EditProposalContainer {...{
                    ...defaultProps,
                    onClose: onCloseAction
                }} />
            }} />
        </Stack>
    );
};
