import { Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon, Typography } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import { MaskedInput } from 'components/Forms/MaskedInput'
import { activeTheme } from 'services/theme'
import { cpfMask, phoneNumberMask } from 'utils'

const palette = activeTheme().palette;

type AddPersonFormProps = {
    onClose: () => void;
    isLoading: boolean; 
}

export const AddPersonForm = ({ onClose, isLoading }: AddPersonFormProps) => {
    return (
        <Stack spacing={2}>
            <Typography>Informações da pessoa</Typography>
            <TextFormField
                name="registrationNumber"
                label="CPF"
                variant="outlined"
                required
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: cpfMask },
                }}
                fullWidth
            />
            <TextFormField
                name='name'
                label='Nome'
                variant='outlined'
                fullWidth
                required
            />
            <TextFormField
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                required
            />
            <TextFormField
                name="phone"
                id="phone"
                label="Telefone Celular"
                variant="outlined"
                placeholder="(00) 00000-0000"
                fullWidth
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: phoneNumberMask },
                }}
                required
            />
            <DatePickerFormField
                label='Data de nascimento'
                name='birthDate'
                fullWidth
                required
                disableFuture
            />
            <TextFormField
                id="nationality"
                name="nationality"
                label="Nacionalidade"
                variant="outlined"
                fullWidth
            />
            <SelectFormField
                label="Sexo"
                name="gender"
                id="gender"
                options={[
                    { label: 'Não informado', value: 'NotInformed' },
                    { label: 'Masculino', value: 'Male' },
                    { label: 'Feminino', value: 'Female' },
                ]}
            />
            <SelectFormField
                name="civilStatus"
                label="Estado Civil"
                required
                options={[
                    { label: 'Não informado', value: '' },
                    { label: 'Casado(a)', value: 'Married' },
                    { label: 'Viúvo(a)', value: 'Widowed' },
                    { label: 'Separado(a)', value: 'Separated' },
                    { label: 'Divorciado(a)', value: 'Divorced' },
                    { label: 'Solteiro(a)', value: 'Single' },
                ]}
            />
            <TextFormField
                id="mothersName"
                label="Nome da mãe"
                name="mothersName"
                variant="outlined"
                fullWidth
            />
            <TextFormField
                id="fathersName"
                label="Nome do pai"
                name="fathersName"
                variant="outlined"
                fullWidth
            />

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon htmlColor={palette.primary.main} />}
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CompleteIcon htmlColor={palette.common.white} />}
                    type="submit"
                    disabled={isLoading}
                >
                    Continuar para dados bancários
                </Button>
            </Stack>
        </Stack>
    )
}
