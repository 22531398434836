import React, { useState } from 'react'
import { WalletsList } from './WalletsList'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { WalletsListHeader } from './WalletsListHeader';
import { Grid, Stack } from '@mui/material';
import { FilterProvider } from 'contexts/filterContext';
import { ApiResponseError, ToastType, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { Drawer, Modal } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { Error } from 'components/Errors/Error';
import { useDeleteWalletByIdAsyncMutation, useGetWalletListToBankSlipCosts } from 'contexts/wallet/walletContext/walletContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { CreateWalletFormContainer } from './CreateWalletForm/CreateWalletFormContainer';

export const WalletsListContainer = () => {
    const { filterValues } = useFilterValues();
    const [stepAction, setStepAction] = useState<{ step: string, data?: unknown } | undefined>(undefined)
    const hasFilters = Object.values(filterValues.filters).length > 0
    const [toast, setToast] = useState<ToastType>(toastState);
    const { handleErrorException } = useApiRequest();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );

    const onCloseStep = () => setStepAction(undefined);

    const { data, isLoading, refetch, error, isFetching } = useGetWalletListToBankSlipCosts({ page, size: rowsPerPage! });

    const onSuccess = () => {
        refetch();
        const title = "Sucesso ao exluir a carteira";
        const description = undefined;
        onCloseStep();
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast);

    const { mutateAsync: deleteMutateAsync } = useDeleteWalletByIdAsyncMutation(onSuccess, onError);

    if (data?.type === 'error') return <Error error={error} />

    const walletSelected = data?.data?.find(item => item?.id === stepAction?.data);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => setPage(page);;

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={3}>
                <WalletsListHeader refresh={refetch} addWallet={() => setStepAction({step: 'addWallet'})}/>
            </Grid>
            <Stack pb={1} pt={-1}>
                <RefreshProgress refreshing={isFetching} />
            </Stack>
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    name: { label: 'Nome', type: 'text' },
                    walletsCode: { label: 'Código convênio', type: 'text', multiple: true },
                    bankAccount: { label: 'Conta', type: 'text' },
                }}
            >
                <ListSkeleton
                    isLoading={isLoading && !hasFilters}
                >
                    <WalletsList {...{
                        page,
                        queryData: data,
                        rowsPerPage,
                        setRowsPerPage: onChangeRowsPerPage,
                        setPage: onChangePage,
                        deleteWalleById: (walletId) => setStepAction({
                            data: walletId as string,
                            step: 'delete'
                        })
                    }} />
                </ListSkeleton>
            </FilterProvider>

            <Modal
                title={`Excluir carteira ${walletSelected?.beneficiaryName} - #${walletSelected?.walletCode}`}
                description='Tem certeza que deseja exluir essa carteira?'
                open={stepAction?.step === 'delete'}
                onClose={onCloseStep}
                sizeModal="large"
            >
                <GenericActionHandler
                    handleSubmit={() => deleteMutateAsync(stepAction?.data as string)}
                    onClose={onCloseStep}
                    isModal
                    titleCancel='Não, cancelar'
                    titleConfirm='Sim, confirmar'
                />
            </Modal>

            <Drawer 
                anchor='right'
                open={stepAction?.step === 'addWallet'}
                title='Adicionar carteira'
                onClose={onCloseStep}
            >
                <CreateWalletFormContainer onClose={onCloseStep} refreshList={refetch} setToast={setToast}/>
            </Drawer>
        </React.Fragment>
    )
}
