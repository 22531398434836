/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { Checkbox, Typography } from '@uy3/web-components';
import { marginDetailsSiape, marginDetailsZetra } from 'components/GenericForms/MarginQueryForm/Summary';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import { activeTheme } from 'services/theme';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { IMarginDetails, mapSiapeWarrantyPublicPayroll, mapZetraWarrantyPublicPayroll } from 'services/creditNote';
import { WarrantySelectedType } from './SimulateContainer';
import { useEffect, useState } from 'react';

type MarginDetailsProps = {
    marginQueryData: ISiapeMarginQueryReadModel | IZetraMarginQueryReadModel;
    isZetra: boolean;
    registrationNumber: string;
    setWarrantySelected: React.Dispatch<React.SetStateAction<WarrantySelectedType[]>>;
    warrantySelected: any[]; 
    isCompraDeDivida: boolean;
}

const theme = activeTheme();

export default function MarginDetails({ marginQueryData, isZetra, registrationNumber, setWarrantySelected, warrantySelected, isCompraDeDivida }: MarginDetailsProps) {
    const [isFirstSelectionDone, setIsFirstSelectionDone] = useState(false);

    const options = isZetra
        ? marginDetailsZetra(marginQueryData as IZetraMarginQueryReadModel)
        : marginDetailsSiape(marginQueryData as ISiapeMarginQueryReadModel);

    const warranty = (isZetra
        ? mapZetraWarrantyPublicPayroll(
            (marginQueryData as IZetraMarginQueryReadModel)?.result?.servidores ?? [], 
            {}, 
            registrationNumber
        )
        : mapSiapeWarrantyPublicPayroll(
            (marginQueryData as ISiapeMarginQueryReadModel)?.result?.VinculoFuncionals ?? [])) as [] || [];

    useEffect(() => {
        if (warranty?.length > 0 && !isFirstSelectionDone && !isCompraDeDivida) {
            //@ts-ignore
            setWarrantySelected([warranty[0]]);
            setIsFirstSelectionDone(true);
        }
    }, [warranty, isFirstSelectionDone, setWarrantySelected]);


    const handleCheckboxChange = (option: IMarginDetails, checked: boolean) => {
        const currentSelected = [...warrantySelected];

        //@ts-ignore
        const methodToSearch = (x: (typeof warranty)[number]) => x.employeeCode === option.employeeCode;

        if (checked) {
            //@ts-ignore
            const findItem = warranty.find(methodToSearch);
            if (findItem) {
                currentSelected.push(findItem);
            }
        } else {
            //@ts-ignore
            const index = currentSelected.findIndex(methodToSearch);
            if (index > -1) {
                currentSelected.splice(index, 1);
            }
        }
        setWarrantySelected(currentSelected);
    };

    return (
        <Grid container columns={10} spacing={2.5}>
            {options?.map((option, index) => {
                const isActive = warrantySelected?.length > 0 && warrantySelected?.find((x: any) => x.governmentDepartmentCode === option.governmentDepartmentCode);
                return (
                    //@ts-ignore
                    <TooltipComponent key={index} {...{ ...option?.tooltipComponent }}>
                        <Grid item xs={3}
                            sx={{
                                border: isActive ? `1px solid ${theme.palette.primary.main}` : '1px solid #373737',
                                padding: '20px',
                                borderRadius: '8px',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleCheckboxChange(option, !isActive)}
                        >
                            <Stack sx={{ display: 'grid', gridTemplateColumns: 'auto 30px', alignItems: 'center' }}>
                                <Typography fontSize='15px' fontWeight='600' mb={2}>
                                    {option.name}
                                </Typography>
                                <Checkbox onChange={(_, checked) => handleCheckboxChange(option, checked)} checked={isActive} />
                            </Stack>

                            {RenderOption('Matrícula', option.employeeCode)}
                            {RenderOption('Data da consulta', option.date)}
                            {RenderOption('Valor da margem', option.vlMargemDisp, true)}

                        </Grid>
                    </TooltipComponent>
                )
            })}
        </Grid>
    )
}


const RenderOption = (title: string, value: string, isBold: boolean = false) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between' pb={1} pt={2} borderBottom='1px solid #EAEAEA'>
            <Typography fontSize='17px' fontWeight='400'>
                {title}
            </Typography>
            <Typography fontSize='17px' fontWeight={isBold ? 'bold' : '400}'} sx={{ color: isBold ? theme.palette.primary.main : 'grey' }} >
                {value}
            </Typography>

        </Stack>
    )
}