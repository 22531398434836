import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { CostsParamsQuery } from "./costs.type";

const url = "/Costs"

const getParamsFromListAndExport = (params: CostsParamsQuery): CostsParamsQuery => {
    let filters: Partial<CostsParamsQuery> = {};
    return {
        ...params,
        ...filters
    }
}

export async function getAllCosts(params: CostsParamsQuery, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: getParamsFromListAndExport(params)
    };
    return await billingAxiosApi.get(`${url}`, config);
}

export async function exportWalletsCosts(params: CostsParamsQuery, token: string) {
    const config = {
        responseType: 'blob' as 'blob',
        headers: { Authorization: `Bearer ${token}` },
        params: getParamsFromListAndExport(params)
    };

    return await billingAxiosApi.get(`${url}/Export`, config);
}

