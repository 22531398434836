import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';

type PublicPayrollFormProps = {
    isReadOnlyMode?: boolean;
};

export function PublicPayrollForm({ isReadOnlyMode = false }: PublicPayrollFormProps) {
    const { watch } = useFormContext();
    const warrantyOption = watch('warrantyOption');
    const isPortability = warrantyOption === 'Portability';

    return (
        <>
            <SelectFormField
                name="warrantyOption"
                label="Opção de operação"
                disabled={isReadOnlyMode}
                required
                options={[
                    { label: 'Margem Livre', value: 'FreeMargin' },
                    { label: 'Portabilidade', value: 'Portability' },
                ]}
            />

            <DatePickerFormField
                label="Data limite de averbação"
                name="warrantyDeadline"
                fullWidth
            />

            {isPortability && !isReadOnlyMode && (
                <TextFormField
                    label="Nº ADE anterior"
                    name="previousAdeNumber"
                    variant="outlined"
                    fullWidth
                    required
                />
            )}
        </>
    );
}
