import { Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultvaluesSelectWarrantyForm,
    validationSchemaSelectWarrantyForm,
} from '../SelectWarranty/SelectWarrantySchema';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import React from 'react';
import { ToastType } from 'contexts/apiRequestContext';
import { IServidores, IZetraMarginQueryReadModel } from 'services/zetra';
import { ShowAvailableMargins } from './ShowAvailableMargins';
import { FieldValues } from 'react-hook-form';
import { fetchAndMapWarrantyData, IWarrantyReadModel, mapSiapeWarrantyPublicPayroll, mapZetraWarrantyPublicPayroll } from 'services/creditNote';
import { useIdentity } from 'contexts/identityContext';

type ListAvailableMarginsProps = {
    marginQueryData: IZetraMarginQueryReadModel | ISiapeMarginQueryReadModel | undefined;
    onCloseAll: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    handleMarginQuery: () => void;
    onConfirm: () => void;
    isZetra: boolean;
    registrationNumber: string;
    isLoadingUpdateMargin: boolean;

};

export const ListAvailableMargins: React.FC<ListAvailableMarginsProps> = ({
    marginQueryData,
    onCloseAll,
    onConfirm,
    handleMarginQuery,
    isZetra,
    isLoadingUpdateMargin,
    setToast,
    registrationNumber
}) => {
    const { setValue, watch } = useFormContext();
    const { token } = useIdentity();
    const watchedWarranty = (watch('warranty') ?? []) as IWarrantyReadModel[];

    const handleSubmitForm = async (values: FieldValues) => {
        if (!isZetra) {
            //#region Siape
            const queryData = marginQueryData as ISiapeMarginQueryReadModel;
            const selectedItems = queryData?.result?.VinculoFuncionals.filter((item) => {
                    const margens = item?.Produtos ?? [];
                    return margens.some((margem) => {
                        return `${item?.CnpjOrgao}-${item?.CodOrgao}-${margem?.CdConvenio}`;
                    });

                }) ?? [];

            const internalId = selectedItems[0]?.CodOrgao;

            await fetchAndMapWarrantyData(
                internalId,
                selectedItems,
                mapSiapeWarrantyPublicPayroll,
                token!,
                watchedWarranty,
                setValue,
                setToast,
                onCloseAll
            );
        } else {
            //#region Zetra
            const queryData = marginQueryData as IZetraMarginQueryReadModel;
            let selectedItems = [] as IServidores[];

            selectedItems =
                queryData?.result?.servidores.filter(({ orgaoCodigo, margens }) => {
                    return margens.map((margem) => {
                        return `${margem?.codigo}-${orgaoCodigo}-${margem?.valorDisponivel}`;
                    });
                }) ?? [];

            const internalId = selectedItems[0]?.orgaoCodigo;

            await fetchAndMapWarrantyData(
                internalId,
                selectedItems,
                (serv) => mapZetraWarrantyPublicPayroll(serv, values, registrationNumber),
                token!,
                watchedWarranty,
                setValue,
                setToast,
                onCloseAll
            );
        }
        onCloseAll()
    };

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultvaluesSelectWarrantyForm}
                validationSchema={validationSchemaSelectWarrantyForm()}
                onSubmit={handleSubmitForm}
            >
                <ShowAvailableMargins
                    {...{
                        isZetra,
                        marginQueryData,
                        onClose: onCloseAll,
                        handleMarginQuery,
                        isLoadingUpdateMargin,
                        onConfirm
                    }}
                />
            </FormProvider>
        </Stack>
    );
};
