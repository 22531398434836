import { Stack } from '@mui/material';
import { Button, CancelIcon, CompleteIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type CreateWalletFormProps = {
    onClose: () => void;
    bankAccountAutocomplete: AutocompleteOptionList; 
    billingProductCodeAutocomplete: AutocompleteOptionList; 
    billingCostsDefinitionCodeAutocomplete: AutocompleteOptionList; 
}

const theme = activeTheme();

export const CreateWalletForm = ({ 
    onClose, 
    bankAccountAutocomplete, 
    billingCostsDefinitionCodeAutocomplete, 
    billingProductCodeAutocomplete 
}: CreateWalletFormProps) => {

    return (
        <Stack spacing={2}>

            <AutocompleteField
                label="Conta bancária"
                name="bankAccountId"
                displayName="bankAccountIdDisplay"
                {...bankAccountAutocomplete}
                required
            />

            <AutocompleteField
                label="Produto de cobrança"
                name="billingProductCode"
                displayName="BillingProductCodeDisplay"
                {...billingProductCodeAutocomplete}
                required
            />

            <AutocompleteField
                label="Parâmetros/Custos de cobrança"
                name="billingCostsDefinitionCode"
                displayName="BillingCostsDefinitionCodeDisplay"
                {...billingCostsDefinitionCodeAutocomplete}
                required
            />

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button variant='outlined' onClick={onClose} startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}>
                    Não, cancelar
                </Button>
                <Button type='submit' variant='contained' startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}>
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
