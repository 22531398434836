import { Stack } from '@mui/material'
import { Button, DownloadIcon, Typography } from '@uy3/web-components'
import { ApiResponseError, ToastType, iconSx, useApiRequest } from 'contexts/apiRequestContext'
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { FC } from 'react';
import { useParams } from 'react-router';
import { downloadIncomeTaxBankAccountById } from 'services/accounts/bankAccount/bankAccount';
import { activeTheme } from 'services/theme'

interface IncomeTaxFileAlertProps {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const theme = activeTheme();
export const IncomeTaxAlert: FC<IncomeTaxFileAlertProps> = ({ setToast }) => {
    const { bankAccountId } = useParams();
    const { startRequest, endRequest, submitting } = useApiRequest();

    const { token } = useIdentity();

    const handleDownloadincomeTax = async () => {
        startRequest();
        await downloadIncomeTaxBankAccountById(bankAccountId!, token!)
            .then((result: any) => {
                const file = new Blob([result?.data], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
                endRequest(true);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                endRequest(false);
                setToast({
                    open: true, 
                    severity: 'error', 
                    title: 'Ops, ocorreu um erro', 
                    description: errorMessage
                })
            })
    }

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
                borderRadius: '8px',
                padding: '15px 23px',
                border: '0.5px solid #BABFD0'
            }}
        >
            <Typography variant='md'>
                <span style={{ fontWeight: 'bold' }}>Informe de rendimentos:</span>&nbsp;
                Confira o saldo disponível da conta ao final do exercício 2024
            </Typography>
            <Button
                variant='contained'
                disabled={submitting}
                onClick={handleDownloadincomeTax}
                startIcon={<DownloadIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
            >
                Baixar agora
            </Button>
        </Stack>
    )
}