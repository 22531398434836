import { Grid } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';

type InfoMessageProps = {
  message?: string;
  title?: string
}

const theme = activeTheme();

const InfoMessage = ({
  message = "Você não tem permissão para consultar esse recurso", 
  title
}: InfoMessageProps) => {
  return (
    <Grid sx={{
      backgroundColor: '#BABFD029',
      color: theme.palette.common.black,
      padding: '16px',
      borderRadius: '8px'
      }}>
      {!!title && <Typography variant='h6' mb={1} fontWeight='bold'>{title}</Typography>}
      <Typography variant='h6'>{message}</Typography>
    </Grid>
  )
}

export default InfoMessage
