import React from 'react';
import { Stack } from '@mui/material';
import { Button, ChartWaveIcon, CompleteIcon, LeftIcon } from '@uy3/web-components';
import CardsSelect, { HandleChangeCardsSelectedProps } from 'components/CardSelect/CardSelect';
import { useFormContext } from 'contexts/formContext';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import { activeTheme } from 'services/theme';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { summarySiape, summaryZetra } from '../Summary';
import { PublicPayrollForm } from 'pages/CreditNote/WarrantyTab';

type ShowAvailableMarginsProps = {
    marginQueryData?: IZetraMarginQueryReadModel | ISiapeMarginQueryReadModel;
    onClose: () => void;
    handleMarginQuery: () => void;
    isZetra: boolean;
    isLoadingUpdateMargin: boolean;
    onConfirm: () => void;
};

const color = activeTheme().palette;

export const ShowAvailableMargins: React.FC<ShowAvailableMarginsProps> = ({
    marginQueryData,
    onClose,
    handleMarginQuery,
    isZetra,
    isLoadingUpdateMargin, 
    onConfirm
}) => {
    const { submitting } = useFormContext();

    const options = isZetra
        ? summaryZetra(marginQueryData as IZetraMarginQueryReadModel)
        : summarySiape(marginQueryData as ISiapeMarginQueryReadModel);

    const BtnSiapLabel = marginQueryData ? 'Atualizar margem' : 'Obter margem';
    const isConfirmButtonDisabled = options?.length === 1 && options?.[0]?.disabled;

    return (
        <Stack spacing={2}>
            {isZetra && <PublicPayrollForm />}
            
            {/* cards select in readonly */}
            <CardsSelect
                onChange={(props: HandleChangeCardsSelectedProps) => {}}
                options={options}
                value={""}
                noOptionSelect
            />

            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<LeftIcon htmlColor={color.primary.main} />}
                    onClick={onClose}
                >
                    Voltar
                </Button>
                <Button
                    variant="outlined"
                    disabled={isLoadingUpdateMargin}
                    startIcon={<ChartWaveIcon htmlColor={color.primary.main} />}
                    onClick={handleMarginQuery}
                >
                    {BtnSiapLabel}
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting || isConfirmButtonDisabled}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    onClick={onConfirm}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
