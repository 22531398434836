import { TenantRequestReadModel } from "services/Partern/TenantRequest/Types/tenantRequestReadModel";
import { number } from "yup";
import { object, string } from "yup"

export const partnerFormSchemaValidations = () => {
    return object().shape({
        legalPersonId: string().required('Parceiro: precisa ser preenchido.').typeError('Parceiro: precisa ser preenchido.'),
        tenantRequestBaas: object().shape({
            billingDate: string().notRequired().nullable(),
            setupValue: number().notRequired().nullable(),
            monthlyValue: number().notRequired().nullable(),
            feePixValue: number().notRequired().nullable(),
            clientBillingPix: number().notRequired().nullable(),
            spreadDivisionPixValue: number().notRequired().nullable(),
            cashInPixValue: number().notRequired().nullable(),
            cashOutPixValue: number().notRequired().nullable(),
            spreadCashInPixValue: number().notRequired().nullable(),
            spreadCashOutPixValue: number().notRequired().nullable(),
            feeTedValue: number().notRequired().nullable(),
            clientBillingTed: number().notRequired().nullable(),
            spreadDivisionTedValue: number().notRequired().nullable(),
            cashInTedValue: number().notRequired().nullable(),
            cashOutTedValue: number().notRequired().nullable(),
            spreadCashInTedValue: number().notRequired().nullable(),
            spreadCashOutTedValue: number().notRequired().nullable(),
            feePaymentAccountValue: number().notRequired().nullable(),
            clientBillingPaymentAcountValue: number().notRequired().nullable(),
            spreadDivisionPaymentAcountValue: number().notRequired().nullable(),
            feeEscrowValue: number().notRequired().nullable(),
            clientBillingEscrowValue: number().notRequired().nullable(),
            spreadDivisionEscrowValue: number().notRequired().nullable()
        }),
        tenantRequestCaas: object().shape({
            newSignatureFormalizationValue: number().notRequired().nullable(),
            recurrenceFormalizationValue: number().notRequired().nullable(),
            setupActivationCostValue: number().notRequired().nullable(),
            whiteLabelActivationCostValue: number().notRequired().nullable(),
            monthlyActivationCostValue: number().notRequired().nullable(),
            powerBiActivationCostValue: number().notRequired().nullable(),
            chatBotSetupValue: number().notRequired().nullable(),
            chatBotCostContractValue: number().notRequired().nullable(),
            chatBotMonthlyValue: number().notRequired().nullable(),
            name: number().notRequired().nullable(),
            rateCEFInquiryValue: number().notRequired().nullable(),
            rateCEFEndorsementValue: number().notRequired().nullable(),
            rateCEFAmortizationValue: number().notRequired().nullable(),
            rateCEFLiquidationValue: number().notRequired().nullable(),
            rateCEFCancellationValue: number().notRequired().nullable(),
            zetraInquiryValue: number().notRequired().nullable(),
            zetraLiquidationValue: number().notRequired().nullable(),
            vehicleLienValue: number().notRequired().nullable(),
            bookeepingValue: number().notRequired().nullable(),
            assignmentReconciliationValue: number().notRequired().nullable(),
            otherRatesValue: number().notRequired().nullable()
        }),
        tenantRequestBilling: object().shape({
            methodCollectionValue: number().notRequired().nullable(),
            billingFrequecyValue: number().notRequired().nullable(),
            registrationColtValue: number().notRequired().nullable(),
            registrationSpreadValue: number().notRequired().nullable(),
            registrationTotalValue: number().notRequired().nullable(),
            liquidationColtValue: number().notRequired().nullable(),
            liquidationSpreadValue: number().notRequired().nullable(),
            liquidationTotalValueValue: number().notRequired().nullable(),
            deletionColtValue: number().notRequired().nullable(),
            deletionSpreadValue: number().notRequired().nullable(),
            deletionTotalValueValue: number().notRequired().nullable(),
            deductionColtValue: number().notRequired().nullable(),
            deductionSpreadValue: number().notRequired().nullable(),
            deductionTotalValueValue: number().notRequired().nullable(),
            paymentDateChangeColtValue: number().notRequired().nullable(),
            paymentDateChangeSpreadValue: number().notRequired().nullable(),
            paymentDateChangeTotalValueValue: number().notRequired().nullable(),
            protestColtValue: number().notRequired().nullable(),
            protestSpreadValue: number().notRequired().nullable(),
            protestTotalValueValue: number().notRequired().nullable(),
            withdrawProtestColtValue: number().notRequired().nullable(),
            withdrawProtestSpreadValue: number().notRequired().nullable(),
            withdrawProtestTotalValueValue: number().notRequired().nullable(),
            protestRemovalColtValue: number().notRequired().nullable(),
            protestRemovalSpreadValue: number().notRequired().nullable(),
            protestRemovalTotalValueValue: number().notRequired().nullable(),
            notarySettlementColtValue: number().notRequired().nullable(),
            notarySettlementSpreadValue: number().notRequired().nullable(),
            notarySettlementTotalValueValue: number().notRequired().nullable(),
            registrationTransferFeeColtValue: number().notRequired().nullable(),
            registrationTransferFeeSpreadValue: number().notRequired().nullable(),
            registrationTransferFeeTotalValueValue: number().notRequired().nullable(),
            lowTransferCostValue: number().notRequired().nullable(),
            lowTransferCostSpreadValue: number().notRequired().nullable(),
            lowTransferCostTotalValueValue: number().notRequired().nullable(),
            elapseTimeCostValue: number().notRequired().nullable(),
            elapseTimeSpreadValue: number().notRequired().nullable(),
            elapseTimeTotalValueValue: number().notRequired().nullable(),
            elapseTimeSpreadDivisionValue: number().notRequired().nullable(),
        }),
    });
}

export const partnerFormDefaultValues = (tenantRequestData: TenantRequestReadModel | null) => {
    const legalPerson = tenantRequestData?.legalPerson;
    const tenantRequestBaas = tenantRequestData?.tenantRequestBaas;
    const tenantRequestCaas = tenantRequestData?.tenantRequestCaas;
    const tenantRequestBilling = tenantRequestData?.tenantRequestBilling;
    return {
        legalPersonId: legalPerson?.id ?? null,
        legalPersonIdDisplay: legalPerson?.companyName ?? legalPerson?.name ?? null,
        tenantRequestBaas: {
            billingDate: tenantRequestBaas?.billingDate ?? new Date().toISOString(),
            setupValue: tenantRequestBaas?.setupValue ?? 0,
            monthlyValue: tenantRequestBaas?.monthlyValue ?? 0,
            feePixValue: tenantRequestBaas?.feePixValue ?? 0,
            clientBillingPix: tenantRequestBaas?.clientBillingPix ?? 0,
            clientBillingTed: tenantRequestBaas?.clientBillingTed ?? 0,
            clientBillingPaymentAcountValue: tenantRequestBaas?.clientBillingPaymentAcountValue ?? 0,
            clientBillingEscrowValue: tenantRequestBaas?.clientBillingEscrowValue ?? 0,
            spreadDivisionPixValue: tenantRequestBaas?.spreadDivisionPixValue ?? 0,
            cashInPixValue: tenantRequestBaas?.cashInPixValue ?? 0,
            cashOutPixValue: tenantRequestBaas?.cashOutPixValue ?? 0,
            spreadCashInPixValue: tenantRequestBaas?.spreadCashInPixValue ?? 0,
            spreadCashOutPixValue: tenantRequestBaas?.spreadCashOutPixValue ?? 0,
            feeTedValue: tenantRequestBaas?.feeTedValue ?? 0,
            spreadDivisionTedValue: tenantRequestBaas?.spreadDivisionTedValue ?? 0,
            cashInTedValue: tenantRequestBaas?.cashInTedValue ?? 0,
            cashOutTedValue: tenantRequestBaas?.cashOutTedValue ?? 0,
            spreadCashInTedValue: tenantRequestBaas?.spreadCashInTedValue ?? 0,
            spreadCashOutTedValue: tenantRequestBaas?.spreadCashOutTedValue ?? 0,
            feePaymentAccountValue: tenantRequestBaas?.feePaymentAccountValue ?? 0,
            spreadDivisionPaymentAcountValue: tenantRequestBaas?.spreadDivisionPaymentAcountValue ?? 0,
            feeEscrowValue: tenantRequestBaas?.feeEscrowValue ?? 0,
            spreadDivisionEscrowValue: tenantRequestBaas?.spreadDivisionEscrowValue ?? 0
        },
        tenantRequestCaas: {
            newSignatureFormalizationValue: tenantRequestCaas?.newSignatureFormalizationValue ?? 0,
            recurrenceFormalizationValue: tenantRequestCaas?.recurrenceFormalizationValue ?? 0,
            setupActivationCostValue: tenantRequestCaas?.setupActivationCostValue ?? 0,
            whiteLabelActivationCostValue: tenantRequestCaas?.whiteLabelActivationCostValue ?? 0,
            monthlyActivationCostValue: tenantRequestCaas?.monthlyActivationCostValue ?? 0,
            powerBiActivationCostValue: tenantRequestCaas?.powerBiActivationCostValue ?? 0,
            chatBotSetupValue: tenantRequestCaas?.chatBotSetupValue ?? 0,
            chatBotCostContractValue: tenantRequestCaas?.chatBotCostContractValue ?? 0,
            chatBotMonthlyValue: tenantRequestCaas?.chatBotMonthlyValue ?? 0,
            rateCEFInquiryValue: tenantRequestCaas?.rateCEFInquiryValue ?? 0,
            rateCEFEndorsementValue: tenantRequestCaas?.rateCEFEndorsementValue ?? 0,
            rateCEFAmortizationValue: tenantRequestCaas?.rateCEFAmortizationValue ?? 0,
            rateCEFLiquidationValue: tenantRequestCaas?.rateCEFLiquidationValue ?? 0,
            rateCEFCancellationValue: tenantRequestCaas?.rateCEFCancellationValue ?? 0,
            zetraInquiryValue: tenantRequestCaas?.zetraInquiryValue ?? 0,
            zetraLiquidationValue: tenantRequestCaas?.zetraLiquidationValue ?? 0,
            vehicleLienValue: tenantRequestCaas?.vehicleLienValue ?? 0,
            bookeepingValue: tenantRequestCaas?.bookeepingValue ?? 0,
            assignmentReconciliationValue: tenantRequestCaas?.assignmentReconciliationValue ?? 0,
            otherRatesValue: tenantRequestCaas?.otherRatesValue ?? 0
        },
        tenantRequestBilling: {
            methodCollectionValue: tenantRequestBilling?.methodCollectionValue ?? 0,
            billingFrequecyValue: tenantRequestBilling?.billingFrequecyValue ?? 0,
            registrationColtValue: tenantRequestBilling?.registrationColtValue ?? 0,
            registrationSpreadValue: tenantRequestBilling?.registrationSpreadValue ?? 0,
            registrationTotalValue: tenantRequestBilling?.registrationTotalValue ?? 0,
            liquidationColtValue: tenantRequestBilling?.liquidationColtValue ?? 0,
            liquidationSpreadValue: tenantRequestBilling?.liquidationSpreadValue ?? 0,
            liquidationTotalValueValue: tenantRequestBilling?.liquidationTotalValueValue ?? 0,
            deletionColtValue: tenantRequestBilling?.deletionColtValue ?? 0,
            deletionSpreadValue: tenantRequestBilling?.deletionSpreadValue ?? 0,
            deletionTotalValueValue: tenantRequestBilling?.deletionTotalValueValue ?? 0,
            deductionColtValue: tenantRequestBilling?.deductionColtValue ?? 0,
            deductionSpreadValue: tenantRequestBilling?.deductionSpreadValue ?? 0,
            deductionTotalValueValue: tenantRequestBilling?.deductionTotalValueValue ?? 0,
            paymentDateChangeColtValue: tenantRequestBilling?.paymentDateChangeColtValue ?? 0,
            paymentDateChangeSpreadValue: tenantRequestBilling?.paymentDateChangeSpreadValue ?? 0,
            paymentDateChangeTotalValueValue: tenantRequestBilling?.paymentDateChangeTotalValueValue ?? 0,
            protestColtValue: tenantRequestBilling?.protestColtValue ?? 0,
            protestSpreadValue: tenantRequestBilling?.protestSpreadValue ?? 0,
            protestTotalValueValue: tenantRequestBilling?.protestTotalValueValue ?? 0,
            withdrawProtestColtValue: tenantRequestBilling?.withdrawProtestColtValue ?? 0,
            withdrawProtestSpreadValue: tenantRequestBilling?.withdrawProtestSpreadValue ?? 0,
            withdrawProtestTotalValueValue: tenantRequestBilling?.withdrawProtestTotalValueValue ?? 0,
            protestRemovalColtValue: tenantRequestBilling?.protestRemovalColtValue ?? 0,
            protestRemovalSpreadValue: tenantRequestBilling?.protestRemovalSpreadValue ?? 0,
            protestRemovalTotalValueValue: tenantRequestBilling?.protestRemovalTotalValueValue ?? 0,
            notarySettlementColtValue: tenantRequestBilling?.notarySettlementColtValue ?? 0,
            notarySettlementSpreadValue: tenantRequestBilling?.notarySettlementSpreadValue ?? 0,
            notarySettlementTotalValueValue: tenantRequestBilling?.notarySettlementTotalValueValue ?? 0,
            registrationTransferFeeColtValue: tenantRequestBilling?.registrationTransferFeeColtValue ?? 0,
            registrationTransferFeeSpreadValue: tenantRequestBilling?.registrationTransferFeeSpreadValue ?? 0,
            registrationTransferFeeTotalValueValue: tenantRequestBilling?.registrationTransferFeeTotalValueValue ?? 0,
            lowTransferCostValue: tenantRequestBilling?.lowTransferCostValue ?? 0,
            lowTransferCostSpreadValue: tenantRequestBilling?.lowTransferCostSpreadValue ?? 0,
            lowTransferCostTotalValueValue: tenantRequestBilling?.lowTransferCostTotalValueValue ?? 0,
            elapseTimeCostValue: tenantRequestBilling?.elapseTimeCostValue ?? 0,
            elapseTimeSpreadValue: tenantRequestBilling?.elapseTimeSpreadValue ?? 0,
            elapseTimeTotalValueValue: tenantRequestBilling?.elapseTimeTotalValueValue ?? 0,
            elapseTimeSpreadDivisionValue: tenantRequestBilling?.elapseTimeSpreadDivisionValue ?? 0,
        }
    }
}