import { Grid, IconButton, Stack } from '@mui/material';
import {  DeleteIcon, LeftIcon, SaveIcon, Typography, UpdateIcon,  } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useNavigate } from 'react-router';
import { activeTheme } from 'services/theme';

type PartnerFormHeaderProps = {
  enableOnSubmit: boolean;
  isNew: boolean;
  handleDelete: () => void;
  refetch: () => void;
};

const theme = activeTheme();

export const PartnerFormHeader = ({enableOnSubmit, isNew, handleDelete, refetch}: PartnerFormHeaderProps) => {
  const { watch } = useFormContext();
  const navigate = useNavigate(); 
  const ownerUser = watch('ownerUser.userIdDisplay')
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={2}>
        <IconButton onClick={() => navigate('/parceiros/solicitacoes')}>
          <LeftIcon sx={iconSx} />
        </IconButton>
        <Grid>
          <Typography fontSize="23.51px" fontWeight="800">
            {watch('legalPersonIdDisplay') ?? 'Novo parceiro comercial'}
          </Typography>
          {!!ownerUser && <Typography fontSize="14px">
            Comercial responsável: {ownerUser}
          </Typography>}
        </Grid>
      </Stack>

      <Actions
        buttonsActionsList={[
          {
            label: 'Excluir',
            enable: !isNew,
            icon: <DeleteIcon sx={iconSx} htmlColor={enableOnSubmit ? theme.palette.common.black : theme.palette.grey["500"]}/>,
            action: handleDelete
          },
          {
            label: 'Atualizar',
            enable: !isNew,
            icon: <UpdateIcon sx={iconSx} htmlColor={enableOnSubmit ? theme.palette.common.black : theme.palette.grey["500"]}/>,
            action: refetch
          },
          {
            label: 'Salvar',
            enable: true,
            icon: <SaveIcon sx={iconSx} htmlColor={enableOnSubmit ? theme.palette.common.black : theme.palette.grey["500"]}/>,
            disabled: !enableOnSubmit,
            type: 'submit'
          },
        ]}
        numberOfButtons={3}
      />
    </Stack>
  );
};
