import { activeConfig } from "services/config";

const config = activeConfig();

export const labelCompraDeDividaConfig = config?.FORM_FIELDS?.optionsNewSimulation?.compraDeDivida?.Label.Label ?? 'Novo + Compra de divida';
export const labelConsignadoPublicoConfig = config?.FORM_FIELDS?.optionsNewSimulation?.consignadoPublico?.Label.Label ?? 'Consignado público';


export const pathNewSimulationConsignedConfig = `/ccb/nova-simulacao/${formatRoute(labelConsignadoPublicoConfig)}`
export const pathCompraDeDividaConfig = formatRoute(labelCompraDeDividaConfig);


function formatRoute(value:string) {
    return value?.toLowerCase().replaceAll(" ", '-')
}