import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultValuesUpdateConsignment,
    validationSchemaUpdateConsignment,
} from './UpdateConsignmentSchema';
import UpdateConsignmentForm from './UpdateConsignmentForm';
import { useConsignmentForPurchase } from 'contexts/zetra';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';

type UpdateZetraProps = {
    openDrawer: boolean;
    onCloseDrawer: () => void;
    registrationNumber: string;
    employeeCode?: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetch: () => void;
    resource?: "Zetra" | "Siape", 
    title?: string;
    description?: string;
};

export default function UpdateConsignmentForPurchase({
    openDrawer,
    onCloseDrawer,
    registrationNumber,
    employeeCode, 
    setToast,
    refetch,
    resource = 'Zetra',
    title = "Consultar consignações passíveis",
    description = "Consulte as consignações passíveis de serem envolvidas em processos de compra."
}: UpdateZetraProps) {
    const { watch } = useFormContext();
    const creditProductId = watch('productId');

    const onSucess = () => {
        showSuccessToast(
            'Consigação atualizada com sucesso!',
            'Ótimo! Agora você pode visualizar o novo registro.',
            setToast
        );
        onCloseDrawer();
    };

    const { mutateUpdateZetra, isLoading } = useConsignmentForPurchase(
        onSucess, 
        (err: ApiResponseError) => handleOnError(err, setToast),
        resource
    );

    const handleOnSubmit = async (data: FieldValues) => {
        const payload = {
            ...data,
            creditProductId,
        };

        await mutateUpdateZetra(payload);
        refetch();
    };

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            title={title}
            description={description}
            toggleDrawer
            onClose={onCloseDrawer}
        >
            <FormProvider
                validationSchema={validationSchemaUpdateConsignment}
                defaultValues={defaultValuesUpdateConsignment(registrationNumber, employeeCode)}
                onSubmit={handleOnSubmit}
            >
                <UpdateConsignmentForm onCloseDrawer={onCloseDrawer} isDisableAction={isLoading}/>
            </FormProvider>
        </Drawer>
    );
}
