import AvatarComponent from "components/Avatar/Avatar";

type RenderAvatarsNamesType = {
    list: ListNamesType[];
    title?: string;
    maxAvatars?: number; 
    renderType?: "AvatarGroup" | "AvatarAction"; 
}

export type ListNamesType = {
    id: string;
    name: string;
    tenant: string;
    onClick?: () => void;
}

export const RenderAvatarsName = ({ list, title = "Credores", maxAvatars = 3, renderType = 'AvatarGroup'}: RenderAvatarsNamesType) => {
    return (
        <AvatarComponent
            optionsList={list}
            title={title}
            maxAvatars={maxAvatars}
            renderType={renderType}
        />
    );
};