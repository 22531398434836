/* eslint-disable react-hooks/exhaustive-deps */
import { ExpandMore, ReportProblemOutlined } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { CheckCircleIcon, RightIcon, Typography } from '@uy3/web-components';
import { AlertWithAction } from 'components/AlertWithAction/AlertWithAction';
import { iconSx } from 'contexts/apiRequestContext';
import { maskCurrencyInCents, validationInformation } from 'helpers';
import React from 'react';
import { activeTheme } from 'services/theme';
import { IResumoWithContractsFull } from 'services/zetra';
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from './BankAccounts.style';
import { ShowInformationTypography } from '../../Generics';
import { ShowBankAccountDebtPurchase } from './ShowBankAccountDebtPurchase';

type BankAccountsProps = {
    contracts: IResumoWithContractsFull[];
    handleEditOrCreateBankAccountContract: (resource: 'create' | 'edit', index: number) => void;
};

const theme = activeTheme();

export const BankAccounts = (props: BankAccountsProps) => {
    return <AccordionBankAccount {...props} />;
};

const AccordionBankAccount: React.FC<BankAccountsProps> = ({
    contracts,
    handleEditOrCreateBankAccountContract,
}) => {
    return (
        <Stack spacing={2}>
            {contracts?.map((item, index) => {
                const id = item?.adeIdentificador;
                const bankAccount = item?.bankAccount;
                const hasBankAccount = bankAccount !== null && bankAccount !== undefined;

                return (
                    <CustomAccordion key={id}>
                        <CustomAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: 20 }} />}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {hasBankAccount ? (
                                    <CheckCircleIcon
                                        sx={iconSx}
                                        htmlColor={theme.palette.success.main}
                                    />
                                ) : (
                                    <ReportProblemOutlined
                                        sx={iconSx}
                                        htmlColor={theme.palette.warning.main}
                                    />
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center',
                                        pr: 2,
                                    }}
                                >
                                    <Typography
                                        fontSize="16px"
                                        fontWeight="bold"
                                    >{`ADE: ${item?.adeNumero}`}</Typography>
                                </Box>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <Stack direction="row" alignItems="center" pr={8} gap={7}>
                                    <ShowInformationTypography
                                        title="Consignatária"
                                        value={item?.consignataria}
                                    />
                                    <ShowInformationTypography
                                        title="Valor da Parcela"
                                        value={maskCurrencyInCents(item?.valorParcela)}
                                    />
                                    <ShowInformationTypography
                                        title="Prazo:"
                                        value={validationInformation(item?.prazo.toString())}
                                    />
                                    <ShowInformationTypography
                                        title="Pagas"
                                        value={validationInformation(item?.pagas.toString())}
                                    />
                                </Stack>
                            </Stack>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                            <Typography fontSize="17px" fontWeight="800">
                                Dados da dívida
                            </Typography>
                            <Typography fontSize="12px" fontWeight="400">
                                Antes de personalizar a proposta, analise os parâmetros.{' '}
                            </Typography>

                            <Grid
                                container
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 6, sm: 12, md: 16 }}
                                pt={3}
                            >
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Situação"
                                        value={item?.situacao}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Identificador"
                                        value={item?.adeIdentificador}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="ADE"
                                        value={validationInformation(item?.adeNumero.toString())}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Cod. Consignatária"
                                        value={item?.consignatariaCodigo}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Serviço"
                                        value={item?.servico}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Código verba"
                                        value={item?.codVerba}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <ShowInformationTypography
                                        title="Data reserva"
                                        value={new Date(item?.dataReserva).toLocaleDateString(
                                            'pt-br'
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Stack spacing={2} mt={3}>
                                {hasBankAccount && (
                                    <Stack spacing={2} borderTop='1px solid #E1E1E1' pt={2}>
                                        <ShowBankAccountDebtPurchase bankAccount={bankAccount}/>
                                    </Stack>
                                )}
                                <ShowAlertInformation
                                    hasBankAccount={hasBankAccount}
                                    onClick={() => handleEditOrCreateBankAccountContract(hasBankAccount ? "edit" : "create", index)}
                                />
                            </Stack>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                );
            })}
        </Stack>
    );
};

const ShowAlertInformation = ({
    hasBankAccount,
    onClick,
}: {
    hasBankAccount: boolean;
    onClick: () => void;
}) => {
    const message = hasBankAccount
        ? 'Dados bancários cadastrados. Caso seja necessário você pode editar os dados'
        : 'Você ainda não informou os dados bancários para pagamento';
    return (
        <AlertWithAction
            message={message}
            severityType={hasBankAccount ? 'Success' : 'Warning'}
            action={{
                label: hasBankAccount
                    ? 'Editar dados para pagamento'
                    : 'Informar dados para pagamento',
                Icon: RightIcon,
                onClick,
            }}
        />
    );
};