import { Stack } from '@mui/material';
import { Drawer, Typography } from '@uy3/web-components';
import React, { lazy, Suspense, useState } from 'react';
import { FreeMargin } from './FreeMargin';
import { FormProvider } from 'contexts/formContext';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import { SimualtionDetailsSkeleton } from 'components/Skeleton/SimualtionDetailsSkeleton';
import { FieldValues } from 'react-hook-form';
import { PayrollCreditNoteSharedProps } from '../../PayrollCreditNoteContainer';
import { simulateDefaultValues, simulateSchema } from './SimulateSchema';
import { useAmortizationSimulationMutate } from 'contexts/creditNote/creditContext';
import { ApiResponseError, showSuccessToast } from 'contexts/apiRequestContext';
import { handleOnErrorAmortizationSimulation } from 'helpers/Exceptions/Exceptions';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useCreditProdutList } from 'contexts/creditProductContext';
import { applyFilteredQueryProduct, formatCurrencyInCents, mapDescriptionFreeMargin, onlyNumbers, toIsoStringWithTimezone } from 'helpers';
import { CreditProductReadModel } from 'services/creditProduct';
import { useSiapeMarginQuery } from 'contexts/datasetContext';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { useZetraMarginQuery } from 'contexts/zetra';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import { BaseContainerStepForm } from '../Generics';
import { AmortizationReadModel, PublicPayrollCreateModel } from 'services/creditNote';
import MarginDetails from './MarginDetails';
import { MarginQueryToNewSimulation } from 'components/GenericForms/MarginQueryForm/NewSimulationMarginQuery/MarginQueryToNewSimulation';
import { EPeriodicity } from 'utils';
import { activeConfig } from 'services/config';

const Simulate = lazy(() => import('./Simulate'));

type SimulateContainerProps = {} & PayrollCreditNoteSharedProps;

export type WarrantySelectedType = {
  marginValue: number
} & PublicPayrollCreateModel; 

const config = activeConfig();

export const SimulateContainer = (props: SimulateContainerProps) => {
  const [action, setAction] = useState<string | undefined>(undefined);
  const [warrantySelected, setWarrantySelected] = useState<WarrantySelectedType[]>([]);

  const { isCompraDeDivida, setToast, setFormValues, onNextStep, formValues } = props;
  const employeeCode = formValues.employeeCode;
  const registrationNumber = onlyNumbers(formValues?.registrationNumber ?? "");
  const warrantyRegistrationOffice = formValues?.payrollAgreement?.warrantyRegistrationOffice;
  const isZetra = String(warrantyRegistrationOffice ?? "").toLowerCase() === 'zetra';
  const supportedOffices = ["zetra", "siape"];
  const isSupoortedOffice = supportedOffices.includes(warrantyRegistrationOffice?.toLowerCase());

  const { setFormFieldsErrors } = useFormFieldsError();
  const { data: permissionData } = useUserPermissionData();
  const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionData, 'CreditNote');

  const { data: productData, isLoading: isLoadingProduct } = useCreditProdutList(applyFilteredQueryProduct(formValues?.payrollAgreementCode, isCompraDeDivida));

  const { data: siapeMarginQuery } = useSiapeMarginQuery({
    registrationNumber: isZetra ? undefined : registrationNumber,
  });

  const { zetraMarginQueryData } = useZetraMarginQuery(
    { registrationNumber, employeeCode },
    isZetra,
    setToast
  );

  const zetraData = zetraMarginQueryData?.[0] as IZetraMarginQueryReadModel;
  const siapeData = siapeMarginQuery?.[0] as ISiapeMarginQueryReadModel;

  const marginQueryData = isZetra ? zetraData : siapeData;

  const products = (productData?.data ?? []) as CreditProductReadModel[];

  const onSuccess = (response: any) => {
    var productsFiltered: any[] = [];
    const allItemsHasError = (response as any[])?.every(x => isCodeError(x));
    if (allItemsHasError) {
      return response?.forEach((data: ApiResponseError) => onErrorSimulation(data));
    }

    const responseFiltered = response?.filter((res: any) => !isCodeError(res)) as AmortizationReadModel[];
    responseFiltered?.forEach((res: any) => {
      const product = products.find(x => x.id === res?.productId);
      productsFiltered.push(product);
    });

    setFormValues((prev) => ({
      ...prev,
      simulationResponse: responseFiltered,
      products: productsFiltered
    }));

    setFormFieldsErrors([]);
    showSuccessToast('Sucesso ao realizar a simulação', '', setToast);
    onNextStep();
  };

  const onErrorSimulation = (error: ApiResponseError) => handleOnErrorAmortizationSimulation(error, setFormFieldsErrors, setToast);
  const { mutateAsync, isLoading: isLoadingSimulation } = useAmortizationSimulationMutate(onSuccess, onErrorSimulation);

  const onSubmit = (values: FieldValues) => {
    const amortization = values?.amortization;
    const totalInstallmentValue = formValues?.contracts?.map((c: any) => c?.valorParcela)?.reduce((acc: any, crr: any) => acc + crr, 0) * 100;
    const valueMarginReserve = amortization?.valueMarginReserve;

    saveInformations(amortization, setFormValues);

    if (!isCompraDeDivida && warrantySelected?.length === 0) {
      return setToast({
        open: true,
        severity: 'info',
        title: 'É necessário selecionar ao menos 1 margem livre para seguir.'
      });
    } else if (isCompraDeDivida && warrantySelected?.length === 0 && (valueMarginReserve > totalInstallmentValue)) {
      return setToast({
        open: true,
        severity: 'info',
        title: "Selecione ao menos 1 margem livre para seguir",
        description: `O valor informado de ${formatCurrencyInCents(valueMarginReserve)} excede a soma dos valores das parcelas dos contratos selecionados, que é de ${formatCurrencyInCents(totalInstallmentValue)}.`
      });
    }

    //@ts-ignore
    const models: QueryAmortizationCreateModel[] = products.map((product) => {
      return {
        personId: null,
        legalPerson: false,
        registrationNumber: formValues?.registrationNumber,
        productId: product.id,
        product,
        amortization: amortizationMapper(product, amortization, formValues, isCompraDeDivida)
      }
    });

    const warrantySelectedMapped = (warrantySelected ?? [])?.map((warranty) => {
      return {
        ...warranty,
        paymentAmountInCents: valueMarginReserve
      }
    });

    const warranty = [...(formValues?.warrantyConsignment ?? []), ...(warrantySelectedMapped ?? [])];

    setFormValues((prev) => ({
      ...prev,
      modelSimulation: models,
      warranty
    }));

    mutateAsync(models);
  };

  const onClose = () => setAction(undefined);

  // @ts-ignore
  const { title, description } = mapDescriptionFreeMargin({
    zetraMarginQueryData: zetraData,
    siapeMarginQuery: siapeData,
    hasPermission: hasPermissionToReadDataset,
    warrantyRegistrationOffice,
    registrationNumber
  });


  return (
    <Suspense fallback={<SimualtionDetailsSkeleton />}>
      <FormProvider
        {...{
          defaultValues: simulateDefaultValues(formValues),
          validationSchema: simulateSchema(),
          onSubmit
        }}
      >
        <BaseContainerStepForm {...{ ...props, disabled: (isLoadingSimulation || isLoadingProduct) }}>
          <Stack spacing={4}>
            <Typography fontSize="23px" fontWeight={800}>
              {isCompraDeDivida ? 'Agora que selecionou os contratos, como deseja simular o calculo?' : 'Como deseja simular o cálculo?'}
            </Typography>

            <FreeMargin
              title={title}
              description={description}
              enableMarginQuery={hasPermissionToReadDataset && isSupoortedOffice}
              marginQueryHandle={() => setAction('marginQuery')}
            />

            <MarginDetails
              marginQueryData={marginQueryData}
              isZetra={isZetra}
              registrationNumber={registrationNumber}
              warrantySelected={warrantySelected}
              setWarrantySelected={setWarrantySelected}
              isCompraDeDivida={isCompraDeDivida}
            />

            <Simulate
              isCompraDeDivida={isCompraDeDivida}
              formValues={formValues}
              warrantySelected={warrantySelected}
              hasNoProducts={products?.length === 0 && isLoadingProduct === false}
            />

            <Drawer
              anchor="right"
              open={action === 'marginQuery'}
              title="Consultar margem"
              onClose={onClose}
            >
              <MarginQueryToNewSimulation
                onClose={onClose}
                setToast={setToast}
                employeeCode={employeeCode}
                registrationNumber={registrationNumber}
                isZetra={isZetra}
              />
            </Drawer>
          </Stack>
        </BaseContainerStepForm>
      </FormProvider>
    </Suspense>
  );
};

export const amortizationMapper = (
  product: CreditProductReadModel,
  values: FieldValues,
  formValues: FieldValues,
  isCompraDeDivida: boolean
) => {
  const isPublicPayrollLoan = product.categoryName.toLowerCase() === 'consignado público';
  const totalValueOutstandingBalance = formValues?.totalvalueOutstandingBalance; // soma total dos contratos selecionados
  const requestedAmount = values?.valueMarginReserve; // valor preenchido no formulário

  const base = {
    amortizationType: product.amortizationType,
    startDate: toIsoStringWithTimezone(new Date(values?.startDate)),
    firstPaymentDate: isPublicPayrollLoan ?
      toIsoStringWithTimezone(new Date(values?.firstPaymentDate)) :
      toIsoStringWithTimezone(new Date(new Date(values?.firstPaymentDate).setDate(config.SET_FIRST_PAYMENT_ON_DAY))),
    apr: product.maximumInterestRate,
    includePaymentFixedCosts: true,
    financeTaxExempted: product?.allowFinanceTaxExemption
  } as const;

  return {
    ...base,
    requestedAmount: isPublicPayrollLoan ? requestedAmount : totalValueOutstandingBalance ?? requestedAmount,
    calculateByValueType: isPublicPayrollLoan ? "Payment" : "Liquid",
    numberOfPayments: isPublicPayrollLoan ? product?.maximumAmortizationPeriods ?? product?.minimumAmortizationPeriods : 1,
    calculationType: isPublicPayrollLoan ? "V360Meses" : "V360DiasCorridos",
    paymentPeriodicity: {
      every: isPublicPayrollLoan ? 1 : 7,
      periodicity: EPeriodicity[isPublicPayrollLoan ? EPeriodicity.Monthly : EPeriodicity.Daily],
    }
  };
};

const saveInformations = (
  values: FieldValues,
  setFormValues: React.Dispatch<React.SetStateAction<FieldValues | null>>
) => {
  const data = {
    amortization: {
      valueMarginReserve: values?.valueMarginReserve,
      startDate: values?.startDate,
      firstPaymentDate: values?.firstPaymentDate,
      numberOfPayments: values?.numberOfPayments,
    },
  };
  setFormValues((prev) => ({ ...prev, ...data }));
};

export const isCodeError = (response: any): boolean => (response?.id === null || response?.id === undefined);