import { IconButton, Stack } from '@mui/material'
import { ArrowLeftIcon, LiquidateIcon, Typography } from '@uy3/web-components'
import GetLabelForField from 'components/GetLabelForField'
import { OptionCards } from 'components/OptionCards/OptionCards'
import { pathNewSimulationConsignedConfig, pathCompraDeDividaConfig, labelConsignadoPublicoConfig } from 'constants/labelConfig'
import { useNavigate } from 'react-router'

export const SelectPayrollCreditNote = () => {
  const navigate = useNavigate();
  const customTitle = GetLabelForField("optionsNewSimulation.compraDeDivida.Label", "Compra de dívida");

  return (
    <Stack spacing={3}>
      <Stack direction='row' alignItems='center'>
        <IconButton onClick={() => navigate('/ccb/nova-simulacao')}>
          <ArrowLeftIcon sx={{ height: 24, width: 24 }} />
        </IconButton>
        <Typography fontSize='24px' fontWeight={800}>{labelConsignadoPublicoConfig}</Typography>
      </Stack>

      <OptionCards
        options={[
          {
            Icon: LiquidateIcon,
            onClick: () => navigate(`${pathNewSimulationConsignedConfig}/novo`),
            label: "Novo",
            title: GetLabelForField("optionsNewSimulation.consignadoPublico.Submenu.Novo.Title", ""),
            description: GetLabelForField("optionsNewSimulation.consignadoPublico.Submenu.Novo.Description", ""),
          },
          {
            Icon: LiquidateIcon,
            onClick: () => navigate(`${pathNewSimulationConsignedConfig}/${pathCompraDeDividaConfig}`),
            label: `Novo + ${customTitle}`,
            title: GetLabelForField("optionsNewSimulation.consignadoPublico.Submenu.Novo+.Title", ""),
            description: GetLabelForField("optionsNewSimulation.consignadoPublico.Submenu.Novo+.Description", ""),
          }
        ]}
      />
    </Stack>
  )
}
