import { useGetProductsCostsDefinition } from 'contexts/wallet/Product/BillingProductContext';
import { CostsBillingParameters } from './CostsBillingParameters';
import { Fragment, useState } from 'react';
import { toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { Drawer } from '@uy3/web-components';
import { CostsBillingParametersFormContainer } from './Drawers/CostsBillingParametersForm/CostsBillingParametersFormContainer';

export const CostsBillingParametersContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const [page, setPage] = useState(0);
    const { data: queryData, isLoading, refetch, isFetching } = useGetProductsCostsDefinition({
        page,
        size: rowsPerPage
    });

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onClose = () => setAction(undefined);

    const onChangePage = (newPage: number) => setPage(newPage);

    return (
        <Fragment>
            <Toast toast={toast} setToast={setToast} />
            <CostsBillingParameters
                {...{
                    page,
                    queryData,
                    rowsPerPage,
                    setPage: onChangePage,
                    setRowsPerPage: onChangeRowsPerPage,
                    isLoading,
                    refetch,
                    isFetching,
                    addCostsBillingParameters() {
                      setAction('addCostsBilling');
                  },
                }}
            />
            <Drawer
                {...{
                    anchor: 'right',
                    open: action === 'addCostsBilling',
                    title: 'Adicionar custos de cobrança',
                    onClose,
                    children: (
                        <CostsBillingParametersFormContainer
                            onClose={onClose}
                            refetchList={refetch}
                            setToast={setToast}
                        />
                    ),
                }}
            />
        </Fragment>
    );
};
