import { useQueryClient } from "@tanstack/react-query";
import { ApiResponseError, ToastType, handleOnError, showSuccessToast, toastState, useApiRequest } from "contexts/apiRequestContext";
import { FormProvider, useFormContext } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { useParams } from "react-router";
import { cancelCreditNoteById } from "services/creditNote";
import { validationSchemaCancelForm, defaultValuesCancelForm } from "./CancelSchema";
import { FieldValues } from 'react-hook-form';
import { RefreshProgress } from "components/RefreshProgress";
import { CancelForm } from "./CancelForm";
import { useState } from "react";
import Toast from "components/Toast/Toast";
import { useTenant } from "contexts/tenantContext";
import { CreditProductReadModel } from "services/creditProduct";
import { isZetraProduct } from "services/zetra";

type CancelFormContainerProps = {
    setPopupProps: (props: any) => void;
    navigate: () => void;
    statusCreditNote: string
    product: CreditProductReadModel;
};

export const CancelFormContainer = ({ setPopupProps, navigate, statusCreditNote, product }: CancelFormContainerProps) => {
    const { token } = useIdentity();
    const { isRootTenancy } = useTenant();
    const isZetra = isZetraProduct(product);
    const { id } = useParams();
    const { watch } = useFormContext();
    const [toast, setToast] = useState<ToastType>(toastState);
    const queryClient = useQueryClient();
    const { startRequest, endRequest, submitting } = useApiRequest();

    const enableAction = () => {
        const status = statusCreditNote?.toLowerCase(); 
        if (status === 'warranty') {
            if (isRootTenancy && isZetra) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    }

    const schema = validationSchemaCancelForm();
    const defaultValues = defaultValuesCancelForm;

    const onSubmit = async (values: FieldValues) => {
        startRequest();
        
        try 
        {
            const payload = {
                message: values?.message,
                insertBlock: values?.insertBlock,
                blockUntil: values?.blockUntil,
                rejectionReasonCode: values?.rejectionReasonCode,
                attributeTypes: values?.complianceChecklist,
            }

            await cancelCreditNoteById(payload, id!, token!);

            queryClient.invalidateQueries({
                queryKey: ["credit-note-list"],
                refetchType: 'active',
            });

            showSuccessToast("Operação cancela com sucesso!", "", setToast)
            navigate();
            setPopupProps(undefined);
        }
        catch (expetion) 
        {
            handleOnError(expetion as ApiResponseError, setToast);
            endRequest(false);
        }
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={submitting} />
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onChangeField={
                    [{
                        fieldName: "insertBlock",
                        delegate: (value: string | null, setValue: (name: any, value: any) => void) => setValue("insertBlock", value)
                    }]}
                onSubmit={onSubmit}>
                <CancelForm
                    enableAction={enableAction()}
                    setPopupProps={setPopupProps}
                    personInformation={{
                        discriminator: watch('personDiscriminator'),
                        personId: watch('personId')
                    }}
                />
            </FormProvider>
        </>
    );
};