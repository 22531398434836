import { Skeleton, Stack } from '@mui/material';
import { CardData, CardValue, NaturalPersonIcon, Typography } from '@uy3/web-components';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { activeTheme } from 'services/theme';
import { CardDataCharges } from './CardDataCharges';
import { ActionsCharges } from './ActionsCharges';
import { useNavigate } from 'react-router';
import { BillingFull } from 'services/walletManagement/billing/billing.types';
import { useBankSlipByBarCodeList } from 'contexts/wallet/bankSlip/bankSlip';
import moment from 'moment';
import { ToastType } from 'contexts/apiRequestContext';
import { RenderBarCode } from './RenderBarCode';
import { RefreshProgress } from 'components/RefreshProgress';

const theme = activeTheme();

type ViewDetailsChargesProps = {
    data: BillingFull;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    downloadPDFBankSlip: (barCode: string, walletCode: number) => void
    onClose: () => void;
    isLoadingDownload: boolean;
}

const colorMain = theme.palette.primary.main;
export const ViewDetailsCharges = ({ data, onClose, setToast, downloadPDFBankSlip, isLoadingDownload }: ViewDetailsChargesProps) => {
    const navigate = useNavigate();
    const bankSlipId = data?.bankSlip.id;
    
    const { data: bankSlipData, isLoading: isLoadingBanlSlip } = useBankSlipByBarCodeList(bankSlipId!);
    const bankSlip = bankSlipData?.bankSlip;
     
    const statusRegister = bankSlip?.statusRegisterValueDisplay; 

    const LoadingCardData = () => {
        return <>
            {[1, 2, 3, 4].map(() => {
                return <Skeleton width='100%' height='20px' />
            })}
        </>
    }

    return (
        <Stack spacing={4}>
            <CardValue
                color="primary"
                icon={<NaturalPersonIcon htmlColor={colorMain} sx={{ height: 30, width: 30 }} />}
                title="Devedor"
                description={`Nome: ${data?.payer?.name} | Convênio: ${data?.walletCode}`}
            />

            <Typography variant="md" fontWeight={600}>
                Dados da cobrança
            </Typography>

            {data !== null &&
                <CardDataCharges data={data} />}

            {!!bankSlipId && <>
                <Typography variant="md" fontWeight={600}>
                    Dados do boleto
                </Typography>
                {isLoadingBanlSlip ?
                    <LoadingCardData /> :
                    <CardData
                        listItem={[
                            { id: '1', title: "Código de barras ", value: <RenderBarCode barCode={bankSlip?.barCode!} setToast={setToast} /> },
                            { id: '2', title: "Data limite para pagamento ", value: moment(bankSlip?.overdueLimitDate + "T00:00:00").format('L') },
                            { id: '3', title: "Situação protesto ", value: bankSlip?.statusDebtCollectionValueDisplay ?? "N/D" },
                            { id: '4', title: "Situação registro ", value: statusRegister ?? "N/D" },
                            { id: '5', title: "Situação liquidação ", value: bankSlip?.statusSettlementValueDisplay ?? "N/D" },
                        ]}
                    />
                }
            </>}  
                      
            <HorizontalInfo
                title='Valor da cobrança'
                type='currency'
                value={data?.amount?.toString()}
            />
            <RefreshProgress refreshing={isLoadingDownload} />
            <ActionsCharges
                downloadPDFBankSlip={() => downloadPDFBankSlip(bankSlip?.barCode!, bankSlip?.walletCode!)}
                onClose={onClose}
        
                editBillingAction={() => navigate(`/gestao-carteira/cobrancas/${data?.id}`)}
                bankSlipId={bankSlipId}
                disableActionDownload={statusRegister?.toLowerCase() !== 'registrado'}
            />
        </Stack>
    )
}