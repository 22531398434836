import { FieldValues } from 'react-hook-form';
import { number, string } from 'yup';
import { object } from 'yup';

export const paymentAccountSchema = (currentVelues?: FieldValues) => {
    return object().shape({
        adeNumero: number()
            .typeError('Número ADE: precisa ser preenchido')
            .required('Número ADE: precisa ser preenchido'),
        codVerba: string()
            .typeError('Código verba: precisa ser preenchido')
            .required('Código verba: precisa ser preenchido'),
        consignataria: string()
            .typeError('Consignataria: precisa ser preenchido')
            .required('Consignataria: precisa ser preenchido'),
        consignatariaCodigo: number()
            .typeError('Código consignataria: precisa ser preenchido')
            .required('Código consignataria: precisa ser preenchido'),
        dataReserva: string().typeError('Data reserva: precisa ser preenchida').required('Data reserva: precisa ser preenchida'),
        pagas: number().typeError('Pagas: precisa ser preenchida').required('Pagas: precisa ser preenchida'),
        prazo: number().typeError('Prazo: precisa ser preenchido').required('Prazo: precisa ser preenchido'),
        adeIdentificador: string().typeError('Identificador: precisa ser preenchido').required('Identificador: precisa ser preenchido'),
        // servico: string().typeError('Serviço: precisa ser preenchido').required('Serviço: precisa ser preenchido'),
        // situacao: string().typeError('Stuação: precisa ser preenchido').required('Stuação: precisa ser preenchido'),
        valorParcela: string().typeError('Valor da parcela: precisa ser preenchido').required('Valor da parcela: precisa ser preenchido'),
    });
};

export const paymentAccountDefaultValues = (currentVelues?: FieldValues) => {
    return {
        adeNumero: null,
        codVerba: null,
        consignataria: null,
        consignatariaCodigo: null,
        dataReserva: null,
        pagas: null,
        prazo: null,
        adeIdentificador: null,
        servico: 'EMPRÉSTIMO',
        situacao: 'Deferida',
        valorParcela: null,
    };
};
