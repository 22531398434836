import { IOption } from 'components/CardSelect/CardSelect';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { maskCurrencyInCents } from 'helpers';
import { TooltipZetra } from '../SelectWarranty/TooltipZetra';
import { IMarginDetails } from 'services/creditNote';

export function summaryZetra(data: IZetraMarginQueryReadModel | undefined): IOption[] {
    const vinculoFuncionals = data?.result?.servidores ?? [];
    const createdAt = data?.createdAt!;

    const options: IOption[] = [];

    vinculoFuncionals.forEach((item) => {
        const margens = item?.margens ?? [];
        
        margens.forEach((margem) => {
            const vlMargemDisp = maskCurrencyInCents(margem?.valorDisponivel);
            
            options.push({
                label: `${item?.orgao} - ${margem?.descricao}`,
                description: `Matrícula: ${item.matricula} | Data da consulta: ${new Date(
                    createdAt
                ).toLocaleDateString('pt-br')} | Valor da margem: ${vlMargemDisp}`,
                value: `${margem?.codigo}-${item?.orgaoCodigo}-${margem?.valorDisponivel}`,
                disabled: false,
                //@ts-ignore
                tooltipComponent: {
                    title: <TooltipZetra values={item} />,
                    placement: 'left',
                },
            });
        });
    });

    return options;
}

export function marginDetailsZetra(data: IZetraMarginQueryReadModel | undefined): IMarginDetails[] {
    const vinculoFuncionals = data?.result?.servidores ?? [];
    const createdAt = data?.createdAt!;

    const options: IMarginDetails[] = [];

    vinculoFuncionals.forEach((item) => {
        const margens = item?.margens ?? [];
        
        margens.forEach((margem) => {
            const vlMargemDisp = maskCurrencyInCents(margem?.valorDisponivel);
            
            options.push({
                name: `${item?.orgao} - ${margem?.descricao}`,
                date: new Date(createdAt).toLocaleDateString('pt-br'), 
                employeeCode: item.matricula, 
                governmentDepartmentCode: item.orgaoCodigo,
                vlMargemDisp: vlMargemDisp, 
                //@ts-ignore
                tooltipComponent: {
                    title: <TooltipZetra values={item} />,
                    placement: 'top',
                },
            });
        });
    });

    return options;
}